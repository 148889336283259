import { MapViewerNode, V2 } from '@3ddv/dvm-internal';
import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { createPopper, Placement } from '@popperjs/core';
import { AvailabilityService } from 'src/app/shared/services';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { DvmService } from 'src/app/shared/services/dvm.service';
import { SeatPopoverComponent } from '../popovers/seat-popover/seat-popover.component';
import { SectionPopoverComponent } from '../popovers/section-popover/section-popover.component';
declare var $: any;
// import * as $ from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class Popover3dviewService {
  popoverTimerTime = 100; // ms
  popoverTimer: ReturnType<typeof setTimeout>;
  currentView: MapViewerNode['id'];
  tooltip: HTMLElement;

  constructor(
    private dvm: DvmService,
    private availableService: AvailabilityService,
    private configuration: ConfigurationService
  ) {}

  setTimer() {
    // deleting the timer prevents us from coming into component init with a timer asynchronously running
    this.deleteTimer();

    this.popoverTimer = setTimeout(() => {
      this.removeTooltip();
    }, this.popoverTimerTime);
  }

  deleteTimer() {
    if (this.popoverTimer) {
      clearTimeout(this.popoverTimer);
    }
  }

  removeTooltip() {
    // const tooltip: HTMLElement = document.querySelector('#tooltip');
    if (this.tooltip) {
      this.tooltip.removeAttribute('data-show');
    }
  }

  public showTooltip(
    node: MapViewerNode,
    tooltip: HTMLElement,
    popover: ViewContainerRef,
    container: HTMLElement
  ) {
    // VARS
    let offset!: number[];
    this.tooltip = tooltip;

    // CLEAN BEFORE SHOWING
    this.deleteTimer();
    this.currentView = node.id;
    popover.clear();

    // SECTION | SEAT POPOVER
    if (
      (node.type === 'section' || node.type === 'general_admission') &&
      this.dvm.isTopView
    ) {
      offset = [0, -10];
      this.createSectionPopover(popover, node);
    } else if (node.type === 'seat') {
      offset = [0, 6];
      this.createSeatPopover(popover, node);
    }

    // CALCULATE POSITION
    const placement: Placement = this.calculatePlacement(node, container);

    // SHOW TOOLTIP
    createPopper(node, this.tooltip, {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset,
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: [],
          },
        },
      ],
    });

    this.tooltip.setAttribute('data-show', '');
  }

  // HELPER METHODS
  private calculatePlacement(
    node: MapViewerNode,
    container: HTMLElement
  ): Placement {
    const nodeDomCenter: V2 = this.dvm.viewerService.fromSceneToDom([
        node.aabb[0],
        node.aabb[1],
      ]),
      nodeHalfHeight: number = node.aabb[3] / 2,
      nodeVerticalCenter: number = nodeDomCenter[1] + nodeHalfHeight;

    if (nodeVerticalCenter > container.offsetHeight / 2) {
      return 'top';
    } else {
      return 'bottom';
    }
  }

  private createSectionPopover(
    element: ViewContainerRef,
    node: MapViewerNode
  ): void {
    let tdcId: string = node.id;
    const componentRef: ComponentRef<SectionPopoverComponent> =
      element.createComponent(SectionPopoverComponent);

    // Si es cubs o default
    if (
      this.configuration.environment === 'chicagocubs' ||
      this.configuration.environment === 'default'
    ) {
      const gaSectorsIdArray: string[] =
          this.configuration.configuration.dvmData.gaSectorsIdArray,
        gaAdaIdArray: string[] =
          this.configuration.configuration.dvmData.gaAdaIdArray;

      // Group info for different mmc sectors to the bleacher sector
      if (gaSectorsIdArray.includes(node.id)) {
        this.dvm.viewerService.hover(gaSectorsIdArray);
        tdcId = 'S_Bleacher';
      } else if (gaAdaIdArray.includes(node.id)) {
        this.dvm.viewerService.hover(gaAdaIdArray);
        tdcId = 'S_BLCHADA';
      }
    }

    // ADAS | SECTIONS
    if (Object.keys(this.availableService.sectionsOnlyAdas).includes(tdcId)) {
      componentRef.instance.sectionData =
        this.availableService.sectionsOnlyAdas[tdcId];
    } else {
      componentRef.instance.sectionData =
        this.availableService.sectionsAvailability[tdcId];
    }

    componentRef.instance.sectionData.mmcID = node.original_id;
    componentRef.instance.tooltipData.type = node.type;
  }

  private createSeatPopover(
    element: ViewContainerRef,
    node: MapViewerNode
  ): void {
    const componentRef: ComponentRef<SeatPopoverComponent> =
      element.createComponent(SeatPopoverComponent);
    componentRef.instance.seatData =
      this.availableService.getSeatByIdFromAvailability(node.id);
  }
}
