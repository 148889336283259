import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  // INPUTS
  @Input()
  public debugMode!: boolean | undefined;
  @Input()
  public isTabsOpen: boolean = true;

  @Output() toggleTabs: EventEmitter<boolean> = new EventEmitter();

  // SERVICES
  private router: Router = inject(Router);

  // STATE
  public isVip: boolean = false;
  public activeTab: 'filters' | 'cart' = 'filters';

  // TEMPLATE VARS
  public templateVars: { title_1: string; title_2: string; button: string } = {
    title_1: 'Set your Search Options',
    title_2: 'Summary',
    button: 'Home',
  };

  public backToHome(): void {
    this.router.navigate(['/events']);
  }

  public toggleShowTabs() {
    this.toggleTabs.emit(!this.isTabsOpen);
  }
}
