import { Tutorial } from '../models';
import {
  Configuration,
  Custom,
  DvmData,
  DvmStyles,
  Theme,
} from '../models/configuration.model';

const custom: Custom = {
  paymentPage: {
    captureAddress: false,
    capturePhone: false,
  },
};

const theme: Theme = {
  'default-color': '0 0 0',
  'main-color': '0 0 0',
  'accent-color': '0 163 224',
  'secondary-color': '25 149 255 ',
  'tertiary-color': '242 246 250',
  red: '189 48 57',
  success: '86 163 67',
  'success-off-white': '236 246 232',
};

const dvmData: DvmData = {
  venueId: 'nam-us-10182-marlins',
  mapId: 'main_venuemap',
  miniMapId: 'main_venuemap_minimap',
  miniMapEnabled: false,
  gaSectorsIdArray: [],
  gaAdaIdArray: [],
};

const dvmStyles: DvmStyles = {
  'seat-available-none-fill': '25 149 255',
  'seat-available-none-stroke': '0 90 156',
  'seat-available-disabled-fill': '25 149 255',
  'seat-available-disabled-stroke': '0 90 156',
  'seat-unavailable-none': '214 214 214',
  'seat-available-pending': '230 145 56',
  'section-available-normal-fill': '25 149 255',
  'section-available-normal-stroke': '0 90 156',
  'section-available-normal-group1-fill': '255 77 77',
  'section-available-normal-group1-stroke': '255 77 77',
  'section-available-hover-fill': '0 90 156',
  'section-available-hover-stroke': '255 255 255',
  'section-selected-normal-fill': '122 64 185',
  'section-selected-normal-stroke': '122 64 185',
  'section-selected-hover-stroke': '255 255 255',
};

const tutorialDesktop: Tutorial = {
  1: {
    stepNum: 1,
    img: '/assets/img/miamimarlins/tutorial/1.jpg',
    language: 'Start by selecting the total number of seats to purchase.',
    arrowPosition: 'top',
  },
  2: {
    stepNum: 2,
    img: '/assets/img/miamimarlins/tutorial/2.jpg',
    language: 'Use the slider to set your desired price range per ticket.',
    arrowPosition: 'top',
  },
  3: {
    stepNum: 3,
    img: '/assets/img/miamimarlins/tutorial/3.jpg',
    language:
      'If necessary, select the number of wheelchair accessible seats you need. *Note that this number will be included in the total number of seats selected in step 1.',
    arrowPosition: 'top',
  },
  4: {
    stepNum: 4,
    img: '/assets/img/miamimarlins/tutorial/4.jpg',
    language:
      'Use the seat map to select the section(s) you want to sit in. Selected sections appear in purple.',
    arrowPosition: 'right',
  },
  5: {
    stepNum: 5,
    img: '/assets/img/miamimarlins/tutorial/5.jpg',
    language:
      'Hover over a section to preview a view from that section. Click “Open Seat Map” to manually select seats in that section.',
    arrowPosition: 'right',
  },
  6: {
    stepNum: 6,
    img: '/assets/img/miamimarlins/tutorial/6.jpg',
    language:
      'Selected seats appear red with a checkmark. If needed, change your selection by clicking each seat individually or hold Ctrl + click and drag to select a group of seats.',
    arrowPosition: 'right',
  },
  7: {
    stepNum: 7,
    img: '/assets/img/miamimarlins/tutorial/7.jpg',
    language:
      'Selected seats appear in the Cart. Click the map icon to go to that section in the map. Click the trash can to remove seats and make a different selection.',
    arrowPosition: 'left',
  },
  8: {
    stepNum: 8,
    img: '/assets/img/miamimarlins/tutorial/8.jpg',
    language: 'When you are ready to purchase, click “Go to Checkout.”',
    arrowPosition: 'left',
  },
  9: {
    stepNum: 9,
    img: '/assets/img/miamimarlins/tutorial/9.jpg',
    language: 'Review  your ticket information.',
    arrowPosition: 'left',
  },
  10: {
    stepNum: 10,
    img: '/assets/img/miamimarlins/tutorial/10.jpg',
    language: 'Enhance your group outing with an optional upgraded ticket.',
    arrowPosition: 'right',
  },
  11: {
    stepNum: 11,
    img: '/assets/img/miamimarlins/tutorial/11.jpg',
    language:
      'Review the details of your group ticket pricing. Click “Proceed to Payment” to complete your purchase.',
    arrowPosition: 'right',
  },
};

const tutorialMobile: Tutorial = {
  1: {
    stepNum: 1,
    img: '/assets/img/miamimarlins/tutorial/mobile/1.jpg',
    language: 'Start by selecting the total number of seats to purchase.',
    arrowPosition: 'bot',
  },
  2: {
    stepNum: 2,
    img: '/assets/img/miamimarlins/tutorial/mobile/2.jpg',
    language: 'Use the slider to set your desired price range per ticket.',
    arrowPosition: 'bot',
  },
  3: {
    stepNum: 3,
    img: '/assets/img/miamimarlins/tutorial/mobile/3.jpg',
    language:
      'If necessary, select the number of wheelchair accessible seats you need. *Note that this number will be included in the total number of seats selected in step 1.',
    arrowPosition: 'bot',
  },
  4: {
    stepNum: 4,
    img: '/assets/img/miamimarlins/tutorial/mobile/4.jpg',
    language:
      'Use the seat map to select the section(s) you want to sit in. Selected sections appear in purple.',
    arrowPosition: 'top',
  },
  5: {
    stepNum: 5,
    img: '/assets/img/miamimarlins/tutorial/mobile/12.jpg',
    language:
      'Click a section to preview a view from that section. Click “Select“ to find your seats in that particular section or click “Open Seat Map“ to manually select seats in that section.',
    arrowPosition: 'top',
  },
  6: {
    stepNum: 6,
    img: '/assets/img/miamimarlins/tutorial/mobile/5.jpg',
    language:
      'Selected seats appear red with a checkmark. If needed, change your selection by clicking each seat.',
    arrowPosition: 'top',
  },
  7: {
    stepNum: 7,
    img: '/assets/img/miamimarlins/tutorial/mobile/6.jpg',
    language:
      'Selected seats appear in the Cart. Click the map icon to go to that section in the map. Click the trash can to remove seats and make a different selection.',
    arrowPosition: 'bot',
  },
  8: {
    stepNum: 8,
    img: '/assets/img/miamimarlins/tutorial/mobile/7.jpg',
    language: 'When you are ready to purchase, click “Go to Checkout.”',
    arrowPosition: 'bot',
  },
  9: {
    stepNum: 9,
    img: '/assets/img/miamimarlins/tutorial/mobile/8.jpg',
    language: 'Review  your ticket information.',
    arrowPosition: 'bot',
  },
  10: {
    stepNum: 10,
    img: '/assets/img/miamimarlins/tutorial/mobile/9.jpg',
    language: 'Enhance your group outing with an optional upgraded ticket.',
    arrowPosition: 'top',
  },
  11: {
    stepNum: 11,
    img: '/assets/img/miamimarlins/tutorial/mobile/11.jpg',
    language:
      'Review the details of your group ticket pricing. Click “Proceed to Payment” to complete your purchase.',
    arrowPosition: 'top',
  },
};

export const MiamiMarlinsConfig: Configuration = {
  id: 'miamimarlins',
  clientName: 'Miami Marlins',
  appName: 'Miami Marlins',
  favicon: '/assets/img/miamimarlins/favicon.png',
  logo: '/assets/img/miamimarlins/logo.png',
  phone: '',
  email: 'groupsales@miamimarlins.com',
  paymentMethod: 'braintree',
  rainout: false,
  clientWebUrl: 'https://www.marlins.com/',
  clientWebText: 'www.marlins.com.',
  clientBallParkUrl:
    'https://www.mlb.com/marlins/apps/ballpark?partnerId=redirect-chc-ballparkapp',
  clientBallParkText: 'marlins.com/BallparkApp',
  mlbShopUrl:
    'https://www.mlbshopeurope.com/en/miami-marlins/t-25000926+z-73041-754230621',
  merchandiseWebUrl: '',
  rainoutInsuranceUrl: '',
  termsAndConditionsUrl:
    'https://www.mlb.com/marlins/tickets/terms-and-conditions',
  analytics: '',
  adas: true,
  custom,
  theme,
  dvmData,
  dvmStyles,
  tutorial: {
    desktop: tutorialDesktop,
    mobile: tutorialMobile,
  },
  extraCheckbox: {
    active: false,
    required: true,
    text: '',
  },
  summaryPage: {
    image1: '/assets/img/miamimarlins/logo.png',
    image2: 'url(/assets/img/miamimarlins/logo.png)',
  },
};
