<div
  *ngIf="!confirmationPage"
  id="payment"
  class="h-full bg-gray-100 overflow-y-auto">
  <!-- Back Button -->
  <div class="hidden md:block m-5 mb-3">
    <button class="btn-white" (click)="goCheckout()">
      <span class="mr-4"
        ><img src="/assets/img/arrow-left-black-icon.svg" alt=""
      /></span>
      Back
    </button>
  </div>

  <div class="w-full px-5 py-7 lg:px-10 xl:px-20 md:py-14 md:pt-0 md:mx-auto">
    <div class="mb-7 text-center md:mb-3 md:text-left">
      <h1 class="text-4xl mb-1">Payment</h1>
      <div class="flex flex-col sm:flex-row justify-around items-center mt-3">
        <app-timer
          (transactionExpired)="onTransactionExpired()"
          [createdTime]="this.transaction?.transaction.created_at"></app-timer>
        <app-okta-container
          (patronEmmiter)="oktaEventEmitter($event)"></app-okta-container>
      </div>
    </div>
    <!-- IF PATRON IS LOGGED WITH OKTA, SHOW IT -->
    <div
      *ngIf="patron"
      class="w-100 flex box-white px-6 py-4 mb-3 flex-col gap-y-7 md:flex-row">
      <!-- ACCOUNT CREDIT COLUMN-->
      <div class="flex flex-col account-credit-col w-full md:w-1/2">
        <div class="title">Account Credit</div>
        <div class="sub-title mb-2 md:mb-5">
          <b>Your account’s available credit: {{ patron.accountCredit }}</b
          ><br />
          Please select the amount you would like to use:
        </div>
        <div
          class="w-full flex flex-col gap-y-2 items-baseline md:flex-row md:items-center">
          <div class="gap-x-3 flex mr-5 justify-center items-center w-full">
            <span class="flex justify-center items-center">$</span>
            <input
              type="text"
              value="0"
              class="selector-filter flex"
              (blur)="onBlurInputCredits()"
              [(ngModel)]="paymentDetails.accountCredit.credits" />
          </div>
          <div class="flex gap-x-4">
            <input
              type="checkbox"
              name="payInFull"
              [(ngModel)]="paymentDetails.accountCredit.payFull"
              (change)="checkboxPayInFullChange($event)" />
            <span>Use all available credit</span>
          </div>
        </div>
      </div>
      <!-- DIGITAL WALLET COLUMN-->
      <div class="flex flex-col digital-wallet-col w-full md:ml-10 md:w-1/2">
        <div class="title">Payment Options</div>
        <div class="sub-title mb-5">
          <b
            >Total amount due:
            {{
              transaction?.transaction.tdc_transaction.balance
                | currency: 'USD' : 'symbol' : '1.2-2'
            }}</b
          >
          <br />
          Credit applied:
          {{ showCreditsApplies | currency: 'USD' : 'symbol' : '1.2-2' }} <br />
          Remaining balance:
          {{ substractCreditsToBalance | currency: 'USD' : 'symbol' : '1.2-2' }}
          <br />
          Please select the digital wallet card you would like to use:
        </div>
        <div>
          <select
            [(ngModel)]="paymentDetails.digitalWallet"
            (change)="onChangeDigitalWalletSelector($event.target)"
            name="digitalWallet"
            class="selector-filter w-full">
            <option [ngValue]="null">Select your Digital wallet</option>
            <option
              [ngValue]="item"
              *ngFor="let item of patron.digitalWalletList">
              {{ item.nickname }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!-- Payment Options -->
    <div class="box-white w-full px-4 py-2 xs:px-6 md:py-4 md:min-h-[704px]">
      <form #paymentForm="ngForm">
        <div class="flex flex-wrap md:h-full">
          <div
            class="md:w-1/2 md:h-auto md:flex md:flex-col md:p-5 my-2 md:my-0">
            <!-- Checkout Recap -->
            <div class="mb-6">
              <div class="px-4 py-3 bg-gray-200 rounded-lg mb-6 md:mb-8">
                <h4 class="font-bold">
                  Total amount due: ${{
                    transaction?.transaction.tdc_transaction.balance
                      | number: '1.2-2'
                  }}
                </h4>
              </div>
              <div class="w-full mb-4">
                <div
                  class="px-3 py-2 mb-2 text-xs bg-main-off-white rounded-lg flex justify-between items-center">
                  <p>Seats</p>
                  <p class="font-bold text-main">
                    ${{
                      transaction?.transaction.tdc_transaction.totalPriceSeats
                        | number: '1.2-2'
                    }}
                  </p>
                </div>
              </div>
              <div class="w-full mb-4">
                <div
                  class="px-3 py-2 mb-2 text-xs bg-main-off-white rounded-lg flex justify-between items-center">
                  <p>Per ticket fees</p>
                  <p class="font-bold text-main">
                    ${{
                      transaction?.transaction.tdc_transaction.serviceCharges
                        | number: '1.2-2'
                    }}
                  </p>
                </div>
              </div>
              <div
                class="w-full mb-4"
                *ngIf="transaction?.transaction.tdc_transaction.amusementTax">
                <div
                  class="px-3 py-2 mb-2 text-xs bg-main-off-white rounded-lg flex justify-between items-center">
                  <p>Amusement tax (12% of seat cost):</p>
                  <p class="font-bold text-main">
                    ${{
                      transaction?.transaction.tdc_transaction.amusementTax
                        | number: '1.2-2'
                    }}
                  </p>
                </div>
              </div>
              <div
                class="w-full mb-4"
                *ngIf="
                  transaction?.transaction.tdc_transaction.hasInsuranceRainOut
                ">
                <div
                  class="px-3 py-2 mb-2 text-xs bg-main-off-white rounded-lg flex justify-between items-center">
                  <p>Rainout insurance</p>
                  <p class="font-bold text-main">
                    ${{
                      transaction?.transaction.tdc_transaction
                        .insueranceRainOutPrice | number: '1.2-2'
                    }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Terms&Conditions -->
            <div class="mb-4 md:mb-2">
              <div class="flex items-center mb-1">
                <input
                  type="checkbox"
                  class="w-4 h-4"
                  placeholder=""
                  id="marketing"
                  name="marketing"
                  [value]="true"
                  [(ngModel)]="paymentDetails.acceptedTerms" />
                <label
                  for="marketing"
                  class="ml-2 text-xs text-gray-600 font-bold"
                  >I agree to the following</label
                >
              </div>
              <div class="h-32 italic text-gray-600 overflow-y-auto">
                <p class="mb-3">
                  As part of your ticket purchase, you are required to
                  acknowledge the
                  <a
                    [href]="
                      configurationService.configuration.termsAndConditionsUrl
                    "
                    target="_blank"
                    >{{ configurationService.configuration.clientName }} Ticket
                    Terms and conditions</a
                  >
                  The Terms and conditions of your ticket and policies
                  applicable to its use are updated to address circumstances
                  related to COVID-19, health and safety, and seating pods. By
                  clicking the Place Order button, you agree to accept all
                  <a
                    [href]="
                      configurationService.configuration.termsAndConditionsUrl
                    "
                    target="_blank"
                    >Terms and Conditions</a
                  >
                  applicable to ticket purchase, and all related policies.
                  Further, due to the COVID-19 pandemic, scheduling may be
                  impacted and games may be canceled, rescheduled, postponed or
                  games may be held with limited capacity. Games may also have
                  restricted capacity that prevents the use of specific seats.
                  In the event that the game(s) for which the ticket(s) is
                  issued does not occur or you are unable to use the specific
                  seat(s) purchased, a refund or credit will be issued according
                  to the refund policy available at
                  <a
                    [href]="configurationService.configuration.clientWebUrl"
                    target="_blank"
                    >{{ configurationService.configuration.clientWebText }}</a
                  >
                </p>
                <p class="mb-3">
                  By selecting the Place Order button you agree to purchase the
                  tickets selected, to accept any restrictions noted, and
                  understand you are responsible for any payment needed to
                  complete the purchase. Upon completing your purchase by
                  selecting the Place Order button, you acknowledge and agree
                  your credit card will be charged. All sales are final. All
                  tickets are subject to the
                  <a
                    [href]="
                      configurationService.configuration.termsAndConditionsUrl
                    "
                    target="_blank"
                    >Terms and Conditions</a
                  >
                </p>
                <p class="mb-3">
                  In connection with your purchase, you may receive customer
                  service-related emails from the
                  {{ configurationService.configuration.clientName }}, including
                  optional surveys regarding your gameday experience. You also
                  may receive ticket-related commercial email from the
                  {{ configurationService.configuration.clientName }}. You may
                  unsubscribe from ticket-related commercial emails at any time.
                </p>
              </div>
            </div>
            <!-- Extra Checkbox if exist in cfg client -->
            <div
              *ngIf="
                configurationService.configuration.extraCheckbox &&
                configurationService.configuration.extraCheckbox.active
              "
              class="mt-2 mb-4 md:mb-2">
              <div class="flex items-center mb-1">
                <input
                  type="checkbox"
                  class="w-4 h-4"
                  placeholder=""
                  id="extra-checkbox"
                  name="extra-checkbox"
                  [value]="true"
                  [(ngModel)]="paymentDetails.extraCheckbox" />
                <label
                  for="extra-checkbox"
                  class="ml-2 text-xs text-gray-600 font-bold"
                  >I agree to the following</label
                >
              </div>
              <div
                class="h-32 italic text-gray-600 overflow-y-auto"
                [innerHTML]="
                  configurationService.configuration.extraCheckbox.text
                "></div>
            </div>
          </div>

          <!-- Guest/Customer data -->
          <div id="braintree" class="w-full md:w-1/2 md:p-5 mb-2 md:mb-0">
            <div class="mb-2 sm:mb-0">
              <div class="sm:mb-4 sm:flex">
                <div class="w-full">
                  <label class="label" for="first-name">First name</label>
                  <input
                    type="text"
                    class="input"
                    placeholder="First name"
                    id="first-name"
                    name="first-name"
                    [(ngModel)]="paymentDetails.firstName"
                    [readOnly]="patron" />
                </div>
                <div class="w-full sm:ml-6">
                  <label class="label" for="last-name">Last name</label>
                  <input
                    type="text"
                    class="input"
                    placeholder="Last name"
                    id="last-name"
                    name="last-name"
                    [(ngModel)]="paymentDetails.lastName"
                    [readOnly]="patron" />
                </div>
              </div>
              @if (configuration.custom.paymentPage.captureAddress) {
                <!-- Address and Postal Code-->
                <div class="sm:mb-4 sm:flex">
                  <div class="w-full">
                    <label class="label" for="first-name">Address</label>
                    <input
                      type="text"
                      class="input"
                      placeholder="Line 1"
                      id="address"
                      name="address"
                      [(ngModel)]="paymentDetails.address.address1"
                      [readOnly]="patron" />
                  </div>
                  <div class="w-full my-3 sm:my-0 sm:ml-6">
                    <label class="label" for="last-name">Postal Code</label>
                    <input
                      type="text"
                      class="input"
                      placeholder="Postal Code"
                      id="postalCode"
                      name="postalCode"
                      [(ngModel)]="paymentDetails.address.postalCode"
                      [readOnly]="patron" />
                  </div>
                </div>
                <!-- CITY, SUB AND COUNTRY-->
                <div class="sm:mb-4 sm:flex">
                  <div class="w-full">
                    <label class="label" for="Country">Country</label>
                    <select
                      type="text"
                      [disabled]="patron !== null"
                      class="input bg-white"
                      (ngModelChange)="onChangeCountry($event)"
                      id="countryCode"
                      name="countryCode"
                      [(ngModel)]="paymentDetails.address.countryCode">
                      <option
                        [value]="item.icaoCode"
                        *ngFor="let item of countryList">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <div class="w-full my-3 sm:my-0 sm:ml-6">
                    <label class="label" for="last-name"
                      >Sub Country Code</label
                    >
                    <select
                      type="text"
                      class="input bg-white"
                      [disabled]="patron !== null"
                      id="SubCountryCode"
                      name="SubCountryCode"
                      [(ngModel)]="paymentDetails.address.subCountryCode">
                      <option
                        [value]="item.sub_country_code"
                        *ngFor="let item of subCountryList">
                        {{ item.sub_country_name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="sm:mb-4">
                  <div class="w-full my-3 sm:my-0">
                    <label class="label" for="city">City</label>
                    <input
                      type="text"
                      class="input"
                      placeholder="Boston"
                      id="city"
                      name="city"
                      [(ngModel)]="paymentDetails.address.city"
                      [readOnly]="patron" />
                  </div>
                </div>
              }
              <div class="mb-3 md:mb-4">
                <div class="w-full">
                  <label class="label" for="email">Email</label>
                  <input
                    type="email"
                    class="input"
                    placeholder="example@gmail.com"
                    id="email"
                    name="email"
                    [(ngModel)]="paymentDetails.email"
                    email
                    [readOnly]="patron" />
                </div>
                <p class="mt-1">
                  *Tickets will be delivered to this email in the MLB Ballpark
                  App.
                  <button
                    class="btn-icon w-4 h-4 text-white inline-flex bg-main rounded-full"
                    (click)="infoEmailModal()">
                    i
                  </button>
                </p>
              </div>
            </div>

            <div
              id="dropin-container"
              [class.hidden]="paymentDetails.digitalWallet !== null"></div>
            <button
              id="braintree-button"
              class="btn-main bg-main-light ml-auto"
              [class.hidden]="paymentDetails.digitalWallet !== null">
              Use this card
            </button>
            <div></div>
          </div>
        </div>
      </form>
    </div>

    <div class="mt-7 flex justify-center md:justify-end">
      <button
        data-cy="pay-button"
        class="btn-black disabled:bg-gray-600"
        (click)="checkout()">
        Checkout
        <span class="ml-4"
          ><img src="/assets/img/arrow-right-white-icon.svg" alt=""
        /></span>
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="confirmationPage"
  id="confirmation"
  class="h-full flex justify-center items-center bg-success-off-white px-8 py-10 md:px-20 md:py-14">
  <div
    class="box-white py-12 text-center w-full h-auto px-14 md:w-128 md:h-72 md:px-20">
    <img
      class="m-auto"
      src="/assets/img/checkmark-green-icon.svg"
      alt="Green checkmark icon" />
    <h2 class="my-4">Order Placed!</h2>
    <h4 class="font-normal text-gray-500">
      Your order was placed successfully.
    </h4>
  </div>
</div>
