import { MapViewerNode } from '@3ddv/dvm-internal';
import { Component, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import { Subscription } from 'rxjs';
import { AvailabilityService, DvmService } from 'src/app/shared/services';
import { ConfigurationService } from '../../../../shared/services/configuration.service';

@Component({
  selector: 'app-map-controls',
  templateUrl: './map-controls.component.html',
  styleUrl: './map-controls.component.scss',
})
export class MapControlsComponent implements OnInit, OnDestroy {
  // SERVICES
  private dvmService: DvmService = inject(DvmService);
  private configurationService: ConfigurationService =
    inject(ConfigurationService);
  private availabilityService: AvailabilityService =
    inject(AvailabilityService);

  // STATE
  public isTopView: Signal<boolean> = this.dvmService.isTopView;
  public isView3d: Signal<boolean> = this.dvmService.is3dView;
  public sectionLabelPlaceholder = 'Loading';
  private neighborsData: { [x: string]: { [x: string]: string } };
  public previousAvailableSectionId: string | null = null;
  public nextAvailableSectionId: string | null = null;
  private currentSectionNode: MapViewerNode | null;

  // SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  // LIFECYCLE HOOKS
  ngOnInit(): void {
    import(
      `../../../../../assets/data/neighbours/${this.configurationService.configuration.id}.json`
    ).then(neighborsData => {
      this.neighborsData = neighborsData['default'];
    });

    this.initComponent();
  }

  ngOnDestroy(): void {
    this.destroyComponent();
  }

  get sectionLabel(): string {
    const currentSectionMmcId = this.currentSectionNode?.original_id;
    return currentSectionMmcId
      ? currentSectionMmcId.split('_')[1]
      : this.sectionLabelPlaceholder;
  }

  //METHODS
  public backToTopView(): void {
    this.dvmService.openSectionMap().subscribe();
  }

  public close3dView(): void {
    this.dvmService.close3dView();
  }

  public changeSection(goTo: 'prev' | 'next') {
    const tdcId =
      goTo === 'prev'
        ? this.previousAvailableSectionId
        : this.nextAvailableSectionId;

    if (!tdcId) {
      return;
    }

    const openSeatMap$ = this.dvmService.openSeatMap(tdcId).subscribe(() => {
      openSeatMap$.unsubscribe();
    });
  }

  private destroyComponent(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private initComponent(): void {
    this.dvmService.viewerService.waitInitialize().subscribe({
      next: () => {
        const loadSuccessSub = this.dvmService.viewerService
          .getObservable('load_success')
          .subscribe(() => {
            const mapId = this.dvmService.viewerService.getMapId();
            this.updateCurrentSectionNode(mapId);

            if (this.isTopView()) {
              return;
            }

            const currentSectionTdcId = this.currentSectionNode?.id;

            this.setAvailableSection('prev', currentSectionTdcId);
            this.setAvailableSection('next', currentSectionTdcId);
          });

        this.subscriptions.push(loadSuccessSub);
      },
    });
  }

  private updateCurrentSectionNode(sectionMapId: string): void {
    if (this.isTopView()) {
      this.currentSectionNode = null;
    } else {
      const node = this.dvmService.viewerService.getNodeById(sectionMapId);
      this.currentSectionNode = node || null;
    }
  }

  private setAvailableSection(
    type: 'prev' | 'next',
    currentSectionTdcId: string
  ): void {
    const noNeighborValue = 'none';
    const neighborKey = type === 'prev' ? 'l' : 'r';

    const currentSectionMmcId =
      this.dvmService.translateSectionTdcId(currentSectionTdcId);
    const neighbor = this.neighborsData?.[currentSectionMmcId];
    const neighborSectionMmcId = neighbor[neighborKey];
    const neighborSectionTdcId =
      this.dvmService.translateSectionMmcId(neighborSectionMmcId);
    const hasAvailability =
      !!this.availabilityService.sectionsAvailability[neighborSectionTdcId];

    if (neighborSectionTdcId && hasAvailability) {
      if (type === 'prev') {
        this.previousAvailableSectionId = neighborSectionTdcId;
      }
      if (type === 'next') {
        this.nextAvailableSectionId = neighborSectionTdcId;
      }
      return;
    }

    if (neighborSectionMmcId === noNeighborValue) {
      if (type === 'prev') {
        this.previousAvailableSectionId = null;
      }
      if (type === 'next') {
        this.nextAvailableSectionId = null;
      }
      return;
    }

    return this.setAvailableSection(type, neighborSectionTdcId);
  }
}
