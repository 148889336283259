<div class="hidden lg:block h-full relative overflow-x-visible">
  <div
    class="absolute z-30 top-0 min-w-[480px] h-[calc(100vh-94px)] overflow-y-auto no-scrollbar px-4 pt-4 3xl:py-4 w-full text-white">
    <!-- BUTTONS -->
    <div class="mb-4">
      <!-- BACK BUTTON -->
      <button
        data-cy="back-home-button"
        (click)="backToHome()"
        class="flex flex-row justify-evenly items-center w-28 h-10 rounded-3xl text-white bg-gray-700/50 hover:bg-gray-800/70 transition-colors duration-200 ease-in origin-center">
        <!-- Icon -->
        <i>
          <img src="/assets/img/bx-home-alt.svg" alt="home icon" />
        </i>
        <!-- Text -->
        <span> Home </span>
      </button>

      <!-- VIP BUTTON -->
      <!-- TO DO -->
    </div>

    <!-- SEARCH OPTIONS -->
    <div>
      <!-- TITLE -->
      <h2>
        {{ templateVars.title_1 }}
      </h2>

      <!-- FILTERS -->
      <app-filters [debugMode]="debugMode" />

      <!-- CART -->
      <app-cart />
    </div>

    <!-- SUMMARY -->
    <div>
      <h2>
        {{ templateVars.title_2 }}
      </h2>

      <app-total />
    </div>
  </div>
</div>

<!-- MOBILE -->
<div class="block lg:hidden h-full relative">
  <div
    data-cy="toggle-map-full-screen"
    (click)="toggleShowTabs()"
    class="mx-auto p-2 absolute -top-8 left-1/2 duration-700 transform -translate-x-1/2 cursor-pointer">
    <img
      src="/assets/img/arrow-down-white-mobile-icon.svg"
      [ngClass]="!isTabsOpen ? 'transform rotate-180' : ''"
      alt="Arrow down icon" />
  </div>
  <!-- Tabs -->
  <div>
    <!-- h-12 same height as app-sidebar wrapper -->
    <ul class="h-12 flex text-sm text-gray-400 border-gray-100 bg-white">
      <li class="flex-1 w-1/2">
        <button
          id="filters-tab"
          data-cy="mobile-filters-tab"
          class="w-full px-4 py-3 font-bold border-b-3"
          [ngClass]="{ 'text-black border-red': activeTab === 'filters' }"
          (click)="activeTab = 'filters'">
          How many Tickets?
        </button>
      </li>
      <li class="flex-1 w-1/2">
        <button
          id="cart-tab"
          data-cy="mobile-cart-tab"
          class="w-full px-4 py-3 font-bold border-b-3"
          [ngClass]="{ 'text-black border-main': activeTab === 'cart' }"
          (click)="activeTab = 'cart'">
          Your Seats
        </button>
      </li>
    </ul>
  </div>

  <!-- Tabs Content -->
  <!-- 3rem same height as app-sidebar wrapper -->
  <div class="bg-white overflow-y-auto h-[calc(100%-3rem)]">
    <div
      id="filters"
      class="h-full"
      [ngClass]="activeTab === 'filters' ? 'block' : 'hidden'">
      <!--* FILTERS -->
      <app-filters></app-filters>
    </div>
    <div
      id="cart"
      class="h-full"
      [ngClass]="activeTab === 'cart' ? 'block' : 'hidden'">
      <!--* CART -->
      <app-cart />

      <section class="px-4 py-2">
        <h4 class="font-bold text-center my-4">Price Balance</h4>
        <app-total />
      </section>
    </div>
  </div>
</div>
