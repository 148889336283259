<div
  data-cy="pagination"
  class="pagination"
  *ngIf="total_items_in_db > items_per_page">
  <div class="pagination-btns">
    <div class="aside-page mr-11">
      <button
        data-cy="first-page-button"
        type="button"
        (click)="goto(1)"
        [ngClass]="{ disabled: pagination.current === 1 }">
        1
      </button>
    </div>
    <div>
      <ul class="quickpages">
        <li [ngClass]="{ disabled: pagination.current === 1 }">
          <button
            data-cy="prev-page-button"
            type="button"
            (click)="goto(pagination.previous)">
            <i class="ti ti-angle-left"></i>
          </button>
        </li>
        <li *ngFor="let i of pagination.quickpages.previous">
          <button
            [ngClass]="{
              active: i === pagination.current,
              disabled: pagination.last === pagination.current,
            }"
            type="button"
            (click)="goto(i)">
            {{ i }}
          </button>
        </li>
        <li class="current">
          <button type="button">
            {{ pagination.current }}
          </button>
        </li>
        <li
          *ngFor="let i of pagination.quickpages.next"
          class="hidden sm:block">
          <button
            [ngClass]="{
              active: i === pagination.current,
              disabled: pagination.last === pagination.current,
            }"
            type="button"
            (click)="goto(i)">
            {{ i }}
          </button>
        </li>
        <li [ngClass]="{ disabled: pagination.last === pagination.current }">
          <button
            data-cy="next-page-button"
            type="button"
            (click)="goto(pagination.next)">
            <i class="ti ti-angle-right"></i>
          </button>
        </li>
      </ul>
    </div>
    <div class="aside-page last ml-0 sm:ml-11">
      <button
        data-cy="last-page-button"
        type="button"
        (click)="goto(pagination.last)"
        [ngClass]="{ disabled: pagination.last === pagination.current }">
        {{ pagination.last }}
      </button>
    </div>
  </div>
</div>
