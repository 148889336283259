<div class="h-full relative">
  <!-- Topview Map -->
  <div id="map-container" class="h-full"></div>

  <!-- 3D View -->
  <div
    id="viewer3d-container"
    class="w-full h-full absolute top-0 left-0"
    [ngClass]="{ invisible: !is3dOpen }"></div>

  <!-- Overlay -->
  <div
    class="h-full absolute top-0 left-0 pointer-events-none"
    [ngClass]="[
      !isSectionMap && !is3dOpen ? 'invisible' : '',
      isMobile ? 'w-full p-4 ml-0' : 'map-overlay p-7',
    ]">
    <button
      *ngIf="!isMobile"
      class="btn-white absolute top-7 left-7 flex justify-center items-center pointer-events-auto"
      (click)="back()">
      <span class="mr-4"
        ><img src="/assets/img/arrow-left-black-icon.svg" alt=""
      /></span>
      Back
    </button>

    <!-- Neighbors -->
    <div
      *ngIf="!is3dOpen"
      class="absolute left-1/2 transform -translate-x-1/2 flex justify-center items-center pointer-events-auto"
      [ngClass]="isMobile ? 'mx-auto top-4' : 'mx-3 top-7'">
      <button
        *ngIf="
          neighbors &&
          neighbors[currentSection] &&
          neighbors[currentSection]['l'] &&
          neighbors[currentSection]['l'] !== 'none'
        "
        (click)="changeSection(false)"
        class="mx-1"
        [ngClass]="isMobile ? 'btn-icon w-8 h-8 bg-white' : 'btn-white'">
        <span [ngClass]="isMobile ? 'mr-0' : 'mr-3'"
          ><img src="/assets/img/arrow-left-black-icon.svg" alt=""
        /></span>
        <span *ngIf="!isMobile">Previous section</span>
      </button>
      <div
        *ngIf="currentSection"
        class="btn-white mx-1 flex justify-center items-center"
        [ngClass]="{ 'h-8': isMobile }">
        Section
        <span class="ml-2 font-bold">{{ currentSection.split('_')[1] }}</span>
      </div>
      <button
        *ngIf="
          neighbors &&
          neighbors[currentSection] &&
          neighbors[currentSection]['r'] &&
          neighbors[currentSection]['r'] !== 'none'
        "
        (click)="changeSection(true)"
        class="mx-1"
        [ngClass]="isMobile ? 'btn-icon w-8 h-8 bg-white' : 'btn-white'">
        <span *ngIf="!isMobile">Next section</span>
        <span [ngClass]="isMobile ? 'ml-0' : 'ml-3'"
          ><img
            src="/assets/img/arrow-left-black-icon.svg"
            alt=""
            class="transform rotate-180"
        /></span>
      </button>
    </div>

    <!-- Mini Map -->
    <div
      [hidden]="
        is3dOpen ||
        !configurationService.configuration.dvmData.miniMapId ||
        !configurationService.configuration.dvmData.miniMapEnabled
      "
      (click)="back()"
      class="p-1 absolute bg-gray-800 bg-opacity-70 pointer-events-auto"
      [ngClass]="
        isMobile
          ? 'w-24 h-16 right-3 bottom-3 rounded'
          : 'w-56 h-36 right-10 bottom-10 rounded-lg'
      ">
      <div id="mini-map-container" class="h-full pointer-events-none"></div>
    </div>
  </div>
</div>
