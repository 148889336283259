<figure
  role="alert"
  class="relative z-30 w-full bg-black bg-opacity-90 outlined-text text-white p-4 rounded-2xl font-medium flex flex-col items-start text-xs">
  <figcaption
    class="text-gray-500 pb-2 font-bold w-full flex justify-between items-center">
    <div>MULTIPLE SELECTION</div>
    <div (click)="hide()" class="cursor-pointer">
      <img
        class="h-3"
        src="/assets/img/close.svg"
        alt="control key"
        aria-label="Control key" />
    </div>
  </figcaption>

  <div>
    <div class="mb-2">
      <span>You can press the key</span>
      <img
        class="inline h-4 ml-2"
        src="/assets/img/control-key.svg"
        alt="control key"
        aria-label="Control key" />
    </div>

    <div>
      <span> and left click</span>
      <img
        class="mouse-left-click-icon inline h-6 -mt-1 mx-1"
        src="/assets/img/mouse-left-click.svg"
        alt="mouse left click"
        aria-label="Mouse left click" />
      <span>while</span>
    </div>

    <div class="my-2">
      <span class="tracking-wide"> dragging your mouse to </span>
    </div>

    <div>
      <span class="tracking-wide"> select multiple chairs. </span>
    </div>
  </div>
</figure>
