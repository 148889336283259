import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Component, Input, ViewChild, Output, NgModule } from '@angular/core';
import { Observable, from, AsyncSubject, Subject, timer } from 'rxjs';
import { loadModule } from '@3ddv/dvm-internal';
import { debounce } from 'rxjs/operators';
const _c0 = ["container"];
const _c1 = ["main"];
const _c2 = ["*"];
const _c3 = "[_nghost-%COMP%]{height:100%;border:0;padding:0;margin:0}.ngx-dvm-container-root[_ngcontent-%COMP%]{position:relative;width:100%;border:0;padding:0;margin:0}.ngx-dvm-container[_ngcontent-%COMP%]{position:absolute;width:100%;height:100%;border:0;padding:0;margin:0}.ngx-dvm-container-content[_ngcontent-%COMP%]{pointer-events:none}";
function loadScript(url) {
  return new Promise((resolve, reject) => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'application/javascript';
    script.async = true;
    script.defer = true;
    script.charset = 'utf-8';
    script.onload = () => {
      head.removeChild(script);
      resolve();
    };
    script.onerror = () => {
      reject(new Error(url + ' not found'));
    };
    script.src = url;
    head.appendChild(script);
  });
}
let promiseMapViewer = null;
let promiseViewer3d = null;
const localMapViewerUrl = 'https://local.3ddigitalvenue.com:8080/build/debug/viewer.js';
const localViewer3dUrl = 'https://local.3ddigitalvenue.com:8081/build/debug/viewer3d.js';
function fakeLoadMapViewerModule(input) {
  if (!promiseMapViewer) {
    promiseMapViewer = loadScript(localMapViewerUrl);
  }
  return promiseMapViewer.then(() => {
    const viewer = new window.D2M.Viewer(input);
    return viewer.init();
  });
}
function fakeLoadViewer3dModule(input) {
  if (!promiseViewer3d) {
    promiseViewer3d = loadScript(localViewer3dUrl);
  }
  return promiseViewer3d.then(() => {
    const viewer3d = new window.DVM.Viewer3d(input);
    return viewer3d.init();
  });
}
const useFakeLoadModule = false;
// const useFakeLoadModule = true; // false
let serviceIdCounter = 1;
const mapViewers = new Map();
const viewers3d = new Map();
function generateServiceId() {
  return `${serviceIdCounter++}`;
}
function registerMapViewer(instance, id) {
  if (mapViewers.has(id)) {
    throw new Error('Id already in use');
  }
  mapViewers.set(id, instance);
}
function registerViewer3d(instance, id) {
  if (viewers3d.has(id)) {
    new Error('Id already in use');
  }
  viewers3d.set(id, instance);
}
class DvmService {
  constructor(zone) {
    this.zone = zone;
  }
  getMapViewerServiceList() {
    return Array.from(mapViewers.values());
  }
  getMapViewerServiceById(id) {
    return mapViewers.get(id) ?? null;
  }
  getViewer3dServiceList() {
    return Array.from(viewers3d.values());
  }
  getViewer3dServiceById(id) {
    return viewers3d.get(id) ?? null;
  }
  loadMapViewerModule(input) {
    return this.zone.runOutsideAngular(() => {
      if (useFakeLoadModule) {
        return new Observable(subscriber => {
          fakeLoadMapViewerModule(input).then(viewer => {
            subscriber.next(viewer);
            subscriber.complete();
          });
        });
      } else {
        return new Observable(subscriber => {
          loadModule('map_viewer', input).then(viewer => {
            subscriber.next(viewer);
            subscriber.complete();
          });
        });
      }
    });
  }
  loadViewer3dModule(input) {
    return this.zone.runOutsideAngular(() => {
      if (useFakeLoadModule) {
        return new Observable(subscriber => {
          fakeLoadViewer3dModule(input).then(viewer => {
            subscriber.next(viewer);
            subscriber.complete();
          });
        });
      } else {
        return new Observable(subscriber => {
          loadModule('3d_viewer', input).then(viewer => {
            subscriber.next(viewer);
            subscriber.complete();
          });
        });
      }
    });
  }
  static {
    this.ɵfac = function DvmService_Factory(t) {
      return new (t || DvmService)(i0.ɵɵinject(i0.NgZone));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DvmService,
      factory: DvmService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DvmService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }];
  }, null);
})();
class ApiSharedFlags {
  /**
   * Specifies if `hover=true` must be marked automatically when the mouse is over a node. Leaving this to `false`
   * might be useful when multiple selection is desired using methods like `getNeighbors`. In this case a manual
   * management should be done using the hover method.
   *
   * Take into account that `hover`, `enter` and `leave` callbacks will still be triggered, even if `automatic_hover`
   * is `false`.
   *
   * - If `true`: When the mouse is over an entity, the hover will be marked, and their corresponding styles will be used.
   * - If `false`: The nodes will not be marked as hover (the corresponding callbacks will be still triggered).
   * The hover method has to be used manually.
   */
  get automatic_hover() {
    return this.zone.runOutsideAngular(() => this.viewer.flags.automatic_hover);
  }
  set automatic_hover(value) {
    this.zone.runOutsideAngular(() => this.viewer.flags.automatic_hover = value);
  }
  /**
   * Specifies if a click on an entity causes an automatic selection (in case that its state is available) or automatic deselection (in case that its state is selected).
   *
   * - If `true`: When there is a click on an entity with `available` state, it will change its state to `selected`.
   * When there is a `click` on an entity with `selected` state, it will change its state to `available`. The
   * corresponding callbacks of `click`, `selected` or `unselected` will be triggered. This is the recommended
   * method for simple integrations where you can avoid using `click` callbacks.
   * - If `false`: Anything will be automatically selected/unselected, and it will be up to the editor-app to
   * use the corresponding available callbacks to define the desired logic. This is the recommended method
   * for a more complex integration as you will have full control of the interaction.
   */
  get automatic_selection() {
    return this.zone.runOutsideAngular(() => this.viewer.flags.automatic_selection);
  }
  set automatic_selection(value) {
    this.zone.runOutsideAngular(() => this.viewer.flags.automatic_selection = value);
  }
  /**
   * Specifies the rescaling mode of the map viewer.
   *
   * - If `true`: The map viewer will take up the 100% of the container width, and the height
   * will be computed multiplying the width by the aspect_ratio. For instance, if aspect_ratio = 0.5,
   * the height will be 500px when the Viewer container has width of 1000px.
   * - If `false`: The Viewer will take up the 100% of the container width and height. It is important
   * to ensure that the container has some height, or it will be set as 0px. aspect_ratio will be ignored.
   */
  get fixed_aspect_ratio() {
    return this.zone.runOutsideAngular(() => this.viewer.flags.fixed_aspect_ratio);
  }
  set fixed_aspect_ratio(value) {
    this.zone.runOutsideAngular(() => this.viewer.flags.fixed_aspect_ratio = value);
  }
  /**
   * Specifies the recompute mode for the camera upper limit (minimum scaling_factor when it is far):
   *
   * - If `true`: Once the first map limit is reached (for instance up/down limits for wider Viewers) the camera
   * will not go further anymore, allowing that that camera can still move to the opposite axis.
   * - If `false:` The camera will be adjusted to the last limit, adding additional vertical and horizontal stripes
   * to the map.
   */
  get max_zoom_on_first_limit() {
    return this.zone.runOutsideAngular(() => this.viewer.flags.max_zoom_on_first_limit);
  }
  set max_zoom_on_first_limit(value) {
    this.zone.runOutsideAngular(() => this.viewer.flags.max_zoom_on_first_limit = value);
  }
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
}
let ApiCoreFlags$1 = class ApiCoreFlags extends ApiSharedFlags {
  /**
   * Enables or disables panning with mouse or touch.
   *
   * - If `true`: allows panning with mouse or touch.
   * - If `false`: disables panning on the map when using mouse or touch. You still can move with
   * interface buttons or other methods.
   */
  get panning() {
    return this.zone.runOutsideAngular(() => this.viewer.flags.panning);
  }
  set panning(value) {
    this.zone.runOutsideAngular(() => this.viewer.flags.panning = value);
  }
  /**
   *  Enables or disables zooming with mouse or touch.
   *
   * - If `true`: allows zooming on the map with mouse scroll or pinch.
   * - If `false`: disables zooming on the map with scroll of pinch. You still can zoom in and
   * out with interface buttons or other methods. Disabling it will allow scrolling on the page over the map.
   */
  get zooming() {
    return this.zone.runOutsideAngular(() => this.viewer.flags.zooming);
  }
  set zooming(value) {
    this.zone.runOutsideAngular(() => this.viewer.flags.zooming = value);
  }
  /**
   * Specifies the map behavior when there is a rescale.
   *
   * - If `true`: The `scaling_factor` will be adjusted so the view is not modified when the viewport does.
   *   This may cause that the limits where the seat layer is shown or hide are reached.
   * - If `false`: The `scaling_factor` will remain the same when there is a resize, so the smaller the viewport
   *   of the Viewer is, less map content will be seen, and vice versa.
   */
  get variable_zoom_on_resize() {
    return this.zone.runOutsideAngular(() => this.viewer.flags.variable_zoom_on_resize);
  }
  set variable_zoom_on_resize(value) {
    this.zone.runOutsideAngular(() => this.viewer.flags.variable_zoom_on_resize = value);
  }
  /**
   * By default, `scaling_factor` is preserved while contained between `min_scaling_factor` and `max_scaling_factor`.
   * If the viewport changes, `min_scaling_factor` might change, and might force `scaling_factor` to change if it goes
   * off range. If `min_scaling_factor` changes again, the map could stay zoomed.
   *
   * When this flag is `true`, if `scaling_factor` is equal to
   * `min_scaling_factor`, and `min_scaling_factor` changes, `scaling_factor` will stick to it.
   *
   * - **If `true`**: `scaling_factor` will be preserved when it is within range, except when it is equal
   *   to `min_scaling_factor`, where it will stick to it.
   * - **If `false`**: `scaling_factor` will be preserved when it is within range.
   *
   * `false` by default.
   */
  get preserve_min_scaling_factor() {
    return this.zone.runOutsideAngular(() => this.viewer.flags.preserve_min_scaling_factor);
  }
  set preserve_min_scaling_factor(value) {
    this.zone.runOutsideAngular(() => this.viewer.flags.preserve_min_scaling_factor = value);
  }
  /**
   * Requires two fingers to allow panning the map with touch devices.
   */
  get panning_with_two_fingers() {
    return this.zone.runOutsideAngular(() => this.viewer.flags.panning_with_two_fingers);
  }
  set panning_with_two_fingers(value) {
    this.zone.runOutsideAngular(() => this.viewer.flags.panning_with_two_fingers = value);
  }
  /**
   * Requires ctrl/super key to zoom. It will allow to scroll the page.
   */
  get scroll_with_mod_key() {
    return this.zone.runOutsideAngular(() => this.viewer.flags.scroll_with_mod_key);
  }
  set scroll_with_mod_key(value) {
    this.zone.runOutsideAngular(() => this.viewer.flags.scroll_with_mod_key = value);
  }
  constructor(service, zone, viewer) {
    super(service, zone, viewer);
  }
};
class NavigableMinimapCoreFlags extends ApiSharedFlags {
  constructor(service, zone, minimap) {
    super(service, zone, minimap);
  }
}
class ApiCoreLayers {
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
    this.flags = new ApiCoreLayersFlags(service, zone, viewer);
  }
  /**
   * Returns a list of layer ids that forms the current map
   */
  getLayers() {
    return this.zone.runOutsideAngular(() => this.viewer.layers.getLayers());
  }
  /**
   * Sets the considered main layer. `automatic_control_layers` should be set to `false`.
   * @param layerId
   */
  setMainLayer(layerId) {
    return this.zone.runOutsideAngular(() => this.viewer.layers.setMainLayer(layerId));
  }
  /**
   * Shows a layer if it was hidden. `automatic_control_layers` should be set to `false`.
   * @param layerId
   * @param time
   */
  showLayer(layerId, time) {
    return this.zone.runOutsideAngular(() => this.viewer.layers.showLayer(layerId, time));
  }
  /**
   * Hides a Layer. `automatic_control_layers` should be set to `false`.
   * @param layerId
   * @param time
   */
  hideLayer(layerId, time) {
    return this.zone.runOutsideAngular(() => this.viewer.layers.hideLayer(layerId, time));
  }
  /**
   * Resumes a paused layer. `automatic_control_layers` should be set to `false`.
   * @param layerId
   */
  resumeLayer(layerId) {
    return this.zone.runOutsideAngular(() => this.viewer.layers.resumeLayer(layerId));
  }
  /**
   * Pauses a layer. It will stop updating and rendering. `automatic_control_layers` should be set to `false`.
   * @param layerId
   */
  pauseLayer(layerId) {
    return this.zone.runOutsideAngular(() => this.viewer.layers.pauseLayer(layerId));
  }
  /**
   * Returns the current layer level.
   */
  getLayerLevel() {
    return this.zone.runOutsideAngular(() => this.viewer.layers.getLayerLevel());
  }
  /**
   * Sets the current layer level. `automatic_control_level` flag must be set to `false`.
   * @param level
   */
  setLayerLevel(level) {
    return this.zone.runOutsideAngular(() => this.viewer.layers.setLayerLevel(level));
  }
}
class ApiCoreLayersFlags {
  /**
   * Disables the automatic control of the layer levels.
   */
  get automatic_control_level() {
    return this.zone.runOutsideAngular(() => this.viewer.layers.flags.automatic_control_level);
  }
  set automatic_control_level(value) {
    this.zone.runOutsideAngular(() => this.viewer.layers.flags.automatic_control_level = value);
  }
  /**
   * Disables the automatic control of the layers (pause/resume, show/hide)
   */
  get automatic_control_layers() {
    return this.zone.runOutsideAngular(() => this.viewer.layers.flags.automatic_control_layers);
  }
  set automatic_control_layers(value) {
    this.zone.runOutsideAngular(() => this.viewer.layers.flags.automatic_control_layers = value);
  }
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
}
class ApiEditor {
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
    this.tools = new ApiEditorTools(service, zone, viewer);
  }
  getSelection() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.getSelection());
  }
  isSelected(node) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.isSelected(node));
  }
  select(nodes) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.select(nodes));
  }
  unselect(nodes) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.unselect(nodes));
  }
  unselectAll() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.unselectAll());
  }
  enableViewportTool(toolId) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.enableViewportTool(toolId));
  }
  enableDefaultViewportTool() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.enableDefaultViewportTool());
  }
  getViewportToolsList() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.getViewportToolsList());
  }
  getCurrentViewportTool() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.getCurrentViewportTool());
  }
  setDefaultViewportTool(toolId) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.setDefaultViewportTool(toolId));
  }
  createNodeImage(image) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.createNodeImage(image));
  }
  // TODO: type
  createNodes(obj) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.createNodes(obj));
  }
  removeNodes(nodes) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.removeNodes(nodes));
  }
  translateNodes(nodes, v) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.translateNodes(nodes, v));
  }
  rotateNodes(nodes, angleRad, origin) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.rotateNodes(nodes, angleRad, origin));
  }
  flipNodes(nodes, flipX, flipY, origin, flipRotation) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.flipNodes(nodes, flipX, flipY, origin, flipRotation));
  }
  cloneNode(node, newId, layerId, newParent) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.cloneNode(node, newId, layerId, newParent));
  }
  changeNodeId(nodeId, newId) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.changeNodeId(nodeId, newId));
  }
  changeNodeType(nodeId, newType) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.changeNodeType(nodeId, newType));
  }
  changeMapSize(bb) {
    return this.zone.runOutsideAngular(() => this.viewer.editor.changeMapSize(bb));
  }
  exportMap(venueId, mapId) {
    return this.zone.runOutsideAngular(() => from(this.viewer.editor.exportMap(venueId, mapId)));
  }
  importMap(spec) {
    return this.zone.runOutsideAngular(() => from(this.viewer.editor.importMap(spec)));
  }
  recomputeMapBoundingBox() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.recomputeMapBoundingBox());
  }
  cancelViewportTool() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.cancelViewportTool());
  }
  endViewportTool() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.endViewportTool());
  }
}
class ApiEditorTools {
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
    this.viewport_node_edition = new ApiEditorToolsViewportNodeEdition(service, zone, viewer);
    this.section_creator = new ApiEditorToolsSectionCreator(service, zone, viewer);
    this.row_creator = new ApiEditorToolsRowCreator(service, zone, viewer);
  }
}
class ApiEditorToolsViewportNodeEdition {
  get preserve_aspect_ratio() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.tools.viewport_node_edition.preserve_aspect_ratio);
  }
  set preserve_aspect_ratio(value) {
    this.zone.runOutsideAngular(() => this.viewer.editor.tools.viewport_node_edition.preserve_aspect_ratio = value);
  }
  get resize_from_center() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.tools.viewport_node_edition.resize_from_center);
  }
  set resize_from_center(value) {
    this.zone.runOutsideAngular(() => this.viewer.editor.tools.viewport_node_edition.resize_from_center = value);
  }
  get selection_mode() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.tools.viewport_node_edition.selection_mode);
  }
  set selection_mode(value) {
    this.zone.runOutsideAngular(() => this.viewer.editor.tools.viewport_node_edition.selection_mode = value);
  }
  get mode() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.tools.viewport_node_edition.mode);
  }
  set mode(value) {
    this.zone.runOutsideAngular(() => this.viewer.editor.tools.viewport_node_edition.mode = value);
  }
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
}
class ApiEditorToolsSectionCreator {
  get mode() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.tools.section_creator.mode);
  }
  set mode(value) {
    this.zone.runOutsideAngular(() => this.viewer.editor.tools.section_creator.mode = value);
  }
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
}
class ApiEditorToolsRowCreator {
  get mode() {
    return this.zone.runOutsideAngular(() => this.viewer.editor.tools.row_creator.mode);
  }
  set mode(value) {
    this.zone.runOutsideAngular(() => this.viewer.editor.tools.row_creator.mode = value);
  }
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
}
class ApiSelectionArea {
  get enabled() {
    return this.zone.runOutsideAngular(() => this.viewer.selection_area.enabled);
  }
  set enabled(value) {
    this.zone.runOutsideAngular(() => this.viewer.selection_area.enabled = value);
  }
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
    this.mouse_options = new ApiSelectionAreaMouseOpts(service, zone, viewer);
    this.style_options = new ApiSelectionAreaStyleOpts(service, zone, viewer);
  }
}
class ApiSelectionAreaMouseOpts {
  get altKey() {
    return this.zone.runOutsideAngular(() => this.viewer.selection_area.mouse_options.altKey);
  }
  set altKey(value) {
    this.zone.runOutsideAngular(() => this.viewer.selection_area.mouse_options.altKey = value);
  }
  get ctrlKey() {
    return this.zone.runOutsideAngular(() => this.viewer.selection_area.mouse_options.ctrlKey);
  }
  set ctrlKey(value) {
    this.zone.runOutsideAngular(() => this.viewer.selection_area.mouse_options.ctrlKey = value);
  }
  get metaKey() {
    return this.zone.runOutsideAngular(() => this.viewer.selection_area.mouse_options.metaKey);
  }
  set metaKey(value) {
    this.zone.runOutsideAngular(() => this.viewer.selection_area.mouse_options.metaKey = value);
  }
  get shiftKey() {
    return this.zone.runOutsideAngular(() => this.viewer.selection_area.mouse_options.shiftKey);
  }
  set shiftKey(value) {
    this.zone.runOutsideAngular(() => this.viewer.selection_area.mouse_options.shiftKey = value);
  }
  get button() {
    return this.zone.runOutsideAngular(() => this.viewer.selection_area.mouse_options.button);
  }
  set button(value) {
    this.zone.runOutsideAngular(() => this.viewer.selection_area.mouse_options.button = value);
  }
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
}
class ApiSelectionAreaStyleOpts {
  get border_width1() {
    return this.zone.runOutsideAngular(() => this.viewer.selection_area.style_options.border_width1);
  }
  set border_width1(value) {
    this.zone.runOutsideAngular(() => this.viewer.selection_area.style_options.border_width1 = value);
  }
  get border_width2() {
    return this.zone.runOutsideAngular(() => this.viewer.selection_area.style_options.border_width2);
  }
  set border_width2(value) {
    this.zone.runOutsideAngular(() => this.viewer.selection_area.style_options.border_width2 = value);
  }
  get border_color1() {
    return this.zone.runOutsideAngular(() => this.viewer.selection_area.style_options.border_color1);
  }
  set border_color1(value) {
    this.zone.runOutsideAngular(() => this.viewer.selection_area.style_options.border_color1 = value);
  }
  get border_color2() {
    return this.zone.runOutsideAngular(() => this.viewer.selection_area.style_options.border_color2);
  }
  set border_color2(value) {
    this.zone.runOutsideAngular(() => this.viewer.selection_area.style_options.border_color2 = value);
  }
  get fill_color() {
    return this.zone.runOutsideAngular(() => this.viewer.selection_area.style_options.fill_color);
  }
  set fill_color(value) {
    this.zone.runOutsideAngular(() => this.viewer.selection_area.style_options.fill_color = value);
  }
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
}
class ApiBestNodes {
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
  getBestNodes(input) {
    return this.zone.runOutsideAngular(() => from(this.viewer.best_nodes.getBestNodes(input)));
  }
  getBestFullGroupsOfNodes(input) {
    return this.zone.runOutsideAngular(() => from(this.viewer.best_nodes.getBestFullGroupsOfNodes(input)));
  }
}
class ApiSocialDistancing {
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
  compute(options, nodes, onProgress) {
    return this.zone.runOutsideAngular(() => from(this.viewer.social_distancing.compute(options, nodes, onProgress)));
  }
  getNeighbors(options, input, position) {
    return this.zone.runOutsideAngular(() => from(this.viewer.social_distancing.getNeighbors(options, input, position)));
  }
  getGroups(options, only_count, nodes) {
    return this.zone.runOutsideAngular(() =>
    // @ts-ignore
    from(this.viewer.social_distancing.getGroups(options, only_count, nodes)));
  }
  filterNearest(options, input) {
    return this.zone.runOutsideAngular(() => from(this.viewer.social_distancing.filterNearest(options, input)));
  }
  filterEdges(options) {
    return this.zone.runOutsideAngular(() => from(this.viewer.social_distancing.filterEdges(options)));
  }
  getRows(options) {
    return this.zone.runOutsideAngular(() => from(this.viewer.social_distancing.getRows(options)));
  }
  getReport() {
    return this.zone.runOutsideAngular(() => this.viewer.social_distancing.getReport());
  }
  hasExternalData() {
    return this.zone.runOutsideAngular(() => this.viewer.social_distancing.hasExternalData());
  }
  assignGroups(input) {
    return this.zone.runOutsideAngular(() => from(this.viewer.social_distancing.assignGroups(input)));
  }
  assignGroupsToCurrent(options, groups, max_assigned_clients) {
    return this.zone.runOutsideAngular(() => from(this.viewer.social_distancing.assignGroupsToCurrent(options, groups, max_assigned_clients)));
  }
}
class ApiSelect3d {
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
  loadMapData(input) {
    return this.zone.runOutsideAngular(() => from(this.viewer.select3d.loadMapData(input)));
  }
  compute(availability) {
    return this.zone.runOutsideAngular(() => this.viewer.select3d.compute(availability));
  }
  getBest(amount) {
    return this.zone.runOutsideAngular(() => this.viewer.select3d.getBest(amount));
  }
  reset() {
    return this.zone.runOutsideAngular(() => this.viewer.select3d.reset());
  }
}
class ApiNeighbors {
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
  getNeighbors(node, count, options) {
    return this.zone.runOutsideAngular(() => from(this.viewer.neighbors.getNeighbors(node, count, options)));
  }
  getClosestNeighbors(node, count, options) {
    return this.zone.runOutsideAngular(() => from(this.viewer.neighbors.getClosestNeighbors(node, count, options)));
  }
}
class DvmModuleService {
  get service_id() {
    return this._service_id;
  }
  /**
   * Returns true if the module started initializing
   */
  get started() {
    return this._started;
  }
  /**
   * Returns the original module. It should be used only for
   * debugging or internal purposes.
   * @internal
   */
  get native_module() {
    this._throwIfNotInitialized();
    return this._native_module;
  }
  constructor(dvm, _zone, _appRef) {
    this.dvm = dvm;
    this._zone = _zone;
    this._appRef = _appRef;
    this._native_module = null;
    this._initSubject$ = new AsyncSubject();
    this._initialized = false;
    this._started = false;
    this._service_id = generateServiceId();
    this._subscriptions = [];
    this._subjects = new Map();
    this._needTick = false;
    this._frame = 0;
  }
  needTick() {
    this._needTick = true;
  }
  destroy() {
    cancelAnimationFrame(this._frame);
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subjects.forEach(subject => subject.complete());
    this._subjects.clear();
  }
  /**
   * Waits until the module is initialized. It is recommended to get the module
   * from here always you need the module inside a new component or service.
   *
   * @returns Observable that is completed when the service is initialized.
   *
   * @example
   * service.waitInitialize().subscribe((service) => {
   *   console.log("Service initialized");
   * }
   */
  waitInitialize() {
    return this._initSubject$.asObservable();
  }
  /**
   * Returns true if the module has been initialized.
   */
  isInitialized() {
    return this._zone.runOutsideAngular(() => this._initialized && this._native_module != null && this._native_module.isInitialized());
  }
  /**
   * Returns true if the module has something loaded
   */
  isLoaded() {
    return this._zone.runOutsideAngular(() => this.isInitialized() && this.native_module.isLoaded());
  }
  /**
   * Returns the name identifier of the module.
   */
  getModuleName() {
    return this._zone.runOutsideAngular(() => this.native_module.getModuleName());
  }
  /**
   * Returns the module version.
   */
  getModuleVersion() {
    return this._zone.runOutsideAngular(() => this.native_module.getModuleVersion());
  }
  /**
   * Returns the module version.
   */
  getClientId() {
    return this._zone.runOutsideAngular(() => this.native_module.getClientId());
  }
  /**
   * Returns a list with the current plugins in use (if any).
   */
  getPluginsList() {
    return this._zone.runOutsideAngular(() => this.native_module.getPluginsList());
  }
  _throwIfNotInitialized() {
    if (!this.isInitialized()) {
      throw new Error(`Service is not initialized. Call 'initialize()' and 'waitInitialize() first'`);
    }
  }
  _initializeSubscriptions() {
    const func = () => {
      if (this._needTick) {
        this._appRef.tick();
        this._needTick = false;
      }
      this._frame = requestAnimationFrame(func);
    };
    this._frame = requestAnimationFrame(func);
  }
}
class SharedViewerService extends DvmModuleService {
  /**
   * aspect ratio from the map viewer. The value is obtained by dividing height/width. For instance,
   * to use a 16:9 aspect ratio, the value is 9/16. o 9/16 by default.
   */
  get aspect_ratio() {
    return this._zone.runOutsideAngular(() => this.native_module.aspect_ratio);
  }
  set aspect_ratio(ratio) {
    this._zone.runOutsideAngular(() => this.native_module.aspect_ratio = ratio);
  }
  /**
   * Configures the maximum number of elements allowed to be selected. Once this number is reached by the selection,
   * new selections will not be allowed (and no elements will be unselected). There is no limitation by default (`Infinity`).
   */
  get max_selection() {
    return this._zone.runOutsideAngular(() => this.native_module.max_selection);
  }
  set max_selection(max) {
    this._zone.runOutsideAngular(() => this.native_module.max_selection = max);
  }
  /**
   * Returns a limits object that contains properties useful to control UI elements (for instance for
   * buttons enable/disable). It gives information about the current scaling_factor and the map limits.
   */
  get limits() {
    return this._zone.runOutsideAngular(() => this.native_module.limits);
  }
  constructor(dvm, zone, appRef) {
    super(dvm, zone, appRef);
    // const tick = this._appRef.tick.bind(this._appRef);
    // (this._appRef.tick as any) = () => {
    //   console.log('tick');
    //   tick();
    // };
  }
  /**
   * Returns the id of the current loaded venue. If there is no venue loaded yet, it will return `null`.
   */
  getVenueId() {
    return this._zone.runOutsideAngular(() => this.native_module.getVenueId());
  }
  /**
   * Returns the id of the current loaded map. If there is no map loaded yet, it will return `null`.
   */
  getMapId() {
    return this._zone.runOutsideAngular(() => this.native_module.getMapId());
  }
  /**
   * Unloads the current map (if any).
   */
  reset() {
    return this._zone.runOutsideAngular(() => {
      this.native_module.reset();
      this.needTick();
    });
  }
  /**
   * Detaches the map viewer from the DOM.
   */
  close() {
    return this._zone.runOutsideAngular(() => {
      this.native_module.close();
      this.needTick();
    });
  }
  /**
   * Appends the map viewer to the DOM. If there is no map loaded, nothing will be shown.
   */
  open() {
    return this._zone.runOutsideAngular(() => {
      this.native_module.open();
      this.needTick();
    });
  }
  /**
   * Sets a node or nodes tag.
   * @param elements nodes
   * @param tag tag to be set. 'null' to remove it (Equivalent to 'none')
   *
   * @example
   * viewer.setNodesTag(["node1", "node2"], "vip");
   */
  setNodesTag(elements, tag) {
    return this._zone.runOutsideAngular(() => this.native_module.setNodesTag(elements, tag));
  }
  /**
   * Force the css cursor when the mouse is inside the map.
   * @url https://developer.mozilla.org/en-US/docs/Web/CSS/cursor
   * @param cursor
   */
  forceCursor(cursor) {
    return this._zone.runOutsideAngular(() => {
      this.native_module.forceCursor(cursor);
      this.needTick();
    });
  }
  /**
   * Overrides the map styles. If merged styles is true, tag and hover styles will merge with its base styles. if
   * `styles_by_groups` is being used, this is true by default.
   * @param styles
   * @param mergedStyles
   *
   * @example
   * var styles =
   *     [
   *         // Level 0. sections are visible, seats are hidden (no styles needed).
   *         {
   *             // Level 0 styles for nodes with "section" type
   *             section: {
   *                 available: {
   *                     normal: {
   *                         none: {
   *                             fillStyle: "#2E2EFE",
   *                             strokeStyle: "#2E2EFE",
   *                             lineWidth: 0.3,
   *                             cursor: "auto"
   *                         }
   *                     },
   *                     hover: {
   *                         none: {
   *                             fillStyle: "#819FF7",
   *                             strokeStyle: "#819FF7",
   *                             lineWidth: 0.6,
   *                             cursor: "pointer"
   *                         }
   *                     }
   *                 },
   *                 unavailable: {
   *                     normal: {
   *                         none: {
   *                             fillStyle: "grey",
   *                             strokeStyle: "grey",
   *                             lineWidth: 0.3,
   *                             cursor: "auto"
   *                         }
   *                     }
   *                 },
   *                 disabled: "unavailable"
   *             },
   *             // Level 0 styles for nodes with "seat" type (no styles = not shown)
   *
   *             seat: null
   *         },
   *
   *         // Level 1. sections are "open", seats are visible
   *         {
   *             // Level 1 styles for nodes with "section" type
   *             section: {
   *                 available: {
   *                     normal: {
   *                         none: {
   *                             fillStyle: "#E0E6F8",
   *                             strokeStyle: "#2E2EFE",
   *                             lineWidth: 0.3,
   *                             cursor: "auto"
   *                         }
   *                     },
   *                     hover: {
   *                         none: {
   *                             fillStyle: "#E0E6F8",
   *                             strokeStyle: "#2E2EFE",
   *                             lineWidth: 0.6,
   *                             cursor: "auto"
   *                         }
   *                     }
   *                 },
   *                 unavailable: {
   *                     normal: {
   *                         none: {
   *                             fillStyle: "#BDBDBD",
   *                             strokeStyle: "grey",
   *                             lineWidth: 0.3,
   *                             cursor: "auto"
   *                         }
   *                     }
   *                 },
   *                 disabled: "unavailable"
   *             },
   *
   *             // Level 1 styles for nodes with "seat" type
   *             seat:  {
   *                 available: {
   *                     normal: {
   *                         none: {
   *                             fillStyle: "#2E2EFE",
   *                             cursor: "auto"
   *                         },
   *                         vip: {
   *                             fillStyle: "#2E2EFE",
   *                             cursor: "auto",
   *                             icon: "vipicon"
   *                         }
   *                     },
   *                     hover: {
   *                         none: {
   *                             fillStyle: "#2E2EFE",
   *                             strokeStyle: "#2E2EFE",
   *                             lineWidth: 0.1,
   *                             cursor: "pointer"
   *                         },
   *                         vip: {
   *                             fillStyle: "#2E2EFE",
   *                             strokeStyle: "#2E2EFE",
   *                             lineWidth: 0.1,
   *                             cursor: "pointer",
   *                             icon: "vipicon"
   *                         }
   *                     }
   *                 },
   *                 selected: {
   *                     normal: {
   *                         none: {
   *                             fillStyle: "#01DF01",
   *                             strokeStyle: "#01DF01",
   *                             lineWidth: 0.1,
   *                             cursor: "pointer",
   *                             icon: "selectedicon"
   *                         },
   *                         vip: {
   *                             fillStyle: "#01DF01",
   *                             strokeStyle: "#01DF01",
   *                             lineWidth: 0.1,
   *                             cursor: "pointer",
   *                             icon: "vipicon"
   *                         }
   *                     }
   *                 },
   *                 unavailable: {
   *                     normal: {
   *                         none: {
   *                             fillStyle: "grey",
   *                             cursor: "auto"
   *                         },
   *                         vip: {
   *                             fillStyle: "grey",
   *                             cursor: "auto",
   *                             icon: "vipicon"
   *                         }
   *                     }
   *                 },
   *                 disabled: "unavailable"
   *             }
   *         }
   *     ];
   *
   *
   * viewer.setStyles(styles);
   */
  setStyles(styles, mergedStyles) {
    return this._zone.runOutsideAngular(() => this.native_module.setStyles(styles, mergedStyles));
  }
  /**
   * Returns the styles of this map.
   */
  getStyles() {
    return this._zone.runOutsideAngular(() => this.native_module.getStyles());
  }
  /**
   *
   * @param labels
   * @deprecated
   */
  setLabels(labels) {
    return this._zone.runOutsideAngular(() => this.native_module.setLabels(labels));
  }
  /**
   * Creates a new label for a node.
   * @param node
   * @param label
   */
  addLabel(node, label) {
    return this._zone.runOutsideAngular(() => this.native_module.addLabel(node, label));
  }
  /**
   * Updates an existing label from a node.
   * @param node
   * @param label
   * @param [index]
   */
  updateLabel(node, label, index) {
    return this._zone.runOutsideAngular(() => this.native_module.updateLabel(node, label, index));
  }
  /**
   * Removes an existing label from a node.
   * @param node
   * @param [index]
   */
  removeLabel(node, index) {
    return this._zone.runOutsideAngular(() => this.native_module.removeLabel(node, index));
  }
  /**
   * Gets the information of a specific label of a node.
   * @param node
   * @param index
   * @param local
   */
  getLabel(node, index, local) {
    return this._zone.runOutsideAngular(() => this.native_module.getLabel(node, index, local));
  }
  /**
   * Returns the states that a particular type of node can have.
   * @param type
   */
  getStatesList(type) {
    return this._zone.runOutsideAngular(() => this.native_module.getStatesList(type));
  }
  /**
   * Returns the available type of nodes that the map have.
   */
  getTypesList() {
    return this._zone.runOutsideAngular(() => this.native_module.getTypesList());
  }
  /**
   * Set the state of a node or nodes. This method will check if a node can change its state according
   * to its state's graph.
   * @param elements nodes/nodes to change its/their state.
   * @param stateId new state.
   * @param [force] if this flag is `true` the state's graph won't be checked.
   */
  setState(elements, stateId, force) {
    return this._zone.runOutsideAngular(() => this.native_module.setState(elements, stateId, force));
  }
  /**
   * Sets the general availability of a scene. all `'unavailable'` specified nodes will change their
   * state to `'available'`. Any node NOT in the list will change its state to `'unavailable'` (if they can
   * transition to it).
   * @param type type of the node/nodes.
   * @param elements node/nodes to be set.
   * @param [parent] parent of the node/nodes
   *
   * @example
   * var success = viewer.setAvailability("seat", ["S_123-A-11", "S_123-A-12"], "S_123");
   */
  setAvailability(type, elements, parent) {
    return this._zone.runOutsideAngular(() => this.native_module.setAvailability(type, elements, parent));
  }
  /**
   * Sets a node or nodes state to available (change its/their state from `'unavailable'` to `'available'`)
   * @param type type of the node/nodes.
   * @param elements node/nodes to be set.
   * @param [parent] parent of the node/nodes
   *
   * @example
   * var success = viewer.setAvailable("seat", ["S_123-A-11", "S_123-A-12"], "S_123");
   */
  setAvailable(type, elements, parent) {
    return this._zone.runOutsideAngular(() => this.native_module.setAvailable(type, elements, parent));
  }
  /**
   * Sets a node or nodes state to `'unavailable'` (change its state to `'unavailable'` from
   * any transitionable state).
   * @param type type of the node/nodes.
   * @param elements node/nodes to be set.
   * @param [parent] parent of the node/nodes
   *
   * @example
   * var success = viewer.setUnavailable("seat", ["S_123-A-11", "S_123-A-12"], "S_123");
   */
  setUnavailable(type, elements, parent) {
    return this._zone.runOutsideAngular(() => this.native_module.setUnavailable(type, elements, parent));
  }
  /**
   * Selects a node or nodes (change its/their state from `'available'` to `'selected'`) if it's
   * possible according to their state's graph.
   * @param elements
   *
   * @example
   * var success = viewer.select(["S_123-A-11", "S_123-A-12"]);
   */
  select(elements) {
    return this._zone.runOutsideAngular(() => this.native_module.select(elements));
  }
  /**
   * Unselects a node or nodes (change its/their state from `'selected'` to `'available'`) if it's
   * possible according to their state's graph.
   * @param elements
   *
   * @example
   * var success = viewer.unselect(["S_123-A-11", "S_123-A-12"]);
   */
  unselect(elements) {
    return this._zone.runOutsideAngular(() => this.native_module.unselect(elements));
  }
  /**
   * Unselects any node currently selected (change its/their state from `'selected'` to `'available'`).
   *
   * @example
   * var success = viewer.unselectAll();
   */
  unselectAll() {
    return this._zone.runOutsideAngular(() => this.native_module.unselectAll());
  }
  /**
   * Changes the state of a node from `disabled` to `unavailable`. If the node's state is not `disabled`,
   * this method will do nothing.
   *
   * This method is used along with `disable`.
   *
   * @param type type of the node/nodes.
   * @param elements node/nodes to be set.
   * @param [parent] parent of the node/nodes
   *
   * @example
   * var success = viewer.enable("seat", ["S_123-A-11", "S_123-A-12"], "S_123");
   */
  enable(type, elements, parent) {
    return this._zone.runOutsideAngular(() => this.native_module.enable(type, elements, parent));
  }
  /**
   * Set the state of any node to `disabled`. If a node was selected, it will be unselected before being set to `disabled`.
   * A `disabled` node cannot change its state unless you use enable method.
   *
   * Having nodes disabled is useful in some user cases, For example:
   *
   * - If you want to make sure that some nodes remains not available (`disabled`), preventing they being set it to
   * available by accident.
   * - If `disabled` state has no styles, disabled nodes will be invisible, so you can tell whether a node is
   * not available in some event (disabled and invisible) or it simply has been already taken (unavailable and grey).
   *
   * This method is used along with `enable`.
   *
   * @param type type of the node/nodes.
   * @param elements node/nodes to be set.
   * @param [parent] parent of the node/nodes
   *
   * @example
   * var success = viewer.disable("seat", ["S_123-A-11", "S_123-A-12"], "S_123");
   */
  disable(type, elements, parent) {
    return this._zone.runOutsideAngular(() => this.native_module.disable(type, elements, parent));
  }
  /**
   * Forces the pseudostate of hover=true to the specified nodes. This is useful when you want the viewer to
   * react to external actions (for instance, selection outside the map).
   *
   * When this method is used, the previous hovered elements will be overridden.
   *
   * `automatic_hover` has to be disabled, or it will conflict.
   *
   * @param elements
   *
   * @example
   * var success = viewer.hover("S_123");
   */
  hover(elements) {
    return this._zone.runOutsideAngular(() => this.native_module.hover(elements));
  }
  /**
   * Filters the nodes that belong to a row that satisfy the following condition:
   *
   * A series of contiguous nodes with the state available or selected cannot take part of a count sized group.
   * In addition, if the parameter filter_single_seats=true, nodes that neither satisfy the following condition
   * will be filtered:
   *
   * If an available or selected node cannot take part of a joint with contiguous nodes without leaving an available
   * or selected node alone.
   *
   * The output of this method returns the nodes to be filtered (the action is not directly applied) - they are the
   * nodes that do not satisfy the condition of taking part of a count sized group.
   *
   * You can change the state to `unavailable` with `setUnavailable`, or assign a specific group to this joint to know
   * if they were unavailable previously to the filtering process, and which were available but do not obey the
   * necessary conditions to be selected.
   *
   * Internally these nodes are treated like filtered so the method `getNeighbors` works as expected when using
   * `filter_single_seats=true`.
   *
   * This also facilitates making a second filtering process if the desired quantity of seats changes.
   * Having these nodes marked in some common group allows us to retrieve them, revert them to the initial
   * state and filter again.
   *
   * Normally this should be use with `getNeighbors`.
   *
   * @param count
   * @param [filterSingleSeats]
   * @param [parent]
   *
   * @example
   * var old_filtered_seats = viewer.getNodesByGroups("seat", "filtered");
   * viewer.setAvailable("seat", old_filtered_seats);
   * viewer.removeNodesFromGroup(old_filtered_seats, "filtered");
   *
   * var filtered_seats = viewer.filterRows(3, true);
   * viewer.setUnavailable("seat", filtered_seats);
   * viewer.addNodesToGroup(filtered_seats, "filtered");
   */
  filterRows(count, filterSingleSeats, parent) {
    return this._zone.runOutsideAngular(() => this.native_module.filterRows(count, filterSingleSeats, parent));
  }
  /**
   * Returns the nodes quantity count that achieve the following requirements:
   *
   * - The introduced node has to belong to a row.
   * - The introduced node has to be `available` or  `selected`.
   * - There must be enough contiguous nodes that are available or selected.
   * If also `filter_single_seats` flag is set to `true`, the following conditions need also to be achieved:
   *
   * the returned node set cannot leave a node with state available or selected without at least one adjacent
   * node with state available or selected, or a filterRows marked node.
   *
   * In case of achieving the above conditions, an array with the same quantity of nodes requested will be
   * given (including the introduced node).
   *
   * In case of not achieving the above conditions, the returning array will be empty.
   *
   * @param element
   * @param count
   * @param [filterSingleSeats]
   *
   * @example
   * var nodes = viewer.getNeighbors("S_123-A-4", 3, true);
   */
  getNeighbors(element, count, filterSingleSeats) {
    return this._zone.runOutsideAngular(() => this.native_module.getNeighbors(element, count, filterSingleSeats));
  }
  /**
   * It will check if any selected node is leaving any available node isolated. The node must be member of a row.
   *
   * @example
   * viewer.subscribe("select", checkIsolated);
   * viewer.subscribe("unselect", checkIsolated);
   *
   * function checkIsolated() {
   *     var isolated = viewer.checkIsolatedNodes();
   *     if (isolated.length > 0) {
   *         console.warn("Nodes isolated:", isolated);
   *     }
   * }
   */
  checkIsolatedNodes() {
    return this._zone.runOutsideAngular(() => this.native_module.checkIsolatedNodes());
  }
  /**
   * Transforms scene coordinates to DOM coordinates.
   * @param scenePoint
   */
  fromSceneToDom(scenePoint) {
    return this._zone.runOutsideAngular(() => this.native_module.fromSceneToDom(scenePoint));
  }
  /**
   * Transforms DOM coordinates to scene coordinates.
   * @param domPoint
   */
  fromDomToScene(domPoint) {
    return this._zone.runOutsideAngular(() => this.native_module.fromDomToScene(domPoint));
  }
  /**
   * Adds a node or nodes to a group.
   * @param elements node/nodes to be added to.
   * @param group target group.
   *
   * @example
   * viewer.addNodesToGroup(["node1", "node2", "node3"], "group1");
   */
  addNodesToGroup(elements, group) {
    return this._zone.runOutsideAngular(() => this.native_module.addNodesToGroup(elements, group));
  }
  /**
   * Removes a node or nodes from a group.
   * @param elements node/nodes to be removed from.
   * @param group target group.
   *
   * @example
   * viewer.removeNodesFromGroup(["node1", "node2", "node3"], "group1");
   */
  removeNodesFromGroup(elements, group) {
    return this._zone.runOutsideAngular(() => this.native_module.removeNodesFromGroup(elements, group));
  }
  /**
   * Returns an object with node types as key, and an array of group ids, meaning
   * that at least one node of that type is part of that group.
   * @param [types]
   */
  getGroupsByType(types) {
    return this._zone.runOutsideAngular(() => this.native_module.getGroupsByType(types));
  }
  /**
   * Returns a node by its id. It can be `null` if there is no node with this id.
   * @param nodeId id of the node.
   *
   * @example
   * var node = viewer.getNodeById("S_123");
   */
  getNodeById(nodeId) {
    return this._zone.runOutsideAngular(() => this.native_module.getNodeById(nodeId));
  }
  /**
   * Returns a list of nodes which meet the filters criteria.
   * @param [filters] Object with the filters. If it's undefined, the method
   * will return all the set of nodes.
   * @param [nodes]
   *
   * @example
   * const nodes = viewer.getNodes({
   *     type: ["seat", "section"],
   *     state: "available",
   *     group: ["g1", "g2"]
   * });
   */
  getNodes(filters, nodes) {
    return this._zone.runOutsideAngular(() => this.native_module.getNodes(filters, nodes));
  }
  /**
   * Returns an array of nodes by their type. Can be filtered by parent.
   * @param type
   * @param [parent]
   *
   * @example
   * var nodes = viewer.getNodesByType("section");
   */
  getNodesByType(type, parent) {
    return this._zone.runOutsideAngular(() => this.native_module.getNodesByType(type, parent));
  }
  /**
   * Returns a list of nodes from a specific type by their state. Can be filtered by parent.
   * @param type
   * @param stateId
   * @param [parent]
   *
   * @example
   * var nodes = viewer.getNodesByState("section", "available");
   */
  getNodesByState(type, stateId, parent) {
    return this._zone.runOutsideAngular(() => this.native_module.getNodesByState(type, stateId, parent));
  }
  /**
   * Returns a list of nodes from a specific type by their groups. Can be filtered by parent.
   * @param type Type of the nodes
   * @param groups group or groups
   * @param intersection if multiple groups has been specified, false will be the union, and true will be
   * the intersection. `false` by default.
   * @param [parent] parent id
   *
   * @example
   * viewer.addNodesToGroup(["node1", "node2", "node3"], "group1");
   * viewer.addNodesToGroup(["node2", "node3", "node4"], "group2");
   *
   * var g1 = viewer.getNodesByGroups("seat", "group1"); // [node1, node2, node3]
   * var g2 = viewer.getNodesByGroups("seat", "group2"); // [node2, node3, node4]
   * var union = viewer.getNodesByGroups("seat", ["group1", "group2"]); // [node1, node2, node3, node4]
   * var intersection = viewer.getNodesByGroups("seat", ["group1", "group2"], true); // [node2, node3]
   */
  getNodesByGroups(type, groups, intersection, parent) {
    return this._zone.runOutsideAngular(() => this.native_module.getNodesByGroups(type, groups, intersection, parent));
  }
  /**
   * Returns a list of nodes from a specific type by their tag. Can be filtered by parent.
   * @param type
   * @param tag
   * @param [parent]
   *
   * @example
   * var nodes = viewer.getNodesByTag("seat", "vip");
   */
  getNodesByTag(type, tag, parent) {
    return this._zone.runOutsideAngular(() => this.native_module.getNodesByTag(type, tag, parent));
  }
  /**
   * Returns all the children of a node.
   * @param parent
   *
   * @example
   * var nodes = viewer.getNodesByParent("S_123");
   */
  getNodesByParent(parent) {
    return this._zone.runOutsideAngular(() => this.native_module.getNodesByParent(parent));
  }
  /**
   * Returns all nodes that intersect at the scene position. Can be filtered by type.
   * @param scenePosition `[x, y]` scene position
   * @param [type]
   *
   * @example
   * var nodes = viewer.getNodesAt([100, 50], "seat");
   */
  getNodesAt(scenePosition, type) {
    return this._zone.runOutsideAngular(() => this.native_module.getNodesAt(scenePosition));
  }
  /**
   * Returns all nodes that intersect with an area (x, y, w, h). Can be filtered by type.
   * @param area `[x, y, width, height]`, where `[x,y]` is the upper left corner.
   * @param [type]
   *
   * @example
   * var nodes = viewer.getNodesOnArea([100, 50, 100, 100], "seat");
   */
  getNodesOnArea(area, type) {
    return this._zone.runOutsideAngular(() => this.native_module.getNodesOnArea(area, type));
  }
  /**
   * Returns all visible nodes inside the current camera.
   * @param type
   *
   * @example
   * var nodes = viewer.getVisibleNodes("section");
   */
  getVisibleNodes(type) {
    return this._zone.runOutsideAngular(() => this.native_module.getVisibleNodes(type));
  }
  /**
   * Returns the ids of the available icons in this map. They can be used in styles.
   */
  getIconsList() {
    return this._zone.runOutsideAngular(() => this.native_module.getIconsList());
  }
  /**
   * Overrides the default styles of a node (from type > state > pseudostate > tag).
   * @param node
   * @param styles
   * @param [override]
   */
  setNodeCustomStyles(node, styles, override) {
    return this._zone.runOutsideAngular(() => this.native_module.setNodeCustomStyles(node, styles, override));
  }
  /**
   * Asynchronous method that resolves in a map description.
   *
   * @returns promise that will be resolved with an object with "title" and "description".
   *
   * @example
   *
   * viewer3d.getMapDescription().subscribe({
   *     next: (result) => {
   *        console.log("Title:", result.title);
   *        console.log("Description:", result.description);
   *     },
   *     error: (err) => {
   *         // Something went wrong. Maybe you do not have permissions or the map doesn't exist.
   *         console.error(err);
   *     },
   * });
   */
  getMapDescription() {
    return this._zone.runOutsideAngular(() => from(this.native_module.getMapDescription()));
  }
  /**
   * Returns the id of the assigned minimap of the current loaded map. If there is no map loaded yet, or
   * there is no assigned minimap, it will return `null`.
   *
   * @example
   *    viewer.loadMap({ ... }).subscribe(() => {
   *       const minimap_id = viewer.getMinimapId();
   *       if (minimap_id) {
   *            minimap.loadMap({ venue_id: viewer.getVenueId(), map_id: minimap_id });
   *        } else {
   *            minimap.reset();
   *        }
   *    });
   * });
   */
  getMinimapId() {
    return this._zone.runOutsideAngular(() => this.native_module.getMinimapId());
  }
  /**
   * Returns an Observable that wil push every time when the module trigger the subscribed trigger.
   * @param trigger trigger you are going to subscribe to.
   */
  getObservable(trigger) {
    let subject = this._subjects.get(trigger);
    if (subject == null) {
      subject = new Subject();
      this._subjects.set(trigger, subject);
      if (this._native_module) {
        this._registerSubscriptions(trigger, subject);
      }
    }
    return subject.asObservable();
  }
  _registerSubscriptions(trigger, subject) {
    const s = this.native_module.subscribe(trigger, (...args) => {
      subject.next(this._genMapViewerEventObj(args[0]));
    });
    this._subscriptions.push(s);
  }
  _genMapViewerEventObj(event) {
    const service = this;
    return {
      service,
      event
    };
  }
  _initializeSubscriptions() {
    super._initializeSubscriptions();
    this._subscriptions.push(this.getObservable('zooming').subscribe(() => this.needTick()));
    this._subscriptions.push(this.getObservable('panning').subscribe(() => this.needTick()));
  }
}
class ApiPaths {
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
  getCurrentLayerId() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.getCurrentLayerId());
  }
  getCurrentLevel() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.getCurrentLevel());
  }
  getNextLevel() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.getNextLevel());
  }
  getPreviousLevel() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.getPreviousLevel());
  }
  getCurrentPathId() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.getCurrentPathId());
  }
  getPathIds() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.getPathIds());
  }
  closePath() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.closePath());
  }
  loadPath(pathId) {
    return this.zone.runOutsideAngular(() => from(this.viewer.paths.loadPath(pathId)));
  }
  loadSubpath(index) {
    return this.zone.runOutsideAngular(() => from(this.viewer.paths.loadSubpath(index)));
  }
  nextSubpath() {
    return this.zone.runOutsideAngular(() => from(this.viewer.paths.nextSubpath()));
  }
  previousSubpath() {
    return this.zone.runOutsideAngular(() => from(this.viewer.paths.previousSubpath()));
  }
  getCurrentSubpathIndex() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.getCurrentSubpathIndex());
  }
  getTotalSubpath() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.getTotalSubpath());
  }
  getStartNodeId() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.getStartNodeId());
  }
  getEndNodeId() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.getEndNodeId());
  }
  getCurrentSubpathStartNodeId() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.getCurrentSubpathStartNodeId());
  }
  getCurrentSubpathEndNodeId() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.getCurrentSubpathEndNodeId());
  }
  isLastNodeVisible() {
    return this.zone.runOutsideAngular(() => this.viewer.paths.isLastNodeVisible());
  }
}
class MapViewerService extends SharedViewerService {
  /** Max scaling factor allowed for this particular map. It may change depending on the size of the viewport  */
  get max_scaling_factor() {
    return this._zone.runOutsideAngular(() => this.native_module.max_scaling_factor);
  }
  set max_scaling_factor(factor) {
    this._zone.runOutsideAngular(() => this.native_module.max_scaling_factor = factor);
  }
  /** Min scaling factor allowed for this particular map. */
  get min_scaling_factor() {
    return this._zone.runOutsideAngular(() => this.native_module.min_scaling_factor);
  }
  set min_scaling_factor(factor) {
    this._zone.runOutsideAngular(() => this.native_module.min_scaling_factor = factor);
  }
  /**
   * Current scaling factor used in the map. When setting it, if the factor is greater than the maximum zoom or
   * lower than the minimum zoom, the scaling factor will be forced to the nearest limit.
   */
  get scaling_factor() {
    return this._zone.runOutsideAngular(() => this.native_module.scaling_factor);
  }
  set scaling_factor(factor) {
    this._zone.runOutsideAngular(() => this.native_module.scaling_factor = factor);
  }
  /** Allows to customize the speed of the venue-viewer buttons bound with `bindInterfaceAction` method. */
  get navigation_buttons_speed() {
    return this._zone.runOutsideAngular(() => this.native_module.navigation_buttons_speed);
  }
  set navigation_buttons_speed(factor) {
    this._zone.runOutsideAngular(() => this.native_module.navigation_buttons_speed = factor);
  }
  /** Allows to customize the speed of the zoom buttons bound with `bindInterfaceAction` method. */
  get zoom_buttons_speed() {
    return this._zone.runOutsideAngular(() => this.native_module.zoom_buttons_speed);
  }
  set zoom_buttons_speed(factor) {
    this._zone.runOutsideAngular(() => this.native_module.zoom_buttons_speed = factor);
  }
  /** Layers API */
  get layers() {
    return this._layers;
  }
  /** Module core flags */
  get flags() {
    return this._flags;
  }
  /** selection-area plugin API */
  get selection_area() {
    return this._selection_area;
  }
  /** best-nodes plugin API */
  get best_nodes() {
    return this._best_nodes;
  }
  /** editor plugin API */
  get editor() {
    return this._editor;
  }
  /** social-distancing plugin API */
  get social_distancing() {
    return this._social_distancing;
  }
  /** select3d plugin API */
  get select3d() {
    return this._select3d;
  }
  /** neighbors plugin API */
  get neighbors() {
    return this._neighbors;
  }
  /** paths plugin API */
  get paths() {
    return this._paths;
  }
  /** clipping plugin API */
  get clipping() {
    return this._clipping;
  }
  get linkedComponent() {
    return this._linkedComponent;
  }
  constructor(dvm, zone, appRef) {
    super(dvm, zone, appRef);
    this._linkedComponent = null;
    registerMapViewer(this, this.service_id);
  }
  /** @Internal */
  ngOnDestroy() {
    this.destroy();
  }
  /**
   * @internal
   * @param component
   */
  __link__(component) {
    if (this._linkedComponent) throw new Error('Already linked to a component');
    this._linkedComponent = component;
    if (this.isInitialized()) {
      this.native_module.setContainer(this._linkedComponent.containerElementRef.nativeElement);
    }
  }
  /**
   * @internal
   */
  __unlink__() {
    this._linkedComponent = null;
  }
  /**
   * Initializes manually the module. Needs to be already linked to a dvm-map-viewer component.
   * @param initOptions
   */
  initialize(initOptions) {
    if (this._linkedComponent == null) throw new Error('Not linked to a component');
    const obj = Object.assign({
      container: this._linkedComponent.containerElementRef.nativeElement,
      styles_by_groups: true,
      instanced_nodes: true,
      advanced: true
    }, initOptions);
    return this._zone.runOutsideAngular(() => {
      if (!this._started) {
        this._started = true;
        const o = this.dvm.loadMapViewerModule(obj).subscribe(viewer => {
          this._native_module = viewer;
          this._flags = new ApiCoreFlags$1(this, this._zone, viewer);
          this._layers = new ApiCoreLayers(this, this._zone, viewer);
          if (this._native_module.editor) {
            this._editor = new ApiEditor(this, this._zone, viewer);
          }
          if (this._native_module.selection_area) {
            this._selection_area = new ApiSelectionArea(this, this._zone, viewer);
          }
          if (this._native_module.best_nodes) {
            this._best_nodes = new ApiBestNodes(this, this._zone, viewer);
          }
          if (this._native_module.social_distancing) {
            this._social_distancing = new ApiSocialDistancing(this, this._zone, viewer);
          }
          if (this._native_module.select3d) {
            this._select3d = new ApiSelect3d(this, this._zone, viewer);
          }
          if (this._native_module.neighbors) {
            this._neighbors = new ApiNeighbors(this, this._zone, viewer);
          }
          if (this._native_module.paths) {
            this._paths = new ApiPaths(this, this._zone, viewer);
          }
          this._initialized = true;
          this._initializeSubscriptions();
          for (const [key, subject] of this._subjects) {
            this._registerSubscriptions(key, subject);
          }
          this._initSubject$.next(this);
          this._initSubject$.complete();
        });
        this._subscriptions.push(o);
      }
      return this.waitInitialize();
    });
  }
  /**
   * Asynchronous method that loads a map.
   * @param loadOptions Object with the information of the map to be loaded.
   * @returns Observable that will be completed if the map is loaded successfully,
   * or it will fail if there was a problem loading the map.
   *
   * @example
   * var obj = { venue_id: "venue", map_id: "map" };
   * viewer.loadMap(obj).subscribe({
   *       next: () => {
   *         console.log("MAP LOADED");
   *       },
   *       error: (err) => {
   *         // Something went wrong. Maybe you do not have permissions or the map does not exist
   *         console.error(err);
   *       },
   *     });
   */
  loadMap(loadOptions) {
    return this._zone.runOutsideAngular(() => from(this.native_module.loadMap(loadOptions).then(r => {
      this.needTick();
      return r;
    })));
  }
  /**
   * Binds the necessary events to a DOM element to perform certain actions on the map (zoom and venue-viewer).
   * This allows adding custom UI to the integrations without worrying about the events' management.
   *
   * @param element
   * @param action
   */
  bindInterfaceAction(element, action) {
    return this._zone.runOutsideAngular(() => this.native_module.bindInterfaceAction(element, action));
  }
  /**
   * Centers the viewport in the specified element (if it exists) or a scene position. In addition,
   * a scaling factor can be specified.
   *
   * @param input
   * @param scalingFactor
   */
  focusOn(input, scalingFactor) {
    return this._zone.runOutsideAngular(() => this.native_module.focusOn(input, scalingFactor));
  }
  goTo(input, scalingFactor, time) {
    return this._zone.runOutsideAngular(() => from(this.native_module.goTo(input, scalingFactor, time)));
  }
  /**
   * Moves the camera [x, y] from current position.
   * @param x
   * @param y
   */
  move(x, y) {
    return this._zone.runOutsideAngular(() => this.native_module.move(x, y));
  }
  /**
   * Multiplies the current scaling factor by a factor.
   * @param factor
   */
  scaleBy(factor) {
    return this._zone.runOutsideAngular(() => this.native_module.scaleBy(factor));
  }
  getThumbnail(param1, param2, param3) {
    return this._zone.runOutsideAngular(() => from(this.native_module.getThumbnail(param1, param2, param3)));
  }
  /**
   * Asynchronous method that checks if a specific 3d view exists.
   *
   * @param obj Object with the information of the 3d view to be checked.
   * @returns Observable that will be completed with a boolean, being `true` if the view exists,
   * or `false` if it does not. The Observable will fail if there is another problem,
   * for example if you do not have permissions to access this venue, regardless of
   * whether the view exists or not.
   */
  has3dView(obj) {
    return this._zone.runOutsideAngular(() => from(this.native_module.has3dView(obj)));
  }
  /**
   * Asynchronous method that checks if a specific venue has generated 3d views material.
   * @param venueId
   */
  has3dViews(venueId) {
    return this._zone.runOutsideAngular(() => from(this.native_module.has3dViews(venueId)));
  }
  /**
   * Asynchronous method that checks if a specific venue has generated 3d views material.
   * @param obj
   */
  hasMap(obj) {
    return this._zone.runOutsideAngular(() => from(this.native_module.hasMap(obj)));
  }
  static {
    this.ɵfac = function MapViewerService_Factory(t) {
      return new (t || MapViewerService)(i0.ɵɵinject(DvmService), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i0.ApplicationRef));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MapViewerService,
      factory: MapViewerService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MapViewerService, [{
    type: Injectable
  }], function () {
    return [{
      type: DvmService
    }, {
      type: i0.NgZone
    }, {
      type: i0.ApplicationRef
    }];
  }, null);
})();
class MapViewerComponent {
  /**
   * Returns the native module. It should be used only for
   * debugging or internal purposes.
   * @internal
   */
  get nativeModule() {
    if (!this._nativeModule) {
      throw new Error('map-viewer not initialized yet');
    }
    return this._nativeModule;
  }
  /**
   * Returns ElementRef of the module container
   */
  get containerElementRef() {
    return this._container;
  }
  get initOptions() {
    return this._initOptions;
  }
  set initOptions(input) {
    if (!this._nativeModule) {
      this._initOptions = input;
    }
  }
  get loadOptions() {
    return this._loadOptions;
  }
  set loadOptions(input) {
    this._loadOptions = input;
  }
  get service() {
    return this._service;
  }
  set service(input) {
    if (!this.service) {
      this._service = input;
    }
  }
  constructor(componentElementRef, _zone, _injector, _vcr, _renderer) {
    this.componentElementRef = componentElementRef;
    this._zone = _zone;
    this._injector = _injector;
    this._vcr = _vcr;
    this._renderer = _renderer;
    /** CORE **/
    this.dvmInit = new EventEmitter();
    this.dvmPreLoadStart = new EventEmitter();
    this.dvmLoadStart = new EventEmitter();
    /** @deprecated */
    this.dvmStartLoad = new EventEmitter();
    this.dvmLoadSuccess = new EventEmitter();
    this.dvmLoadError = new EventEmitter();
    this.dvmReset = new EventEmitter();
    this.dvmUpdate = new EventEmitter();
    /** FROM MOUSE/TOUCH DEVICES **/
    this.dvmClick = new EventEmitter();
    this.dvmDblclick = new EventEmitter();
    this.dvmHover = new EventEmitter();
    this.dvmEnter = new EventEmitter();
    this.dvmLeave = new EventEmitter();
    this.dvmUp = new EventEmitter();
    this.dvmDown = new EventEmitter();
    this.dvmMove = new EventEmitter();
    this.dvmContextmenu = new EventEmitter();
    this.dvmWheel = new EventEmitter();
    /** STATES **/
    this.dvmStateChange = new EventEmitter();
    this.dvmSelect = new EventEmitter();
    this.dvmUnselect = new EventEmitter();
    /** CAMERA **/
    this.dvmZooming = new EventEmitter();
    this.dvmPanning = new EventEmitter();
    /** LAYERS **/
    this.dvmFirstSeen = new EventEmitter();
    this.dvmLayerLevelChanged = new EventEmitter();
    /** SELECTION AREA PLUGIN **/
    this.dvmStartSelectionArea = new EventEmitter();
    this.dvmUpdateSelectionArea = new EventEmitter();
    this.dvmEndSelectionArea = new EventEmitter();
    /** PATH PLUGIN **/
    this.dvmSubpathEnabled = new EventEmitter();
    /** EDITOR PLUGIN **/
    this.dvmNodesCreated = new EventEmitter();
    this.dvmNodeCreated = new EventEmitter();
    this.dvmNodeEdited = new EventEmitter();
    this.dvmNodeRemoved = new EventEmitter();
    this.dvmNodeDestroyed = new EventEmitter();
    this.dvmNodesUpdated = new EventEmitter();
    this.dvmEditorSelect = new EventEmitter();
    this.dvmEditorUnselect = new EventEmitter();
    this.dvmToolEnabled = new EventEmitter();
    this.dvmToolDisabled = new EventEmitter();
    this.dvmToolStart = new EventEmitter();
    this.dvmToolEnd = new EventEmitter();
    this.dvmNodeChangeId = new EventEmitter();
    this.dvmNodeChangeType = new EventEmitter();
    // public readonly mapViewerService: MapViewerService;
    this._nativeModule = null;
    this._load$ = new Subject();
    this._debounceLoad$ = this._load$.pipe(debounce(() => timer(this._debounceLoadTime)));
    this._debounceLoadTime = 100;
    this._animationId = 0;
    this._afterViewInit = false;
    this._initOptions = null;
    this._loadOptions = null;
    this._subs = [];
  }
  /** @Internal */
  ngOnChanges(changes) {
    if (this.service?.started && changes.loadOptions) {
      const {
        currentValue,
        previousValue
      } = changes.loadOptions;
      if (currentValue?.venue_id !== previousValue?.venue_id || currentValue?.map_id !== previousValue?.map_id) {
        this._load$.next();
      }
    }
    if (this._afterViewInit && !this.service?.started) {
      if (changes.initOptions) {
        const {
          currentValue
        } = changes.initOptions;
        if (currentValue) {
          this.initOptions = currentValue;
          this._subs.push(this.service.initialize(this.initOptions).subscribe(service => {}));
        }
      }
    }
  }
  /** @Internal */
  ngAfterViewInit() {
    this._afterViewInit = true;
    if (!this.service) {
      this.service = this._injector.get(MapViewerService);
    }
    this.service.__link__(this);
    this._subs.push(this.service.waitInitialize().subscribe(() => {
      this._subs.push(this._debounceLoad$.subscribe(() => {
        if (this.loadOptions) {
          this._subs.push(this.service.loadMap(this.loadOptions).subscribe(() => {}));
        }
      }));
    }));
    this._subs.push(this.service.waitInitialize().subscribe(service => {
      this._nativeModule = service.native_module;
      const loop = () => {
        let height = '100%';
        const width = this._main.nativeElement.clientWidth;
        if (width != 0) {
          if (this.service.flags.fixed_aspect_ratio) {
            const ar = this.service.aspect_ratio;
            height = `${Math.round(width * ar)}px`;
          }
          this._main.nativeElement.style.height = height;
        }
        this._animationId = requestAnimationFrame(loop);
      };
      this._animationId = requestAnimationFrame(loop);
      this._zone.run(() => this.dvmInit.emit(this));
      this._load$.next();
    }));
    if (!this.service.started && this.initOptions) {
      this._subs.push(this.service.initialize(this.initOptions).subscribe(service => {}));
    }
    this._bindEmitters();
  }
  /** @Internal */
  ngOnDestroy() {
    if (this._animationId != null) {
      cancelAnimationFrame(this._animationId);
    }
    this._animationId = null;
    if (this.nativeModule) {
      this.nativeModule.reset();
      this.nativeModule.close();
    }
    this._subs.forEach(s => s.unsubscribe());
    this._load$.complete();
    this.service.__unlink__();
  }
  /**
   * Changes the container where the dvm-map-viewer component is.
   * @param container it must be a `HTMLDivElement`.
   *
   * @example
   * viewer.setContainer(container.nativeElement);
   */
  setContainer(container) {
    this._renderer.appendChild(container, this.componentElementRef.nativeElement);
  }
  _bindEmitters() {
    this.service.waitInitialize().subscribe(() => {
      const service = this.service;
      // Bind emitters only if there is any. After view init no event emitters should be added from here,
      // so there is no problem on doing that once
      if (this.dvmPreLoadStart.observed) {
        this._subs.push(service.getObservable('pre_load_start').subscribe(e => this.dvmPreLoadStart.emit(e)));
      }
      if (this.dvmLoadStart.observed) {
        this._subs.push(service.getObservable('load_start').subscribe(e => this.dvmLoadStart.emit(e)));
      }
      if (this.dvmStartLoad.observed) {
        this._subs.push(service.getObservable('start_load').subscribe(e => this.dvmStartLoad.emit(e)));
      }
      if (this.dvmLoadSuccess.observed) {
        this._subs.push(service.getObservable('load_success').subscribe(e => this.dvmLoadSuccess.emit(e)));
      }
      if (this.dvmLoadError.observed) {
        this._subs.push(service.getObservable('load_error').subscribe(e => this.dvmLoadError.emit(e)));
      }
      if (this.dvmReset.observed) {
        this._subs.push(service.getObservable('reset').subscribe(e => this.dvmReset.emit(e)));
      }
      if (this.dvmUpdate.observed) {
        this._subs.push(service.getObservable('update').subscribe(e => this.dvmUpdate.emit(e)));
      }
      if (this.dvmClick.observed) {
        this._subs.push(service.getObservable('click').subscribe(e => this.dvmClick.emit(e)));
      }
      if (this.dvmDblclick.observed) {
        this._subs.push(service.getObservable('dblclick').subscribe(e => this.dvmDblclick.emit(e)));
      }
      if (this.dvmHover.observed) {
        this._subs.push(service.getObservable('hover').subscribe(e => this.dvmHover.emit(e)));
      }
      if (this.dvmEnter.observed) {
        this._subs.push(service.getObservable('enter').subscribe(e => this.dvmEnter.emit(e)));
      }
      if (this.dvmLeave.observed) {
        this._subs.push(service.getObservable('leave').subscribe(e => this.dvmLeave.emit(e)));
      }
      if (this.dvmUp.observed) {
        this._subs.push(service.getObservable('up').subscribe(e => this.dvmUp.emit(e)));
      }
      if (this.dvmDown.observed) {
        this._subs.push(service.getObservable('down').subscribe(e => this.dvmDown.emit(e)));
      }
      if (this.dvmMove.observed) {
        this._subs.push(service.getObservable('move').subscribe(e => this.dvmMove.emit(e)));
      }
      if (this.dvmContextmenu.observed) {
        this._subs.push(service.getObservable('contextmenu').subscribe(e => this.dvmContextmenu.emit(e)));
      }
      if (this.dvmWheel.observed) {
        this._subs.push(service.getObservable('wheel').subscribe(e => this.dvmWheel.emit(e)));
      }
      if (this.dvmStateChange.observed) {
        this._subs.push(service.getObservable('state_change').subscribe(e => this.dvmStateChange.emit(e)));
      }
      if (this.dvmSelect.observed) {
        this._subs.push(service.getObservable('select').subscribe(e => this.dvmSelect.emit(e)));
      }
      if (this.dvmUnselect.observed) {
        this._subs.push(service.getObservable('unselect').subscribe(e => this.dvmUnselect.emit(e)));
      }
      if (this.dvmZooming.observed) {
        this._subs.push(service.getObservable('zooming').subscribe(e => this.dvmZooming.emit(e)));
      }
      if (this.dvmPanning.observed) {
        this._subs.push(service.getObservable('panning').subscribe(e => this.dvmPanning.emit(e)));
      }
      if (this.dvmFirstSeen.observed) {
        this._subs.push(service.getObservable('first_seen').subscribe(e => this.dvmFirstSeen.emit(e)));
      }
      if (this.dvmLayerLevelChanged.observed) {
        this._subs.push(service.getObservable('layer_level_changed').subscribe(e => this.dvmLayerLevelChanged.emit(e)));
      }
      if (this.dvmStartSelectionArea.observed) {
        this._subs.push(service.getObservable('start_selection_area').subscribe(e => this.dvmStartSelectionArea.emit(e)));
      }
      if (this.dvmUpdateSelectionArea.observed) {
        this._subs.push(service.getObservable('update_selection_area').subscribe(e => this.dvmUpdateSelectionArea.emit(e)));
      }
      if (this.dvmEndSelectionArea.observed) {
        this._subs.push(service.getObservable('end_selection_area').subscribe(e => this.dvmEndSelectionArea.emit(e)));
      }
      if (this.dvmSubpathEnabled.observed) {
        this._subs.push(service.getObservable('subpath_enabled').subscribe(e => this.dvmSubpathEnabled.emit(e)));
      }
      if (this.dvmNodesCreated.observed) {
        this._subs.push(service.getObservable('nodes_created').subscribe(e => this.dvmNodesCreated.emit(e)));
      }
      if (this.dvmNodeCreated.observed) {
        this._subs.push(service.getObservable('node_created').subscribe(e => this.dvmNodeCreated.emit(e)));
      }
      if (this.dvmNodeEdited.observed) {
        this._subs.push(service.getObservable('node_edited').subscribe(e => this.dvmNodeEdited.emit(e)));
      }
      if (this.dvmNodeRemoved.observed) {
        this._subs.push(service.getObservable('node_removed').subscribe(e => this.dvmNodeRemoved.emit(e)));
      }
      if (this.dvmNodeDestroyed.observed) {
        this._subs.push(service.getObservable('node_destroyed').subscribe(e => this.dvmNodeDestroyed.emit(e)));
      }
      if (this.dvmNodesUpdated.observed) {
        this._subs.push(service.getObservable('nodes_updated').subscribe(e => this.dvmNodesUpdated.emit(e)));
      }
      if (this.dvmEditorSelect.observed) {
        this._subs.push(service.getObservable('editor_select').subscribe(e => this.dvmEditorSelect.emit(e)));
      }
      if (this.dvmEditorUnselect.observed) {
        this._subs.push(service.getObservable('editor_unselect').subscribe(e => this.dvmEditorUnselect.emit(e)));
      }
      if (this.dvmToolEnabled.observed) {
        this._subs.push(service.getObservable('tool_enabled').subscribe(e => this.dvmToolEnabled.emit(e)));
      }
      if (this.dvmToolDisabled.observed) {
        this._subs.push(service.getObservable('tool_disabled').subscribe(e => this.dvmToolDisabled.emit(e)));
      }
      if (this.dvmToolStart.observed) {
        this._subs.push(service.getObservable('tool_start').subscribe(e => this.dvmToolStart.emit(e)));
      }
      if (this.dvmToolEnd.observed) {
        this._subs.push(service.getObservable('tool_end').subscribe(e => this.dvmToolEnd.emit(e)));
      }
      if (this.dvmNodeChangeId.observed) {
        this._subs.push(service.getObservable('node_change_id').subscribe(e => this.dvmNodeChangeId.emit(e)));
      }
      if (this.dvmNodeChangeType.observed) {
        this._subs.push(service.getObservable('node_change_type').subscribe(e => this.dvmNodeChangeType.emit(e)));
      }
    });
  }
  static {
    this.ɵfac = function MapViewerComponent_Factory(t) {
      return new (t || MapViewerComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MapViewerComponent,
      selectors: [["dvm-map-viewer"]],
      viewQuery: function MapViewerComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
          i0.ɵɵviewQuery(_c1, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._container = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._main = _t.first);
        }
      },
      inputs: {
        initOptions: "initOptions",
        loadOptions: "loadOptions",
        service: "service"
      },
      outputs: {
        dvmInit: "dvmInit",
        dvmPreLoadStart: "dvmPreLoadStart",
        dvmLoadStart: "dvmLoadStart",
        dvmStartLoad: "dvmStartLoad",
        dvmLoadSuccess: "dvmLoadSuccess",
        dvmLoadError: "dvmLoadError",
        dvmReset: "dvmReset",
        dvmUpdate: "dvmUpdate",
        dvmClick: "dvmClick",
        dvmDblclick: "dvmDblclick",
        dvmHover: "dvmHover",
        dvmEnter: "dvmEnter",
        dvmLeave: "dvmLeave",
        dvmUp: "dvmUp",
        dvmDown: "dvmDown",
        dvmMove: "dvmMove",
        dvmContextmenu: "dvmContextmenu",
        dvmWheel: "dvmWheel",
        dvmStateChange: "dvmStateChange",
        dvmSelect: "dvmSelect",
        dvmUnselect: "dvmUnselect",
        dvmZooming: "dvmZooming",
        dvmPanning: "dvmPanning",
        dvmFirstSeen: "dvmFirstSeen",
        dvmLayerLevelChanged: "dvmLayerLevelChanged",
        dvmStartSelectionArea: "dvmStartSelectionArea",
        dvmUpdateSelectionArea: "dvmUpdateSelectionArea",
        dvmEndSelectionArea: "dvmEndSelectionArea",
        dvmSubpathEnabled: "dvmSubpathEnabled",
        dvmNodesCreated: "dvmNodesCreated",
        dvmNodeCreated: "dvmNodeCreated",
        dvmNodeEdited: "dvmNodeEdited",
        dvmNodeRemoved: "dvmNodeRemoved",
        dvmNodeDestroyed: "dvmNodeDestroyed",
        dvmNodesUpdated: "dvmNodesUpdated",
        dvmEditorSelect: "dvmEditorSelect",
        dvmEditorUnselect: "dvmEditorUnselect",
        dvmToolEnabled: "dvmToolEnabled",
        dvmToolDisabled: "dvmToolDisabled",
        dvmToolStart: "dvmToolStart",
        dvmToolEnd: "dvmToolEnd",
        dvmNodeChangeId: "dvmNodeChangeId",
        dvmNodeChangeType: "dvmNodeChangeType"
      },
      features: [i0.ɵɵProvidersFeature([MapViewerService]), i0.ɵɵNgOnChangesFeature],
      ngContentSelectors: _c2,
      decls: 6,
      vars: 0,
      consts: [["main", ""], ["container", ""], [1, "ngx-dvm-container-root"], [1, "ngx-dvm-container", "ngx-dvm-container-main"], [1, "ngx-dvm-container", "ngx-dvm-container-content"]],
      template: function MapViewerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 2, 0);
          i0.ɵɵelement(2, "div", 3, 1);
          i0.ɵɵelementStart(4, "div", 4);
          i0.ɵɵprojection(5);
          i0.ɵɵelementEnd()();
        }
      },
      styles: ["[_nghost-%COMP%]{height:100%;border:0;padding:0;margin:0}.ngx-dvm-container-root[_ngcontent-%COMP%]{position:relative;width:100%;border:0;padding:0;margin:0}.ngx-dvm-container[_ngcontent-%COMP%]{position:absolute;width:100%;height:100%;border:0;padding:0;margin:0}.ngx-dvm-container-content[_ngcontent-%COMP%]{pointer-events:none}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MapViewerComponent, [{
    type: Component,
    args: [{
      selector: 'dvm-map-viewer',
      providers: [MapViewerService],
      template: "<div #main class=\"ngx-dvm-container-root\">\n  <div #container class=\"ngx-dvm-container ngx-dvm-container-main\"></div>\n  <div class=\"ngx-dvm-container ngx-dvm-container-content\">\n    <ng-content></ng-content>\n  </div>\n</div>\n",
      styles: [":host{height:100%;border:0;padding:0;margin:0}.ngx-dvm-container-root{position:relative;width:100%;border:0;padding:0;margin:0}.ngx-dvm-container{position:absolute;width:100%;height:100%;border:0;padding:0;margin:0}.ngx-dvm-container-content{pointer-events:none}\n"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    initOptions: [{
      type: Input
    }],
    loadOptions: [{
      type: Input
    }],
    service: [{
      type: Input
    }],
    _container: [{
      type: ViewChild,
      args: ['container']
    }],
    _main: [{
      type: ViewChild,
      args: ['main']
    }],
    dvmInit: [{
      type: Output
    }],
    dvmPreLoadStart: [{
      type: Output
    }],
    dvmLoadStart: [{
      type: Output
    }],
    dvmStartLoad: [{
      type: Output
    }],
    dvmLoadSuccess: [{
      type: Output
    }],
    dvmLoadError: [{
      type: Output
    }],
    dvmReset: [{
      type: Output
    }],
    dvmUpdate: [{
      type: Output
    }],
    dvmClick: [{
      type: Output
    }],
    dvmDblclick: [{
      type: Output
    }],
    dvmHover: [{
      type: Output
    }],
    dvmEnter: [{
      type: Output
    }],
    dvmLeave: [{
      type: Output
    }],
    dvmUp: [{
      type: Output
    }],
    dvmDown: [{
      type: Output
    }],
    dvmMove: [{
      type: Output
    }],
    dvmContextmenu: [{
      type: Output
    }],
    dvmWheel: [{
      type: Output
    }],
    dvmStateChange: [{
      type: Output
    }],
    dvmSelect: [{
      type: Output
    }],
    dvmUnselect: [{
      type: Output
    }],
    dvmZooming: [{
      type: Output
    }],
    dvmPanning: [{
      type: Output
    }],
    dvmFirstSeen: [{
      type: Output
    }],
    dvmLayerLevelChanged: [{
      type: Output
    }],
    dvmStartSelectionArea: [{
      type: Output
    }],
    dvmUpdateSelectionArea: [{
      type: Output
    }],
    dvmEndSelectionArea: [{
      type: Output
    }],
    dvmSubpathEnabled: [{
      type: Output
    }],
    dvmNodesCreated: [{
      type: Output
    }],
    dvmNodeCreated: [{
      type: Output
    }],
    dvmNodeEdited: [{
      type: Output
    }],
    dvmNodeRemoved: [{
      type: Output
    }],
    dvmNodeDestroyed: [{
      type: Output
    }],
    dvmNodesUpdated: [{
      type: Output
    }],
    dvmEditorSelect: [{
      type: Output
    }],
    dvmEditorUnselect: [{
      type: Output
    }],
    dvmToolEnabled: [{
      type: Output
    }],
    dvmToolDisabled: [{
      type: Output
    }],
    dvmToolStart: [{
      type: Output
    }],
    dvmToolEnd: [{
      type: Output
    }],
    dvmNodeChangeId: [{
      type: Output
    }],
    dvmNodeChangeType: [{
      type: Output
    }]
  });
})();
class ApiCoreFlags {
  /**
   * Specifies the rescaling mode of the 3d viewer.
   *
   * - If true: The 3d viewer will take up the 100% of the container width, and the height
   * will be computed multiplying the width by the aspect_ratio. For instance, if aspect_ratio = 0.5,
   * the height will be 500px when the Viewer container has width of 1000px.
   * - If false: The Viewer will take up the 100% of the container width and height. It is important
   * to ensure that the container has some height, or it will be set as 0px. aspect_ratio will be ignored.
   */
  get fixed_aspect_ratio() {
    return this.zone.runOutsideAngular(() => this.viewer3d.flags.fixed_aspect_ratio);
  }
  set fixed_aspect_ratio(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.flags.fixed_aspect_ratio = value);
  }
  /**
   * show/hides fullscreen button.
   *
   * - If true: The 3d viewer will take up the 100% of the container width, and the
   * height will be computed multiplying the width by the aspect_ratio. For instance,
   * if aspect_ratio = 0.5, the height will be 500px when the Viewer container has width of 1000px.
   * - If false: The Viewer will take up the 100% of the container width and height.
   * It is important to ensure that the container has some height, or it will be
   * set as 0px. aspect_ratio will be ignored.
   */
  get show_fullscreen_button() {
    return this.zone.runOutsideAngular(() => this.viewer3d.flags.show_fullscreen_button);
  }
  set show_fullscreen_button(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.flags.show_fullscreen_button = value);
  }
  /**
   * Enables/disables the loading screen when a view is loaded.
   */
  get show_loading_screen() {
    return this.zone.runOutsideAngular(() => this.viewer3d.flags.show_loading_screen);
  }
  set show_loading_screen(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.flags.show_loading_screen = value);
  }
  /**
   * Enables/disables the tutorial for the first view loaded. It should be disabled before loading
   * a view.
   */
  get show_tutorial() {
    return this.zone.runOutsideAngular(() => this.viewer3d.flags.show_tutorial);
  }
  set show_tutorial(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.flags.show_tutorial = value);
  }
  /**
   * Enables/disables events bound to left/right arrows. If `true`, the camera will rotate with keyboard arrows.
   *
   * @warning You need to have the focus of the viewer container to be able to rotate. If this is not enough,
   * we recommend binding your own specific keyboard events for your website, and use the `rotateCamera` method.
   */
  get keyboard_arrows_rotation() {
    return this.zone.runOutsideAngular(() => this.viewer3d.flags.keyboard_arrows_rotation);
  }
  set keyboard_arrows_rotation(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.flags.keyboard_arrows_rotation = value);
  }
  /**
   * Enables or disables panning with mouse or touch.
   *
   * - If `true`: allows panning with mouse or touch.
   * - If `false`: disables panning on the view when using mouse or touch. You still can move with
   * interface buttons or other methods.
   */
  get panning() {
    return this.zone.runOutsideAngular(() => this.viewer3d.flags.panning);
  }
  set panning(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.flags.panning = value);
  }
  /**
   *  Enables or disables zooming with mouse or touch. Not all 3D views allows to zoom, independently
   *  of the value of this flag.
   *
   * - If `true`: allows zooming on the 3d view (if the view allows it) with mouse scroll or pinch.
   * - If `false`: disables zooming on the view with scroll or pinch. You still can zoom in and
   * out with interface buttons or other API methods. Disabling it will allow scrolling on the page over the view.
   */
  get zooming() {
    return this.zone.runOutsideAngular(() => this.viewer3d.flags.zooming);
  }
  set zooming(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.flags.zooming = value);
  }
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
  }
}
class ApiCoreInterface {
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
  }
  /**
   * Sets an element position. You can get the available elements from `getInterfaceElementsList`
   * @param element_name Element name
   * @param element_position element position
   */
  setInterfaceElementPosition(element_name, element_position) {
    return this.zone.runOutsideAngular(() => this.viewer3d.interface.setInterfaceElementPosition(element_name, element_position));
  }
  /**
   * Returns a list with the available elements that can be moved from the interfae
   */
  getInterfaceElementsList() {
    return this.zone.runOutsideAngular(() => this.viewer3d.interface.getInterfaceElementsList());
  }
  /**
   * Sets the position ot the 'powered by' label.
   * @param position
   */
  setLabelPosition(position) {
    return this.zone.runOutsideAngular(() => this.viewer3d.interface.setLabelPosition(position));
  }
  /**
   * Sets the 'powered by' label offset.
   * @param x x offset in pixels.
   * @param y y position in pixels.
   */
  setLabelOffset(x, y) {
    return this.zone.runOutsideAngular(() => this.viewer3d.interface.setLabelOffset(x, y));
  }
  /**
   * Sets 'powered by' label font size.
   * @param size
   */
  setLabelFontSize(size) {
    return this.zone.runOutsideAngular(() => this.viewer3d.interface.setLabelFontSize(size));
  }
  /**
   * Overrides the default element that triggers fullscreen
   * @param element
   */
  overrideFullscreenElement(element) {
    return this.zone.runOutsideAngular(() => this.viewer3d.interface.overrideFullscreenElement ? this.viewer3d.interface.overrideFullscreenElement(element) : undefined);
  }
}
class ApiCoreVR {
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
    this.flags = new ApiCoreVRFlags(service, zone, viewer3d);
  }
  /**
   * enables/disables the panning with the orientation controls of the device.
   */
  toggleDeviceOrientationControls() {
    return this.zone.runOutsideAngular(() => from(this.viewer3d.vr.toggleDeviceOrientationControls()));
  }
  /**
   * enables/disables the stereoscopic camera.
   */
  toggleStereoscopicCamera() {
    return this.zone.runOutsideAngular(() => from(this.viewer3d.vr.toggleStereoscopicCamera()));
  }
  /**
   * Enables/disables VR mode. It's a combination of:
   * - Full screen
   * - Device orientation controls
   * - Stereoscopic camera
   */
  toggleVR() {
    return this.zone.runOutsideAngular(() => from(this.viewer3d.vr.toggleVR()));
  }
}
class ApiCoreVRFlags {
  /**
   * enables/disables the panning with the orientation controls of the device.
   *
   * @warning used as a setter, it calls an asynchronous method.
   * Better use `toggleDeviceOrientationControls` method.
   */
  get device_orientation_controls_enabled() {
    return this.zone.runOutsideAngular(() => this.viewer3d.vr.flags.device_orientation_controls_enabled);
  }
  set device_orientation_controls_enabled(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.vr.flags.device_orientation_controls_enabled = value);
  }
  /**
   * enables/disables the stereoscopic camera.
   *
   * @warning used as a setter, it calls an asynchronous method.
   * Better use `toggleStereoscopicCamera` method.
   */
  get stereoscopic_camera_enabled() {
    return this.zone.runOutsideAngular(() => this.viewer3d.vr.flags.stereoscopic_camera_enabled);
  }
  set stereoscopic_camera_enabled(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.vr.flags.stereoscopic_camera_enabled = value);
  }
  /**
   * Enables/disables VR mode. It's a combination of:
   * - Full screen
   * - Device orientation controls
   * - Stereoscopic camera
   * @warning used as a setter, it calls an asynchronous method.
   * Better use `toggleVR` method.
   */
  get vr_enabled() {
    return this.zone.runOutsideAngular(() => this.viewer3d.vr.flags.vr_enabled);
  }
  set vr_enabled(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.vr.flags.vr_enabled = value);
  }
  /**
   * show/hide vr button
   */
  get show_vr_button() {
    return this.zone.runOutsideAngular(() => this.viewer3d.vr.flags.show_vr_button);
  }
  set show_vr_button(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.vr.flags.show_vr_button = value);
  }
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
  }
}
class ApiCoreNodes {
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
  }
  /**
   * Return all nodes in the view.
   */
  getAllNodes() {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.getAllNodes());
  }
  /**
   * Returns a node by its id. `null` if it doesn't exist.
   * @param node_id id of the node.
   */
  getNodeById(node_id) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.getNodeById(node_id));
  }
  /**
   * returns an array of nodes by their type.
   * @param type type of the nodes.
   */
  getNodesByType(type) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.getNodesByType(type));
  }
  /**
   * returns an array of nodes by their state. Optionally you can specify also their type.
   * @param state state of the nodes.
   * @param [type] type of the nodes.
   */
  getNodesByState(state, type) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.getNodesByState(state, type));
  }
  /**
   * returns an array of nodes by their groups. Optionally you can specify also their type.
   * @param group group or groups
   * @param [intersection] if multiple groups has been specified, false will be the union, and true will be
   * the intersection. `false` by default.
   * @param [type] type of the nodes
   */
  getNodesByGroups(group, intersection, type) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.getNodesByGroups(group, intersection, type));
  }
  /**
   * Set the state of a node or nodes. This method will check if a node can change its state according
   * to its state's graph.
   * @param nodes nodes/nodes to change its/their state.
   * @param state new state.
   * @param [type] Optionally their type can be specified to filter them.
   * @param [force] if this flag is `true` the state's graph won't be checked.
   */
  setNodesState(nodes, state, type, force) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.setNodesState(nodes, state, type, force));
  }
  /**
   * Adds a node or nodes to a group.
   * @param nodes node/nodes to be added to.
   * @param group target group.
   */
  addNodesToGroup(nodes, group) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.addNodesToGroup(nodes, group));
  }
  /**
   * Removes a node or nodes from a group.
   * @param nodes node/nodes to be removed from.
   * @param group target group.
   */
  removeNodesFromGroup(nodes, group) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.removeNodesFromGroup(nodes, group));
  }
  /**
   * Selects a node or nodes (change its/their state from `'available'` to `'selected'`) if it's
   * possible according to their state's graph.
   * @param nodes node/nodes to be selected.
   * @param [type] Optionally their type can be specified to filter them.
   */
  select(nodes, type) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.select(nodes, type));
  }
  /**
   * Unselects a node or nodes (change its/their state from `'selected'` to `'available'`) if it's
   * possible according to their state's graph.
   * @param nodes node/nodes to be unselected.
   * @param [type] Optionally their type can be specified to filter them.
   */
  unselect(nodes, type) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.unselect(nodes, type));
  }
  /**
   * Unselects any node currently selected (change its/their state from `'selected'` to `'available'`).
   * @param [type] Optionally their type can be specified to filter them.
   */
  unselectAll(type) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.unselectAll(type));
  }
  /**
   * Sets a node or nodes state to available (change its/their state from `'unavailable'` to `'available'`)
   * @param nodes node/nodes to be set.
   * @param [type] Optionally their type can be specified to filter them.
   */
  setAvailable(nodes, type) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.setAvailable(nodes, type));
  }
  /**
   * Sets a node or nodes state to `'unavailable'` (change its state to `'unavailable'` from
   * any transitionable state).
   * @param nodes node/nodes to be set.
   * @param [type] Optionally their type can be specified to filter them.
   */
  setUnavailable(nodes, type) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.setUnavailable(nodes, type));
  }
  /**
   * Sets the general availability of a scene. all `'unavailable'` specified nodes will change their
   * state to `'available'`. Any node NOT in the list will change its state to `'unavailable'` (if they can
   * transition to it).
   * @param nodes node/nodes to be set.
   * @param [type] Optionally their type can be specified to filter them.
   */
  setAvailability(nodes, type) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.setAvailability(nodes, type));
  }
  /**
   * Sets a node or nodes state to `'disable'` from any state.
   * @param nodes node/nodes to be set.
   * @param [type] Optionally their type can be specified to filter them.
   */
  disable(nodes, type) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.disable(nodes, type));
  }
  /**
   * Sets a node or nodes state to `'unavailable'` from any `'disabled'` state.
   * @param nodes node/nodes to be set.
   * @param [type] Optionally their type can be specified to filter them.
   */
  enable(nodes, type) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.enable(nodes, type));
  }
  /**
   * Sets the maximum allowed elements on a particular state for a specific type. `Infinity` by default.
   * @param type type to set.
   * @param state state to set.
   * @param max must be integer greater or equal to 0.
   */
  setMax(type, state, max) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.setMax(type, state, max));
  }
  /**
   * Gets the maximum allowed elements on a particular state for a specific type. `Infinity` by default.
   * @param type type to set.
   * @param state state to set.
   * @returns maximum allowed selection for a particular type + state.
   */
  getMax(type, state) {
    return this.zone.runOutsideAngular(() => this.viewer3d.nodes.getMax(type, state));
  }
}
class ApiGallery {
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
    this.flags = new ApiGalleryFlags(service, zone, viewer3d);
  }
  /**
   * [Gallery plugin]
   *
   * Returns `true` if the current loaded view has gallery images available, `false` if not.
   */
  hasGallery() {
    return this.zone.runOutsideAngular(() => this.viewer3d.gallery.hasGallery());
  }
  /**
   * [Gallery plugin]
   *
   * Returns the current visible view. Its values may be:
   *
   * - `"view3d"`: The view 3d is currently visible.
   * - `"gallery"`: The gallery is currently visible.
   * - `null`: No view.
   */
  getCurrentView() {
    return this.zone.runOutsideAngular(() => this.viewer3d.gallery.getCurrentView());
  }
  /**
   * [Gallery plugin]
   *
   * Alternates between view3d and gallery.
   */
  toggleCurrentView() {
    return this.zone.runOutsideAngular(() => this.viewer3d.gallery.toggleCurrentView());
  }
}
class ApiGalleryFlags {
  /**
   * [Gallery plugin]
   *
   * Enables or disables the gallery button to toggle between gallery and view 3d.
   */
  get show_gallery_button() {
    return this.zone.runOutsideAngular(() => this.viewer3d.gallery.flags.show_gallery_button);
  }
  set show_gallery_button(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.gallery.flags.show_gallery_button = value);
  }
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
  }
}
class ApiRelated {
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
  }
  /**
   * [Related plugin]
   *
   * If the current view has related views, it will return an object with the related views,
   * otherwise it will return null.
   */
  getRelatedViews() {
    return this.zone.runOutsideAngular(() => this.viewer3d.related.getRelatedViews());
  }
  /**
   * [Related plugin]
   *
   * Returns the related view by its identifier name. If it does not exist, it will return `null`.
   * @param name unique identifier name of the related view.
   */
  getRelatedViewByName(name) {
    return this.zone.runOutsideAngular(() => this.viewer3d.related.getRelatedViewByName(name));
  }
}
class ApiIndoor {
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
    this.flags = new ApiIndoorFlags(service, zone, viewer3d);
  }
  /**
   * [Indoor plugin]
   *
   * If the view has an indoor related view, it will return the same output as
   * `related.getRelatedViewsByName()`, or `null` if it has not.
   */
  getIndoorRelatedView() {
    return this.zone.runOutsideAngular(() => this.viewer3d.indoor.getIndoorRelatedView());
  }
}
class ApiIndoorFlags {
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
  }
  /**
   * [Indoor plugin]
   *
   * enables or disables the indoor preview.
   */
  get show_indoor_preview() {
    return this.zone.runOutsideAngular(() => this.viewer3d.indoor.flags.show_indoor_preview);
  }
  set show_indoor_preview(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.indoor.flags.show_indoor_preview = value);
  }
}
class ApiNavigation {
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
    this.flags = new ApiNavigationFlags(service, zone, viewer3d);
  }
  /**
   * Returns the closest destination (if any) to a node from certain intersection
   * @param node
   * @param intersection
   */
  getClosestDestination(node, intersection) {
    return this.zone.runOutsideAngular(() => this.viewer3d.navigation.getClosestDestination(node, intersection));
  }
  /**
   * [Navigation plugin]
   *
   * Returns the closest destination (if any) from a particular direction.
   * @param direction
   */
  getClosestDestinationFromDirection(direction) {
    return this.zone.runOutsideAngular(() => this.viewer3d.navigation.getClosestDestinationFromDirection(direction));
  }
}
class ApiNavigationFlags {
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
  }
  /**
   * [Navigation plugin]
   *
   * Allows venue-viewer with up and down keys
   */
  get keyboard_arrows_navigation() {
    return this.zone.runOutsideAngular(() => this.viewer3d.navigation.flags.keyboard_arrows_navigation);
  }
  set keyboard_arrows_navigation(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.navigation.flags.keyboard_arrows_navigation = value);
  }
}
class ApiRelatedPreviews {
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
    this.flags = new ApiRelatedPreviewsFlags(service, zone, viewer3d);
  }
  generateRelatedPreview(id) {
    return this.zone.runOutsideAngular(() => this.viewer3d.related_previews.generateRelatedPreview(id));
  }
  getTrackedPreviewIds() {
    return this.zone.runOutsideAngular(() => this.viewer3d.related_previews.getTrackedPreviewIds());
  }
  showPreview(id) {
    return this.zone.runOutsideAngular(() => this.viewer3d.related_previews.showPreview(id));
  }
  hidePreview(id) {
    return this.zone.runOutsideAngular(() => this.viewer3d.related_previews.hidePreview(id));
  }
  showTitle(id) {
    return this.zone.runOutsideAngular(() => this.viewer3d.related_previews.showTitle(id));
  }
  hideTitle(id) {
    return this.zone.runOutsideAngular(() => this.viewer3d.related_previews.hideTitle(id));
  }
}
class ApiRelatedPreviewsFlags {
  constructor(service, zone, viewer3d) {
    this.service = service;
    this.zone = zone;
    this.viewer3d = viewer3d;
  }
  get enabled() {
    return this.zone.runOutsideAngular(() => this.viewer3d.related_previews.flags.enabled);
  }
  set enabled(value) {
    this.zone.runOutsideAngular(() => this.viewer3d.related_previews.flags.enabled = value);
  }
}
class Viewer3dService extends DvmModuleService {
  /** Nodes API */
  get nodes() {
    return this._nodes;
  }
  /** VR API */
  get vr() {
    return this._vr;
  }
  /** interface API */
  get interface() {
    return this._interface;
  }
  /** Core flags */
  get flags() {
    return this._flags;
  }
  /** Navigation plugin API */
  get navigation() {
    return this._navigation;
  }
  /** Indoor plugin API */
  get indoor() {
    return this._indoor;
  }
  /** Related plugin API */
  get related() {
    return this._related;
  }
  /** RelatedPreviews plugin API */
  get related_previews() {
    return this._related_previews;
  }
  /** Gallery plugin API */
  get gallery() {
    return this._gallery;
  }
  /**
   * aspect ratio from the 3d viewer. The value is obtained by dividing height/width. For instance,
   * to use a 16:9 aspect ratio, the value is 9/16. o 9/16 by default.
   */
  get aspect_ratio() {
    return this._zone.runOutsideAngular(() => this.native_module.aspect_ratio);
  }
  set aspect_ratio(ratio) {
    this._zone.runOutsideAngular(() => this.native_module.aspect_ratio = ratio);
  }
  /**
   * Sets the canvas pixel ratio. By default, it's set to 1.
   * @url https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
   */
  get pixel_ratio() {
    return this._zone.runOutsideAngular(() => this.native_module.pixel_ratio);
  }
  set pixel_ratio(ratio) {
    this._zone.runOutsideAngular(() => this.native_module.pixel_ratio = ratio);
  }
  /**
   * Field of view (in degrees)
   */
  get fov() {
    return this._zone.runOutsideAngular(() => this.native_module.fov);
  }
  set fov(deg) {
    this._zone.runOutsideAngular(() => this.native_module.fov = deg);
  }
  get linkedComponent() {
    return this._linkedComponent;
  }
  constructor(dvm, zone, appRef) {
    super(dvm, zone, appRef);
    this._linkedComponent = null;
    registerViewer3d(this, this.service_id);
  }
  /** @Internal */
  ngOnDestroy() {
    this.destroy();
  }
  /**
   * @internal
   * @param component
   */
  __link__(component) {
    if (this._linkedComponent) throw new Error('Already linked to a component');
    this._linkedComponent = component;
    if (this.isInitialized()) {
      this.native_module.setContainer(this._linkedComponent.containerElementRef.nativeElement);
    }
  }
  /**
   * @internal
   */
  __unlink__() {
    this._linkedComponent = null;
  }
  /**
   * Initializes manually the module. Needs to be already linked to a dvm-viewer3d component.
   * @param initOptions
   */
  initialize(initOptions) {
    if (this._linkedComponent == null) throw new Error('Not linked to a component');
    const obj = Object.assign({
      container: this._linkedComponent.containerElementRef.nativeElement
    }, initOptions);
    return this._zone.runOutsideAngular(() => {
      if (!this._started) {
        this._started = true;
        const o = this.dvm.loadViewer3dModule(obj).subscribe(viewer3d => {
          this._native_module = viewer3d;
          this._flags = new ApiCoreFlags(this, this._zone, this._native_module);
          this._interface = new ApiCoreInterface(this, this._zone, this._native_module);
          this._vr = new ApiCoreVR(this, this._zone, this._native_module);
          this._nodes = new ApiCoreNodes(this, this._zone, this._native_module);
          if (this._native_module.gallery) {
            this._gallery = new ApiGallery(this, this._zone, this._native_module);
          }
          if (this._native_module.related) {
            this._related = new ApiRelated(this, this._zone, this._native_module);
          }
          if (this._native_module.related_previews) {
            this._related_previews = new ApiRelatedPreviews(this, this._zone, this._native_module);
          }
          if (this._native_module.indoor) {
            this._indoor = new ApiIndoor(this, this._zone, this._native_module);
          }
          if (this._native_module.navigation) {
            this._navigation = new ApiNavigation(this, this._zone, this._native_module);
          }
          this._initialized = true;
          this._initializeSubscriptions();
          for (const [key, subject] of this._subjects) {
            this._registerSubscriptions(key, subject);
          }
          this._initSubject$.next(this);
          this._initSubject$.complete();
        });
        this._subscriptions.push(o);
      }
      return this.waitInitialize();
    });
  }
  /**
   * Returns the id of the current loaded venue. If there is no venue loaded yet, it will return `null`.
   */
  getVenueId() {
    return this._zone.runOutsideAngular(() => this.native_module.getVenueId());
  }
  /**
   * Returns the id of the current loaded 3d view. If there is no view loaded yet, it will return `null`.
   */
  getViewId() {
    return this._zone.runOutsideAngular(() => this.native_module.getViewId());
  }
  /**
   * Returns the id of the current loaded 3d view. If there is no view loaded yet, it will return `null`.
   */
  getSetId() {
    return this._zone.runOutsideAngular(() => this.native_module.getSetId());
  }
  /**
   * Unloads the current 3d view (if any). It is necessary to call loadView3d again after this.
   */
  reset() {
    return this._zone.runOutsideAngular(() => {
      this.native_module.reset();
      this.needTick();
    });
  }
  /**
   * Asynchronous method that loads a View 3D.
   * @param loadOptions Object with the information of the 3d view to be loaded.
   * @returns Observable that will be completed when the 3d view is loaded successfully,
   * or it will fail if there is a problem loading the 3d view.
   *
   * @example
   * var obj = { venue_id: "venue", view_id: "view" };
   * viewer3d.loadView3d(obj).subscribe({
   *       next: () => {
   *         console.log('VIEW LOADED');
   *       },
   *       error: (err) => {
   *         // Something went wrong. Maybe you do not have permissions or the view does not exist
   *         console.error(err);
   *       },
   *     });
   */
  loadView3d(loadOptions) {
    return this._zone.runOutsideAngular(() => from(this.native_module.loadView3d(loadOptions).then(r => {
      this.needTick();
      return r;
    })));
  }
  /**
   * @deprecated use hasView3d
   * @param obj
   */
  checkView3d(obj) {
    return this._zone.runOutsideAngular(() => from(this.native_module.checkView3d(obj)));
  }
  /**
   * Asynchronous method that checks if a specific 3d view exists.
   *
   * @param obj Object with the information of the 3d view to be checked.
   * @returns Observable that will be completed with a boolean, being `true` if the view exists,
   * or `false` if it does not. The Observable will fail if there is another problem,
   * for example if you do not have permissions to access this venue, regardless of
   * whether the view exists or not.
   *
   * @example
   * var obj = { venue_id: 'venue', view_id: 'view' };
   * viewer3d.checkView3d(obj).subscribe({
   *   next: (exists) => {
   *     if (exists) {
   *       // The view does exist
   *       viewer3d.loadView3d(obj).subscribe((result) => {
   *         console.log('VIEW LOADED');
   *       });
   *     } else {
   *       // The view does not exist.
   *       doSomething();
   *     }
   *   },
   *   error: (err) => {
   *     // Something went wrong. Maybe you do not have permissions
   *     console.error(err);
   *   },
   * });
   */
  has3dView(obj) {
    return this._zone.runOutsideAngular(() => from(this.native_module.has3dView(obj)));
  }
  /**
   * Asynchronous method that checks if a specific venue has generated 3d views material.
   * @param venueId
   */
  has3dViews(venueId) {
    return this._zone.runOutsideAngular(() => from(this.native_module.has3dViews(venueId)));
  }
  /**
   * Asynchronous method that returns an image with a preview of the 3d view.
   *
   * The image has a size of 256x256 pixels.
   *
   * @param options Object with the information of the 3d view thumbnail.
   * @param [url] `true` if you need the image url, otherwise you will get an
   * HTMLImageElement. `false` by default.
   * @returns Observable that completes with an url to the image if url=true, HTMLImageElement otherwise.
   */
  getThumbnail(options, url) {
    return this._zone.runOutsideAngular(() => from(this.native_module.getThumbnail(options, url)));
  }
  /**
   * Detaches the 3d viewer from the DOM.
   */
  close() {
    return this._zone.runOutsideAngular(() => {
      this.native_module.close();
      this.needTick();
    });
  }
  /**
   * Appends the 3d viewer to the DOM. If there is no view loaded, a black image will be shown.
   */
  open() {
    return this._zone.runOutsideAngular(() => {
      this.native_module.open();
      this.needTick();
    });
  }
  /**
   * enables/disables full screen mode.
   *
   * This method triggers the following callbacks:
   * - fullscreen_enabled
   * - fullscreen_disabled
   *
   * @warning Due to browser security measures, this method should be called from an event
   * triggered by the user. Otherwise, the browser could cancel the action.
   *
   * @returns Observable that will be completed once the fullscreen request has been fulfilled. If the request fails,
   * for example if the browser blocks de request because it has been requested outside a user event, it
   * will fail.
   */
  toggleFullscreen() {
    return this._zone.runOutsideAngular(() => from(this.native_module.toggleFullscreen()));
  }
  /**
   * @param vec point at which the camera will look
   * @param time time of the interpolation animation from the current camera rotation
   * @param [local] `true` if the point is in local coordinates. `false` by default.
   */
  lookAt(vec, time, local) {
    return this._zone.runOutsideAngular(() => from(this.native_module.lookAt(vec, time, local)));
  }
  /**
   * Rotates the camera a certain angle.
   * @param spherical - [phi, theta]
   * @param [inertia] - the rotation will generate inertia if this is `true`. `false` by default.
   */
  rotateCamera(spherical, inertia) {
    return this._zone.runOutsideAngular(() => this.native_module.rotateCamera(spherical, inertia));
  }
  /**
   * Sets the camera rotation to a specific angle
   * @param spherical - [phi, theta]
   */
  setCameraRotation(spherical) {
    return this._zone.runOutsideAngular(() => this.native_module.setCameraRotation(spherical));
  }
  /**
   * Return the current rotation angle of the camera.
   * @output spherical - [phi, theta]
   */
  getCameraRotation() {
    return this._zone.runOutsideAngular(() => this.native_module.getCameraRotation());
  }
  setStyles(styles_tree) {
    return this._zone.runOutsideAngular(() => from(this.native_module.setStyles(styles_tree)));
  }
  getStyles() {
    return this._zone.runOutsideAngular(() => this.native_module.getStyles());
  }
  /**
   * ToDo
   */
  focusAt(target, time, behaviour) {
    return this._zone.runOutsideAngular(() => from(this.native_module.focusAt(target, time, behaviour)));
  }
  /**
   * Asynchronous method resolves in a panoramic view description.
   *
   * @param [obj] Object with the information of the 3d view. If no input is passed, it will get
   * the description of the current loaded view (if any).
   *
   * @returns promise that will be resolved with an object with "title" and "description".
   *
   * @example
   *
   * var obj = { venue_id: "venue", view_id: "view" };
   * viewer3d.getViewDescription(obj).subscribe({
   *     next: (result) => {
   *        console.log("Title:", result.title);
   *        console.log("Description:", result.description);
   *     },
   *     error: (err) => {
   *         // Something went wrong. Maybe you do not have permissions or the view doesn't exist.
   *         console.error(err);
   *     },
   * });
   */
  getViewDescription(obj) {
    return this._zone.runOutsideAngular(() => from(this.native_module.getViewDescription(obj)));
  }
  /**
   * Returns an Observable that wil push every time when the module trigger the subscribed trigger.
   * @param trigger trigger you are going to subscribe to.
   */
  getObservable(trigger) {
    let subject = this._subjects.get(trigger);
    if (subject == null) {
      subject = new Subject();
      this._subjects.set(trigger, subject);
      if (this._native_module) {
        this._registerSubscriptions(trigger, subject);
      }
    }
    return subject.asObservable();
  }
  _registerSubscriptions(trigger, subject) {
    const s = this.native_module.subscribe(trigger, (...args) => {
      subject.next(this._genViewer3dEventObj(args[0]));
    });
    this._subscriptions.push(s);
  }
  _genViewer3dEventObj(event) {
    const service = this;
    return {
      service,
      event
    };
  }
  _initializeSubscriptions() {
    super._initializeSubscriptions();
    this._subscriptions.push(this.getObservable('fullscreen_enabled').subscribe(() => this.needTick()));
    this._subscriptions.push(this.getObservable('fullscreen_disabled').subscribe(() => this.needTick()));
    this._subscriptions.push(this.getObservable('vr_enabled').subscribe(() => this.needTick()));
    this._subscriptions.push(this.getObservable('vr_disabled').subscribe(() => this.needTick()));
  }
  static {
    this.ɵfac = function Viewer3dService_Factory(t) {
      return new (t || Viewer3dService)(i0.ɵɵinject(DvmService), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i0.ApplicationRef));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: Viewer3dService,
      factory: Viewer3dService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Viewer3dService, [{
    type: Injectable
  }], function () {
    return [{
      type: DvmService
    }, {
      type: i0.NgZone
    }, {
      type: i0.ApplicationRef
    }];
  }, null);
})();
const isIpad = /iPad/i.test(window.navigator.userAgent);
// const isIpad = true;
class Viewer3dComponent {
  get needFakeFullScreen() {
    return this._needFakeFullScreen;
  }
  get isFullScreen() {
    return this._isFullScreen;
  }
  /**
   * Returns the native module. It should be used only for
   * debugging or internal purposes.
   * @internal
   */
  get nativeModule() {
    if (!this._nativeModule) {
      throw new Error('3d viewer not initialized yet');
    }
    return this._nativeModule;
  }
  /**
   * Returns ElementRef of the module container
   */
  get containerElementRef() {
    return this._container;
  }
  get initOptions() {
    return this._initOptions;
  }
  set initOptions(input) {
    if (!this._nativeModule) {
      this._initOptions = input;
    }
  }
  get loadOptions() {
    return this._loadOptions;
  }
  set loadOptions(input) {
    this._loadOptions = input;
  }
  get service() {
    return this._service;
  }
  set service(input) {
    if (!this.service) {
      this._service = input;
    }
  }
  constructor(componentElementRef, _zone, _injector, _vcr, _renderer) {
    this.componentElementRef = componentElementRef;
    this._zone = _zone;
    this._injector = _injector;
    this._vcr = _vcr;
    this._renderer = _renderer;
    /** CORE **/
    this.dvmInit = new EventEmitter();
    this.dvmLoadStart = new EventEmitter();
    this.dvmStartLoad = new EventEmitter();
    this.dvmLoadSuccess = new EventEmitter();
    this.dvmLoadError = new EventEmitter();
    this.dvmEndLoadAnimation = new EventEmitter();
    this.dvmReset = new EventEmitter();
    this.dvmUpdate = new EventEmitter();
    /** FROM MOUSE/TOUCH DEVICES **/
    this.dvmClick = new EventEmitter();
    this.dvmDblclick = new EventEmitter();
    this.dvmHover = new EventEmitter();
    this.dvmEnter = new EventEmitter();
    this.dvmLeave = new EventEmitter();
    this.dvmUp = new EventEmitter();
    this.dvmDown = new EventEmitter();
    this.dvmMove = new EventEmitter();
    this.dvmContextmenu = new EventEmitter();
    this.dvmWheel = new EventEmitter();
    /** CAMERA **/
    this.dvmPanning = new EventEmitter();
    /** FULLSCREEN */
    this.dvmFullscreenEnabled = new EventEmitter();
    this.dvmFullscreenDisabled = new EventEmitter();
    /** INDOOR PLUGIN */
    this.dvmCLickIndoorPreview = new EventEmitter();
    /** NAVIGATION PLUGIN */
    this.dvmClickNavigationNode = new EventEmitter();
    this.dvmKeyboardArrowsNavigation = new EventEmitter();
    // public readonly viewer3dService: Viewer3dService;
    this._nativeModule = null;
    this.load$ = new Subject();
    this.debounceLoad$ = this.load$.pipe(debounce(() => timer(this._debounceLoadTime)));
    this._debounceLoadTime = 100;
    this._animationId = 0;
    this._afterViewInit = false;
    this._initOptions = null;
    this._loadOptions = null;
    this._subs = [];
    this._isFullScreen = false;
    this._needFakeFullScreen = false;
  }
  /** @Internal */
  ngOnChanges(changes) {
    if (this.service?.started && changes.loadOptions) {
      const {
        currentValue,
        previousValue
      } = changes.loadOptions;
      if (currentValue?.venue_id !== previousValue?.venue_id || currentValue?.view_id !== previousValue?.view_id) {
        this.load$.next();
      }
    }
    if (this._afterViewInit && !this.service?.started) {
      if (changes.initOptions) {
        const {
          currentValue
        } = changes.initOptions;
        if (currentValue) {
          this.initOptions = currentValue;
          this._subs.push(this.service.initialize(this.initOptions).subscribe(service => {}));
        }
      }
    }
  }
  /** @Internal */
  ngAfterViewInit() {
    this._afterViewInit = true;
    if (!this.service) {
      this.service = this._injector.get(Viewer3dService);
    }
    this.service.__link__(this);
    this._subs.push(this.service.waitInitialize().subscribe(() => {
      this._subs.push(this.debounceLoad$.subscribe(() => {
        if (this.loadOptions) {
          this._subs.push(this.service.loadView3d(this.loadOptions).subscribe(() => {}));
        }
      }));
    }));
    this._subs.push(this.service.waitInitialize().subscribe(service => {
      this._nativeModule = service.native_module;
      const loop = () => {
        let height = '100%';
        if (this.service.flags.fixed_aspect_ratio) {
          const ar = this.service.aspect_ratio;
          const width = this._main.nativeElement.clientWidth;
          height = `${Math.round(width * ar)}px`;
        }
        this._main.nativeElement.style.height = height;
        this._animationId = requestAnimationFrame(loop);
      };
      this._animationId = requestAnimationFrame(loop);
      this.service.interface.overrideFullscreenElement(this._main.nativeElement);
      this._needFakeFullScreen = isIpad || this._main.nativeElement.requestFullscreen == null;
      this._subs.push(this.service.getObservable('fullscreen_enabled').subscribe(event => {
        this._isFullScreen = true;
      }));
      this._subs.push(this.service.getObservable('fullscreen_disabled').subscribe(event => {
        this._isFullScreen = false;
      }));
      this._zone.run(() => this.dvmInit.emit(this));
      this.load$.next();
    }));
    if (!this.service.started && this.initOptions) {
      this._subs.push(this.service.initialize(this.initOptions).subscribe(service => {}));
    }
    this._bindEmitters();
  }
  /** @Internal */
  ngOnDestroy() {
    if (this._animationId != null) {
      cancelAnimationFrame(this._animationId);
    }
    this._animationId = null;
    if (this.nativeModule) {
      this.nativeModule.reset();
      this.nativeModule.close();
    }
    this._subs.forEach(s => s.unsubscribe());
    this.load$.complete();
    this.service.__unlink__();
  }
  /**
   * Changes the container where the dvm-viewer3d component is.
   * @param container it must be a `HTMLDivElement`.
   *
   * @example
   * viewer.setContainer(container.nativeElement);
   */
  setContainer(container) {
    this._renderer.appendChild(container, this.componentElementRef.nativeElement);
  }
  _bindEmitters() {
    this.service.waitInitialize().subscribe(() => {
      const service = this.service;
      // Bind emitters only if there is any. After view init no event emitters should be added from here,
      // so there is no problem on doing that once
      if (this.dvmLoadStart.observed) {
        this._subs.push(service.getObservable('load_start').subscribe(e => this.dvmLoadStart.emit(e)));
      }
      if (this.dvmStartLoad.observed) {
        this._subs.push(service.getObservable('start_load').subscribe(e => this.dvmStartLoad.emit(e)));
      }
      if (this.dvmLoadSuccess.observed) {
        this._subs.push(service.getObservable('load_success').subscribe(e => this.dvmLoadSuccess.emit(e)));
      }
      if (this.dvmLoadError.observed) {
        this._subs.push(service.getObservable('load_error').subscribe(e => this.dvmLoadError.emit(e)));
      }
      if (this.dvmEndLoadAnimation.observed) {
        this._subs.push(service.getObservable('end_load_animation').subscribe(e => this.dvmEndLoadAnimation.emit(e)));
      }
      if (this.dvmReset.observed) {
        this._subs.push(service.getObservable('reset').subscribe(e => this.dvmReset.emit(e)));
      }
      if (this.dvmUpdate.observed) {
        this._subs.push(service.getObservable('update').subscribe(e => this.dvmUpdate.emit(e)));
      }
      if (this.dvmClick.observed) {
        this._subs.push(service.getObservable('click').subscribe(e => this.dvmClick.emit(e)));
      }
      if (this.dvmDblclick.observed) {
        this._subs.push(service.getObservable('dblclick').subscribe(e => this.dvmDblclick.emit(e)));
      }
      if (this.dvmHover.observed) {
        this._subs.push(service.getObservable('hover').subscribe(e => this.dvmHover.emit(e)));
      }
      if (this.dvmEnter.observed) {
        this._subs.push(service.getObservable('enter').subscribe(e => this.dvmEnter.emit(e)));
      }
      if (this.dvmLeave.observed) {
        this._subs.push(service.getObservable('leave').subscribe(e => this.dvmLeave.emit(e)));
      }
      if (this.dvmUp.observed) {
        this._subs.push(service.getObservable('up').subscribe(e => this.dvmUp.emit(e)));
      }
      if (this.dvmDown.observed) {
        this._subs.push(service.getObservable('down').subscribe(e => this.dvmDown.emit(e)));
      }
      if (this.dvmMove.observed) {
        this._subs.push(service.getObservable('move').subscribe(e => this.dvmMove.emit(e)));
      }
      if (this.dvmContextmenu.observed) {
        this._subs.push(service.getObservable('contextmenu').subscribe(e => this.dvmContextmenu.emit(e)));
      }
      if (this.dvmWheel.observed) {
        this._subs.push(service.getObservable('wheel').subscribe(e => this.dvmWheel.emit(e)));
      }
      if (this.dvmPanning.observed) {
        this._subs.push(service.getObservable('panning').subscribe(e => this.dvmPanning.emit(e)));
      }
      if (this.dvmFullscreenEnabled.observed) {
        this._subs.push(service.getObservable('fullscreen_enabled').subscribe(e => this.dvmFullscreenEnabled.emit(e)));
      }
      if (this.dvmFullscreenDisabled.observed) {
        this._subs.push(service.getObservable('fullscreen_disabled').subscribe(e => this.dvmFullscreenDisabled.emit(e)));
      }
      if (this.dvmCLickIndoorPreview.observed) {
        this._subs.push(service.getObservable('click_indoor_preview').subscribe(e => this.dvmCLickIndoorPreview.emit(e)));
      }
      if (this.dvmClickNavigationNode.observed) {
        this._subs.push(service.getObservable('click_navigation_node').subscribe(e => this.dvmClickNavigationNode.emit(e)));
      }
      if (this.dvmKeyboardArrowsNavigation.observed) {
        this._subs.push(service.getObservable('keyboard_arrows_navigation').subscribe(e => this.dvmKeyboardArrowsNavigation.emit(e)));
      }
    });
  }
  static {
    this.ɵfac = function Viewer3dComponent_Factory(t) {
      return new (t || Viewer3dComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: Viewer3dComponent,
      selectors: [["dvm-viewer3d"]],
      viewQuery: function Viewer3dComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
          i0.ɵɵviewQuery(_c1, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._container = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._main = _t.first);
        }
      },
      inputs: {
        initOptions: "initOptions",
        loadOptions: "loadOptions",
        service: "service"
      },
      outputs: {
        dvmInit: "dvmInit",
        dvmLoadStart: "dvmLoadStart",
        dvmStartLoad: "dvmStartLoad",
        dvmLoadSuccess: "dvmLoadSuccess",
        dvmLoadError: "dvmLoadError",
        dvmEndLoadAnimation: "dvmEndLoadAnimation",
        dvmReset: "dvmReset",
        dvmUpdate: "dvmUpdate",
        dvmClick: "dvmClick",
        dvmDblclick: "dvmDblclick",
        dvmHover: "dvmHover",
        dvmEnter: "dvmEnter",
        dvmLeave: "dvmLeave",
        dvmUp: "dvmUp",
        dvmDown: "dvmDown",
        dvmMove: "dvmMove",
        dvmContextmenu: "dvmContextmenu",
        dvmWheel: "dvmWheel",
        dvmPanning: "dvmPanning",
        dvmFullscreenEnabled: "dvmFullscreenEnabled",
        dvmFullscreenDisabled: "dvmFullscreenDisabled",
        dvmCLickIndoorPreview: "dvmCLickIndoorPreview",
        dvmClickNavigationNode: "dvmClickNavigationNode",
        dvmKeyboardArrowsNavigation: "dvmKeyboardArrowsNavigation"
      },
      features: [i0.ɵɵProvidersFeature([Viewer3dService]), i0.ɵɵNgOnChangesFeature],
      ngContentSelectors: _c2,
      decls: 6,
      vars: 4,
      consts: [["main", ""], ["container", ""], [1, "ngx-dvm-container-root"], [1, "ngx-dvm-container", "ngx-dvm-container-main"], [1, "ngx-dvm-container", "ngx-dvm-container-content"]],
      template: function Viewer3dComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 2, 0);
          i0.ɵɵelement(2, "div", 3, 1);
          i0.ɵɵelementStart(4, "div", 4);
          i0.ɵɵprojection(5);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("fullscreen", ctx.isFullScreen)("fullscreen-fake", ctx.isFullScreen && ctx.needFakeFullScreen);
        }
      },
      styles: ["[_nghost-%COMP%]{height:100%;border:0;padding:0;margin:0}.ngx-dvm-container-root[_ngcontent-%COMP%]{position:relative;width:100%;border:0;padding:0;margin:0}.ngx-dvm-container-root.fullscreen[_ngcontent-%COMP%]{background-color:red;width:100%!important;height:100%!important}.ngx-dvm-container-root.fullscreen-fake[_ngcontent-%COMP%]{position:fixed;top:0;left:0;z-index:15777271}.ngx-dvm-container[_ngcontent-%COMP%]{position:absolute;width:100%;height:100%;border:0;padding:0;margin:0}.ngx-dvm-container-content[_ngcontent-%COMP%]{pointer-events:none}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Viewer3dComponent, [{
    type: Component,
    args: [{
      selector: 'dvm-viewer3d',
      providers: [Viewer3dService],
      template: "<div\n  #main\n  class=\"ngx-dvm-container-root\"\n  [class.fullscreen]=\"isFullScreen\"\n  [class.fullscreen-fake]=\"isFullScreen && needFakeFullScreen\">\n  <div #container class=\"ngx-dvm-container ngx-dvm-container-main\"></div>\n  <div class=\"ngx-dvm-container ngx-dvm-container-content\">\n    <ng-content></ng-content>\n  </div>\n</div>\n",
      styles: [":host{height:100%;border:0;padding:0;margin:0}.ngx-dvm-container-root{position:relative;width:100%;border:0;padding:0;margin:0}.ngx-dvm-container-root.fullscreen{background-color:red;width:100%!important;height:100%!important}.ngx-dvm-container-root.fullscreen-fake{position:fixed;top:0;left:0;z-index:15777271}.ngx-dvm-container{position:absolute;width:100%;height:100%;border:0;padding:0;margin:0}.ngx-dvm-container-content{pointer-events:none}\n"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Injector
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    initOptions: [{
      type: Input
    }],
    loadOptions: [{
      type: Input
    }],
    service: [{
      type: Input
    }],
    _container: [{
      type: ViewChild,
      args: ['container']
    }],
    _main: [{
      type: ViewChild,
      args: ['main']
    }],
    dvmInit: [{
      type: Output
    }],
    dvmLoadStart: [{
      type: Output
    }],
    dvmStartLoad: [{
      type: Output
    }],
    dvmLoadSuccess: [{
      type: Output
    }],
    dvmLoadError: [{
      type: Output
    }],
    dvmEndLoadAnimation: [{
      type: Output
    }],
    dvmReset: [{
      type: Output
    }],
    dvmUpdate: [{
      type: Output
    }],
    dvmClick: [{
      type: Output
    }],
    dvmDblclick: [{
      type: Output
    }],
    dvmHover: [{
      type: Output
    }],
    dvmEnter: [{
      type: Output
    }],
    dvmLeave: [{
      type: Output
    }],
    dvmUp: [{
      type: Output
    }],
    dvmDown: [{
      type: Output
    }],
    dvmMove: [{
      type: Output
    }],
    dvmContextmenu: [{
      type: Output
    }],
    dvmWheel: [{
      type: Output
    }],
    dvmPanning: [{
      type: Output
    }],
    dvmFullscreenEnabled: [{
      type: Output
    }],
    dvmFullscreenDisabled: [{
      type: Output
    }],
    dvmCLickIndoorPreview: [{
      type: Output
    }],
    dvmClickNavigationNode: [{
      type: Output
    }],
    dvmKeyboardArrowsNavigation: [{
      type: Output
    }]
  });
})();
class ApiPluginNavigableMinimap {
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
}
class ApiNavigableMinimap {
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
    this.flags = new ApiNavigableMinimapFlags(service, zone, viewer);
  }
}
class ApiNavigableMinimapFlags {
  get minimap_interactive() {
    return this.zone.runOutsideAngular(() => this.viewer.navigable_minimap.flags.minimap_interactive);
  }
  set minimap_interactive(value) {
    this.zone.runOutsideAngular(() => this.viewer.navigable_minimap.flags.minimap_interactive = value);
  }
  get minimap_automatic_load() {
    return this.zone.runOutsideAngular(() => this.viewer.navigable_minimap.flags.minimap_interactive);
  }
  set minimap_automatic_load(value) {
    this.zone.runOutsideAngular(() => this.viewer.navigable_minimap.flags.minimap_interactive = value);
  }
  get minimap_automatic_states() {
    return this.zone.runOutsideAngular(() => this.viewer.navigable_minimap.flags.minimap_automatic_states);
  }
  set minimap_automatic_states(value) {
    this.zone.runOutsideAngular(() => this.viewer.navigable_minimap.flags.minimap_automatic_states = value);
  }
  constructor(service, zone, viewer) {
    this.service = service;
    this.zone = zone;
    this.viewer = viewer;
  }
}
class NavigableMinimapService extends SharedViewerService {
  get flags() {
    return this._flags;
  }
  get navigable_minimap() {
    return this._navigable_minimap;
  }
  get linkedComponent() {
    return this._linkedComponent;
  }
  constructor(dvm, zone, appRef) {
    super(dvm, zone, appRef);
    this.mapViewerService = null;
    this._linkedComponent = null;
  }
  /** @Internal */
  ngOnDestroy() {
    this.destroy();
  }
  /**
   * @internal
   * @param component
   */
  __link__(component) {
    if (this._linkedComponent) throw new Error('Already linked to a component');
    this._linkedComponent = component;
  }
  /**
   * @internal
   */
  __unlink__() {
    this._linkedComponent = null;
  }
  /**
   * Initializes manually the module. Needs to be already linked to a dvm-navigable-minimap component.
   * @param mapViewerService map-viewer module service to be linked.
   */
  initialize(mapViewerService) {
    if (this._linkedComponent == null) throw new Error('Not linked to a component');
    const container = this._linkedComponent.containerElementRef.nativeElement;
    return this._zone.runOutsideAngular(() => {
      if (!this._started) {
        this._started = true;
        this.mapViewerService = mapViewerService;
        const o = mapViewerService.waitInitialize().subscribe(result => {
          if (result.native_module.navigable_minimap) {
            const m = result.native_module.navigable_minimap.getNavigableMinimap();
            if (m) {
              m.setContainer(container);
              this.initializeMinimap(m);
            } else {
              result.native_module.navigable_minimap.createNavigableMinimap({
                container
              }).then(minimap => {
                this.initializeMinimap(minimap);
              }).catch(e => this._initSubject$.error(e));
            }
          } else {
            throw new Error("Linked map-viewer module is missing 'navigable_minimap' plugin");
          }
        });
        this._subscriptions.push(o);
      }
      return this.waitInitialize();
    });
  }
  initializeMinimap(minimap) {
    // TODO
    this._native_module = minimap;
    this._flags = new NavigableMinimapCoreFlags(this, this._zone, minimap);
    this._layers = new ApiCoreLayers(this, this._zone, minimap);
    this._navigable_minimap = new ApiNavigableMinimap(this, this._zone, minimap);
    this._initialized = true;
    this._initializeSubscriptions();
    for (const [key, subject] of this._subjects) {
      this._registerSubscriptions(key, subject);
    }
    this._initSubject$.next(this);
    this._initSubject$.complete();
  }
  static {
    this.ɵfac = function NavigableMinimapService_Factory(t) {
      return new (t || NavigableMinimapService)(i0.ɵɵinject(DvmService), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i0.ApplicationRef));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NavigableMinimapService,
      factory: NavigableMinimapService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NavigableMinimapService, [{
    type: Injectable
  }], function () {
    return [{
      type: DvmService
    }, {
      type: i0.NgZone
    }, {
      type: i0.ApplicationRef
    }];
  }, null);
})();
class NavigableMinimapComponent {
  /**
   * Returns the native module. It should be used only for
   * debugging or internal purposes.
   * @internal
   */
  get nativeModule() {
    if (!this._nativeModule) {
      throw new Error('navigable-minimap not initialized yet');
    }
    return this._nativeModule;
  }
  /**
   * Returns ElementRef of the module container
   */
  get containerElementRef() {
    return this._container;
  }
  get linkedMapViewer() {
    return this._linkedMapViewer;
  }
  set linkedMapViewer(linkedMapViewer) {
    if (this._linkedMapViewer === linkedMapViewer) return;
    if (!this._initialized) {
      this._linkedMapViewer = linkedMapViewer;
      if (this._linkedMapViewer instanceof MapViewerService) {
        this._initialize();
      }
    }
  }
  constructor(componentElementRef, service, zone, vcr, renderer) {
    this.componentElementRef = componentElementRef;
    this.service = service;
    this.zone = zone;
    this.vcr = vcr;
    this.renderer = renderer;
    /** CORE **/
    this.dvmInit = new EventEmitter();
    this.dvmPreLoadStart = new EventEmitter();
    this.dvmLoadStart = new EventEmitter();
    /** @deprecated use dvmLoadStart */
    this.dvmStartLoad = new EventEmitter();
    this.dvmLoadSuccess = new EventEmitter();
    this.dvmLoadError = new EventEmitter();
    this.dvmReset = new EventEmitter();
    this.dvmUpdate = new EventEmitter();
    /** FROM MOUSE/TOUCH DEVICES **/
    this.dvmClick = new EventEmitter();
    this.dvmDblclick = new EventEmitter();
    this.dvmHover = new EventEmitter();
    this.dvmEnter = new EventEmitter();
    this.dvmLeave = new EventEmitter();
    this.dvmUp = new EventEmitter();
    this.dvmDown = new EventEmitter();
    this.dvmMove = new EventEmitter();
    this.dvmContextmenu = new EventEmitter();
    this.dvmWheel = new EventEmitter();
    /** STATES **/
    this.dvmStateChange = new EventEmitter();
    this.dvmSelect = new EventEmitter();
    this.dvmUnselect = new EventEmitter();
    /** LAYERS **/
    this.dvmFirstSeen = new EventEmitter();
    this.dvmLayerLevelChanged = new EventEmitter();
    this._nativeModule = null;
    this._animationId = 0;
    this._afterViewInit = false;
    this._subs = [];
    this._linkedMapViewer = null;
    this._ready = false;
    this._initialized = false;
  }
  /** @Internal */
  ngAfterViewInit() {
    this._afterViewInit = true;
    this.service.__link__(this);
    this._ready = true;
    this._subs.push(this.service.waitInitialize().subscribe(service => {
      this._nativeModule = service.native_module;
      const loop = () => {
        let height = '100%';
        if (this.service.flags.fixed_aspect_ratio) {
          const ar = this.service.aspect_ratio;
          const width = this._main.nativeElement.clientWidth;
          height = `${Math.round(width * ar)}px`;
        }
        this._main.nativeElement.style.height = height;
        this._animationId = requestAnimationFrame(loop);
      };
      this._animationId = requestAnimationFrame(loop);
      this.zone.run(() => this.dvmInit.emit(this));
    }));
    if (!this._initialized && this._linkedMapViewer) {
      this._initialize();
    }
  }
  /** @Internal */
  ngOnDestroy() {
    if (this._animationId != null) {
      cancelAnimationFrame(this._animationId);
    }
    this._animationId = null;
    if (this.nativeModule) {
      this.nativeModule.reset();
      this.nativeModule.close();
    }
    this._subs.forEach(s => s.unsubscribe());
    this.service.__unlink__();
  }
  /**
   * Changes the container where the dvm-navigable-minimap component is.
   * @param container it must be a `HTMLDivElement`.
   *
   * @example
   * viewer.setContainer(container.nativeElement);
   */
  setContainer(container) {
    this.renderer.appendChild(container, this.componentElementRef.nativeElement);
  }
  _initialize() {
    if (!this._ready) return;
    if (this._initialized) {
      throw new Error('Already initialized');
    }
    this._initialized = true;
    if (this.linkedMapViewer == null) {
      throw new Error('Missing linkedMapViewer');
    }
    this._subs.push(this.service.initialize(this.linkedMapViewer).subscribe(service => {}));
    this._bindEmitters();
  }
  _bindEmitters() {
    this.service.waitInitialize().subscribe(() => {
      const service = this.service;
      // Bind emitters only if there is any. After view init no event emitters should be added from here,
      // so there is no problem on doing that once
      if (this.dvmPreLoadStart.observed) {
        this._subs.push(service.getObservable('pre_load_start').subscribe(e => this.dvmPreLoadStart.emit(e)));
      }
      if (this.dvmLoadStart.observed) {
        this._subs.push(service.getObservable('load_start').subscribe(e => this.dvmLoadStart.emit(e)));
      }
      // @deprecated
      if (this.dvmStartLoad.observed) {
        this._subs.push(service.getObservable('start_load').subscribe(e => this.dvmStartLoad.emit(e)));
      }
      if (this.dvmLoadSuccess.observed) {
        this._subs.push(service.getObservable('load_success').subscribe(e => this.dvmLoadSuccess.emit(e)));
      }
      if (this.dvmLoadError.observed) {
        this._subs.push(service.getObservable('load_error').subscribe(e => this.dvmLoadError.emit(e)));
      }
      if (this.dvmReset.observed) {
        this._subs.push(service.getObservable('reset').subscribe(e => this.dvmReset.emit(e)));
      }
      if (this.dvmUpdate.observed) {
        this._subs.push(service.getObservable('update').subscribe(e => this.dvmUpdate.emit(e)));
      }
      if (this.dvmClick.observed) {
        this._subs.push(service.getObservable('click').subscribe(e => this.dvmClick.emit(e)));
      }
      if (this.dvmDblclick.observed) {
        this._subs.push(service.getObservable('dblclick').subscribe(e => this.dvmDblclick.emit(e)));
      }
      if (this.dvmHover.observed) {
        this._subs.push(service.getObservable('hover').subscribe(e => this.dvmHover.emit(e)));
      }
      if (this.dvmEnter.observed) {
        this._subs.push(service.getObservable('enter').subscribe(e => this.dvmEnter.emit(e)));
      }
      if (this.dvmLeave.observed) {
        this._subs.push(service.getObservable('leave').subscribe(e => this.dvmLeave.emit(e)));
      }
      if (this.dvmUp.observed) {
        this._subs.push(service.getObservable('up').subscribe(e => this.dvmUp.emit(e)));
      }
      if (this.dvmDown.observed) {
        this._subs.push(service.getObservable('down').subscribe(e => this.dvmDown.emit(e)));
      }
      if (this.dvmMove.observed) {
        this._subs.push(service.getObservable('move').subscribe(e => this.dvmMove.emit(e)));
      }
      if (this.dvmContextmenu.observed) {
        this._subs.push(service.getObservable('contextmenu').subscribe(e => this.dvmContextmenu.emit(e)));
      }
      if (this.dvmWheel.observed) {
        this._subs.push(service.getObservable('wheel').subscribe(e => this.dvmWheel.emit(e)));
      }
      if (this.dvmStateChange.observed) {
        this._subs.push(service.getObservable('state_change').subscribe(e => this.dvmStateChange.emit(e)));
      }
      if (this.dvmSelect.observed) {
        this._subs.push(service.getObservable('select').subscribe(e => this.dvmSelect.emit(e)));
      }
      if (this.dvmUnselect.observed) {
        this._subs.push(service.getObservable('unselect').subscribe(e => this.dvmUnselect.emit(e)));
      }
      if (this.dvmFirstSeen.observed) {
        this._subs.push(service.getObservable('first_seen').subscribe(e => this.dvmFirstSeen.emit(e)));
      }
      if (this.dvmLayerLevelChanged.observed) {
        this._subs.push(service.getObservable('layer_level_changed').subscribe(e => this.dvmLayerLevelChanged.emit(e)));
      }
    });
  }
  static {
    this.ɵfac = function NavigableMinimapComponent_Factory(t) {
      return new (t || NavigableMinimapComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(NavigableMinimapService), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NavigableMinimapComponent,
      selectors: [["dvm-navigable-minimap"]],
      viewQuery: function NavigableMinimapComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
          i0.ɵɵviewQuery(_c1, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._container = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._main = _t.first);
        }
      },
      inputs: {
        linkedMapViewer: "linkedMapViewer"
      },
      outputs: {
        dvmInit: "dvmInit",
        dvmPreLoadStart: "dvmPreLoadStart",
        dvmLoadStart: "dvmLoadStart",
        dvmStartLoad: "dvmStartLoad",
        dvmLoadSuccess: "dvmLoadSuccess",
        dvmLoadError: "dvmLoadError",
        dvmReset: "dvmReset",
        dvmUpdate: "dvmUpdate",
        dvmClick: "dvmClick",
        dvmDblclick: "dvmDblclick",
        dvmHover: "dvmHover",
        dvmEnter: "dvmEnter",
        dvmLeave: "dvmLeave",
        dvmUp: "dvmUp",
        dvmDown: "dvmDown",
        dvmMove: "dvmMove",
        dvmContextmenu: "dvmContextmenu",
        dvmWheel: "dvmWheel",
        dvmStateChange: "dvmStateChange",
        dvmSelect: "dvmSelect",
        dvmUnselect: "dvmUnselect",
        dvmFirstSeen: "dvmFirstSeen",
        dvmLayerLevelChanged: "dvmLayerLevelChanged"
      },
      features: [i0.ɵɵProvidersFeature([NavigableMinimapService])],
      ngContentSelectors: _c2,
      decls: 6,
      vars: 0,
      consts: [["main", ""], ["container", ""], [1, "ngx-dvm-container-root"], [1, "ngx-dvm-container", "ngx-dvm-container-main"], [1, "ngx-dvm-container", "ngx-dvm-container-content"]],
      template: function NavigableMinimapComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 2, 0);
          i0.ɵɵelement(2, "div", 3, 1);
          i0.ɵɵelementStart(4, "div", 4);
          i0.ɵɵprojection(5);
          i0.ɵɵelementEnd()();
        }
      },
      styles: [_c3]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NavigableMinimapComponent, [{
    type: Component,
    args: [{
      selector: 'dvm-navigable-minimap',
      providers: [NavigableMinimapService],
      template: "<div #main class=\"ngx-dvm-container-root\">\n  <div #container class=\"ngx-dvm-container ngx-dvm-container-main\"></div>\n  <div class=\"ngx-dvm-container ngx-dvm-container-content\">\n    <ng-content></ng-content>\n  </div>\n</div>\n",
      styles: [":host{height:100%;border:0;padding:0;margin:0}.ngx-dvm-container-root{position:relative;width:100%;border:0;padding:0;margin:0}.ngx-dvm-container{position:absolute;width:100%;height:100%;border:0;padding:0;margin:0}.ngx-dvm-container-content{pointer-events:none}\n"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: NavigableMinimapService
    }, {
      type: i0.NgZone
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    linkedMapViewer: [{
      type: Input
    }],
    _container: [{
      type: ViewChild,
      args: ['container']
    }],
    _main: [{
      type: ViewChild,
      args: ['main']
    }],
    dvmInit: [{
      type: Output
    }],
    dvmPreLoadStart: [{
      type: Output
    }],
    dvmLoadStart: [{
      type: Output
    }],
    dvmStartLoad: [{
      type: Output
    }],
    dvmLoadSuccess: [{
      type: Output
    }],
    dvmLoadError: [{
      type: Output
    }],
    dvmReset: [{
      type: Output
    }],
    dvmUpdate: [{
      type: Output
    }],
    dvmClick: [{
      type: Output
    }],
    dvmDblclick: [{
      type: Output
    }],
    dvmHover: [{
      type: Output
    }],
    dvmEnter: [{
      type: Output
    }],
    dvmLeave: [{
      type: Output
    }],
    dvmUp: [{
      type: Output
    }],
    dvmDown: [{
      type: Output
    }],
    dvmMove: [{
      type: Output
    }],
    dvmContextmenu: [{
      type: Output
    }],
    dvmWheel: [{
      type: Output
    }],
    dvmStateChange: [{
      type: Output
    }],
    dvmSelect: [{
      type: Output
    }],
    dvmUnselect: [{
      type: Output
    }],
    dvmFirstSeen: [{
      type: Output
    }],
    dvmLayerLevelChanged: [{
      type: Output
    }]
  });
})();
class DvmModule {
  static {
    this.ɵfac = function DvmModule_Factory(t) {
      return new (t || DvmModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: DvmModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DvmModule, [{
    type: NgModule,
    args: [{
      declarations: [MapViewerComponent, Viewer3dComponent, NavigableMinimapComponent],
      imports: [],
      exports: [MapViewerComponent, NavigableMinimapComponent, Viewer3dComponent]
    }]
  }], null, null);
})();
class DvmFactoryService {
  constructor() {}
  /**
   * Creates a dvm-map-viewer component
   * @param input
   * @param container
   */
  createMapViewerComponent(input, container) {
    const comp = container.createComponent(MapViewerComponent);
    comp.instance.initOptions = input;
    return comp;
  }
  /**
   * Creates a dvm-viewer3d component
   * @param input
   * @param container
   */
  createViewer3dComponent(input, container) {
    const comp = container.createComponent(Viewer3dComponent);
    comp.instance.initOptions = input;
    return comp;
  }
  static {
    this.ɵfac = function DvmFactoryService_Factory(t) {
      return new (t || DvmFactoryService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DvmFactoryService,
      factory: DvmFactoryService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DvmFactoryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();

/*
 * Public API Surface of ngx-dvm-internal
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DvmFactoryService, DvmModule, DvmService, MapViewerComponent, MapViewerService, NavigableMinimapComponent, NavigableMinimapService, Viewer3dComponent, Viewer3dService };
