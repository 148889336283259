// import { loadModule } from '@3ddv/dvm-internal';
import { MapViewerSubscription } from '@3ddv/dvm-internal';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ClickHandler,
  HoverHandler,
  LeaveHandler,
  LoadHandler,
  SelectionAreaEndHandler,
} from 'src/app/digital-venue/handlers';
import { DvmService } from 'src/app/digital-venue/services/dvm.service';
import { UtilitiesService } from 'src/app/shared/services';
import * as neighbors from 'src/assets/data/neighbours/redsox.json';
import { Popover3dviewService } from '../../digital-venue/services/popover-3dview.service';
import { ConfigurationService } from '../../shared/services/configuration.service';

@Component({
  selector: 'app-dvm',
  templateUrl: './dvm.component.html',
  styleUrls: ['./dvm.component.scss'],
  providers: [
    //? Es necesario proveer los handlers en el componente dónde se van a activar para reconocerlos.
    LoadHandler,
    HoverHandler,
    LeaveHandler,
    SelectionAreaEndHandler,
    ClickHandler,
  ],
})
export class DvmComponent implements OnInit, OnDestroy {
  isMobile: boolean;

  neighbors: { [x: string]: { [x: string]: string } };
  currentSection: string;
  currentSectionTdc: string;
  subscriptions: MapViewerSubscription[] = [];

  get isSectionMap() {
    return this.dvmService.isSectionMap;
  }

  get is3dOpen() {
    return this.dvmService.is3dOpen;
  }

  constructor(
    private dvmService: DvmService,
    private utilitiesService: UtilitiesService,
    public configurationService: ConfigurationService,
    private loadHandler: LoadHandler, //? Es necesario importar los hanlders en el constructor para que se ejecuten.
    private leaveHandler: LeaveHandler,
    private selectionArea: SelectionAreaEndHandler,
    private popover3dview: Popover3dviewService,
    private enterHandler: HoverHandler,
    private clickHandler: ClickHandler
  ) {}

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;

    // DVM
    this.dvmService.initializeDVM();
    this.dvmService.initializeDVMMiniMap();
    this.neighbors = neighbors['default'];
    const subscribeToMapChange = () => {
      const subscription = this.dvmService.viewer.subscribe(
        'end_load',
        this.updateCurrentSection.bind(this)
      );
      this.dvmService.viewerSubscriptions.push(subscription);
    };
    if (this.dvmService.viewer) {
      subscribeToMapChange();
    } else {
      const subscription =
        this.dvmService.viewerSubject.subscribe(subscribeToMapChange);
      this.dvmService.viewerSubscriptions.push(subscription);
    }
  }

  ngOnDestroy(): void {
    this.dvmService.viewerSubscriptions.forEach(s => s.unsubscribe());
    const selectedSeats = this.dvmService.viewer.getNodesByState(
      'seat',
      'selected'
    );
    const selectedSections = this.dvmService.viewer.getNodesByState(
      'section',
      'selected'
    );
    this.dvmService.viewer.unselect([...selectedSeats, ...selectedSections]);
  }

  updateCurrentSection() {
    const nodes = this.dvmService.viewer.getNodes();
    const [node] = nodes;

    this.currentSection = node?.original_id;
    this.currentSectionTdc = node?.id;
  }

  back() {
    if (this.dvmService.is3dOpen) {
      // Close 3D
      this.dvmService.close3dView();
    } else {
      this.dvmService.loadMap();
    }
  }

  changeSection(next: boolean) {
    let currentSection = this.dvmService.viewer.getMapId();

    const currentSectionMmcId =
      this.dvmService.viewer.getNodeById(currentSection).original_id;

    if (this.neighbors[currentSectionMmcId]) {
      if (next && this.neighbors[currentSectionMmcId]['r']) {
        const tdcId =
          this.dvmService.sectionsMmcIdToTdc[
            this.neighbors[currentSectionMmcId]['r']
          ];
        this.dvmService.loadMap(tdcId);
      } else if (!next && this.neighbors[currentSectionMmcId]['l']) {
        const tdcId =
          this.dvmService.sectionsMmcIdToTdc[
            this.neighbors[currentSectionMmcId]['l']
          ];
        this.dvmService.loadMap(tdcId);
      }
    }
  }
}
