import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Seat, SeatsBySection } from '../models';

export type SelectedSeats = { [key: string]: Seat };

@Injectable({
  providedIn: 'root',
})
export class CartService {
  selectedSeats: SelectedSeats = {};
  selectedSeats$: Subject<SelectedSeats> = new Subject<{
    [key: string]: Seat;
  }>();

  selectedSeatsTotalPrice: number = 0;

  selectedSeatsBySection: { [key: string]: SeatsBySection } = {};

  sortedSelectedSeatsBySectionArray: SeatsBySection[] = [];
  sortedSelectedSeatsBySectionArray$: Subject<SeatsBySection[]> = new Subject<
    SeatsBySection[]
  >();

  constructor() {}

  resetCart() {
    this.selectedSeats = {};
    this.selectedSeatsTotalPrice = 0;
    this.selectedSeatsBySection = {};
    this.sortedSelectedSeatsBySectionArray = [];
    this.selectedSeats$.next({});
    this.sortedSelectedSeatsBySectionArray$.next([]);
  }
}
