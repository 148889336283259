import { Component, inject, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SeatManagementService } from 'src/app/digital-venue/services/seat-management.service';
import { Seat, SeatsBySection } from 'src/app/shared/models';
import { SeatsByRow } from 'src/app/shared/models/section.model';
import { UtilitiesService } from 'src/app/shared/services';
import {
  CartService,
  SelectedSeats,
} from 'src/app/shared/services/cart.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
})
export class CartComponent implements OnInit {
  // INPUT DATA
  @Input()
  public debugMode: boolean = false;

  // SERVICES
  private cartService: CartService = inject(CartService);
  private utilitiesService: UtilitiesService = inject(UtilitiesService);
  private seatManagementService: SeatManagementService = inject(
    SeatManagementService
  );

  // STATE
  public cart: Subject<SelectedSeats> = this.cartService.selectedSeats$;
  public seatsBySection: SeatsBySection[] = [];

  // GETTERS
  public get hasItems(): boolean {
    return this.seatsBySection.length > 0;
  }

  // LIFE CYCLE
  public ngOnInit(): void {
    this.initComponent();
  }
  // METHODS
  public removeAllSeats(): void {
    this.seatManagementService.unselectAllSelectedSeats();
  }

  public trackBySection(index: number, section: SeatsBySection): string {
    return section.id;
  }

  private initComponent(): void {
    this.cartService.sortedSelectedSeatsBySectionArray$.subscribe({
      next: (response: SeatsBySection[]) => {
        // Formateamos la respuesta para añadir la distribución de las filas
        const sortedSeatsBySectionAndRowArray: SeatsBySection[] =
          this.utilitiesService.addRowDsitribution(response);

        sortedSeatsBySectionAndRowArray.forEach((section: SeatsBySection) => {
          // Reasignamos los valores de los objetos a arrays para poder ordenarlos
          section.rows = Object.values(section.rows);
          // Ordenamos las filas por número de fila
          section.rows.forEach((row: SeatsByRow) => {
            row.seats = Object.values(row.seats).sort((a: Seat, b: Seat) => {
              return +a.seat - +b.seat;
            });
          });
        });

        // Asignamos el valor formateado y ordenado.
        this.seatsBySection = sortedSeatsBySectionAndRowArray;
      },
      error: error => console.error(error),
    });
  }
}
