import { Component, OnInit } from '@angular/core';
import { AvailabilityService, CartService } from 'src/app/shared/services';
import { DvmService } from 'src/app/shared/services/dvm.service';
import { GeneralAvailability } from '../../../shared/models';
import { BestAvailableService } from '../../services/best-available.service';
import { Popover3dviewService } from '../../services/popover-3dview.service';

@Component({
  selector: 'app-section-popover',
  templateUrl: './section-popover.component.html',
  styleUrls: ['./section-popover.component.scss'],
})
export class SectionPopoverComponent implements OnInit {
  sectionData: GeneralAvailability;
  tooltipData: { section: string; img: string; type: string } = {
    img: '',
    section: '',
    type: '',
  };
  includedSectionForBA: boolean;

  constructor(
    private dvmService: DvmService,
    private availabilityService: AvailabilityService,
    private bestAvailableService: BestAvailableService,
    private popover3dview: Popover3dviewService,
    private cartService: CartService
  ) {}

  get priceRange() {
    let priceRange = this.sectionData.priceRange[0].value.toString(10);
    if (!this.sectionData) {
      return '';
    }
    if (
      this.sectionData.priceRange[0].value !=
      this.sectionData.priceRange[1].value
    ) {
      priceRange = `${this.sectionData.priceRange[0].value} - ${this.sectionData.priceRange[1].value}`;
    }
    return priceRange;
  }

  get adaQuantity() {
    // TODO: change this access to mmcID
    if (
      this.availabilityService.sectionsAvailability[
        this.sectionData.sectionID
      ] &&
      this.availabilityService.sectionsAvailability[this.sectionData.sectionID]
        .availableQuantityAda
    ) {
      return this.availabilityService.sectionsAvailability[
        this.sectionData.sectionID
      ].availableQuantityAda;
    } else if (
      this.availabilityService.sectionsOnlyAdas[this.sectionData.sectionID] &&
      this.availabilityService.sectionsOnlyAdas[this.sectionData.sectionID]
        .availableQuantityAda
    ) {
      return this.availabilityService.sectionsOnlyAdas[
        this.sectionData.sectionID
      ].availableQuantityAda;
    }
    return 0;
  }

  ngOnInit(): void {
    // Device detection
    this.includedSectionForBA =
      this.bestAvailableService.selectedSectionsForBA.includes(
        this.sectionData.sectionID
      );

    this.tooltipData.section = this.sectionData.mmcID;

    // THUMBNAIL
    let thumbSection = this.sectionData.sectionID;

    if (this.tooltipData.section === 'S_BLCHADA') {
      thumbSection = 'S_Bleacher';
    }

    this.dvmService.loadThumbnail(thumbSection).subscribe({
      next: (img: HTMLImageElement) => (this.tooltipData.img = img.src),
      error: error => console.error(error),
    });
  }

  load3dView() {
    this.popover3dview.setTimer();
    this.dvmService.load3dView(this.sectionData.sectionID);
  }

  onEnter(event: MouseEvent) {
    this.popover3dview.deleteTimer();
  }

  onLeave(event: MouseEvent) {
    this.popover3dview.setTimer();
  }

  openSeatMap(): void {
    const sectionId: string = this.sectionData.sectionID;

    this.dvmService.openSeatMap(sectionId).subscribe();

    this.popover3dview.setTimer();
  }

  selectSectionForBA() {
    if (!this.includedSectionForBA) {
      this.bestAvailableService.selectSectionForBA(this.sectionData.sectionID);
    } else {
      this.bestAvailableService.unselectSectionForBA(
        this.sectionData.sectionID
      );
    }
    this.includedSectionForBA =
      this.bestAvailableService.selectedSectionsForBA.includes(
        this.sectionData.sectionID
      );
  }
}
