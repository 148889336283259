import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { ConnectionService } from '../services/connection.service';

export const eventDataResolver = (route: ActivatedRouteSnapshot) => {
  const connectionService = inject(ConnectionService);

  const ev = route.queryParamMap.get('event');
  const eventId = parseInt(ev, 10);
  connectionService.eventId = eventId;

  return connectionService.getEventById(eventId).pipe(
    map(site => {
      connectionService.eventId = eventId;
      return site;
    }),
    catchError(error => {
      console.log(error);
      return of({ event: null, error: error });
    })
  );
};
