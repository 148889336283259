import {
  MapViewerEventObject,
  MapViewerNode,
  MapViewerPluginsList,
  MapViewerSelectionAreaEventObject,
} from '@3ddv/dvm-internal';
import {
  MapViewerComponent,
  MapViewerService,
  Viewer3dComponent,
  Viewer3dService,
} from '@3ddv/ngx-dvm-internal';
import { SharedViewerEvent } from '@3ddv/ngx-dvm-internal/lib/map-viewer/shared/shared-viewer.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  NgZone,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subscription, take } from 'rxjs';
import { BestAvailableService } from 'src/app/digital-venue/services/best-available.service';
import { Popover3dviewService } from 'src/app/digital-venue/services/popover-3dview.service';
import { SeatManagementService } from 'src/app/digital-venue/services/seat-management.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import {
  GeneralAvailabilityDict,
  SeatAvailability,
  SeatAvailabilitySectionDict,
} from 'src/app/shared/models';
import { DvmData, DvmStyles } from 'src/app/shared/models/configuration.model';
import { AvailabilityService, CartService } from 'src/app/shared/services';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { DvmService } from 'src/app/shared/services/dvm.service';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrl: './viewer.component.scss',
})
export class ViewerComponent implements AfterViewInit, OnDestroy {
  // VIEW CHILDS
  @ViewChild('mapContainer')
  public mapContainer!: ElementRef<HTMLElement>;

  @ViewChild('viewer', { static: false })
  viewer!: MapViewerComponent;

  @ViewChild('viewer3d', { static: false })
  viewer3d!: Viewer3dComponent;

  @ViewChild('minimap', { static: false })
  minimap!: MapViewerComponent;

  @ViewChild('tooltip', { static: false })
  public tooltip!: ElementRef<HTMLElement>;

  @ViewChild('dynamicPopover', { read: ViewContainerRef })
  public popover: ViewContainerRef;

  // INPUTS
  @Input()
  public baseAvailability!: string[];

  @Input()
  public debugMode!: boolean | undefined;

  // SERVICES
  private bestAvailable: BestAvailableService = inject(BestAvailableService);
  private availability: AvailabilityService = inject(AvailabilityService);
  private cartService: CartService = inject(CartService);
  private seatManagment: SeatManagementService = inject(SeatManagementService);
  private configuration: ConfigurationService = inject(ConfigurationService);
  private modalService: ModalsService = inject(ModalsService);
  private popoverService: Popover3dviewService = inject(Popover3dviewService);
  private zone: NgZone = inject(NgZone);

  // DVM
  protected dvmService: DvmService = inject(DvmService);
  private readonly dvmData: DvmData =
    inject(ConfigurationService).configuration.dvmData;
  private readonly dvmStyle: DvmStyles =
    inject(ConfigurationService).configuration.dvmStyles;

  // STATE
  private subscriptions: Subscription[] = [];

  // LIFE CYCLE
  public ngAfterViewInit(): void {
    this.initComponent();
  }

  public ngOnDestroy(): void {
    this.destroyComponent();
  }

  // METHODS
  public backToTopView(): void {
    const backSub = this.dvmService.openSectionMap().subscribe();
    this.subscriptions.push(backSub);
  }

  protected hideMultiSelectionToast() {
    this.dvmService.isMultiSelectTooltipVisible = false;
  }
  protected showMultiSelectionToast() {
    this.dvmService.isMultiSelectTooltipVisible = true;
  }

  protected sectionClick(node: MapViewerNode): void {
    switch (node.state) {
      case 'available':
        this.zone.run(() => {
          this.bestAvailable.lastSelectedSection = node.id;
          this.bestAvailable.selectSectionForBA(node.id);
        });
        break;
      case 'selected':
        this.zone.run(() => this.bestAvailable.unselectSectionForBA(node.id));
        break;
    }
  }

  protected seatClick(node: MapViewerNode): void {
    const data = { id: node.id, original_id: node.original_id };

    switch (node.state) {
      case 'available':
        this.zone.run(() => this.seatManagment.selectAvailableSeats([data]));
        break;
      case 'selected':
        this.zone.run(() => this.seatManagment.unselectSelectedSeats([data]));
        break;
    }
  }

  private initComponent(): void {
    const dvmData: DvmData = this.dvmData,
      plugins: MapViewerPluginsList[] = ['best_nodes', 'selection_area'],
      flags: null = null,
      customStyles: DvmStyles = this.dvmStyle,
      customClient: string | null = null,
      availability: string[] = this.baseAvailability,
      debugMode: boolean = this.debugMode ?? false,
      minimap: MapViewerService = this.minimap.service,
      viewer3d: Viewer3dService = this.viewer3d.service;

    this.dvmService.initMapViewer(
      dvmData,
      plugins,
      flags,
      customStyles,
      customClient,
      availability,
      debugMode
    );

    this.dvmService.initViewer3d(viewer3d, dvmData.venueId);

    this.dvmService.initMinimap(minimap, dvmData, debugMode);

    this.dvmService.viewerService.waitInitialize().subscribe({
      next: () => {
        const click: Subscription = this.dvmService.viewerService
            .getObservable('click')
            .subscribe(
              (
                event: SharedViewerEvent<MapViewerEventObject, MapViewerService>
              ) => this.handleClick(event['event'])
            ),
          enter: Subscription = this.dvmService.viewerService
            .getObservable('enter')
            .subscribe(
              (
                event: SharedViewerEvent<MapViewerEventObject, MapViewerService>
              ) => this.handleEnter(event['event'])
            ),
          area: Subscription = this.dvmService.viewerService
            .getObservable('end_selection_area')
            .subscribe(
              (
                event: SharedViewerEvent<
                  MapViewerSelectionAreaEventObject,
                  MapViewerService
                >
              ) => this.handleSelectionArea(event['event'])
            ),
          leave: Subscription = this.dvmService.viewerService
            .getObservable('leave')
            .subscribe(() => this.handleLeave()),
          load: Subscription = this.dvmService.viewerService
            .getObservable('end_load')
            .subscribe(() => this.handleLoad());
        this.subscriptions.push(click, enter, area, leave, load);
      },
    });
  }

  private destroyComponent(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );

    this.dvmService.isFirstSeatMapLoad = true;
  }

  private handleClick(event: MapViewerEventObject): void {
    const node: MapViewerNode = event.nodes[0];

    if (!node || node.state === 'unavailable') {
      return;
    }

    const gaSectorsIds: string[] =
      this.configuration.configuration.dvmData.gaSectorsIdArray;
    const gaAdaIds: string[] =
      this.configuration.configuration.dvmData.gaAdaIdArray;

    if (gaSectorsIds.includes(node.id)) {
      const modalData = {
        title: 'General Admission Section',
        content:
          'Please, select the number of seats to add from the General Admission section.',
        closeBtnName: 'CANCEL',
        acceptBtnName: 'CONFIRM',
        isAda: false,
        acceptFunction: () => {},
      };
      this.bestAvailable.lastSelectedSection = node.id;
      this.modalService.openGaModal(modalData);
      return;
    } else if (gaAdaIds.includes(node.id)) {
      const modalData = {
        title: 'General Admission Section',
        content:
          'Please, select the number of seats to add from the General Admission section.',
        closeBtnName: 'CANCEL',
        acceptBtnName: 'CONFIRM',
        isAda: true,
        acceptFunction: () => {},
      };
      this.bestAvailable.lastSelectedSection = node.id;
      this.modalService.openGaModal(modalData);
      return;
    } else {
      switch (node.type) {
        case 'section':
          this.sectionClick(node);
          break;
        case 'seat':
          this.seatClick(node);
          break;
      }
    }
  }

  private handleEnter(event: MapViewerEventObject): void {
    const node: MapViewerNode = event.nodes[0];

    if (!node || node.state === 'unavailable') {
      return;
    }

    const tooltip: HTMLElement = this.tooltip.nativeElement,
      container: HTMLElement = this.mapContainer.nativeElement;

    this.zone.run(() => {
      this.popoverService.showTooltip(node, tooltip, this.popover, container);
    });
  }

  private handleSelectionArea(event: MapViewerSelectionAreaEventObject): void {
    const currentNodes: MapViewerNode[] = event.selection_area.current;

    const selectedNodes = currentNodes
      .filter(item => item.state === 'selected' && item.type !== 'section')
      .map(item => {
        return { id: item.id, original_id: item.original_id };
      });

    const availableNodes = currentNodes
      .filter(item => item.state === 'available' && item.type !== 'section')
      .map(item => {
        return { id: item.id, original_id: item.original_id };
      });

    this.zone.run(() => {
      // Deseleccionamos la seleccion actual
      this.seatManagment.unselectSelectedSeats(selectedNodes);
      this.dvmService.viewerService.unselect(selectedNodes);

      // Añadimos los nuevos asientos
      this.seatManagment.selectSeatsForCart(availableNodes);
      this.dvmService.viewerService.select(availableNodes);
    });
  }

  private handleLeave(): void {
    this.zone.run(() => this.popoverService.setTimer());
  }

  private handleLoad(): void {
    const mode: 'section' | 'seat' = this.dvmService.isTopView()
      ? 'section'
      : 'seat';

    switch (mode) {
      case 'section':
        this.handleSectionLoad();
        break;
      case 'seat':
        this.handleSeatLoad();
        break;
    }
  }

  private handleSectionLoad(): void {
    const sectionsAvailability: GeneralAvailabilityDict =
        this.availability.sectionsAvailability,
      seatAvailability: SeatAvailabilitySectionDict =
        this.availability.seatsAvailability,
      bleacherSection: string = 'S_Bleacher',
      bleacherAdaSection: string = 'S_BLCHADA';

    // SECTION AVAILABILITY
    // we check if we have availability data, if not, we request it
    if (Object.keys(sectionsAvailability).length) {
      // Section Ids Array
      let sectionsAvailable: string[] = Object.keys(sectionsAvailability);

      // Check bleacher section
      if (sectionsAvailable.includes(bleacherSection)) {
        if (sectionsAvailability[bleacherSection].availableQuantity > 0) {
          sectionsAvailable = sectionsAvailable.concat(
            this.dvmData.gaSectorsIdArray
          );
        }
      }

      // Check bleacher ADA section
      if (sectionsAvailable.includes(bleacherAdaSection)) {
        if (sectionsAvailability[bleacherAdaSection].availableQuantityAda > 0) {
          sectionsAvailable = sectionsAvailable.concat(
            this.dvmData.gaAdaIdArray
          );
        }
      }

      this.dvmService.setMapAvailability('section', sectionsAvailable);
      this.dvmService.setMapAvailability(
        'general_admission',
        sectionsAvailable
      );

      if (this.seatManagment.needADA) {
        this.availability.activeAdaSections();
      }
    } else {
      this.availability
        .getSectionAvailability()
        .pipe(take(1))
        .subscribe({
          next: (sectionsAvailability: string[]) => {
            if (sectionsAvailability.includes(bleacherSection)) {
              if (sectionsAvailability[bleacherSection].availableQuantity > 0) {
                sectionsAvailability = sectionsAvailability.concat(
                  this.dvmData.gaSectorsIdArray
                );
              }
            }

            if (sectionsAvailability.includes(bleacherAdaSection)) {
              if (
                sectionsAvailability[bleacherAdaSection].availableQuantityAda >
                0
              ) {
                sectionsAvailability = sectionsAvailability.concat(
                  this.dvmData.gaAdaIdArray
                );
              }
            }

            this.dvmService.setMapAvailability('section', sectionsAvailability);
            this.dvmService.setMapAvailability(
              'general_admission',
              sectionsAvailability
            );
          },
          error: error => {
            console.error(error);
            const modalData = {
              title: 'ERROR',
              content:
                'An Error occurred while trying to get Section Availability.',
              // closeBtnName: 'CLOSE',
              acceptBtnName: 'CLOSE',
              // acceptFunction: () => {this.goTo('checkout')}
            };
            // If there's a custom api error.
            if (error.error.hasOwnProperty('code')) {
              modalData.content = error.error.message;
              if (error.error.code === 'E_EVENT_CANNOT_BE_SOLD') {
                modalData.title = 'WE’RE SORRY';
                modalData.content = `Your request cannot be processed online. Please call ${this.configuration.configuration.phone} or email ${this.configuration.configuration.email} to purchase tickets for this game.`;
              }
            }

            this.modalService.openModal(modalData);
          },
        });
    }

    // SEAT AVAILABILITY
    if (Object.keys(seatAvailability).length) {
      let seatAvailability: string[] = [];

      Object.entries(seatAvailability).forEach(([key, value]) => {
        seatAvailability = seatAvailability.concat(Object.keys(value));
      });

      this.dvmService.setMapAvailability('seat', seatAvailability);
    }

    // RESELECT SELECTED SECTIONS
    let selectedSections = this.cartService.selectedSeatsBySection;

    if (Object.keys(selectedSections).length) {
      const selectedSectionsId: string[] = Object.values(selectedSections).map(
        section => section.id
      );

      this.dvmService.viewerService.select(selectedSectionsId);

      this.seatManagment.updateSelectedSectionsOnTopview();
    }
  }

  private handleSeatLoad(): void {
    this.dvmService.isFirstSeatMapLoad = false;

    const seatAvailability: SeatAvailabilitySectionDict =
        this.availability.seatsAvailability,
      mapId: string = this.dvmService.viewerService.getMapId();

    if (seatAvailability && seatAvailability[mapId]) {
      const adaSeats: string[] = Object.values(seatAvailability[mapId])
        .filter((seat: SeatAvailability) => seat.isAda === true)
        .map((seat: SeatAvailability) => seat.id);
      const availableSeats: string[] = Object.keys(seatAvailability[mapId]);

      this.dvmService.setMapAvailability('seat', availableSeats);
      this.dvmService.viewerService.addNodesToGroup(adaSeats, 'disabled');
    } else {
      this.availability
        .getSeatAvailability([mapId])
        .pipe(take(1))
        .subscribe({
          next: (availability: string[]) => {
            this.dvmService.setMapAvailability('seat', availability);
          },
          error: error => {
            console.error('error is:::', error);
            const modalData = {
              title: 'ERROR',
              content:
                'An Error occurred while trying to get Seat Availability.',
              acceptBtnName: 'CLOSE',
            };
            // If there's a custom api error.
            if (error?.error?.hasOwnProperty('code')) {
              modalData.content = error.error.message;
            }
            this.modalService.openModal(modalData);
          },
        });
    }

    // MINIMAP
    if (
      this.dvmService.minimapService &&
      this.dvmService.minimapService.isInitialized()
    ) {
      const sections = Object.keys(this.availability.sectionsAvailability);

      this.dvmService.minimapService.unselectAll();
      this.dvmService.minimapService.setUnavailable('section', sections);
      this.dvmService.minimapService.setAvailable(
        'section',
        this.dvmService.viewerService.getMapId()
      );
      this.dvmService.minimapService.select(
        this.dvmService.viewerService.getMapId()
      );
    }

    // BEST AVAILABLE
    if (Object.keys(this.cartService.selectedSeats).length) {
      this.dvmService.viewerService.select(
        Object.keys(this.cartService.selectedSeats)
      );
    }
  }
}
