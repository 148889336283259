import { Injectable } from '@angular/core';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { DvmService } from '../services/dvm.service';

@Injectable()
export class ZoomHandler {
  constructor(
    private dvmService: DvmService,
    private modalsService: ModalsService
  ) {
    // ZOOM subscribe
    const viewerSubscription = this.dvmService.viewerSubject.subscribe({
      next: viewer => {
        const subscription = viewer.subscribe(
          'zooming',
          this.handle.bind(this)
        );

        this.dvmService.viewerSubscriptions.push(subscription);
      },
      error: error => {
        console.error(error);
        const modalData = {
          title: 'ERROR',
          content: 'An Error occurred while trying to get the Viewer.',
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      },
    });
    this.dvmService.viewerSubscriptions.push(viewerSubscription);
  }

  private handle() {
    if (this.dvmService.viewer.getMapId() === 'main_tiers') {
      // if (this.dvmService.viewer.getMapId() === 'main_venuemap') {
      const currentLevel = this.dvmService.viewer.layers.getLayerLevel();
      const currentZoom = this.dvmService.viewer.scaling_factor;
      if (currentZoom >= 5) {
        if (currentLevel % 2 === 0) {
          this.dvmService.viewer.layers.setLayerLevel(currentLevel + 1);
        }
      } else {
        if (currentLevel % 2 === 1) {
          this.dvmService.viewer.layers.setLayerLevel(currentLevel - 1);
        }
      }
    }
  }
}
