<div class="h-full flex items-center justify-between">
  <!-- Header -->
  <div class="flex flex-col">
    <h4 class="text-sm">
      {{ templateVars.header }}
    </h4>
    <p>
      {{ templateVars.subtitle }}
    </p>
  </div>

  <!-- Button -->
  <div>
    <button
      class="btn-white h-9 disabled:text-gray-400 disabled:bg-gray-100 transition-transform hover:scale-95 hover:disabled:transform-none"
      [disabled]="!hasItems"
      (click)="onRemoveAll()">
      <span data-cy="cart-remove-all">
        {{ templateVars.button }}
      </span>
    </button>
  </div>
</div>
