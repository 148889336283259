<div id="event" class="hidden sm:flex flex-row justify-around items-center">
  <div class="box first flex flex-row w-full items-center justify-center">
    <div class="mr-3" *ngIf="event.urlImage">
      <img [src]="event.meta.image" alt="event image" />
    </div>
    <div class="flex flex-col" style="width: 240px">
      <span
        ><b>{{ event.description }}</b></span
      >
      <span *ngIf="event.extraText" class="text-sm">{{ event.extraText }}</span>
    </div>
  </div>
  <div
    class="box middle py-1 px-12 text-nowrap text-ellipsis overflow-hidden min-w-44">
    <span>{{ event.date | date }}</span
    ><br />
    <span>{{ event.date | date: 'hh:mm a' }}</span>
  </div>
  <div class="box last">
    <button
      [attr.data-cy]="'buy-ticket-' + event.id"
      type="button"
      class="select-button w-32 md:w-40"
      (click)="notifyParent(event.pvEventId)">
      Buy Tickets
    </button>
  </div>
</div>

<div id="event-mobile" class="flex sm:hidden flex-col w-80 mx-auto text-center">
  <div class="rival">
    <b>{{ event.description }}</b>
  </div>
  <div class="date">
    <span>{{ event.date | date }}</span
    ><br />
    <span>{{ event.date | date: 'hh:ss a' }}</span>
  </div>
  <div class="buy-ticket">
    <button
      [attr.data-cy]="'buy-ticket-' + event.id"
      type="button"
      class="select-button-mobile"
      (click)="notifyParent(event.pvEventId)">
      Buy Tickets
    </button>
  </div>
</div>
