import { Component, inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SeatDefinition } from '../../models';

@Component({
  selector: 'app-seat-definitions-modal',
  templateUrl: './seat-definitions-modal.component.html',
  styleUrl: './seat-definitions-modal.component.scss',
})
export class SeatDefinitionsModalComponent {
  public bsModalRef = inject(BsModalRef);
  definitions: SeatDefinition[];
  isAda: boolean;
  seatId: string;
  title = 'Seat Definitions';

  close() {
    this.bsModalRef.hide();
  }

  get seatSection() {
    return this.seatId.split('-')[0].split('_')[1];
  }

  get seatRow() {
    return this.seatId.split('-')[1];
  }

  get seat() {
    return this.seatId.split('-')[2];
  }
}
