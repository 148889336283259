<ul class="h-full relative block">
  <li class="h-full relative section z-20">
    <app-cart-item
      [mode]="'section'"
      [data]="seatsBySection"
      [showSeats]="showSeats"
      (toggle)="toggleSeats()" />
  </li>

  <div class="z-0 h-0 block relative overflow-hidden" #seatsContainer>
    <ng-container *ngFor="let row of seatsBySection.rows; trackBy: trackByRow">
      <li *ngFor="let seat of row.seats; trackBy: trackBySeat">
        <app-cart-item [mode]="'seat'" [data]="seat" />
      </li>
    </ng-container>
  </div>
</ul>
