import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SaleEvent } from '../../shared/models';

@Component({
  selector: 'app-sale-event',
  templateUrl: './sale-event.component.html',
  styleUrls: ['./sale-event.component.scss'],
})
export class SaleEventComponent {
  @Input() event: SaleEvent;
  @Output() notifySelected: EventEmitter<number> = new EventEmitter();

  constructor() {}

  notifyParent(pvEventId: number) {
    this.notifySelected.emit(pvEventId);
  }
}
