<div
  id="footer"
  class="flex text-white h-full w-full items-center text-sm"
  [ngClass]="[switchColor ? 'bg-black' : 'bg-main-dark']">
  <div class="flex w-full justify-between items-center">
    <div class="flex-1 text-center">
      For questions or assistance, please call
      <b>{{ configurationService.configuration.phone }}</b> or email
      <b>{{ configurationService.configuration.email }}</b>
    </div>
    <div class="flex-initial">
      <span class="whitespace-nowrap mr-5 text-xs"
        >Version: {{ appPackage.version }}</span
      >
    </div>
  </div>
</div>
