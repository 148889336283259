import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DvmService } from 'src/app/shared/services/dvm.service';

@Component({
  selector: 'app-best-seats',
  templateUrl: './best-seats.component.html',
  styleUrl: './best-seats.component.scss',
})
export class BestSeatsComponent implements OnInit, OnDestroy {
  // INPUT DATA
  @Input()
  public enableButton: boolean = false;

  @Input()
  public debugMode: boolean = false;

  // EVENT EMITTER
  @Output()
  public addBestSeats: EventEmitter<void> = new EventEmitter<void>();

  // SERVICES
  private dvmService: DvmService = inject(DvmService);

  // STATE
  public isMapLoading: boolean = true;

  // DVM SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  // TEMPLATE
  public templateVars: { text: string; button: string; tooltip: string } = {
    text: 'Accessible seats included in ticket total',
    button: 'Add Best Seats',
    tooltip: 'Wait till the map has loaded',
  };

  // LIFE CYCLE
  public ngOnInit(): void {
    this.initComponent();
  }

  public ngOnDestroy(): void {
    this.destroyComponent();
  }

  // METHODS
  public startAddBestSeats(): void {
    if (this.debugMode) {
      console.log('Add Best Seats called');
    }

    this.addBestSeats.emit();
  }

  private initComponent(): void {
    this.dvmService.viewerService.waitInitialize().subscribe({
      next: () => {
        this.subscriptions.push(
          this.dvmService.viewerService
            .getObservable('load_success')
            .subscribe(() => {
              this.isMapLoading = false;
              this.templateVars.tooltip = 'Works on the overview map';
            })
        );
      },
    });
  }

  private destroyComponent(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
