import { MapViewer } from '@3ddv/dvm-internal';
import { Component, inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DvmService } from '../../../digital-venue/services/dvm.service';
import { SeatWithDefinitions } from '../../models';
import { ConnectionService } from '../../services/connection.service';

@Component({
  selector: 'app-restricted-seats-modal',
  templateUrl: './restricted-seats-modal.component.html',
  styleUrl: './restricted-seats-modal.component.scss',
})
export class RestrictedSeatsModalComponent {
  public bsModalRef = inject(BsModalRef);
  mapViewer: MapViewer = inject(DvmService).viewer;
  connectionService: ConnectionService = inject(ConnectionService);
  title = 'Warning !';
  acceptFunc: () => void;
  seats: SeatWithDefinitions[];

  close() {
    this.bsModalRef.hide();
  }

  continue() {
    if (this.acceptFunc) {
      this.acceptFunc();
    }
    this.bsModalRef.hide();
  }
}
