<div class="flex gap-x-3 justify-between">
  <h5
    class="flex flex-col justify-center"
    [ngClass]="{ hidden: showIcon === false }">
    <div class="flex items-center">
      <span class="inline md:hidden"> Number of Seats </span>
      <span class="hidden md:inline"> Select </span>

      <i class="w-3 mx-1">
        <img
          src="/assets/img/chair-white-icon.svg"
          alt="white chair icon"
          class="w-3" />
      </i>

      <span> : </span>
    </div>

    <p class="block md:hidden">Minimum of {{ minimumValue }}</p>
  </h5>

  <div class="flex items-center">
    <!-- SUBSTRACT BUTTON -->
    <button
      [attr.data-cy]="useCaseId + '-selector-substract-button'"
      (click)="decrement()"
      [ngClass]="reverseColors ? 'bg-main' : 'bg-red'"
      class="btn-icon w-8 h-8 transition-transform hover:scale-95 ease-in">
      <i class="w-5 h-auto">
        <img src="/assets/img/dash-symbol.svg" alt="dash symbol" />
      </i>
    </button>

    <div
      class="w-16 px-4 py-1 mx-2 flex justify-center items-center rounded-full border overflow-hidden">
      <input
        #counter
        appNumbersInput
        [placeholder]="minimumValue"
        [(ngModel)]="value"
        (focusout)="manualUpdate($event)"
        [ngClass]="['text-' + inputColor]"
        type="text"
        class="w-8 font-bold text-center bg-transparent text-sm focus-visible:outline-none" />
    </div>

    <!-- ADD BUTTON -->
    <button
      [attr.data-cy]="useCaseId + '-selector-add-button'"
      (click)="increment()"
      [ngClass]="reverseColors ? 'bg-main' : 'bg-red'"
      class="btn-icon w-8 h-8 transition-transform hover:scale-95 ease-in">
      <i class="w-5 h-auto">
        <img src="/assets/img/plus-symbol.svg" alt="plus symbol" />
      </i>
    </button>
  </div>
</div>
