import {
  MapViewer,
  MapViewerBestNodesAlgorithm,
  MapViewerGetBestNodesInput,
} from '@3ddv/dvm-internal';
import { Injectable } from '@angular/core';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { AvailabilityService, CartService } from 'src/app/shared/services';
import { DvmService } from 'src/app/shared/services/dvm.service';
import { Seat } from '../../shared/models';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { CombinedIds, SeatManagementService } from './seat-management.service';

@Injectable({
  providedIn: 'root',
})
export class BestAvailableService {
  viewer: MapViewer;
  selectedSectionsForBA: string[] = [];
  bestSeats: Seat[];
  bestSeatsIds: CombinedIds[];
  lastSelectedSection: string;

  constructor(
    private dvmService: DvmService,
    private seatManagementService: SeatManagementService,
    private configurationService: ConfigurationService,
    private availabilityService: AvailabilityService,
    private cartService: CartService,
    private modalsService: ModalsService
  ) {
    // const viewerSubscription = this.dvmService.viewerSubject.subscribe({
    //   next: response => (this.viewer = response),
    //   error: error => {
    //     console.error(error);
    //     const modalData = {
    //       title: 'ERROR',
    //       content: 'An Error occurred while trying to get the Viewer.',
    //       acceptBtnName: 'CLOSE',
    //     };
    //     this.modalsService.openModal(modalData);
    //   },
    // });
    // this.dvmService.viewerSubscriptions.push(viewerSubscription);
  }

  selectSectionForBA(nodeId: string) {
    // Select Section on Viewer
    const node = this.dvmService.viewerService.getNodeById(nodeId);

    let success = this.dvmService.viewerService.select([nodeId]);

    // console.log('Success SELECT Section? ', success);
    this.selectedSectionsForBA = this.dvmService.viewerService
      .getNodesByState('section', 'selected')
      .map(node => node.id);
  }

  unselectSectionForBA(nodeId: string) {
    // Select Section on Viewer
    let success = this.dvmService.viewerService.unselect([nodeId]);
    this.selectedSectionsForBA = this.dvmService.viewerService
      .getNodesByState('section', 'selected')
      .map(node => node.id);
  }

  selectAccessibleSeats(number: number, preferredSections: string | string[]) {
    const accessibleSections = [];
    let seatsToSelect = number;
    Object.entries(this.availabilityService.seatsAvailability).forEach(
      ([section, seatsAvailability]) => {
        if (seatsToSelect <= 0) return;

        if (preferredSections && !preferredSections.includes(section)) return;

        const consecutiveGroupsByCount = {
          even: [],
          odd: [],
        };

        const adaInSectionIds = Object.values(seatsAvailability)
          .filter(a => a.isAda && !this.cartService.selectedSeats[a.id])
          .map(a => a.id);
        const adaInSectionCount = adaInSectionIds.length;

        if (adaInSectionCount) {
          // Utilizamos la Row info para calcular las sillas contiguas
          const adaRow = this.dvmService.viewerService.getNodeById(
            adaInSectionIds[0]
          )['row'];
          // Filtramos de la Row info las sillas que sabemos que son ADA
          const adaRowFiltered = adaRow.filter(a => {
            const x = this.dvmService.viewerService.getNodeById(a);
            return x['tag'] === 'ada' || x['tag'] === 'adacomp';
          });

          // Ordenamos nuestra availability según el orden de la Row info
          adaInSectionIds.sort((a, b) => {
            const ia = adaRowFiltered.indexOf(a);
            const ib = adaRowFiltered.indexOf(b);
            if (ia < 0) return 1;
            if (ib < 0) return -1;
            return ia - ib;
          });

          // Buscamos los grupos consecutivos y los separamos
          const consecutiveGroups = [];
          let lastGroup = -1;
          adaInSectionIds.forEach(a => {
            const aNum = adaRowFiltered.indexOf(a);
            const lastItem = adaRowFiltered[aNum - 1];

            if (
              !(
                lastGroup > -1 &&
                consecutiveGroups[lastGroup] &&
                consecutiveGroups[lastGroup].includes(lastItem)
              )
            ) {
              lastGroup = lastGroup + 1;
              consecutiveGroups[lastGroup] = [];
            }
            consecutiveGroups[lastGroup].push(a);
          });

          // Dividimos los grupos consecutivos por pares e impares
          consecutiveGroups.forEach(a => {
            if (a.length % 2) {
              consecutiveGroupsByCount.odd =
                consecutiveGroupsByCount.odd.concat(a);
            } else {
              consecutiveGroupsByCount.even =
                consecutiveGroupsByCount.even.concat(a);
            }
          });

          // Los grupos impares se pueden convertir en grupos pares restando una de las sillas
          // El resultado es una array de todas las sillas que dan grupos pares,
          // mas una silla separada en caso que haya un recuento impar
          if (consecutiveGroupsByCount.odd.length > 0) {
            const tmp = consecutiveGroupsByCount.odd[0];
            const index = consecutiveGroupsByCount.odd.indexOf(tmp);
            const tmpLength = tmp.length;
            // restar 2 sillas de odd y pasarlas a even
            const toAdd = consecutiveGroupsByCount.odd.splice(
              index,
              tmpLength - 2
            );

            consecutiveGroupsByCount.even =
              consecutiveGroupsByCount.even.concat(toAdd);
          }
        }

        // Si tenemos ADA en este sector
        if (adaInSectionCount > 0) {
          // Si tenemos una selección impar y tenemos sillas en grupos impares, primero seleccioanmos esto
          if (seatsToSelect % 2 && consecutiveGroupsByCount.odd.length) {
            this.seatManagementService.selectSeatsForCart([
              consecutiveGroupsByCount.odd[0],
            ]);
            seatsToSelect = seatsToSelect - 1;
          }
          // Si quedan sillas por seleccionar...
          if (seatsToSelect > 0) {
            // Seleccionamos el resto, normalmente grupos pares
            consecutiveGroupsByCount.even.forEach(adaSeat => {
              if (seatsToSelect <= 0) return;
              const s = this.dvmService.viewerService.getNodeById(adaSeat);
              this.seatManagementService.selectSeatsForCart([
                { id: s.id, original_id: s.original_id },
              ]);
              seatsToSelect = seatsToSelect - 1;
            });
          }

          accessibleSections.push(section);
        } else {
          console.warn('This section has no ADA availability: ', section);
        }
      }
    );

    if (seatsToSelect > 0 && preferredSections != null) {
      this.selectAccessibleSeats(seatsToSelect, null);
    } else if (seatsToSelect > 0) {
      this.openModalWithNoAvailableBA();
      this.seatManagementService.unselectAllSelectedSeatsWithoutLoadingMap();
    }
    return accessibleSections;
  }

  getBestXSeats(nSeats: number, accessibleSeats?: number) {
    const input: MapViewerGetBestNodesInput = {
      type: 'seat',
      quantity: nSeats,
      algorithm: MapViewerBestNodesAlgorithm.NEIGHBORS,
      filter_single_nodes: true,
      // parent: ''
      // subset: []
    };

    // Request Availability
    let avSeatsForBA: string[] = [];
    let sectionsSelection = [];

    this.seatManagementService.unselectAllSelectedSeatsWithoutLoadingMap();
    if (this.selectedSectionsForBA.length) {
      sectionsSelection = this.selectedSectionsForBA;
      // todo GA change quitar los ga del best available
    } else {
      sectionsSelection = this.dvmService.viewerService
        .getNodesByState('section', 'available')
        .map(a => a.id);
    }

    const sectionsHolder = [];
    for (const section of sectionsSelection.values()) {
      if (
        !this.configurationService.configuration.dvmData.gaSectorsIdArray.includes(
          section
        ) &&
        !this.configurationService.configuration.dvmData.gaAdaIdArray.includes(
          section
        )
      ) {
        if (section != null) {
          sectionsHolder.push(section);
        }
      }
    }

    sectionsSelection = sectionsHolder;

    if (sectionsSelection.length === 0 || sectionsSelection[0] == null) {
      this.openModalWithNoAvailableBA();
      this.seatManagementService.unselectAllSelectedSeats();
      return;
    }

    this.availabilityService.getSeatAvailability(sectionsSelection).subscribe({
      next: availability => {
        this.dvmService.viewerService.setAvailability('seat', availability);

        if (input.quantity > 0) {
          // if the user has current seats, they must be removed from availability
          let currentSeats = Object.keys(this.cartService.selectedSeats);
          // Remove ADA seats from the subset
          const adaSeats = this.dvmService.viewerService
            .getNodesByTag('seat', 'ada')
            .map(a => a.id)
            .concat(
              this.dvmService.viewerService
                .getNodesByTag('seat', 'adacomp')
                .map(a => a.id)
            );

          if (accessibleSeats) {
            const result = {
              ...this.availabilityService.sectionsAvailability,
              ...this.availabilityService.sectionsOnlyAdas,
            };

            avSeatsForBA = availability.filter(a => {
              const sectionId = a.split('-')[0];
              const section = result[sectionId];
              return (
                section.availableQuantityAda > 0 &&
                section.availableQuantity > 0
              );
            });

            if (
              avSeatsForBA.length === 0 ||
              avSeatsForBA.filter(x => !adaSeats.includes(x)).length === 0
            ) {
              avSeatsForBA = availability.filter(a => {
                const sectionId = a.split('-')[0];
                const section = result[sectionId];
                return section.availableQuantity > 0;
              });
            }
          } else {
            avSeatsForBA = availability;
          }

          let availabilityExcludingAdaAndSelectedSeats = avSeatsForBA
            .filter(x => !adaSeats.includes(x))
            .filter(x => !currentSeats.includes(x));
          avSeatsForBA = availabilityExcludingAdaAndSelectedSeats;
          input.subset = avSeatsForBA;

          this.dvmService.viewerService.best_nodes
            .getBestNodes(input)
            .subscribe({
              next: nodes => {
                if (nodes.length) {
                  const nodeIds = nodes.map(node => {
                    return { id: node.id, original_id: node.original_id };
                  });
                  this.bestSeatsIds = nodeIds;
                  this.seatManagementService.selectSeatsForCart(nodeIds);

                  let selectedSections = [
                    ...new Set(this.bestSeatsIds.map(a => a.id.split('-')[0])),
                  ];

                  // ADA Selection flow
                  if (accessibleSeats > 0) {
                    const accessibleSections = this.selectAccessibleSeats(
                      accessibleSeats,
                      selectedSections
                    );
                    // Change sections eligible for Best Nodes
                    // if (accessibleSections.length) {
                    //   accessibleSections.forEach(section => {
                    //     avSeatsForBA = avSeatsForBA.concat(Object.keys(this.availabilityService.seatsAvailability[section]));
                    //   });
                    // }
                  }

                  // Load Section map and goTo where selected seats are
                  const mostSelectedSeatsSectionId =
                    this.cartService.sortedSelectedSeatsBySectionArray[0]?.id;

                  if (mostSelectedSeatsSectionId) {
                    this.dvmService
                      .openSeatMap(mostSelectedSeatsSectionId)
                      .subscribe({
                        next: () => {
                          this.dvmService.viewerService.setAvailability(
                            'seat',
                            availability
                          );
                          this.selectBestXSeats();
                        },
                      });
                  } else {
                    this.openModalWithNoAvailableBA();
                    this.seatManagementService.unselectAllSelectedSeats();
                  }
                } else {
                  console.warn('NO Best Nodes');
                  this.openModalWithNoAvailableBA();
                  this.seatManagementService.unselectAllSelectedSeats();
                }
              },
              error: error => {
                this.openModalWithNoAvailableBA();
                this.seatManagementService.unselectAllSelectedSeats();
              },
            });
        } else {
          this.selectAccessibleSeats(accessibleSeats, null);
          const mostSelectedSeatsSectionId =
            this.cartService.sortedSelectedSeatsBySectionArray[0]?.id;

          if (mostSelectedSeatsSectionId) {
            this.dvmService.openSeatMap(mostSelectedSeatsSectionId).subscribe({
              next: () => {
                this.dvmService.viewerService.setAvailability(
                  'seat',
                  availability
                );
                this.selectBestXSeats();
              },
            });
          }
        }
      },
      error: error => {
        console.error(error);
        const modalData = {
          title: 'ERROR',
          content: 'An Error occurred while trying to get Seat Availability.',
          acceptBtnName: 'CLOSE',
        };
        // If there's a custom api error.
        if (error.error.hasOwnProperty('code')) {
          modalData.content = error.error.message;
        }
        this.modalsService.openModal(modalData);
      },
    });
    // console.log('Input: ', input);
  }

  openModalWithNoAvailableBA() {
    console.warn('NO Best Nodes');
    const modalData = {
      title: 'We are sorry!',
      content:
        'We could not find results matching your Best Seats selection. Please, try again with a different selection.',
      acceptBtnName: 'OK',
    };
    this.modalsService.openModal(modalData);
  }

  // Called from load handler, when the section map is loaded (because it can't select seats before)
  selectBestXSeats() {
    this.seatManagementService.selectAvailableSeats(this.bestSeatsIds);
  }
}
