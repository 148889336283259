<div
  class="h-1/3 px-4 xs:px-12 sm:px-24 md:px-36 lg:px-4 py-2 sm:py-4 lg:py-2 flex justify-between items-stretch md:items-center flex-col lg:flex-row">
  <!-- TEXT -->
  <h5 class="mr-3 font-normal whitespace-nowrap">
    What's your price range per ticket?
  </h5>

  <!-- RANGE -->
  <div class="w-full pt-3 md:pt-0">
    <div class="custom-slider w-full">
      <ngx-slider
        [(value)]="minValue"
        [(highValue)]="maxValue"
        [options]="sliderOptions"
        (userChangeEnd)="updateSliderValues($event)"></ngx-slider>
    </div>
  </div>
</div>
