import { MapViewerOutputNodes } from '@3ddv/dvm-internal';
import { MapViewerService } from '@3ddv/ngx-dvm-internal';
import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import anime from 'animejs/lib/anime.es.js';
import { IsolatedSeatsService } from 'src/app/digital-venue/services/isolated-seats.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { SeatWithDefinitions } from 'src/app/shared/models';
import { AvailabilityService, CartService } from 'src/app/shared/services';
import { SelectedSeats } from 'src/app/shared/services/cart.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';
import { DvmService } from 'src/app/shared/services/dvm.service';
@Component({
  selector: 'app-total',
  templateUrl: './total.component.html',
  styleUrl: './total.component.scss',
  providers: [CurrencyPipe],
})
export class TotalComponent {
  //VIEWCHILD
  @ViewChild('total')
  public total: ElementRef<HTMLElement>;

  // SERVICES
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);
  private viewerService: MapViewerService = inject(DvmService).viewerService;
  private cartService: CartService = inject(CartService);
  private availabilityService: AvailabilityService =
    inject(AvailabilityService);
  private currencyPipe: CurrencyPipe = inject(CurrencyPipe);
  private isolatedSeats: IsolatedSeatsService = inject(IsolatedSeatsService);
  private modalService: ModalsService = inject(ModalsService);
  private connection: ConnectionService = inject(ConnectionService);

  // STATE
  public totalSeats: number = 0;
  public totalAmount: number = 0;

  protected minSeatsLimit: number = 0;
  protected maxSeatsLimit: number = 0;

  private time: number = 150;
  private timer: NodeJS.Timeout | null = null;

  // GETTERS
  public get canCheckout(): boolean {
    return (
      this.totalSeats >= this.minSeatsLimit &&
      this.totalSeats <= this.maxSeatsLimit &&
      this.totalSeats > 0 &&
      this.totalAmount > 0
    );
  }

  public get hasMaxSeatsError(): boolean {
    return this.totalSeats > this.maxSeatsLimit;
  }

  // LIFE CYCLE
  ngOnInit(): void {
    this.initComponent();
  }

  public startCheckout(): void {
    // Comprobamos que cumpla con las condiciones para poder hacer checkout
    if (!this.canCheckout) return;

    // Comprobamos si hay asientos aislados, si es true es que hay asientos aislados
    if (this.hasIsolatedSeats()) return;

    // Comprobamos si hay asientos con restricciones (definiciones o adas)
    if (this.hasRestrinctions()) return;

    this.goToCheckout();
  }

  protected transformCurrency(value: number): string {
    return this.currencyPipe.transform(value, 'USD', '', '1.2-2');
  }

  private hasRestrinctions(): boolean {
    const list: SeatWithDefinitions[] = Object.entries(
        this.cartService.selectedSeats
      ).map(value => {
        const [id, seat] = value;
        const [section, _] = id.split('-');
        const data = this.availabilityService.seatsAvailability[section][id];
        return { ...seat, definitions: data.seatDefinition };
      }),
      result: SeatWithDefinitions[] = list.filter(value => {
        let r = value.definitions.filter(item => item.restriction);
        return r.length > 0;
      });

    if (result && result.length > 0) {
      const checkoutFunc = this.goToCheckout,
        bindedCheckout = checkoutFunc.bind(this);

      this.modalService.openRestrictedSeatsModal({
        func: bindedCheckout,
        seats: result,
      });

      return true;
    }

    return false;
  }

  private hasIsolatedSeats(): boolean {
    // Check isolated seats
    const isolatedSeats: MapViewerOutputNodes =
      this.viewerService.checkIsolatedNodes();

    if (isolatedSeats.length > 0) {
      // Modal
      const modalData = {
        title: "Wait! There's a problem",
        content: `The following seats are left isolated with your selection: ${isolatedSeats.map(a => a.original_id).join(', ')}.
        Please, fix your selection before continuing to checkout.`,
        acceptBtnName: 'CLOSE',
      };

      this.modalService.openModal(modalData);

      // Isolated seats
      this.isolatedSeats.isolatedSeatsActive = true;

      // Add isolated seats to pending group
      this.viewerService.addNodesToGroup(isolatedSeats, 'pending');

      return true;
    }

    return false;
  }

  private updateTotalValue(totalPrice: number): void {
    anime({
      targets: this.total.nativeElement,
      innerHTML: [this.totalAmount, totalPrice],
      easing: 'linear',
      duration: 1000,
      round: 100,
      complete: () => {
        this.totalAmount = totalPrice;
        this.total.nativeElement.innerHTML = this.transformCurrency(
          this.totalAmount
        );
      },
    });
  }

  private goToCheckout(): void {
    const event = this.activatedRoute.snapshot.data['event'];

    this.connection
      .createTransaction(
        event.pvEventId,
        Object.keys(this.cartService.selectedSeats)
      )
      .subscribe({
        next: response => {
          console.log('TRANSACTION CREATED', response);
          if (response['result'].id && response['result'].token) {
            this.router.navigate(['/', 'checkout', response['result'].id], {
              queryParams: { token: response['result'].token },
            });
          }
        },
        error: error => {
          console.error(error);
          console.error(error.error.message);
          const modalData = {
            title: 'ERROR',
            content:
              'An Error occurred while trying to create the Transaction.',
            acceptBtnName: 'CLOSE',
            // acceptFunction: ,
          };
          // If there's a custom api error.
          if (error.error.hasOwnProperty('code')) {
            modalData.content = error.error.message;
          }
          this.modalService.openModal(modalData);
        },
      });
  }

  private initComponent(): void {
    this.minSeatsLimit = this.availabilityService.minSeatsLimit;
    this.maxSeatsLimit = this.availabilityService.maxSeatsLimit;

    this.cartService.selectedSeats$.subscribe({
      next: (response: SelectedSeats) => {
        // Asignamos el total de asientos seleccionados
        this.totalSeats = Object.values(response).length;

        // Reiniciamos timer
        this.timer ? clearTimeout(this.timer) : null;

        // Seteamos timer para lanzar animación
        this.timer = setTimeout(() => {
          this.updateTotalValue(this.cartService.selectedSeatsTotalPrice);
        }, this.time);
      },
    });
  }
}
