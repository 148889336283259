{
  "CAN": [
      {
          "sub_country_name": "Alberta",
          "sub_country_code": "AB"
      },
      {
          "sub_country_name": "British Columbia",
          "sub_country_code": "BC"
      },
      {
          "sub_country_name": "Manitoba",
          "sub_country_code": "MB"
      },
      {
          "sub_country_name": "New Brunswick",
          "sub_country_code": "NB"
      },
      {
          "sub_country_name": "Newfoundland",
          "sub_country_code": "NL"
      },
      {
          "sub_country_name": "Nova Scotia",
          "sub_country_code": "NS"
      },
      {
          "sub_country_name": "Northwest Territory",
          "sub_country_code": "NT"
      },
      {
          "sub_country_name": "Nunavut",
          "sub_country_code": "NU"
      },
      {
          "sub_country_name": "Ontario",
          "sub_country_code": "ON"
      },
      {
          "sub_country_name": "Prince Edward Island",
          "sub_country_code": "PE"
      },
      {
          "sub_country_name": "Quebec",
          "sub_country_code": "QC"
      },
      {
          "sub_country_name": "Saskatchewan",
          "sub_country_code": "SK"
      },
      {
          "sub_country_name": "Yukon Territory",
          "sub_country_code": "YT"
      }
  ],
  "USA": [
      {
          "sub_country_name": "Vermont",
          "sub_country_code": "VT"
      },
      {
          "sub_country_name": "Washington",
          "sub_country_code": "WA"
      },
      {
          "sub_country_name": "Wisconsin",
          "sub_country_code": "WI"
      },
      {
          "sub_country_name": "West Virginia",
          "sub_country_code": "WV"
      },
      {
          "sub_country_name": "Wyoming",
          "sub_country_code": "WY"
      },
      {
          "sub_country_name": "Armed Forces",
          "sub_country_code": "AE"
      },
      {
          "sub_country_name": "Alaska",
          "sub_country_code": "AK"
      },
      {
          "sub_country_name": "Alabama",
          "sub_country_code": "AL"
      },
      {
          "sub_country_name": "Armed Forces Pacific",
          "sub_country_code": "AP"
      },
      {
          "sub_country_name": "Arkansas",
          "sub_country_code": "AR"
      },
      {
          "sub_country_name": "Arizona",
          "sub_country_code": "AZ"
      },
      {
          "sub_country_name": "California",
          "sub_country_code": "CA"
      },
      {
          "sub_country_name": "Colorado",
          "sub_country_code": "CO"
      },
      {
          "sub_country_name": "Connecticut",
          "sub_country_code": "CT"
      },
      {
          "sub_country_name": "District Of Columbia",
          "sub_country_code": "DC"
      },
      {
          "sub_country_name": "Delaware",
          "sub_country_code": "DE"
      },
      {
          "sub_country_name": "Florida",
          "sub_country_code": "FL"
      },
      {
          "sub_country_name": "Georgia",
          "sub_country_code": "GA"
      },
      {
          "sub_country_name": "Guam",
          "sub_country_code": "GU"
      },
      {
          "sub_country_name": "Hawaii",
          "sub_country_code": "HI"
      },
      {
          "sub_country_name": "Iowa",
          "sub_country_code": "IA"
      },
      {
          "sub_country_name": "Idaho",
          "sub_country_code": "ID"
      },
      {
          "sub_country_name": "Illinois",
          "sub_country_code": "IL"
      },
      {
          "sub_country_name": "Indiana",
          "sub_country_code": "IN"
      },
      {
          "sub_country_name": "Kansas",
          "sub_country_code": "KS"
      },
      {
          "sub_country_name": "Kentucky",
          "sub_country_code": "KY"
      },
      {
          "sub_country_name": "Louisiana",
          "sub_country_code": "LA"
      },
      {
          "sub_country_name": "Massachusetts",
          "sub_country_code": "MA"
      },
      {
          "sub_country_name": "Maryland",
          "sub_country_code": "MD"
      },
      {
          "sub_country_name": "Maine",
          "sub_country_code": "ME"
      },
      {
          "sub_country_name": "Michigan",
          "sub_country_code": "MI"
      },
      {
          "sub_country_name": "Minnesota",
          "sub_country_code": "MN"
      },
      {
          "sub_country_name": "Missouri",
          "sub_country_code": "MO"
      },
      {
          "sub_country_name": "Mississippi",
          "sub_country_code": "MS"
      },
      {
          "sub_country_name": "Montana",
          "sub_country_code": "MT"
      },
      {
          "sub_country_name": "Not Applicable",
          "sub_country_code": "NA"
      },
      {
          "sub_country_name": "North Carolina",
          "sub_country_code": "NC"
      },
      {
          "sub_country_name": "North Dakota",
          "sub_country_code": "ND"
      },
      {
          "sub_country_name": "Nebraska",
          "sub_country_code": "NE"
      },
      {
          "sub_country_name": "New Hampshire",
          "sub_country_code": "NH"
      },
      {
          "sub_country_name": "New Jersey",
          "sub_country_code": "NJ"
      },
      {
          "sub_country_name": "New Mexico",
          "sub_country_code": "NM"
      },
      {
          "sub_country_name": "Nevada",
          "sub_country_code": "NV"
      },
      {
          "sub_country_name": "New York",
          "sub_country_code": "NY"
      },
      {
          "sub_country_name": "Ohio",
          "sub_country_code": "OH"
      },
      {
          "sub_country_name": "Oklahoma",
          "sub_country_code": "OK"
      },
      {
          "sub_country_name": "Oregon",
          "sub_country_code": "OR"
      },
      {
          "sub_country_name": "Pennsylvania",
          "sub_country_code": "PA"
      },
      {
          "sub_country_name": "Puerto Rico",
          "sub_country_code": "PR"
      },
      {
          "sub_country_name": "Rhode Island",
          "sub_country_code": "RI"
      },
      {
          "sub_country_name": "South Carolina",
          "sub_country_code": "SC"
      },
      {
          "sub_country_name": "South Dakota",
          "sub_country_code": "SD"
      },
      {
          "sub_country_name": "Tennessee",
          "sub_country_code": "TN"
      },
      {
          "sub_country_name": "Texas",
          "sub_country_code": "TX"
      },
      {
          "sub_country_name": "Utah",
          "sub_country_code": "UT"
      },
      {
          "sub_country_name": "Virginia",
          "sub_country_code": "VA"
      },
      {
          "sub_country_name": "Virgin Islands",
          "sub_country_code": "VI"
      }
  ]
}
