import { Component, inject, Input, OnInit } from '@angular/core';
import { BestAvailableService } from 'src/app/digital-venue/services/best-available.service';
import { GeneralAvailability } from 'src/app/shared/models';
import {
  GeneralAvailabilityDict,
  PriceScale,
} from 'src/app/shared/models/general-availability.model';
import { PriceRange } from 'src/app/shared/models/seat-availability.model';
import { AvailabilityService, CartService } from 'src/app/shared/services';
import { DvmService } from 'src/app/shared/services/dvm.service';
import { UpsellsService } from '../../viewer/topbar/upsells/upsells.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss',
})
export class FiltersComponent implements OnInit {
  // INPUT DATA
  @Input()
  public mode: 'default' | 'vip' = 'default';

  @Input()
  public debugMode!: boolean | undefined;

  // SERVICES
  protected availabilityService: AvailabilityService =
    inject(AvailabilityService);
  protected cartService: CartService = inject(CartService);
  protected upsells: UpsellsService = inject(UpsellsService);
  protected bestAvailable: BestAvailableService = inject(BestAvailableService);
  protected dvmService: DvmService = inject(DvmService);

  // SELECT TICKETS
  public minTickets!: number;
  public maxTickets!: number;
  public tickets!: number;

  // PRICE RANGE
  public priceRange: PriceRange = { min: 0, max: 100 };
  public minPrice: number;
  public maxPrice: number;

  // ACCESIBLE SEATS
  public adasSeats: number = 0;

  // LIFE CYCLE
  public ngOnInit(): void {
    this.initComponent();
  }

  // METHODS
  public updateTicketsSelected(value: number): void {
    if (this.tickets === value) {
      return;
    }

    this.tickets = value;

    this.upsells.sendLastUpsellsCall(this.tickets);

    this.applyFilters();

    if (this.debugMode) {
      console.log(`Tickets selected: ${this.tickets}`);
    }
  }

  public updatePriceRange(values: PriceRange): void {
    this.minPrice = values.min;
    this.maxPrice = values.max;

    this.applyFilters();

    if (this.debugMode) {
      console.log(
        `Price ranges updated: Min ${this.minPrice} - Max ${this.maxPrice}`
      );
    }
  }

  public updateAccesibleSeats(value: number): void {
    if (this.adasSeats === value) {
      return;
    }

    this.adasSeats = value;
  }

  public getBestSeats(): void {
    const totalSeats: number = this.tickets - this.adasSeats;

    this.bestAvailable.getBestXSeats(totalSeats, this.adasSeats);
  }

  public resetFilters(): void {
    this.tickets = this.minTickets;
    this.priceRange.min = this.minTickets;
    this.priceRange.max = this.maxTickets;
    this.adasSeats = 0;

    this.applyFilters();
  }

  // FILTER METHODS
  private filterSectionsByTickets(
    sectionAvailability: GeneralAvailability[]
  ): GeneralAvailability[] {
    return sectionAvailability.filter(
      section => section.availableQuantity >= this.tickets
    );
  }

  private filterSectionsByPrice(
    sectionAvailability: GeneralAvailability[]
  ): GeneralAvailability[] {
    let filteredAvailability: Set<GeneralAvailability> = new Set([]);

    sectionAvailability.forEach((section: GeneralAvailability) => {
      Object.values(section.priceScales).forEach((priceScale: PriceScale) => {
        if (
          priceScale.price.value >= this.minPrice &&
          priceScale.price.value <= this.maxPrice
        ) {
          filteredAvailability.add(section);
        }
      });
    });

    return [...filteredAvailability.values()];
  }

  private applyFilters(): void {
    if (this.dvmService.isTopView()) {
      const availability: GeneralAvailabilityDict =
          this.availabilityService.sectionsAvailability,
        filteredSections: GeneralAvailability[] = this.filterSectionsByPrice(
          this.filterSectionsByTickets(Object.values(availability))
        ),
        sectionIds: string[] = filteredSections.map(
          section => section.sectionID
        ),
        selectedSections: string[] = Object.values(
          this.cartService.selectedSeatsBySection
        ).map(section => section.id);

      this.dvmService.setMapAvailability('section', sectionIds);

      if (selectedSections.length) {
        this.dvmService.viewerService.select(selectedSections);
      }
    }

    return;
  }

  private initComponent(): void {
    // Asignamos los valores al selector de cantidad de tickets.
    this.minTickets = this.availabilityService.minSeatsLimit;
    this.maxTickets = this.availabilityService.maxSeatsLimit;
    this.tickets = this.minTickets;

    // Asignamos los valores al selector de precio.
    this.availabilityService.priceRange$.subscribe({
      next: (prices: PriceRange) => {
        this.priceRange = prices;
        this.minPrice = prices.min;
        this.maxPrice = prices.max;
      },
    });

    // Asignamos min tickets a Upsells
    this.upsells.totalCartAndFiltersSeats$.next(this.minTickets);
    this.upsells.checkCartSeatsForUpsellsList(this.minTickets);
  }
}
