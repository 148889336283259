import { Component, inject, isDevMode, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SaleEvent } from '../shared/models';
import { AvailabilityService } from '../shared/services';
import { TutorialService } from '../shared/services/tutorial.service';
import { UpsellsService } from './viewer/topbar/upsells/upsells.service';

@Component({
  selector: 'app-select-seat',
  templateUrl: './select-seat.component.html',
  styleUrl: './select-seat.component.scss',
})
export class SelectSeatComponent implements OnInit {
  // SERVICES
  private availabilityService: AvailabilityService =
    inject(AvailabilityService);
  private upsellsService: UpsellsService = inject(UpsellsService);
  protected tutorialService: TutorialService = inject(TutorialService);

  // DATA
  private event: SaleEvent = inject(ActivatedRoute).snapshot.data['event'];
  public availability!: string[];
  public showTabsOnMobile = true;

  // DEBUGGER
  protected debugger: boolean =
    inject(ActivatedRoute).snapshot.queryParams['debugger'] === 'true' ||
    isDevMode();

  // LIFE CYCLE
  ngOnInit(): void {
    this.initComponent();
  }

  // METHODS
  public toggleTabsOnMobile() {
    this.showTabsOnMobile = !this.showTabsOnMobile;
  }

  private initComponent(): void {
    // Check tutorial data
    this.tutorialService.checkTutorialOnStorage();

    // Check if we have an event response
    if (this.event) {
      // Asignamos los valores de min y max tickets al servicio de Availability
      this.availabilityService.minSeatsLimit = this.event.minTickets;
      this.availabilityService.maxSeatsLimit = this.event.maxTickets;

      // Asignamos upsells
      this.upsellsService.upsells = this.event.upsells;

      // LLamamos al servicio de Availability para obtener la disponibilidad de la sección.
      // Esto nos permite conesguir datos que necesitamos en los componentes child como el price range
      this.availabilityService
        .getSectionAvailability(this.event.pvEventId)
        .subscribe({
          next: (availability: string[]) => {
            this.availability = availability;
          },
          error: error => {
            this.availability = [];
            console.error('Error getting availability', error);
          },
        });
    }
  }
}
