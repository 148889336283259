[
  {
    "code": "CA",
    "icaoCode": "CAN",
    "name": "Canada",
    "phoneCode": "1",
    "schengen": false
  },
  {
    "code": "US",
    "icaoCode": "USA",
    "name": "United States",
    "phoneCode": "1",
    "schengen": false
  }
]
