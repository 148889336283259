<div
  class="relative transition-opacity duration-500"
  [ngClass]="{ 'opacity-0 pointer-events-none': is3dOpen() }">
  <!-- PULSE ANIMATION -->
  <div
    class="absolute -z-10 rounded-full top-0 right-0 h-full w-full"
    [ngClass]="[
      showUpsells ? 'scale-100' : 'scale-0',
      upsellsVars.acquiredUpsell ? 'bg-success' : 'bg-red',
      upsellsVars.reachingUpsell || upsellsVars.acquiredUpsell
        ? 'animate-ping'
        : ''
    ]"></div>

  <!-- ------------------------------ -->

  <!-- OUTER BUTTON  -->
  <button
    (click)="toggleBenefits()"
    [ngClass]="[
      showUpsells ? 'scale-100' : 'scale-0',
      upsellsVars.acquiredUpsell ? 'bg-success' : 'bg-red'
    ]"
    class="relative z-30 w-12 h-12 rounded-full bg-red transition-all ease-in-out flex items-center justify-center hover:scale-105 hover:shadow-lg shadow-red">
    <!-- ICON -->
    <img src="/assets/img/box-gift-icon.svg" alt="Box gift icon" />
  </button>

  <!-- ------------------------------ -->

  <!-- BADGE TOTAL UPSELLS-->
  <ng-container *ngIf="upsellsVars.acquiredUpsells.length">
    <div
      [class]="showUpsells ? 'scale-100' : 'scale-0'"
      class="absolute z-30 flex justify-center items-center bg-white rounded-full w-6 xl:top-8 xl:-right-1">
      <span>
        {{ upsellsVars.acquiredUpsells.length }}
      </span>
    </div>
  </ng-container>

  <!-- ------------------------------ -->

  <!-- TEMPORAL LINE / REACHING OR AQUIRED UPSELL -->
  <div
    class="absolute flex items-center justify-end overflow-hidden top-0 right-4 h-full z-20 w-96"
    [ngClass]="[
      upsellsVars.reachingUpsell || upsellsVars.acquiredUpsell
        ? 'pointer-events-auto '
        : 'pointer-events-none',
      showUpsells ? 'block' : 'hidden'
    ]">
    <!-- SEE SCSS FILE -->
    <!-- REACHING UPSELL -->
    <div
      class="temporal-line-container"
      [class]="upsellsVars.reachingUpsell ? 'show' : 'hide'">
      <p class="text-xs mr-5">
        Purchase
        <span class="font-bold">
          {{ upsellsVars.reachingUpsell?.goal }} tickets
        </span>
        and receive
        <span class="font-bold">
          {{ upsellsVars.reachingUpsell?.name }}
        </span>
      </p>
    </div>

    <!-- ADQUIRED UPSELL -->
    <div
      class="temporal-line-container"
      [class]="upsellsVars.acquiredUpsell ? 'show' : 'hide'">
      <p class="text-xs mr-5">
        You unlocked the
        <span class="font-bold">
          {{ upsellsVars.acquiredUpsell?.name }}
        </span>
        by purchasing
        <span class="font-bold">
          {{ upsellsVars.acquiredUpsell?.goal }} tickets
        </span>
      </p>
    </div>
  </div>

  <!-- ------------------------------ -->

  <!-- UPSELLS CONTAINER -->
  <div
    #benefitsContainer
    class="absolute top-5 right-5 z-10 block w-64 h-auto max-h-80 bg-white rounded-lg overflow-hidden origin-top-right"
    style="transform: scale(0)">
    <!-- TITLE -->
    <div class="p-6">
      <h4 class="text-sm mb-2 text-main">
        {{ templateVars.text }}
      </h4>
      <hr />

      <!-- OBJECTIVES -->
      <div class="mt-3">
        <!-- OBJETIVOS CONSEGUIDOS -->
        <ul class="flex flex-col gap-y-3 mb-2">
          <li
            *ngFor="let upsell of upsellsVars.acquiredUpsells"
            class="flex gap-x-2 items-center cursor-default">
            <div class="w-1/6">
              <span
                class="h-[16px] w-[16px] rounded-full border border-gray-400 block mx-auto">
                <img
                  src="/assets/img/check-circle-fill-icon.svg"
                  alt="Check circle fill icon" />
              </span>
            </div>
            <div class="w-5/6">
              <p>
                You unlocked the
                <span>
                  {{ upsell.name }}
                </span>
                by purchasing
                <span> {{ upsell.goal }} tickets </span>
              </p>
            </div>
          </li>
        </ul>

        <!-- OBJETIVOS DISPONIBLES -->
        <ul class="flex flex-col gap-y-3">
          <li
            *ngFor="let upsell of upsellsVars.availableUpsells"
            class="flex gap-x-2 items-center cursor-default">
            <div class="w-1/6">
              <span
                class="h-[16px] w-[16px] rounded-full border border-gray-400 bg-transparent block mx-auto"></span>
            </div>
            <div class="w-5/6">
              <p>
                Purchase
                <span> {{ upsell.goal }} tickets </span>
                and receive
                <span>
                  {{ upsell.name }}
                </span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
