<div id="checkout" class="h-full bg-gray-100 overflow-y-auto">
  <div
    class="w-full px-5 py-7 md:max-w-max md:px-10 md:py-14 md:mx-auto"
    [class.flex]="!isRainout"
    [class.flex-col]="!isRainout">
    <div class="mb-7 text-center md:mb-5">
      <h1 class="text-4xl mb-1 md:mb-3 lg:mb-0">Your cart</h1>
      <app-timer
        (transactionExpired)="onTransactionExpired()"
        [createdTime]="this.transaction?.transaction.created_at"></app-timer>
    </div>

    @if (isRainout || isMobile) {
      <div class="lg:flex">
        <div class="flex mb-0 flex-wrap lg:mb-5">
          <!-- Cart -->
          <div
            class="box-white h-auto mb-5 box-responsive md:flex md:flex-col md:justify-between md:w-8/12 lg:w-1/2">
            <div>
              <div class="box-header-center md:box-header">
                <div class="w-full text-center md:text-left">
                  <h3 class="mb-1 font-bold">Your Ticket Details</h3>
                  <p class="font-semibold text-gray-400">
                    {{ transaction?.transaction.event.description }} -
                    {{ transaction?.transaction.event.date | date: 'short' }}
                  </p>
                </div>
                <!-- <button class="btn-main-dark ml-5">Delivery method</button> -->
              </div>
              <div class="box-body md:m-2">
                <h4
                  *ngFor="let seat of seatsByPrice"
                  class="font-bold text-main-dark text-center md:text-left">
                  <span class="underline">{{ seat.quantity }} Seats</span> x
                  <span class="underline">{{
                    transaction.transaction.buyerTypeName
                  }}</span>
                  at
                  <span class="text-red"
                    >${{ seat.price | number: '1.2-2' }}</span
                  >
                </h4>
                <div class="w-full px-4 md:px-0">
                  <table class="w-full table-fixed border-separate">
                    <tr
                      *ngFor="let section of seatsForTable"
                      class="mb-3 flex flex-wrap md:mb-3">
                      <td class="td w-full mb-1 md:w-16 md:mb-0">
                        <div class="flex justify-center items-center">
                          <img
                            src="/assets/img/chair-blue-icon.svg"
                            alt="main chair icon"
                            class="mr-1" />
                          <p class="font-bold text-center text-main">
                            {{ section.totalSectionSeats }}
                          </p>
                        </div>
                      </td>
                      <td class="td flex w-full my-1 md:w-min md:my-0 md:mx-2">
                        <p class="flex text-center items-center">
                          Section
                          <span class="ml-2 font-bold text-main">{{
                            section.id
                          }}</span>
                        </p>
                      </td>
                      <td class="p-0 w-full mt-1 md:w-auto md:mt-0">
                        <div
                          *ngFor="let row of section.rows"
                          class="td-lines flex">
                          <p class="mr-10 md:mr-5 lg:mr-10">
                            Row<span class="ml-2 font-bold text-main">{{
                              row.id
                            }}</span>
                          </p>
                          <p>
                            Seats<span class="ml-2 font-bold text-main">{{
                              row.seatsRanges.join(', ')
                            }}</span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="box-footer-center md:box-footer">
              <h5 class="font-bold">
                Price: ${{
                  transaction?.transaction.tdc_transaction.totalPriceSeats
                    | number: '1.2-2'
                }}
              </h5>
            </div>
          </div>

          <!-- Group outing -->
          <div
            class="w-full box-white h-auto box-responsive mb-5 lg:mb-0 md:w-4/12 lg:w-1/2">
            <div class="box-header-center md:box-header">
              <h3 class="font-bold md:ml-5 lg:10">Enhance your group outing</h3>
            </div>
            <div class="box-body flex flex-wrap">
              <!-- Upgrades -->
              <div
                *ngFor="let upgrade of transaction?.transaction.event.upgrades"
                class="w-full md:w-auto">
                <!-- <label for="{{ upgrade.id }}" class="package-card bg-center bg-cover bg-no-repeat bg-opacity-10" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" [ngStyle]="{'background-image': 'url('+upgrade.url_image+')'}"> -->
                <label
                  for="{{ upgrade.id }}"
                  class="package-card relative overflow-hidden"
                  [ngClass]="{
                    checked:
                      upgradeSelected !== null &&
                      upgrade.id === upgradeSelected.id,
                  }">
                  <!-- <label for="{{ upgrade.id }}" class="package-card relative overflow-hidden" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" (click)="deselectUpgrade(upgrade.id)"> -->
                  <!-- <label for="{{ upgrade.id }}" class="package-card relative overflow-hidden" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" (click)="toggleUpgrade(upgrade.id)"> -->
                  <div class="w-full h-full absolute top-0 left-0 opacity-10">
                    <img
                      [src]="upgrade.url_image"
                      class="w-full h-full object-center object-cover"
                      alt="" />
                  </div>
                  <div class="package-radio">
                    <!-- <input type="radio" class="btn-radio" name="package" id="{{ upgrade.id }}"
                      [checked]="upgradeSelected != null && upgrade.id === upgradeSelected.id"> -->
                    <input
                      type="checkbox"
                      class="btn-radio"
                      #radioButton
                      name="{{ upgrade.id }}"
                      id="{{ upgrade.id }}"
                      [value]="upgrade.buyerTypeId"
                      (change)="onChangeUpgrade($event, radioButton)"
                      [checked]="
                        upgradeSelected !== null &&
                        upgrade.id === upgradeSelected.id
                      " />
                  </div>
                  <div class="w-full">
                    <div class="package-header">
                      <h5 class="mr-2 font-bold">{{ upgrade.name }}</h5>
                      <div>
                        <p class="min-w-max font-bold">
                          + ${{ upgrade.price | number: '1.2-2' }}
                        </p>
                        <h6 class="font-bold text-right">Per ticket</h6>
                        <!-- asumimos que es por ticket me suena que lo ha dicho el cliente -->
                        <!-- <h6 class="font-bold text-right">{{ package.priceType === 'ticket'? 'Per ticket' : 'Total' }}</h6> -->
                      </div>
                    </div>
                    <div class="package-body">
                      <p>{{ upgrade.description }}</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div
              *ngIf="upgradeSelected"
              class="box-footer-center md:box-footer">
              <h5 class="font-bold text-main">
                Extra Cost (${{ upgradeSelected.price | number: '1.2-2' }} x
                {{ totalTransactionSeats }} Seats): ${{
                  upgradeSelected.price * totalTransactionSeats
                    | number: '1.2-2'
                }}
              </h5>
            </div>
          </div>
        </div>

        <!-- <div class="px-6 py-4 relative bg-main-dark rounded-2.5xl"> -->
        <div
          class="flex justify-center flex-wrap md:flex-nowrap lg:mt-auto lg:ml-10 lg:mb-5">
          @if (isRainout) {
            <!-- RAIN OUT -->
            <div class="box-main mb-5 box-responsive md:w-1/2 md:h-48 md:flex">
              <div
                class="px-6 py-4 bg-rain bg-cover flex items-center rounded-2.5xl justify-center md:w-1/3">
                <h3 class="font-bold flex text-base md:text-lg">
                  <span class="mr-1 text-red">*</span> Protect your group outing
                </h3>
              </div>
              <!-- Rainout Choose -->
              <div
                *ngIf="!showRainoutInfo"
                class="px-6 py-4 flex flex-wrap justify-center md:flex-nowrap md:justify-start md:w-2/3 md:items-center">
                <div
                  class="mx-8 mb-4 text-center md:mx-0 md:mb-0 md:text-left md:mr-4">
                  <h4
                    *ngIf="eventDate - nowDate > deadlineInMilliseconds"
                    class="mb-2 font-normal text-sm md:text-base">
                    Would you like to add Rainout Insurance to protect your
                    outing?
                  </h4>
                  <div
                    *ngIf="
                      eventDate - nowDate > deadlineInMilliseconds;
                      else deadlineReached
                    "
                    class="flex items-center justify-center md:justify-stretch">
                    <input
                      type="radio"
                      name="rain-out"
                      id="yes"
                      [value]="true"
                      [(ngModel)]="
                        transaction?.transaction.tdc_transaction
                          .hasInsuranceRainOut
                      "
                      (ngModelChange)="onRainoutChange($event)" />
                    <label for="yes" class="ml-2 mr-6">Yes</label>
                    <input
                      type="radio"
                      name="rain-out"
                      id="no"
                      [value]="false"
                      [(ngModel)]="
                        transaction?.transaction.tdc_transaction
                          .hasInsuranceRainOut
                      "
                      (ngModelChange)="onRainoutChange($event)" />
                    <label for="no" class="ml-2">No</label>
                  </div>
                  <ng-template #deadlineReached>
                    <h5 class="font-normal text-gray-400">
                      Rainout Insurance is only available 21+ days before the
                      game.
                    </h5>
                  </ng-template>
                </div>
                <button
                  class="btn-main w-20 my-2 bg-main-dark border border-white"
                  (click)="showRainoutInfo = true">
                  +Info
                </button>
              </div>
              <!-- Rainout Info -->
              <div
                *ngIf="showRainoutInfo"
                class="px-6 py-4 flex flex-wrap justify-center md:flex-nowrap md:justify-stretch md:w-2/3 md:items-center">
                <div class="mb-4 md:h-full md:mr-4 md:overflow-y-auto">
                  <p class="font-bold mb-1">About</p>
                  <p>
                    The
                    {{ configurationService.configuration.clientName }} offer
                    group ticket buyers the opportunity to purchase Group
                    Rainout Insurance (ROI) to protect against the
                    weather-related postponements of games by the
                    {{ configurationService.configuration.clientName }} or Major
                    League Baseball. In the event your group ticket game is
                    postponed due to weather-related reasons, you will be
                    eligible for a full-refund (less the cost of ROI). Rainout
                    Insurance may be purchased for 10 percent of the total
                    ticket package price. For more information on Group Rainout
                    Insurance please
                    <a
                      class="underline text-main-light"
                      [href]="
                        configurationService.configuration.rainoutInsuranceUrl
                      "
                      target="_blank"
                      >click here</a
                    >.
                  </p>
                </div>
                <button
                  class="btn-white w-20 my-2 text-main-dark border border-main-dark"
                  (click)="showRainoutInfo = false">
                  Close
                </button>
              </div>
            </div>
          }

          <!-- SUMMARY -->
          <div
            class="box-white w-full px-6 py-4 shadow-lg box-responsive md:box-summary md:flex">
            <div
              class="m-auto border-gray-200 pb-3 md:w-2/3 md:pr-3 md:border-r">
              <div class="m-auto">
                <h5 class="mb-2 font-bold text-center lg:text-right">
                  Cart Summary
                </h5>
                <div class="text-gray-500">
                  <div
                    class="mb-2 text-center md:sum-list-item md:flex md:items-center">
                    <h5 class="text-nowrap text-left md:text-right">Seats:</h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:ml-4 md:text-right">
                      ${{
                        transaction?.transaction.tdc_transaction.totalPriceSeats
                          | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div
                    class="mb-2 text-center md:sum-list-item md:flex md:items-center">
                    <h5 class="text-nowrap font-normal text-left md:text-right">
                      Per ticket fees:
                    </h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:ml-4 md:text-right">
                      ${{
                        transaction?.transaction.tdc_transaction.serviceCharges
                          | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div
                    *ngIf="
                      transaction?.transaction.tdc_transaction.amusementTax > 0
                    "
                    class="mb-2 text-center md:sum-list-item">
                    <h5 class="font-normal text-right">
                      Amusement tax (12% of seat cost):
                    </h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:ml-4 md:text-right">
                      ${{
                        transaction?.transaction.tdc_transaction.amusementTax
                          | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div
                    *ngIf="rainoutValue"
                    class="mb-2 text-center md:sum-list-item">
                    <h5 class="text-right">
                      <span class="mr-1 font-normal">Insurance:</span>Rainout
                      Insurance:
                    </h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:mt-0 md:ml-4 md:text-right">
                      ${{
                        transaction?.transaction.tdc_transaction
                          .insueranceRainOutPrice | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div *ngIf="acquiredUpsells">
                    <div
                      *ngFor="let upsell of acquiredUpsells"
                      class="mb-2 text-center md:sum-list-item">
                      <h5 class="text-right">
                        <span class="mr-1 font-normal">By purchasing</span
                        >{{ upsell.goal }} tickets
                        <span class="mx-1 font-normal inline-block"
                          >you receive:</span
                        >{{ upsell.name }}.
                      </h5>
                      <div class="min-w-max">
                        <h5
                          class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:mt-0 md:ml-4 md:text-right">
                          BENEFIT
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-3 md:pt-0 md:w-1/3 md:pl-3 md:m-auto">
              <div class="text-center">
                <h4 class="font-bold">Ticket Total:</h4>
                <h4 class="font-bold">
                  ${{
                    transaction?.transaction.tdc_transaction.balance
                      | number: '1.2-2'
                  }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    } @else {
      <!--**** IF NO RAINOUT -->
      <div class="flex h-full flex-col md:flex-row">
        <div class="flex flex-wrap mb-0 md:flex-nowrap md:w-8/12 lg:w-1/2">
          <!-- Cart -->
          <div
            class="box-white h-auto w-full mb-5 md:mb-0 box-responsive md:flex md:flex-col md:justify-between">
            <div>
              <div class="box-header-center md:box-header">
                <div class="w-full text-center md:text-left">
                  <h3 class="mb-1 font-bold">Your Ticket Details</h3>
                  <p class="font-semibold text-gray-400">
                    {{ transaction?.transaction.event.description }} -
                    {{ transaction?.transaction.event.date | date: 'short' }}
                  </p>
                </div>
                <!-- <button class="btn-main-dark ml-5">Delivery method</button> -->
              </div>
              <div class="box-body md:m-2">
                <h4
                  *ngFor="let seat of seatsByPrice"
                  class="font-bold text-main-dark text-center md:text-left">
                  <span class="underline">{{ seat.quantity }} Seats</span> x
                  <span class="underline">{{
                    transaction.transaction.buyerTypeName
                  }}</span>
                  at
                  <span class="text-red"
                    >${{ seat.price | number: '1.2-2' }}</span
                  >
                </h4>
                <div class="w-full md:px-4">
                  <table class="w-full table-fixed border-separate">
                    <tr
                      *ngFor="let section of seatsForTable"
                      class="mb-3 flex flex-wrap md:mb-0 md:flex-nowrap">
                      <td class="td w-full mb-1 md:w-16 md:mb-0">
                        <div class="flex justify-center items-center">
                          <img
                            src="/assets/img/chair-blue-icon.svg"
                            alt="main chair icon"
                            class="mr-1" />
                          <p class="font-bold text-center text-main">
                            {{ section.totalSectionSeats }}
                          </p>
                        </div>
                      </td>
                      <td class="td flex w-full mb-1 md:w-min md:mb-0 md:mx-2">
                        <p class="flex text-center items-center">
                          Section
                          <span class="ml-2 font-bold text-main">{{
                            section.id
                          }}</span>
                        </p>
                      </td>
                      <td class="p-0 w-full md:w-auto">
                        <div
                          *ngFor="let row of section.rows"
                          class="td-lines flex">
                          <p class="mr-10 md:mr-5 lg:mr-10">
                            Row<span class="ml-2 font-bold text-main">{{
                              row.id
                            }}</span>
                          </p>
                          <p>
                            Seats<span class="ml-2 font-bold text-main">{{
                              row.seatsRanges.join(', ')
                            }}</span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="box-footer-center md:box-footer">
              <h5 class="font-bold">
                Price: ${{
                  transaction?.transaction.tdc_transaction.totalPriceSeats
                    | number: '1.2-2'
                }}
              </h5>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col justify-between gap-y-5 flex-wrap md:flex-nowrap md:ml-5 md:w-4/12 lg:w-1/2">
          <!-- Group outing -->
          <div class="box-white h-full box-responsive">
            <div class="box-header-center md:box-header">
              <h3 class="font-bold h-full md:ml-5 lg:10">
                Enhance your group outing
              </h3>
            </div>
            <div class="box-body flex flex-wrap">
              <!-- Upgrades -->
              <div
                *ngFor="let upgrade of transaction?.transaction.event.upgrades"
                class="w-full">
                <!-- <label for="{{ upgrade.id }}" class="package-card bg-center bg-cover bg-no-repeat bg-opacity-10" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" [ngStyle]="{'background-image': 'url('+upgrade.url_image+')'}"> -->
                <label
                  for="{{ upgrade.id }}"
                  class="package-card relative overflow-hidden"
                  [ngClass]="{
                    checked:
                      upgradeSelected !== null &&
                      upgrade.id === upgradeSelected.id,
                  }">
                  <!-- <label for="{{ upgrade.id }}" class="package-card relative overflow-hidden" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" (click)="deselectUpgrade(upgrade.id)"> -->
                  <!-- <label for="{{ upgrade.id }}" class="package-card relative overflow-hidden" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" (click)="toggleUpgrade(upgrade.id)"> -->
                  <div class="w-full h-full absolute top-0 left-0 opacity-10">
                    <img
                      [src]="upgrade.url_image"
                      class="w-full h-full object-center object-cover"
                      alt="" />
                  </div>
                  <div class="package-radio">
                    <!-- <input type="radio" class="btn-radio" name="package" id="{{ upgrade.id }}"
                                  [checked]="upgradeSelected != null && upgrade.id === upgradeSelected.id"> -->
                    <input
                      type="checkbox"
                      class="btn-radio"
                      #radioButton
                      name="{{ upgrade.id }}"
                      id="{{ upgrade.id }}"
                      [value]="upgrade.buyerTypeId"
                      (change)="onChangeUpgrade($event, radioButton)"
                      [checked]="
                        upgradeSelected !== null &&
                        upgrade.id === upgradeSelected.id
                      " />
                  </div>
                  <div class="w-full">
                    <div class="package-header">
                      <h5 class="mr-2 font-bold">{{ upgrade.name }}</h5>
                      <div>
                        <p class="min-w-max font-bold">
                          + ${{ upgrade.price | number: '1.2-2' }}
                        </p>
                        <h6 class="font-bold text-right">Per ticket</h6>
                        <!-- asumimos que es por ticket me suena que lo ha dicho el cliente -->
                        <!-- <h6 class="font-bold text-right">{{ package.priceType === 'ticket'? 'Per ticket' : 'Total' }}</h6> -->
                      </div>
                    </div>
                    <div class="package-body">
                      <p>{{ upgrade.description }}</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div
              *ngIf="upgradeSelected"
              class="box-footer-center md:box-footer">
              <h5 class="font-bold text-main">
                Extra Cost (${{ upgradeSelected.price | number: '1.2-2' }} x
                {{ totalTransactionSeats }} Seats): ${{
                  upgradeSelected.price * totalTransactionSeats
                    | number: '1.2-2'
                }}
              </h5>
            </div>
          </div>

          <!-- SUMMARY -->
          <div
            class="box-white w-full px-6 py-4 shadow-lg box-responsive md:box-summary md:flex">
            <div
              class="m-auto border-gray-200 pb-3 md:pb-0 md:w-2/3 md:pr-3 md:border-r">
              <div class="m-auto">
                <h5 class="mb-2 font-bold text-center lg:text-right">
                  Cart Summary
                </h5>
                <div class="text-gray-500">
                  <div class="mb-2 text-center md:text-left md:sum-list-item">
                    <h5 class="text-nowrap text-left md:text-right">Seats:</h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:mt-0 md:ml-4 md:text-right">
                      ${{
                        transaction?.transaction.tdc_transaction.totalPriceSeats
                          | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div class="mb-2 text-center md:text-left md:sum-list-item">
                    <h5 class="text-nowrap font-normal text-left md:text-right">
                      Per ticket fees:
                    </h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:mt-0 md:ml-4 md:text-right">
                      ${{
                        transaction?.transaction.tdc_transaction.serviceCharges
                          | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div
                    *ngIf="
                      getEnvironment() &&
                      transaction?.transaction?.tdc_transaction.amusementTax > 0
                    "
                    class="mb-2 text-center md:mb-0 md:text-left md:sum-list-item">
                    <h5 class="font-normal text-right">
                      Amusement tax (12% of seat cost):
                    </h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:mt-0 md:ml-4 md:text-right">
                      ${{
                        transaction?.transaction.tdc_transaction.amusementTax
                          | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div
                    *ngIf="rainoutValue"
                    class="mb-2 text-center md:mb-0 md:text-left md:sum-list-item">
                    <h5 class="text-right">
                      <span class="mr-1 font-normal">Insurance:</span>Rainout
                      Insurance:
                    </h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:mt-0 md:ml-4 md:text-right">
                      ${{
                        transaction?.transaction.tdc_transaction
                          .insueranceRainOutPrice | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div *ngIf="acquiredUpsells">
                    <div
                      *ngFor="let upsell of acquiredUpsells"
                      class="mb-2 text-center md:mb-0 md:text-left md:sum-list-item">
                      <h5 class="text-right">
                        <span class="mr-1 font-normal">By purchasing</span
                        >{{ upsell.goal }} tickets
                        <span class="mx-1 font-normal inline-block"
                          >you receive:</span
                        >{{ upsell.name }}.
                      </h5>
                      <div class="min-w-max">
                        <h5
                          class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:mt-0 md:ml-4 md:text-right">
                          BENEFIT
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-3 md:pt-0 md:w-1/3 md:pl-3 md:m-auto">
              <div class="text-center">
                <h4 class="font-bold">Ticket Total:</h4>
                <h4 class="font-bold">
                  ${{
                    transaction?.transaction.tdc_transaction.balance
                      | number: '1.2-2'
                  }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    <div
      class="mt-7 flex flex-col-reverse items-center md:flex-row md:items-stretch md:justify-between">
      <!-- <button class="btn-black disabled:bg-gray-600" (click)="goTo('payment')" [disabled]="!isRainOutAnswred"> -->
      <button class="btn-main-reverse h-12" (click)="restartTransaction()">
        Restart
      </button>
      <button
        data-cy="go-to-payment-button"
        class="btn-black bg-red disabled:bg-gray-600 mb-5 md:mb-0"
        (click)="proceedToPayment()"
        [disabled]="
          configurationService.configuration.rainout &&
          rainoutValue === null &&
          eventDate - nowDate > deadlineInMilliseconds
        "
        [tooltip]="
          configurationService.configuration.rainout &&
          rainoutValue === null &&
          eventDate - nowDate > deadlineInMilliseconds
            ? 'Please select a Rainout Insurance option.'
            : ''
        "
        placement="top"
        triggers="pointerenter:pointerout">
        Proceed to Payment
        <span class="ml-4 pointer-events-none"
          ><img src="/assets/img/arrow-right-white-icon.svg" alt=""
        /></span>
      </button>
    </div>
  </div>
</div>
