<!-- <div class="w-176 text-center rounded-2.5xl"> -->
<div data-cy="modal-component" class="w-full text-center rounded-2.5xl">
  <!-- Header -->
  <div class="border-b border-gray-300 px-12 py-6 md:px-24 md:py-12">
    <h1 class="text-xl md:text-3xl" [innerHTML]="title"></h1>
  </div>
  <!-- <hr> -->
  <!-- Body -->
  <div class="px-12 py-6 md:px-24 md:py-12">
    <!-- <h4 class="font-normal">{{ content }}</h4> -->
    <h4 class="font-normal" [innerHTML]="content"></h4>
    <div class="flex justify-center mt-6 md:mt-12">
      <button
        data-cy="modal-cancel-button"
        *ngIf="closeBtnName"
        (click)="close()"
        class="btn-main-reverse mr-3 md:mr-6">
        {{ closeBtnName }}
      </button>
      <button
        data-cy="modal-accept-button"
        *ngIf="acceptBtnName"
        (click)="accept()"
        class="btn-main">
        {{ acceptBtnName }}
      </button>
    </div>
  </div>
</div>
