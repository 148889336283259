import { Configuration, Custom } from '../models/configuration.model';

const custom: Custom = {
  paymentPage: {
    captureAddress: false,
    capturePhone: false,
  },
};

export const ChicagoCubsConfig: Configuration = {
  id: 'chicagocubs',
  clientName: 'Chicago Cubs',
  appName: 'Chicago Cubs',
  favicon: '/assets/img/favicon.png',
  logo: '/assets/img/logo.svg',
  phone: '733-404-4242',
  rainout: false,
  adas: true,
  email: 'cubsgrouptickets@cubs.com',
  paymentMethod: 'braintree',
  clientWebUrl: 'http://www.cubs.com/',
  clientWebText: 'www.cubs.com.',
  clientBallParkUrl:
    'https://www.mlb.com/cubs/apps/ballpark?partnerId=redirect-chc-ballparkapp',
  clientBallParkText: 'Cubs.com/BallparkApp',
  mlbShopUrl:
    'https://www.mlbshop.com/chicago-cubs/t-36228622+z-808474-1125435766',
  merchandiseWebUrl: 'https://cubs.fanportal-mlb.com/2022tickets/',
  rainoutInsuranceUrl:
    'https://www.mlb.com/cubs/tickets/group-tickets/rainout-insurance',
  termsAndConditionsUrl:
    'https://www.mlb.com/cubs/tickets/terms-and-conditions',
  analytics: '',
  theme: {
    'default-color': '0 90 156',
    'main-color': '0 90 156',
    'secondary-color': '25 149 255',
    'tertiary-color': '242 246 250',
    'accent-color': '189 48 57',
    red: '225 56 63',
    success: '86 163 67',
    'success-off-white': '236 246 232',
  },
  dvmData: {
    venueId: 'nam-us-00096-chicagocubs',
    mapId: 'main_venuemap',
    miniMapId: 'blockmap_split_minimap',
    miniMapEnabled: true,
    gaSectorsIdArray: [
      'S_501',
      'S_502',
      'S_503',
      'S_504',
      'S_505',
      'S_506',
      'S_507',
      'S_511',
      'S_512',
      'S_513',
      'S_514',
      'S_515',
      'S_536',
      'S_538',
      'S_537',
    ],
    gaAdaIdArray: [
      'S_CenterFieldADA',
      'S_LeftFieldADA',
      'S_RightFieldADA',
      'S_LeftFieldADA1',
      'S_LeftFieldADA2',
    ],
  },
  dvmStyles: {
    'seat-available-none-fill': '25 149 255',
    'seat-available-none-stroke': '0 90 156',
    'seat-available-disabled-fill': '25 149 255',
    'seat-available-disabled-stroke': '0 90 156',
    'seat-unavailable-none': '214 214 214',
    'seat-available-pending': '230 145 56',
    'section-available-normal-fill': '25 149 255',
    'section-available-normal-stroke': '0 90 156',
    'section-available-normal-group1-fill': '255 77 77',
    'section-available-normal-group1-stroke': '255 77 77',
    'section-available-hover-fill': '0 90 156',
    'section-available-hover-stroke': '255 255 255',
    'section-selected-normal-fill': '122 64 185',
    'section-selected-normal-stroke': '122 64 185',
    'section-selected-hover-stroke': '255 255 255',
  },
  custom,
  tutorial: {
    desktop: {
      1: {
        stepNum: 1,
        img: '/assets/img/redsox/tutorial/1.jpg',
        language: 'Start by selecting the total number of seats to purchase.',
        arrowPosition: 'top',
      },
    },
    mobile: {},
  },
  summaryPage: {
    image1: '',
    image2: '',
  },
};
