<!-- <div class="w-176 text-center rounded-2.5xl"> -->
<div class="w-full text-center rounded-2.5xl">
  <!-- Header -->
  <div class="border-b border-gray-300 px-12 py-6 md:px-24 md:py-12">
    <h1 class="text-xl md:text-3xl">
      <span class="flex flex-col items-center">
        <img
          [src]="configurationService.configuration.logo"
          alt="Club logo"
          class="h-20 mb-2" />
        <span class="text-red">WELCOME!</span>
      </span>
    </h1>
  </div>
  <div class="px-12 py-6 md:px-24 md:py-12">
    <!-- <h4 class="font-normal">{{ content }}</h4> -->
    <h4 class="font-normal">
      Would you like to view a quick tutorial before purchasing tickets?
    </h4>
    <div class="flex justify-center mt-5 md:mt-12">
      <button
        data-cy="dismiss-tutorial-modal"
        (click)="close()"
        class="btn-main-reverse mr-3 md:mr-6">
        No
      </button>
      <button
        data-cy="accept-tutorial-modal"
        (click)="accept()"
        class="btn-main">
        Yes
      </button>
    </div>
    <div class="hidden md:block small info-help mt-3">
      <span
        >For questions or assistance,<br />please call <b>773-404-4242</b
        ><br />or email
        <b>{{ configurationService.configuration.email }}</b></span
      >
    </div>
  </div>
</div>
