import {
  DVMViewer3dInitializerOptions,
  DVMViewerInitializerOptions,
  loadModule,
  MapViewer,
  MapViewerLoadMapOptions,
  MapViewerStyles,
  MapViewerSubscription,
  Viewer3d,
} from '@3ddv/dvm-internal';
import { Injectable, NgZone, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { UtilitiesService } from '../../shared/services';
import { ConfigurationService } from '../../shared/services/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class DvmService {
  // html placement for popovers
  popoverPlacement: ViewContainerRef;
  isTopview: boolean = false;

  viewer: MapViewer;
  viewerSubject: Subject<MapViewer> = new Subject<MapViewer>();
  viewerSubscriptions: MapViewerSubscription[] = [];
  viewer3d: Viewer3d;
  is3dOpen = false;
  viewerMini: MapViewer;
  viewerMiniSubject: Subject<MapViewer> = new Subject<MapViewer>();
  isSectionMap: boolean;
  seatmapId: string;
  sectionsMmcIdToTdc: Record<string, any> = {};
  sectionsTdcToMmc: Record<string, any> = {};

  inputOptions: DVMViewerInitializerOptions = {
    container: 'map-container', // Container where the viewer will be appended
    client_id: 'tdc', // Para usar los ids de TDC/PV en el mapa sin hacer traducciones ni historias.
    styles_by_groups: true,
    version: 'latest',
    plugins: ['best_nodes', 'selection_area'],
  };
  inputOptions3d: DVMViewer3dInitializerOptions = {
    container: 'viewer3d-container', // Container where the viewer will be appended
    version: 'stable',
  };
  inputOptionsMiniMap: DVMViewerInitializerOptions = {
    container: 'mini-map-container',
    client_id: 'tdc',
    version: 'latest',
    // plugins: ['best_nodes', 'selection_area']
  };

  loadOptions: MapViewerLoadMapOptions = {
    venue_id: '', // Venue to be loaded. 3ddigitalvenue will provide these IDs
    map_id: '', // Map id. "main" by default.
  };
  loadOptions3d = {
    venue_id: '', // Venue to be loaded. 3ddigitalvenue will provide these IDs
  };
  loadOptionsMiniMap = {
    venue_id: '',
    map_id: '',
  };
  // todo GA change for bleacher
  // TEST
  /*GA_sectors = ["S_102", "S_103"]
  bleacherSection = "S_102"*/
  // Production

  // TODO MOVER A CONFIG DE CLIENTE
  bleacherSection = 'S_Bleacher';
  bleacherAdaSection = 'S_BLCHADA';
  /*
   *  MAP BY LAYERS
   *  layer with id 'section_tier1' at level 0
   *  layer with id 'seat_tier1' at level 1
   *  layer with id 'section_tier2' at level 2
   *  layer with id 'seat_tier2' at level 3
   *
   *  viewer.layers.flags.automatic_control_level = false;
   *
   *  viewer.layers.setLayerLevel(2);
   */

  styles: MapViewerStyles;

  constructor(
    private ngZone: NgZone,
    private modalsService: ModalsService,
    private configurationService: ConfigurationService,
    private utilitiesService: UtilitiesService
  ) {
    window['loadMap'] = this.loadMap.bind(this);

    const dvmData = this.configurationService.configuration?.dvmData!;
    this.loadOptions = { map_id: dvmData.mapId, venue_id: dvmData.venueId };
    this.loadOptions3d = { venue_id: dvmData?.venueId };
    this.loadOptionsMiniMap = {
      venue_id: dvmData?.venueId,
      map_id: dvmData?.miniMapId,
    };
  }

  initializeDVM() {
    //* LOADING MODULE
    // This method will return a Promise that will resolve returning the initialized instance of the module.

    /*
    Loads a module asynchronously
    @param {string} module_name - Name of the module to be loaded
    @param {InputOptions} [input] - Object with the necessary properties to
    initialize the module. This input is different for each module.
    */
    // this.ngZone.runOutsideAngular(() => {
    loadModule('map_viewer', this.inputOptions)
      .then(viewer => {
        // console.log("VIEWER RECEIVED BY THE CLIENT", viewer);
        // To be able to use viewer on browser Console
        window['viewer'] = viewer;

        // console.log('Map Id: ', viewer.getMapId());
        //TODO Provisional here. This flag lines may be inside loadMap method, when it could be just one function for all loading maps.
        viewer.flags.automatic_selection = false;
        viewer.flags.automatic_hover = false;
        viewer.selection_area.enabled = false;
        viewer.flags.fixed_aspect_ratio = false;
        // Selection
        viewer.max_selection = 999;

        this.viewer = viewer;
        this.viewerSubject.next(viewer);

        //* LOADING MAP
        this.loadMap();
      })
      .catch(err => {
        console.error(err);
        const modalData = {
          title: 'ERROR',
          content:
            'An Error occurred while trying to receive the Viewer for Map.',
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      });
    // });
  }

  initializeDVMMiniMap() {
    // If minimap is not configured for the club, do not attempt to load
    if (!this.configurationService.configuration.dvmData.miniMapEnabled) return;

    loadModule('map_viewer', this.inputOptionsMiniMap)
      .then(viewerMini => {
        // To be able to use viewer on browser Console
        window['viewerMini'] = viewerMini;

        viewerMini.flags.fixed_aspect_ratio = false;
        viewerMini.flags.automatic_selection = false;
        viewerMini.flags.automatic_hover = false;
        viewerMini.flags.zooming = false;
        viewerMini.flags.panning = false;

        this.viewerMini = viewerMini;
        this.viewerMiniSubject.next(viewerMini);

        //* LOADING MINI MAP
        this.loadMiniMap();
      })
      .catch(err => {
        console.error(err);
        const modalData = {
          title: 'ERROR',
          content:
            'An Error occurred while trying to receive the Viewer for Mini Map.',
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      });
  }

  loadMap(mapId?: string) {
    const loadOptions = JSON.parse(JSON.stringify(this.loadOptions));
    this.isSectionMap = false;
    if (mapId) {
      this.viewer.selection_area.enabled = true;
      loadOptions.map_id = mapId;
      this.isSectionMap = true;
      this.seatmapId = mapId;
    }
    return this.viewer
      .loadMap(loadOptions)
      .then(() => {
        if (this.viewer.getMapId() === this.loadOptions.map_id) {
          this.isTopview = true;
        } else {
          this.isTopview = false;
        }
        if (
          this.utilitiesService.nodeTranslator.size === 0 ||
          this.utilitiesService.tdcToMmcTranslator.size === 0
        ) {
          this.viewer.getNodes().forEach(n => {
            this.utilitiesService.nodeTranslator.set(n.original_id, n.id);
            this.utilitiesService.tdcToMmcTranslator.set(n.id, n.original_id);
          });
        }
        this.viewer.flags.max_zoom_on_first_limit = true;
        this.centerMapOnDesktop();
        this.addGaSectorsToConfiguration();
        this.getSectionsIdTranslator();
      })
      .catch(err => {
        console.error(err);
        const modalData = {
          title: 'ERROR',
          content: 'An Error occurred while trying to load the Venue Map.',
          acceptBtnName: 'CLOSE',
        };
        this.modalsService.openModal(modalData);
      });
  }

  loadMiniMap() {
    this.viewerMini.loadMap(this.loadOptionsMiniMap).catch(err => {
      console.error(err);
      const modalData = {
        title: "ERROR: Mini Map couldn't load",
        content: 'An Error occurred while trying to load the Mini Map.',
        acceptBtnName: 'CLOSE',
      };
      this.modalsService.openModal(modalData);
    });
  }

  changeSectionStyles(tags?: string) {
    this.styles = this.viewer.getStyles();
    const configStyles = this.configurationService.configuration.dvmStyles;
    const formatRgb = this.utilitiesService.formatRgb;

    this.styles[0]['section']['available']!['normal'].none.fillStyle =
      formatRgb(configStyles['section-available-normal-fill']);
    this.styles[0]['section']['available']!['normal'].none.strokeStyle =
      formatRgb(configStyles['section-available-normal-stroke']);
    this.styles[0]['section']['available']!['hover'].none.fillStyle = formatRgb(
      configStyles['section-available-hover-fill']
    );
    this.styles[0]['section']['available']!['hover'].none.strokeStyle =
      formatRgb(configStyles['section-available-hover-stroke']);
    this.styles[0]['section']['selected']!['normal'].none.fillStyle = formatRgb(
      configStyles['section-selected-normal-fill']
    );
    this.styles[0]['section']['selected']!['normal'].none.strokeStyle =
      formatRgb(configStyles['section-selected-normal-stroke']);
    this.styles[0]['section']['selected']!['hover'].none.strokeStyle =
      formatRgb(configStyles['section-selected-hover-stroke']);

    let group1 = JSON.parse(
      JSON.stringify(this.styles[0]['section']['available']!['normal'].none)
    );
    group1.fillStyle = formatRgb(
      configStyles['section-available-normal-group1-fill']
    );
    group1.strokeStyle = formatRgb(
      configStyles['section-available-normal-group1-stroke']
    );
    this.styles[0]['section']['available']!['normal']['group1'] = group1;

    // if (tags === 'withTags') {
    //   console.log('WITH TAGS');
    //   this.styles[0].section.available.normal.myseats.fillStyle = '#FF4D4D';  // Red (Def selected color)
    //   this.styles[0].section.available.normal.myseats.strokeStyle = '#801A1A';  // Red dark (Def selected color)
    // }

    let success = this.viewer.setStyles(this.styles);
    // console.log('Section Styles Setted? ', success);
    // console.log('New Section Map Styles: ', this.styles);
  }

  changeSeatStyles() {
    this.styles = this.viewer.getStyles();
    const configStyles = this.configurationService.configuration.dvmStyles;
    const formatRgb = this.utilitiesService.formatRgb;

    if (!this.styles[0]) return;

    this.styles[0]['seat']['available']['normal'].none.fillStyle = formatRgb(
      configStyles['seat-available-none-fill']
    );
    this.styles[0]['seat']['available']['normal'].none.strokeStyle = formatRgb(
      configStyles['seat-available-none-stroke']
    );
    this.styles[0]['seat']['available']['normal'].disabled.fillStyle =
      formatRgb(configStyles['seat-available-disabled-fill']);
    this.styles[0]['seat']['available']['normal'].disabled.strokeStyle =
      formatRgb(configStyles['seat-available-disabled-stroke']);

    this.styles[0]['seat']['available']['normal'].pending.fillStyle = formatRgb(
      configStyles['seat-available-pending']
    );
    this.styles[0]['seat']['available']['normal'].pending.strokeStyle =
      formatRgb(configStyles['seat-available-pending']);

    // unavailable styles
    this.styles[0]['seat']['unavailable']['normal'] = {
      none: {
        fillStyle: formatRgb(configStyles['seat-unavailable-none']),
        stroke: formatRgb(configStyles['seat-unavailable-none']),
        lineWitdh: 0.05,
        opacity: 0.7,
      },
    };

    // this.styles[0].seat.available.hover.none.fillStyle = '#005A9C';  // main
    // this.styles[0].seat.available.hover.none.strokeStyle = '#173482';  // main dark

    // this.styles[0].seat.available.hover.disabled.fillStyle = '#005A9C';  // main
    // this.styles[0].seat.available.hover.disabled2.fillStyle = '#005A9C';  // main
    // this.styles[0].seat.available.hover.pending.fillStyle = '#005A9C';  // main
    // this.styles[0].seat.available.hover.restricted.fillStyle = '#005A9C';  // main
    // this.styles[0].seat.available.hover['social-distancing'].fillStyle = '#005A9C';  // main
    // this.styles[0].seat.available.hover['visibility-reduced'].fillStyle = '#005A9C';  // main

    let success = this.viewer.setStyles(this.styles);
    // console.log('Seat Styles Setted? ', success);
    // console.log('New Seat Map Styles: ', this.styles);
  }

  load3dView(viewId) {
    const finalize = () => {
      const loadOptions = JSON.parse(JSON.stringify(this.loadOptions3d));
      loadOptions.view_id = viewId;
      return this.viewer3d.loadView3d(loadOptions).then(() => {
        this.viewer3d.flags.fixed_aspect_ratio = false;
        this.is3dOpen = true;
        this.viewer3d.interface.setLabelPosition('bottomright');
      });
    };
    if (!this.viewer3d) {
      return loadModule('3d_viewer', this.inputOptions3d).then(viewer3d => {
        window['viewer3d'] = viewer3d;
        this.viewer3d = viewer3d;
        return finalize();
      });
    } else {
      return finalize();
    }
  }

  close3dView() {
    if (this.viewer3d) {
      // this.viewer3d.close();
      this.is3dOpen = false;
    }
  }

  /**
   * Adds General Admission (GA) sectors to the current configuration by identifying GA nodes within the viewer,
   * extracting their IDs, and updating the configuration service with these GA IDs.
   *
   * This operation is a part of managing event seating configurations, allowing dynamic updates to the configuration
   * based on the GA sectors identified within a viewer's context.
   */
  private addGaSectorsToConfiguration() {
    const gaNodes = this.viewer.getNodesByType('general_admission');

    const gaIds = gaNodes.map(node => node.id);
    this.configurationService.addGAToConfiguration(gaIds);
  }

  private getSectionsIdTranslator() {
    if (this.isTopview) {
      const sections = this.viewer.getNodesByType('section');
      const gaSections = this.viewer.getNodesByType('general_admission');
      const mergedSections = [...sections, ...gaSections];
      for (const section of mergedSections) {
        if (!this.sectionsMmcIdToTdc[section.original_id]) {
          this.sectionsMmcIdToTdc[section.original_id] = section.id;
        }
        if (!this.sectionsTdcToMmc[section.id]) {
          this.sectionsTdcToMmc[section.id] = section.original_id;
        }
      }
    }
  }

  private centerMapOnDesktop() {
    if (!this.utilitiesService.isMobile) {
      this.viewer.focusOn(
        [this.viewer.limits.bbox_scene[1], 0],
        this.viewer.min_scaling_factor
      );
    }
  }
}
