import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserClaims } from '@okta/okta-auth-js';
import { Observable, firstValueFrom } from 'rxjs';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { OktaIntegrationService } from '../../services/okta-integration.service';

@Component({
  selector: 'app-okta-container',
  templateUrl: './okta-container.component.html',
  styleUrls: ['./okta-container.component.scss'],
})
export class OktaContainerComponent implements OnInit {
  @Input() text: string;
  @Output() patronEmmiter: EventEmitter<UserClaims> = new EventEmitter();
  patron: UserClaims;
  isAuthenticated$!: Observable<boolean>;

  constructor(
    private _oktaService: OktaIntegrationService,
    public configurationService: ConfigurationService
  ) {}

  async ngOnInit(): Promise<void> {
    // TODO: Check template
    // TODO: Esto no está usando el OKTA_ID?
    this.isAuthenticated$ = this._oktaService.getIsAuthenticated();

    if (firstValueFrom(this.isAuthenticated$)) {
      this.patron = await this._oktaService.getUserInfo();

      this.patronEmmiter.emit(this.patron);
    }
  }

  signInRedirect(): void {
    let origin = window.location.href;
    this._oktaService.signInWithRedirect(origin, 'okta/redirect');
  }

  signOut(): void {
    let origin = window.location.origin;
    this._oktaService.signOut(origin);
  }
}
