<!-- Header -->
<div class="fixed top-0 h-12 md:h-16 w-full">
  <app-header></app-header>
</div>

<!-- Loading screen -->
<!-- <app-loader></app-loader> -->

<!-- Inserted by route -->
<div
  id="main"
  class="w-full fixed top-12 md:top-16 bottom-0 md:bottom-8 overflow-auto">
  <router-outlet></router-outlet>
</div>

<!-- FOOTER --->
<div class="fixed bottom-0 h-0 lg:h-8 w-full overflow-hidden">
  <app-footer></app-footer>
</div>

<!-- Error -->
<!-- <app-error-modal></app-error-modal> -->
