import { Injectable, Injector } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DvmService } from '../../digital-venue/services/dvm.service';
import { CombinedIds } from '../../digital-venue/services/seat-management.service';
import { SeatsBySection } from '../models';
import { Seat } from '../models/seat.model';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;

  /**
   * Translate node ids, from mmc id to tdc id
   */
  public nodeTranslator: Map<string, string> = new Map();

  /**
   * Translate node ids, from tdc_id to mmc_id
   */
  public tdcToMmcTranslator: Map<string, string> = new Map();

  device: {
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
  };

  constructor(
    private deviceService: DeviceDetectorService,
    private injector: Injector
  ) {
    this.deviceDetector();
  }

  deviceDetector() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();

    this.device = {
      mobile: this.isMobile,
      tablet: this.isTablet,
      desktop: this.isDesktopDevice,
    };

    // console.log('DEVICE Type', this.device);
  }

  // isMobile(): boolean {
  //   if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  distributeSeatsBySection(seats: Seat[]) {
    let seatsBySection: { [key: string]: SeatsBySection } = {};

    for (const seat of seats) {
      // console.log(seat);
      if (!seatsBySection[seat.section || seat.original_id]) {
        seatsBySection[seat.section || seat.original_id] = {
          id: seat.id.split('-')[0],
          original_id: seat.original_id,
          seats: {},
          totalSectionSeats: 0,
          totalSectionPrice: 0,
        };
      }
      // if (seatsBySection[seat.section] && !seatsBySection[seat.section][seat.seat_row]) {
      //   seatsBySection[seat.section][seat.seat_row] = {};
      // }
      seatsBySection[seat.section || seat.original_id].seats[seat.id] = seat;
    }

    // console.log('Seats By Sections: ', seatsBySection);
    return seatsBySection;
  }

  sortSectionsBySeatsQuantity(seatsBySection) {
    let seatsBySectionArray: {
      id: string;
      seats: {
        [key: string]: Seat;
      };
      original_id: string;
      totalSectionSeats: number;
      totalSectionPrice: number;
    }[] = Object.values(seatsBySection);

    this.sectionsTotalSeatsCount(seatsBySectionArray);
    this.sectionsTotalPriceCount(seatsBySectionArray);
    // console.log('Sections Array: ', seatsBySectionArray);

    if (seatsBySectionArray.length > 1) {
      // Sorting Sections if there are more than one with selected Seats
      let sortedSeatsBySectionArray = seatsBySectionArray.sort((a, b) => {
        return b.totalSectionSeats - a.totalSectionSeats;
      });
      // console.log('Sorted Sections Array: ', sortedSeatsBySectionArray);
      return sortedSeatsBySectionArray;
    } else {
      // console.log('Sections Array: ', seatsBySectionArray);
      return seatsBySectionArray;
    }
  }

  addRowDsitribution(seatsBySectionArray: SeatsBySection[]): SeatsBySection[] {
    // console.log(seatsBySectionArray);
    for (const section of seatsBySectionArray) {
      let sectionSeats: Seat[] = Object.values(section.seats);
      let seatsByRow: {
        [key: string]: {
          id: string;
          seats: {
            [key: string]: Seat;
          };
          seatsList: string[];
        };
      } = {};

      for (const seat of sectionSeats) {
        // console.log(seat);
        if (!seatsByRow[seat.seat_row]) {
          seatsByRow[seat.seat_row] = {
            id: seat.seat_row,
            seats: {},
            seatsList: [],
          };
        }
        seatsByRow[seat.seat_row].seats[seat.id] = seat;
        seatsByRow[seat.seat_row].seatsList.push(seat.seat);
      }

      section.rows = seatsByRow as any;
    }

    // console.log('Seats By Rows: ', seatsBySectionArray);
    return seatsBySectionArray;
  }

  addRangeSeats(seatsBySectionAndRowArray) {
    seatsBySectionAndRowArray.forEach(section => {
      section.rows.forEach(row => {
        let ranges: Array<string | number> = [];
        let firstNum: number;
        let lastCurr: number;
        const seatList = row.seatsList.map(seat => seat.split('-')[2]);

        for (const seatNum of seatList) {
          const currNum = parseInt(seatNum, 10);
          // console.log('currNum', currNum);
          // Si no existe firstNum
          if (!firstNum) {
            firstNum = currNum;
            lastCurr = currNum;
            // console.log('Last', lastCurr);
            // Si firstNum es el último num (sólo hay un seat seleccionado en la row)
            if (firstNum == seatList[seatList.length - 1]) {
              ranges.push(firstNum);
              // console.log('Range one (first num = last)', ranges);
            }
            // Si ya hay firstNum
          } else {
            // Si hay num anterior y son consecutivos
            if (lastCurr + 1 === currNum) {
              lastCurr = currNum;
              // console.log('Consecutive', lastCurr, currNum);
              // Si es el último num
              if (currNum == seatList[seatList.length - 1]) {
                // Si el primer y el último num tienen una diferencia de 2 o más
                // console.log('Last num', currNum);
                if (currNum - firstNum >= 2) {
                  ranges.push(firstNum + '-' + currNum);
                  // console.log('Range pushing (last num)', ranges);
                  // Si no
                } else {
                  ranges.push(firstNum);
                  ranges.push(currNum);
                  // console.log('Range false (first and last num)', ranges);
                }
              }
              // Si hay num anterior y NO son consecutivos
            } else if (lastCurr + 1 !== currNum) {
              // Si el primer y el último num tienen una diferencia de 2 o más
              if (lastCurr - firstNum >= 2) {
                ranges.push(firstNum + '-' + lastCurr);
                // console.log('Range pushing', ranges);
                // Si no
              } else {
                // Si el primer num no era el último checkeado, es decir, si había dos nums consecutivos pero no suficientes para hacer un rango, se tienen que guardar los dos
                if (firstNum !== lastCurr) {
                  ranges.push(firstNum);
                }
                ranges.push(lastCurr);
                // console.log('Range false', ranges);
              }
              // Si es el último num
              if (currNum == seatList[seatList.length - 1]) {
                ranges.push(currNum);
              }
              firstNum = currNum;
              lastCurr = currNum;
              // console.log('Nums', lastCurr, currNum);
            }
          }
        }
        // Store seatRanges
        // console.log('Ranges', ranges);
        row['seatsRanges'] = ranges;
      });
    });
    // console.log('SEATS', seatsBySectionAndRowArray);

    return seatsBySectionAndRowArray;
  }

  // addRowDsitribution(seatsBySectionArray) {
  //   // console.log(seatsBySectionArray);

  //   for (const section of seatsBySectionArray) {
  //     let sectionSeats: Seat[] = Object.values(section.seats);
  //     let seatsByRow: {
  //       id: string,
  //       seatsList: string[]
  //     }[] = [];

  //     for (const seat of sectionSeats) {
  //       // console.log(seat);
  //       if (!seatsByRow[seat.seat_row]) {
  //         seatsByRow[seat.seat_row] = {
  //           id: seat.seat_row,
  //           seats: {},
  //           seatsList: []
  //         };
  //       }
  //       seatsByRow[seat.seat_row].seats[seat.id] = seat;
  //       seatsByRow[seat.seat_row].seatsList.push(seat.seat);
  //     }

  //     section.rows = seatsByRow;
  //   }

  //   // console.log('Seats By Rows: ', seatsBySectionArray);
  //   return seatsBySectionArray;
  // }

  sectionsTotalSeatsCount(seatsBySectionArray) {
    for (const section of seatsBySectionArray) {
      section.totalSectionSeats = Object.keys(section.seats).length;
    }
  }

  sectionsTotalPriceCount(seatsBySectionArray) {
    for (const section of seatsBySectionArray) {
      // console.log(section);
      section.totalSectionPrice = Object.values(section.seats).reduce(
        (total, seat) => total + seat['price'],
        0
      );
    }
  }

  // seatsParseForTable(seats: Seat[]) {
  //   let parsedSeatsByRow: {section: string, row: string, seats: string[]}[] = [];
  //   let newRow: {section: string, row: string, seats: string[]} = {section: '', row: '', seats: []};
  //   for (const seat of seats) {
  //     // If seat is from a new row, creates/overwrites the newRow
  //     if (seat.seat_row !== newRow.row) {
  //       // But before, if there was a newRow before, push it
  //       if (newRow.row) {
  //         console.log('newRow: ', newRow);
  //         parsedSeatsByRow.push(newRow);
  //       }
  //       newRow = {
  //         section: seat.section,
  //         row: seat.seat_row,
  //         seats: [seat.seat]
  //       };
  //     // Else, it's from the same row as newRow, adds the seat to it
  //     } else {
  //       newRow.seats.push(seat.seat);
  //       // And if it's the last seat, push the newRow
  //       if (seat === seats[seats.length - 1]) {   // Using ES-2022 Array.at(): if (seat === seats.at(-1)) {
  //         console.log('newRow (last): ', newRow);
  //         parsedSeatsByRow.push(newRow);
  //       }
  //     }
  //   }
  //   // console.log('parsedSeatsByRow: ', parsedSeatsByRow);
  //   return parsedSeatsByRow;
  // }

  splittedSeatInfoFromId(seatIds: CombinedIds | string) {
    // "S_310-8-9"
    let seatInfo: {
      id: string;
      section: string;
      seat_row: string;
      seat: string;
      original_id?: string;
    };
    const isString = typeof seatIds === 'string';
    let stringId = isString && seatIds;

    const splitedInfo = isString
      ? seatIds.split('-')
      : seatIds.original_id.split('-');

    // On general admission, the id received is the TDC one, so we get the MMC one from the service
    let mmcSeatId = '';
    if (isString) {
      const dvmService = this.injector.get(DvmService);
      mmcSeatId = dvmService.sectionsTdcToMmc[seatIds];
    }

    seatInfo = {
      id: isString ? seatIds : seatIds.id,
      section: splitedInfo[0],
      seat_row: splitedInfo[1],
      seat: isString ? seatIds : splitedInfo[2],
      original_id: isString ? mmcSeatId : seatIds?.original_id,
    };

    return seatInfo;
  }

  totalSumApiSeatsPrice(seats: Seat[]): number {
    let sumPrice = seats.reduce(
      (accumulator, current) => accumulator + current.price,
      0
    );
    return sumPrice;
    // let sumPrice = 0;
    // for (const seat of seats) {
    //   sumPrice += seat.price;
    // }
    // return sumPrice;
  }

  totalSumDvmSeatsPrice(seats: { [key: string]: Seat }): number {
    // let sumPrice = Object.values(seats).reduce((accumulator, current) => accumulator + current.price_type['prices'][0].price, 0);
    let sumPrice = Object.values(seats).reduce(
      (accumulator, current) => accumulator + current.price,
      0
    ); //todo By now, bc no real data is received.
    return sumPrice;
  }

  formatRgb(rgbString: string, wrapped = true) {
    return wrapped
      ? 'rgb(' + rgbString.split(' ').join(', ') + ')'
      : rgbString.split(' ').join(', ');
  }
}
