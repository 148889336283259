import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cart-header',
  templateUrl: './cart-header.component.html',
  styleUrl: './cart-header.component.scss'
})
export class CartHeaderComponent {

  // INPUT DATA
  @Input()
  public hasItems:  boolean = false;

  @Input()
  public debugMode: boolean = false;

  // EVENT EMITTER
  @Output()
  public removeAll: EventEmitter<void> = new EventEmitter<void>();

  // TEMPLATE
  public templateVars: {header: string, subtitle: string, button: string} = {
    header:   'Your Cart',
    subtitle: "Accept 'Best Available' or select your own seats.",
    button:   'Remove all'
  };

  // METHODS
  public onRemoveAll(): void {

    if (this.debugMode) {
      console.log('Cart Remove All Called');
    }

    this.removeAll.emit();
  }
  
}
