<div
  data-cy="cart-item-instance"
  class="w-full h-full flex items-center justify-between border-b px-4 xs:px-6 sm:px-12 md:px-16 lg:px-4"
  [ngClass]="mode === 'section' ? 'bg-white' : 'bg-gray-100'"
  (mouseenter)="onHover()"
  (mouseleave)="onLeave()">
  <!-- INFO -->
  <div class="w-8/12">
    <div class="flex justify-between items-center">
      <!-- SECTION TEXT  -->
      <h5 class="font-bold flex flex-col md:flex-row">
        <span class="font-normal text-gray-600 mr-3"> Section </span>
        <span>
          {{ sectionVars ? sectionVars.section : seatVars.section }}
        </span>
      </h5>

      <!-- SECTION DATA -->
      <ng-container *ngIf="mode === 'section'; else seatData">
        <!-- Seat Counter -->
        <div class="flex items-center">
          <div
            class="w-25 h-8 flex justify-center items-center border border-gray-300 rounded-full px-4 text-">
            <!-- Img -->
            <img
              src="/assets/img/chair-blue-icon.svg"
              alt="blue chair icon"
              class="h-3" />

            <!-- Seat Number -->
            <h5 class="min-w-max ml-1 font-bold text-blue">
              {{ sectionVars.seats }} seats
            </h5>
          </div>

          <!-- PRICE -->
          <h5 class="font-bold text-center text-main ml-2">
            {{ sectionVars.price | currency: 'USD' }}
          </h5>
        </div>
      </ng-container>

      <!-- SEAT DATA -->
      <ng-template #seatData>
        <!-- ROW -->
        <h5 class="font-bold flex flex-col md:flex-row">
          <span class="font-normal text-gray-600"> Row </span>
          <span class="ml-2">
            {{ seatVars.row }}
          </span>
        </h5>

        <!-- SEAT -->
        <h5 class="font-bold flex flex-col md:flex-row">
          <span class="font-normal text-gray-600"> Seat </span>
          <span class="ml-2">
            {{ seatVars.seat }}
          </span>
        </h5>

        <!-- PRICE -->
        <h5 class="font-bold text-center text-main flex flex-col md:flex-row">
          {{ seatVars.price | currency: 'USD' }}
        </h5>
      </ng-template>
    </div>
  </div>

  <!-- BUTTONS -->
  <div class="w-4/12 flex justify-between items-center">
    <!-- DELETE BUTTON -->
    <button
      class="btn-icon hover:bg-gray-200"
      (click)="mode === 'section' ? removeSection() : removeSeat()"
      [ngClass]="mode === 'section' ? 'bg-white' : 'bg-gray-100'">
      <img
        data-cy="cart-item-delete-button"
        src="/assets/img/delete-bin-icon.svg"
        alt="delet bin icon" />
    </button>

    <!-- SECTION -->
    <ng-container *ngIf="mode === 'section'; else seatButtons">
      <!-- LOCATION BUTTON -->
      <button class="btn-icon hover:bg-gray-200" (click)="goToSection()">
        <img
          data-cy="cart-item-open-seat-map"
          src="/assets/img/location-sharp-icon.svg"
          alt="location icon" />
      </button>

      <!-- TOGGLE SEAT VISIBILITY -->
      <button
        [ngClass]="{ 'rotate-180': showSeats, 'rotate-0': !showSeats }"
        class="btn-icon hover:bg-gray-200 transition-transform duration-200 ease-linear"
        (click)="toggleSeats()">
        <img
          data-cy="cart-item-accordion-toggle"
          src="/assets/img/arrow-down-blue-icon.svg"
          alt="blue downward arrow icon" />
      </button>
    </ng-container>

    <!-- SEAT -->
    <ng-template #seatButtons>
      <!-- SEAT DEFINITION BUTTON -->
      <ng-container [ngSwitch]="hasSeatDefinition()">
        <!-- INFO BUTTON  -->
        <button
          class="btn-icon"
          *ngSwitchCase="'info'"
          (click)="openSeatDefinition()">
          <img
            src="/assets/img/information-icon-blue.svg"
            alt="location icon" />
        </button>

        <!-- WHEELCHAIR BUTTON -->
        <button class="btn-icon" *ngSwitchCase="'adas'">
          <img src="/assets/img/accessible-icon.svg" alt="accessible seating" />
        </button>
      </ng-container>

      <!-- OPEN 3D VIEW -->
      <button
        (click)="open3dView()"
        class="btn-icon bg-white hover:bg-gray-200">
        <img
          data-cy="cart-item-open-3d-viewer"
          src="/assets/img/feather-eye.svg"
          alt="location icon" />
      </button>
    </ng-template>
  </div>
</div>
