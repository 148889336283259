import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState, UserClaims } from '@okta/okta-auth-js';
import { Observable, filter, map } from 'rxjs';
import { OktaIntegrationModule } from '../okta-integration.module';

@Injectable({
  providedIn: OktaIntegrationModule,
})
export class OktaIntegrationService {
  constructor(
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private _router: Router,
    private _oktaAuthStateService: OktaAuthStateService
  ) {}

  public getIsAuthenticated(): Observable<boolean> {
    return this._oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
  }

  /**
   * Function that will execute the redirection to the okta hosted page to proceed to the user identification.
   * @param {string} origin - page where the client who presses the `login` button is located
   * @param {string} navigateTo - page previous to redirect
   */
  public async signInWithRedirect(
    origin: string,
    navigateTo: string
  ): Promise<void> {
    await this._oktaAuth
      .signInWithRedirect({ originalUri: origin })
      .then(_ => this._router.navigate([`${navigateTo}`]));
  }

  /**
   * Logout function, remove all information okta
   * @param {string} origin
   */
  public async signOut(origin: string): Promise<void> {
    await this._oktaAuth.signOut();
  }

  /**
   * Return Patron info from Okta
   * @returns Promise<UserClaims>
   */
  public async getUserInfo(): Promise<UserClaims> {
    return await this._oktaAuth.getUser();
  }

  public getAccessToken(): string {
    return this._oktaAuth.getAccessToken();
  }
}
