import {
  Component,
  effect,
  EffectCleanupRegisterFn,
  EffectRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { AnimeAnimParams, EasingOptions } from 'animejs';
import anime from 'animejs/lib/anime.es.js';
@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrl: './selector.component.scss',
})
export class SelectorComponent implements OnInit, OnChanges, OnDestroy {
  // INPUT COUNTER
  @ViewChild('counter')
  public counter: ElementRef<HTMLInputElement>;

  // DATA INPUTS
  @Input()
  useCaseId: string; // Id que indica donde se esta usando la instancia del componente

  @Input()
  initialValue: number = 0; // Valor inicial del selector

  @Input()
  steps: number = 1; // Valor a incrementar o decrementar

  @Input()
  minimumValue: number = 0; // Valor mínimo del selector

  @Input()
  maximumValue: number = 100; // Valor máximo del selector

  @Input()
  initMinimumLimit: boolean = true; // Activar límite mínimo

  @Input()
  initMaximumLimit: boolean = true; // Activar límite máximo

  @Input()
  debugMode: boolean = false; // Activar modo de depuración

  // STYLING INPUTS
  @Input()
  public showIcon: boolean = true; // Mostrar icono de asiento

  @Input()
  public reverseColors: boolean = false; // Revertir colores de los botones

  @Input()
  public inputColor: string = 'white'; // Color del texto del input

  // EVENT EMITTER
  @Output()
  public valueChange: EventEmitter<number> = new EventEmitter<number>();

  // STATE
  protected value: WritableSignal<number | null> = signal(null); // Valor con el que trabaja el selector

  // LIFE CYCLE

  public ngOnInit(): void {
    this.initComponent();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['maximumValue']) {
      this.validateMaximumValue();
    }

    if (changes['minimumValue']) {
      this.validateMinimumValue();
    }
  }

  public ngOnDestroy(): void {
    this.updateValue.destroy();
  }

  // METHODS

  /**
   * Incrementa el valor inicial por el número de pasos especificado.
   * Si el límite máximo está establecido y el valor inicial ya es igual o
   * mayor que el valor máximo, el método no hace nada.
   */
  public increment(): void {
    if (this.initMaximumLimit && this.value() >= this.maximumValue) {
      return;
    }

    this.updateCounter('up');
  }

  /**
   * Resta el valor inicial por el número de pasos especificado.
   * Si el límite mínimo está establecido y el valor inicial ya es igual o
   */
  public decrement(): void {
    if (this.initMinimumLimit && this.value() <= this.minimumValue) {
      return;
    }

    this.updateCounter('down');
  }

  /**
   * Manual Update
   * Realiza comprobación del input cuando el usuario introduce un valor manualmente y se va del input.
   * Se realizan las siguientes comprobaciones:
   *
   * 1. Si el valor introducido no es un número, se resetea el contador.
   * 2. Si el valor introducido es menor que el valor mínimo, se resetea el contador.
   * 3. Si el límite máximo está activado y el valor introducido es mayor que el valor máximo, se establece el valor máximo.
   *
   * @param value
   * @returns
   */
  public manualUpdate(value?: FocusEvent): void {
    const inputValue: number | string | undefined =
      parseInt((value.target as HTMLInputElement).value) ?? undefined;

    if (!inputValue || isNaN(inputValue)) {
      this.resetCounter();
      return;
    }

    if (inputValue < this.minimumValue) {
      this.resetCounter();
    } else if (this.initMaximumLimit && inputValue > this.maximumValue) {
      this.value.set(this.maximumValue);
    } else {
      this.value.set(inputValue);
    }
  }

  /**
   * Método de inicialización del componente.
   * Valida los inputs y establece el valor inicial.
   */
  private initComponent(): void {
    // Validate inputs
    this.validateInputs();

    // Set initial value
    this.value.set(this.initialValue);
  }

  /**
   * Método que valida los inputs del componente.
   * Si el valor inicial no es un número, se lanza un error.
   *
   * Validaciones:
   *
   * 1. El valor inicial debe ser un número.
   * 2. Los pasos deben ser mayores a 0.
   * 3. El valor mínimo no puede ser mayor que el valor máximo.
   * 4. Si el límite mínimo está activado, el valor inicial no puede ser menor que el valor mínimo.
   * 5. Si el límite máximo está activado, el valor inicial no puede ser mayor que el valor máximo.
   *
   *
   **/
  private validateInputs(): void | Error {
    if (isNaN(this.initialValue)) {
      throw new Error('Initial value must be a number');
    }

    if (this.steps <= 0) {
      throw new Error('Steps must be greater than 0');
    }

    if (this.minimumValue > this.maximumValue) {
      throw new Error('Minimum value cannot be greater than maximum value');
    }

    if (this.initMinimumLimit && this.initialValue < this.minimumValue) {
      throw new Error('Initial value cannot be less than minimum value');
    }

    if (this.initMaximumLimit && this.initialValue > this.maximumValue) {
      throw new Error('Initial value cannot be greater than maximum value');
    }
  }

  /**
   * Método que valida el valor actual del selector.
   * Este método es llamado por el effect de la signal value. Se llama cada vez que esta señal cambia.
   * Para evitar que emita valores que no queremos, aplicamos una serie de condicionales y retornamos un booleano.
   * Las validaciones son:
   *
   * 1. El valor debe ser un número.
   * 2. Si el límite mínimo está activado, el valor no puede ser menor que el valor mínimo.
   * 3. Si el límite máximo está activado, el valor no puede ser mayor que el valor máximo.
   * @returns {boolean}
   */
  private validateValue(): boolean {
    // Validate if value is a number
    if (typeof this.value() !== 'number') {
      return false;
    }

    // Validate if value is less than minimum value
    if (this.initMinimumLimit && this.value() < this.minimumValue) {
      return false;
    }

    // Validate if value is greater than maximum value
    if (this.initMaximumLimit && this.value() > this.maximumValue) {
      return false;
    }

    return true;
  }

  /**
   * Método que comprueba si el valor actual es mayor que el valor máximo.
   * Se lanza al haber un cambio en el input de valor máximo.
   */
  private validateMaximumValue(): void {
    if (this.initMaximumLimit && this.value() > this.maximumValue) {
      this.value.set(this.maximumValue);
    }
    return;
  }

  /**
   * Método que comprueba si el valor actual es menor que el valor mínimo.
   * Se lanza exclusivamente si el input del valor minimo cambia.
   * @returns
   */
  private validateMinimumValue(): void {
    if (this.initMinimumLimit && this.value() < this.minimumValue) {
      this.resetCounter();
    }
    return;
  }

  /**
   * Método que actualiza el contador del selector mediante una animación.
   *
   * Se declara una variable que contiene un array de objetos con las propiedades de la animación.
   * Dicho array contiene dos objetoos que son el primer paso de la animacion y el paso final.
   *
   * Si el tipo es 'up', se incrementa el valor del contador y se actualiza el valor del selector.
   * Si el tipo es 'down', se decrementa el valor del contador y se actualiza el valor del selector.
   * @param type
   */
  private updateCounter(type: 'up' | 'down'): void {
    const animationParams: {
      easing: EasingOptions;
      rotation: AnimeAnimParams;
      translateY: AnimeAnimParams;
      opacity: AnimeAnimParams;
      scale: AnimeAnimParams;
    }[] =
      type === 'up'
        ? // INCREMENT ANIMATION (UP)
          [
            // First Step
            {
              easing: 'easeOutCubic',
              rotation: ['0deg', '-25deg'],
              translateY: ['0', '5px'],
              opacity: ['1', '0'],
              scale: ['1', '0.7'],
            },
            // Final Step
            {
              easing: 'easeInCubic',
              rotation: ['25deg', '0deg'],
              translateY: ['-5px', '0'],
              opacity: ['0', '1'],
              scale: ['0.7', '1'],
            },
          ]
        : // DECREMENT ANIMATION (DOWN)
          [
            // First Step
            {
              easing: 'easeOutCubic',
              rotation: ['0deg', '25deg'],
              translateY: ['0', '-5px'],
              opacity: ['1', '0'],
              scale: ['1', '0.7'],
            },
            // Final Step
            {
              easing: 'easeInCubic',
              rotation: ['-25deg', '0deg'],
              translateY: ['5px', '0'],
              opacity: ['0', '1'],
              scale: ['0.7', '1'],
            },
          ];

    // ANIMATION
    anime
      .timeline({
        duration: 250,
        targets: this.counter.nativeElement,
      })
      .add({
        rotate: animationParams[0].rotation,
        translateY: animationParams[0].translateY,
        opacity: animationParams[0].opacity,
        easing: animationParams[0].easing,
        scale: animationParams[0].scale,
        complete: () => this.validateAndUpdate(type),
      })
      .add({
        rotate: animationParams[1].rotation,
        translateY: animationParams[1].translateY,
        opacity: animationParams[1].opacity,
        easing: animationParams[1].easing,
        scale: animationParams[1].scale,
      });
  }

  /**
   * Método que resetea el contador a su valor inicial.
   */
  private resetCounter(): void {
    this.value.set(this.initialValue);
  }

  private validateAndUpdate(type: 'up' | 'down'): void {
    if (type === 'up' && this.value() >= this.maximumValue) {
      return;
    } else if (type === 'down' && this.value() <= this.minimumValue) {
      return;
    }

    type === 'up'
      ? this.value.update(value => (value += this.steps))
      : this.value.update(value => (value -= this.steps));
  }

  /**
   * Efecto producido por la señal Value
   * Este efecto crea un temporizador de 300ms (50ms que la animación) que se ejecuta cada vez que el valor del selector cambia.
   * Si el valor cambia antes de esos 300ms se cancela la operación. De este modo, si el usuario está incrementando o decrementando
   * repetidas veces, solo se emitirá el valor final. O si lo introduce manualmente, esperará a que el usuario termine de introducir el valor.
   *
   * Dentro del temporizador, se produce una validación del valor actual. Si el valor es válido, se emite el valor.
   * Si está activo el modo debug, se mostrará en consola el valor.
   *
   * En el ciclo de vida del componente OnDestroy, se destruye el efecto.
   **/
  private updateValue: EffectRef = effect(
    (onCleanup: EffectCleanupRegisterFn) => {
      if (this.debugMode) {
        console.log('Efecto del selector lanzado, esperando valor final...');
      }

      // Instanciamos valor de la señal trackeable
      const value: number = this.value();

      // Temporizador de 300ms con el validador y emisor de valor
      const timer: NodeJS.Timeout = setTimeout(() => {
        if (this.validateValue()) {
          this.valueChange.emit(value);

          if (this.debugMode) {
            console.log('El valor del selector ha cambiado a: ', value);
          }
        }
      }, 200);

      // Si se relanza el efecto, se cancela el temporizador.
      onCleanup(() => {
        clearTimeout(timer);
      });
    }
  );
}
