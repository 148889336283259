<section class="app-card bg-main rounded-2.5xl">
  <div
    class="app-card-header h-full flex items-center w-full flex-col md:flex-row">
    <div
      class="w-full md:w-8/12 flex items-center justify-center md:justify-between my-2 md:my-0">
      <div class="flex items-center gap-x-2">
        <p class="flex items-center">
          <span class="text-center hidden md:inline">
            Total <br />
            Seats
          </span>
          <span class="text-center text-base font-medium inline md:hidden"
            >Total Seats</span
          >

          <span class="ml-3 mr-1">
            <img
              src="/assets/img/chair-white-icon.svg"
              alt="white chair icon"
              class="w-3" />
          </span>
          <span> : </span>
        </p>

        <div
          class="w-14 h-8 px-2 flex justify-center items-center border rounded-full">
          <h5>
            {{ totalSeats }}
          </h5>
        </div>
      </div>

      <div
        class="flex flex-col mx-0 ml-4 md:ml-0 text-center md:justify-center md:flex-auto">
        <p class="text-sm 3xl:text-base font-normal whitespace-nowrap">
          Ticket Total:
        </p>
        <p class="font-semibold text-base">
          <span> $ </span>
          <span #total>
            {{ totalAmount + ',00' }}
          </span>
        </p>
      </div>
    </div>

    <div class="w-full md:w-4/12 relative group">
      <div
        class="absolute bottom-[calc(100%+1em)] left-1/2 -translate-x-1/2 transition-transform hidden"
        [class.group-hover:block]="hasMaxSeatsError">
        <div
          [class.py-2]="hasMaxSeatsError"
          [class.px-4]="hasMaxSeatsError"
          class="bg-white text-gray-500 text-center text-xs font-bold rounded-xl right-0 bottom-full min-w-max">
          <span>You have exceeded</span> <br />
          <span>the ticket limit of {{ maxSeatsLimit }}</span>

          <svg
            class="absolute text-white h-2 w-full left-0 top-full"
            x="0px"
            y="0px"
            viewBox="0 0 255 255"
            xml:space="preserve">
            <polygon class="fill-current" points="0,0 127.5,127.5 255,0" />
          </svg>
        </div>
      </div>

      <button
        data-cy="checkout-button"
        (click)="startCheckout()"
        class="btn-white font-bold disabled:opacity-20 w-40 md:w-full mx-auto my-2 md:mt-0 md:mb-0 disabled:transform-none transition-transform ease-in-out hover:scale-95 text-gray-700 hover:text-blue hover:shadow hover:shadow-white/50"
        [disabled]="!canCheckout">
        <span class="block"> Go To Checkout </span>
      </button>
    </div>
  </div>
</section>
