import {
  Configuration,
  Custom,
  DvmData,
  DvmStyles,
  Theme,
  TutorialData,
} from '../models/configuration.model';

const custom: Custom = {
  paymentPage: {
    captureAddress: true,
    capturePhone: false,
  },
};

const theme: Theme = {
  'default-color': '13 43 86',
  'main-color': '13 43 86',
  'secondary-color': '25 149 255',
  'tertiary-color': '242 246 250',
  'accent-color': '189 48 57',
  red: '189 48 57',
  success: '86 163 67',
  'success-off-white': '236 246 232',
};

const dvmData: DvmData = {
  venueId: 'nam-us-10170-redsox',
  mapId: 'main_venuemap',
  miniMapId: 'main_venuemap_minimap',
  miniMapEnabled: true,
  gaSectorsIdArray: [],
  gaAdaIdArray: [],
};

const dvmStyles: DvmStyles = {
  'seat-available-none-fill': '25 149 255',
  'seat-available-none-stroke': '0 90 156',
  'seat-available-disabled-fill': '25 149 255',
  'seat-available-disabled-stroke': '0 90 156',
  'seat-unavailable-none': '214 214 214',
  'seat-available-pending': '230 145 56',
  'section-available-normal-fill': '25 149 255',
  'section-available-normal-stroke': '0 90 156',
  'section-available-normal-group1-fill': '255 77 77',
  'section-available-normal-group1-stroke': '255 77 77',
  'section-available-hover-fill': '0 90 156',
  'section-available-hover-stroke': '255 255 255',
  'section-selected-normal-fill': '122 64 185',
  'section-selected-normal-stroke': '122 64 185',
  'section-selected-hover-stroke': '255 255 255',
};

const tutorial: TutorialData = {
  desktop: {
    1: {
      stepNum: 1,
      img: '/assets/img/redsox/tutorial/1.jpg',
      language: 'Start by selecting the total number of seats to purchase.',
      arrowPosition: 'top',
    },
    2: {
      stepNum: 2,
      img: '/assets/img/redsox/tutorial/2.jpg',
      language: 'Use the slider to set your desired price range per ticket.',
      arrowPosition: 'top',
    },
    3: {
      stepNum: 3,
      img: '/assets/img/redsox/tutorial/3.jpg',
      language:
        'If necessary, select the number of wheelchair accessible seats you need. *Note that this number will be included in the total number of seats selected in step 1.',
      arrowPosition: 'top',
    },
    4: {
      stepNum: 4,
      img: '/assets/img/redsox/tutorial/4.jpg',
      language:
        'Use the seat map to select the section(s) you want to sit in. Selected sections appear in purple.',
      arrowPosition: 'right',
    },
    5: {
      stepNum: 5,
      img: '/assets/img/redsox/tutorial/5.jpg',
      language:
        'Hover over a section to preview a view from that section. Click “Open Seat Map” to manually select seats in that section.',
      arrowPosition: 'right',
    },
    6: {
      stepNum: 6,
      img: '/assets/img/redsox/tutorial/6.jpg',
      language:
        'Selected seats appear red with a checkmark. If needed, change your selection by clicking each seat individually or hold Ctrl + click and drag to select a group of seats.',
      arrowPosition: 'right',
    },
    7: {
      stepNum: 7,
      img: '/assets/img/redsox/tutorial/7.jpg',
      language:
        'Selected seats appear in the Cart. Click the map icon to go to that section in the map. Click the trash can to remove seats and make a different selection.',
      arrowPosition: 'left',
    },
    8: {
      stepNum: 8,
      img: '/assets/img/redsox/tutorial/8.jpg',
      language: 'When you are ready to purchase, click “Go to Checkout.”',
      arrowPosition: 'left',
    },
    9: {
      stepNum: 9,
      img: '/assets/img/redsox/tutorial/9.jpg',
      language: 'Review  your ticket information.',
      arrowPosition: 'left',
    },
    10: {
      stepNum: 10,
      img: '/assets/img/redsox/tutorial/10.jpg',
      language: 'Enhance your group outing with an optional upgraded ticket.',
      arrowPosition: 'right',
    },
    11: {
      stepNum: 11,
      img: '/assets/img/redsox/tutorial/11.jpg',
      language:
        'Review the details of your group ticket pricing. Click “Proceed to Payment” to complete your purchase.',
      arrowPosition: 'right',
    },
  },
  mobile: {
    1: {
      stepNum: 1,
      img: '/assets/img/redsox/tutorial/mobile/1.jpg',
      language: 'Start by selecting the total number of seats to purchase.',
      arrowPosition: 'bot',
    },
    2: {
      stepNum: 2,
      img: '/assets/img/redsox/tutorial/mobile/2.jpg',
      language: 'Use the slider to set your desired price range per ticket.',
      arrowPosition: 'bot',
    },
    3: {
      stepNum: 3,
      img: '/assets/img/redsox/tutorial/mobile/3.jpg',
      language:
        'If necessary, select the number of wheelchair accessible seats you need. *Note that this number will be included in the total number of seats selected in step 1.',
      arrowPosition: 'bot',
    },
    4: {
      stepNum: 4,
      img: '/assets/img/redsox/tutorial/mobile/4.jpg',
      language:
        'Use the seat map to select the section(s) you want to sit in. Selected sections appear in purple.',
      arrowPosition: 'top',
    },
    5: {
      stepNum: 5,
      img: '/assets/img/redsox/tutorial/mobile/12.jpg',
      language:
        'Click a section to preview a view from that section. Click “Select“ to find your seats in that particular section or click “Open Seat Map“ to manually select seats in that section.',
      arrowPosition: 'top',
    },
    6: {
      stepNum: 6,
      img: '/assets/img/redsox/tutorial/mobile/5.jpg',
      language:
        'Selected seats appear red with a checkmark. If needed, change your selection by clicking each seat.',
      arrowPosition: 'top',
    },
    7: {
      stepNum: 7,
      img: '/assets/img/redsox/tutorial/mobile/6.jpg',
      language:
        'Selected seats appear in the Cart. Click the map icon to go to that section in the map. Click the trash can to remove seats and make a different selection.',
      arrowPosition: 'bot',
    },
    8: {
      stepNum: 8,
      img: '/assets/img/redsox/tutorial/mobile/7.jpg',
      language: 'When you are ready to purchase, click “Go to Checkout.”',
      arrowPosition: 'bot',
    },
    9: {
      stepNum: 9,
      img: '/assets/img/redsox/tutorial/mobile/8.jpg',
      language: 'Review  your ticket information.',
      arrowPosition: 'bot',
    },
    10: {
      stepNum: 10,
      img: '/assets/img/redsox/tutorial/mobile/9.jpg',
      language: 'Enhance your group outing with an optional upgraded ticket.',
      arrowPosition: 'top',
    },
    11: {
      stepNum: 11,
      img: '/assets/img/redsox/tutorial/mobile/11.jpg',
      language:
        'Review the details of your group ticket pricing. Click “Proceed to Payment” to complete your purchase.',
      arrowPosition: 'top',
    },
  },
};

export const BostonRedSoxConfig: Configuration = {
  id: 'redsox',
  clientName: 'Boston Red Sox',
  appName: 'Redsox',
  favicon: '/assets/img/redsox/favicon.png',
  logo: '/assets/img/redsox/logo.png',
  phone: '617-226-6835',
  email: 'groupsales@redsox.com',
  paymentMethod: 'braintree',
  rainout: false,
  clientWebUrl: 'https://www.redsox.com/',
  clientWebText: 'www.redsox.com.',
  clientBallParkUrl:
    'https://www.mlb.com/redsox/apps/ballpark?partnerId=redirect-chc-ballparkapp',
  clientBallParkText: 'redsox.com/BallparkApp',
  mlbShopUrl:
    'https://www.mlbshopeurope.com/en/boston-red-sox/t-25556353+z-9324832-1137929273',
  merchandiseWebUrl: '',
  rainoutInsuranceUrl: '',
  termsAndConditionsUrl:
    'https://www.mlb.com/redsox/tickets/terms-and-conditions',
  analytics: '',
  adas: true,
  theme,
  dvmData,
  dvmStyles,
  tutorial,
  custom,
  extraCheckbox: {
    active: true,
    required: true,
    text:
      '<p>Broker Policy</p>' +
      "<p>Red Sox tickets are revocable licenses and the Red Sox may revoke tickets for any reason, at management's discretion. These ticket licenses do not confer any property right to the ticket holder</p>" +
      '<p>Ticket holders are advised that the Red Sox may cancel or revoke tickets for certain conduct including, but not limited to, the following:</p>' +
      '<p>Any form of fraudulent activity;</p>' +
      '<p>The purchase of tickets for the purpose and intent of reselling the tickets on the secondary market;</p>' +
      '<p>Use of tickets for sweepstakes, contests, and/or promotions without the prior written consent from the Red Sox.</p>' +
      '<p>In the event that ticket holders are unable to attend games and need to sell their tickets, the Club recommends that the ticket holders go through approved entities for this purpose. Ticket holders should comply with applicable Massachusetts law in connection with any such resale.</p>',
  },
  summaryPage: {
    image1: '/assets/img/redsox/logo.png',
    image2: 'url(/assets/img/redsox/red-sox-suites.jpeg)',
  },
};
