<div class="pb-11">
  <div id="filters" class="flex items-center">
    <div
      class="flex flex-col w-full max-w-screen-xl m-auto px-4 xs:px-10 sm:px-14 md:px-16">
      <div id="form">
        <div class="flex justify-between gap-x-4 flex-col gap-y-2 lg:flex-row">
          <div class="w-full lg:w-1/2 flex gap-x-4">
            <!-- select month -->
            <select
              data-cy="month-filter"
              class="bg-white rounded-md pl-4 sm:pl-6 py-3 border-2 border-gray-200 flex-auto w-1/2"
              [(ngModel)]="formSelects.month"
              (change)="calculateDayPerMonth($event)"
              ngDefaultControl>
              <option value="none">Month</option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
            <!-- select day -->
            <select
              data-cy="day-filter"
              class="bg-white rounded-md pl-4 sm:pl-6 py-3 border-2 border-gray-200 flex-auto w-1/2"
              [(ngModel)]="formSelects.day"
              ngDefaultControl>
              <option value="none">Day</option>
              <option *ngFor="let day of dayPerMonth" value="{{ day + 1 }}">
                {{ day + 1 }}
              </option>
            </select>
          </div>
          <!-- select opponent -->
          <select
            data-cy="opponent-filter"
            class="bg-white rounded-md pl-4 sm:pl-6 py-3 border-2 border-gray-200 w-full flex-1 lg:w-2/4 lg:flex-auto"
            [(ngModel)]="formSelects.opponent"
            ngDefaultControl>
            <option value="none">Opponent</option>
            <option
              *ngFor="let rival of opponentList"
              value="{{ rival.description }}">
              {{ rival.description }}
            </option>
          </select>
        </div>
        <div class="mt-3 flex justify-end">
          <!-- select buttons -->
          <button
            data-cy="clear-filters"
            type="button"
            class="button-filter reset"
            (click)="resetForm()">
            Clear All
          </button>
          <button
            data-cy="search-filters"
            type="button"
            class="button-filter submit"
            (click)="onSubmitFilters()">
            Search
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- FILTERS MOBILE END-->
  <div
    class="container max-w-screen-xl flex flex-col mx-auto px-2 xs:px-6 sm:px-8 md:px-16">
    <div id="events">
      <div class="text-center mt-5" style="color: #173482">
        <span>All Times CT. Subject to change.</span>
      </div>
      <div class="mt-5" *ngFor="let item of events.data">
        <app-sale-event
          (notifySelected)="getSelection($event)"
          [event]="item"></app-sale-event>
      </div>
    </div>
    <app-pagination
      [total_items_in_db]="totalItemsInDb"
      [items_per_page]="itemsPerPage"
      [currentPage]="currentPage"></app-pagination>
  </div>
</div>
