<div class="flex">
  <div class="flex-initial">
    <button
      (click)="isView3d() ? close3dView() : backToTopView()"
      [ngClass]="{
        'opacity-80': isView3d(),
        'md:flex': !isTopView(),
      }"
      class="btn-white hidden justify-center items-center pointer-events-auto hover:scale-95 hover:opacity-90 transition-all">
      <span class="mr-4">
        <img src="/assets/img/arrow-left-black-icon.svg" alt="" />
      </span>
      <span data-cy="back-top-view-button">
        {{ isView3d() ? 'Close Viewer' : 'Back' }}
      </span>
    </button>
  </div>

  <div
    [ngClass]="!isTopView() || isView3d() ? 'opacity-100' : 'opacity-0'"
    class="w-full flex justify-center flex-1 transition-opacity ease-in-out duration-300">
    <button
      [ngClass]="
        previousAvailableSectionId
          ? 'btn-white flex hover:scale-95 hover:opacity-90'
          : 'hidden'
      "
      (click)="changeSection('prev')"
      class="w-auto lg:w-28 justify-center pointer-events-auto transition-all">
      <span class="m-0 lg:mr-4">
        <img src="/assets/img/arrow-left-black-icon.svg" alt="" />
      </span>
      <span class="hidden lg:inline"> Previous section </span>
    </button>

    <button
      class="btn-white flex justify-center mx-2 cursor-default"
      [ngClass]="{ invisible: isView3d() }">
      <span>
        {{ sectionLabel }}
      </span>
    </button>

    <button
      [ngClass]="
        nextAvailableSectionId
          ? 'btn-white flex hover:scale-95 hover:opacity-90'
          : 'hidden'
      "
      (click)="changeSection('next')"
      class="w-auto lg:w-28 justify-center pointer-events-auto transition-all">
      <span class="hidden lg:inline"> Next section </span>
      <span class="m-0 lg:ml-4">
        <img src="/assets/img/arrow-right-black-icon.svg" alt="" />
      </span>
    </button>
  </div>
</div>
