<section class="app-card bg-main-accent">
  <!-- HEADER -->
  <div
    class="app-card-header sm:block"
    [ngClass]="hasItems ? 'block' : 'hidden'">
    <app-cart-header
      [hasItems]="hasItems"
      [debugMode]="debugMode"
      (removeAll)="removeAllSeats()" />
  </div>

  <!-- CONTENT -->
  <!-- Ver magic-scroll en cart-component.scss -->
  <div
    class="app-card-body md:h-36 w-full overflow-y-auto no-scrollbar magic-scroll">
    <ng-container *ngIf="seatsBySection.length; else noSeats">
      <app-cart-accordion
        *ngFor="let section of seatsBySection; trackBy: trackBySection"
        [seatsBySection]="section" />
    </ng-container>

    <ng-template #noSeats>
      <div class="h-full flex items-center justify-center px-6 py-2">
        <p class="text-center text-gray-500 text-sm my-4 md:my-0">
          Please select number of seats and price above first.
        </p>
      </div>
    </ng-template>
  </div>
</section>
