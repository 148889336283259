import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  WritableSignal,
} from '@angular/core';
import { ChangeContext, Options } from 'ngx-slider-v2';
import { PriceRange } from 'src/app/shared/models/seat-availability.model';

@Component({
  selector: 'app-price-range',
  templateUrl: './price-range.component.html',
  styleUrl: './price-range.component.scss',
})
export class PriceRangeComponent implements OnInit, OnChanges {
  // DATA INPUTS
  @Input()
  public initialMinValue!: number; // Valor mínimo inicial

  @Input()
  public initialMaxValue!: number; // Valor máximo inicial

  @Input()
  public debugMode: boolean = false; // Activar modo de depuración

  // EVENT EMITTER
  @Output()
  public valueChange: EventEmitter<PriceRange> = new EventEmitter<PriceRange>(); // Emite el valor del slider

  // STATE
  protected minValue: WritableSignal<number | null> = signal(null); // Valor mínimo
  protected maxValue: WritableSignal<number | null> = signal(null); // Valor máximo

  protected sliderOptions!: Options; // Configuración del slider

  // LIFE CYCLE
  ngOnInit(): void {
    this.initComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.initComponent();
    }
  }

  // METHODS

  /**
   * Validador de Inputs
   * Valida los inputs del componente, las validaciones que realiza son:
   *
   * 1. Verifica que los valores iniciales sean números
   * 2. Verifica que el valor mínimo inicial sea menor al valor máximo inicial
   */
  private validateInputs(): void | Error {
    if (isNaN(this.initialMinValue) || isNaN(this.initialMaxValue)) {
      throw new Error('Initial values must be numbers');
    }

    if (this.initialMinValue >= this.initialMaxValue) {
      throw new Error('Initial min value must be less than initial max value');
    }
  }

  /**
   * Inicializador del componente
   * Comprueba que los inputs sean válidos y asigna los valores iniciales a los valores mínimos y máximos.
   */
  private initComponent(): void | Error {
    //Validamos inputs
    this.validateInputs();

    // Asignamos valor a las señales
    this.minValue.set(this.initialMinValue);
    this.maxValue.set(this.initialMaxValue);

    // Asignamos configuración al slider
    this.sliderOptions = {
      floor: this.minValue(),
      ceil: this.maxValue(),
    };

    // Si el modo debug está activado, mostramos los valores por consola si no son los valores por defecto
    if (this.debugMode && this.minValue() !== 0) {
      console.log(
        'Price Range Component:',
        'Min: ' + this.minValue(),
        'Max: ' + this.maxValue()
      );
    }
  }

  /**
   * Método que se ejecuta al cambiar el valor del slider
   * @param event
   */
  public updateSliderValues(event: ChangeContext): void {
    // Emitimos valores
    this.valueChange.emit({ min: this.minValue(), max: this.maxValue() });

    // Si el modo debug está activado, mostramos los valores por consola
    if (this.debugMode) {
      console.log('Slider values:', event);
    }
  }
}
