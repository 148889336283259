<section class="app-card bg-main">
  <!-- HEADER -->
  <div class="app-card-header">
    <!-- DEFAULT -->
    <ng-container *ngIf="mode === 'default'; else vipHeader">
      <app-select-tickets
        [maxSeats]="maxTickets"
        [minSeats]="minTickets"
        [debugMode]="debugMode"
        (updateSelectedSeats)="updateTicketsSelected($event)" />
    </ng-container>

    <!-- VIP -->
    <ng-template #vipHeader> To Do </ng-template>
  </div>

  <!-- CONTENT -->
  <div class="app-card-body">
    <!-- DEFAULT -->
    <ng-container *ngIf="mode === 'default'; else vipContent">
      <!-- PRICE RANGE -->
      <app-price-range
        [initialMinValue]="priceRange.min"
        [initialMaxValue]="priceRange.max"
        [debugMode]="debugMode"
        (valueChange)="updatePriceRange($event)" />

      <hr />

      <!-- ACCESIBLE SEATING -->
      <app-accesible-seating
        [ticketCounter]="tickets"
        [debugMode]="debugMode" />
      <hr />

      <!-- BEST SEATS -->
      <app-best-seats
        [debugMode]="debugMode"
        [enableButton]="dvmService.isTopView()"
        (addBestSeats)="getBestSeats()" />
    </ng-container>

    <!-- VIP -->
    <ng-template #vipContent> To Do </ng-template>
  </div>
</section>
