import { Injectable } from '@angular/core';
import { DvmService } from '../services/dvm.service';
import { Popover3dviewService } from '../services/popover-3dview.service';

@Injectable()
export class LeaveHandler {
  constructor(
    private dvmService: DvmService,
    private popover3dview: Popover3dviewService
  ) {
    // CLICK subscribe
    const viewerSubscription = this.dvmService.viewerSubject.subscribe({
      next: viewer => {
        const subscription = viewer.subscribe('leave', this.handle.bind(this));
        this.dvmService.viewerSubscriptions.push(subscription);
      },
    });
    this.dvmService.viewerSubscriptions.push(viewerSubscription);
  }

  private handle(obj) {
    if (
      obj.nodes.length &&
      (obj.nodes[0].state === 'available' || obj.nodes[0].state === 'selected')
    ) {
      this.dvmService.viewer.hover(null);
      // const tooltip: HTMLElement = document.querySelector('#tooltip');
      // tooltip.removeAttribute('data-show');
      this.popover3dview.setTimer();
    }
  }
}
