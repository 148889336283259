<section
  class="w-full h-full relative md:before:content-normal md:before:block md:before:absolute md:before:w-32 md:before:h-full md:before:bg-gradient-to-r md:before:from-gray-900 md:before:to-transparent md:before:z-10"
  #mapContainer>
  <!-- LOADER -->
  <div data-cy="map-loader" class="absolute top-0 left-0 w-full h-full">
    <app-map-loader />
  </div>

  <!-- TOPBAR -->
  <div class="absolute z-50 top-0 left-0 w-full h-min mt-4 px-7">
    <app-topbar />
  </div>

  <!-- VIEWER -->
  <dvm-map-viewer
    #viewer
    [service]="dvmService.viewerService"
    data-cy="map-viewer" />

  <div
    class="w-24 sm:w-40 md:w-56 absolute bottom-5 right-5"
    [ngClass]="{ 'translate-y-[200%]': !dvmService.showMinimap() }">
    <!-- MULTISELECTION TOAST -->
    <div class="relative hidden md:block">
      <app-multiselection-toast
        [ngClass]="
          dvmService.isMultiSelectTooltipVisible
            ? 'block opacity-100'
            : 'hidden opacity-0 '
        "
        class="allow-discrete transition-all duration-300"
        (hideToast)="hideMultiSelectionToast()" />

      <div
        (click)="showMultiSelectionToast()"
        [ngClass]="
          dvmService.isMultiSelectTooltipVisible ? 'opacity-0 ' : 'opacity-100'
        "
        class="absolute bottom-0 right-0 z-20 min-w-fit max-w-min p-2 bg-black bg-opacity-90 hover:bg-opacity-70 text-white rounded-full pointer-events-auto cursor-pointer">
        <img class="h-4 w-4" src="/assets/img/question-mark.svg" alt="info" />
      </div>
    </div>

    <!-- MINIMAP -->
    <div
      (click)="backToTopView()"
      id="minimap"
      class="bg-[rgba(1,1,1,0.6)] aspect-video mt-3 w-full h-16 sm:h-28 md:h-36 transition-all origin-center opacity-80 hover:opacity-100 hover:shadow-md duration-300 rounded-xl cursor-pointer">
      <dvm-map-viewer
        data-cy="minimap-viewer"
        #minimap
        [service]="dvmService.minimapService" />
    </div>
  </div>

  <!-- 3D VIEWER -->
  <div
    class="absolute top-0 left-0 z-[5] w-full h-full transition-opacity"
    [ngClass]="dvmService.is3dView() ? 'opacity-100' : 'opacity-0 invisible'">
    <dvm-viewer3d
      data-cy="3d-viewer"
      #viewer3d
      [service]="dvmService.viewer3dService" />
  </div>

  <!-- POPOVERS -->
  <div #tooltip class="dvmPopover p-2 z-10" role="tooltip">
    <div id="arrow" data-popper-arrow></div>
    <div #dynamicPopover></div>
  </div>
</section>
