<div
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()"
  class="w-80 h-auto text-white bg-black rounded-xl overflow-hidden">
  <div class="w-full h-48">
    <div class='tooltip-img' [style.background-image]="'url(' + tooltipData.img + ')'"></div>
    <div
      *ngIf="tooltipData.img"
      class="h-full bg-center bg-cover bg-no-repeat"
      [style.background-image]="'url(' + tooltipData.img + ')'"></div>
    <div
      *ngIf="!tooltipData.img"
      class="w-full h-full flex justify-center items-center">
      <div class="lds-dual-ring"></div>
    </div>
  </div>

  <div class="px-3 py-2">
    <div class="flex">
      <div class="w-1/2 mr-5">
        <h5>
          <span class="font-normal">Section:</span>
          {{
            tooltipData.seat.includes('_')
              ? tooltipData.seat.split('_')[1].split('-')[0]
              : tooltipData.seat
          }}
        </h5>
        <h5><span class="font-normal">Row:</span> {{ tooltipData.row }}</h5>
      </div>
      <div class="w-1/2 min-w-max text-right">
        <!--The first one is the default one-->
        <h5>
          <span class="font-normal">Seat:</span>
          {{ tooltipData.seat.split('-')[2] }}
        </h5>
        <h5>
          <span class="font-normal">Price:</span> $
          {{ seatData.price[0].value }}
        </h5>
      </div>
    </div>
  </div>
</div>

<!-- <div class="seat-popover-container">
  <div class="id">
    seat: {{seatData.mmcID}}
  </div>
  <div class="price"> -->
<!--The first one is the default one-->
<!-- seat: {{seatData.price[0].value}}
  </div>
</div> -->
