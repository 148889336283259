import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { AvailabilityService, CartService } from 'src/app/shared/services';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { DvmService } from '../services/dvm.service';
import { IsolatedSeatsService } from '../services/isolated-seats.service';
import { SeatManagementService } from '../services/seat-management.service';

@Injectable()
export class LoadHandler {
  constructor(
    private dvmService: DvmService,
    private availabilityService: AvailabilityService,
    private seatManagementService: SeatManagementService,
    private cartService: CartService,
    private isolatedSeatsService: IsolatedSeatsService,
    private configurationService: ConfigurationService,
    private modalsService: ModalsService
  ) {
    // ENDLOAD subscribe
    const viewerSubscription = this.dvmService.viewerSubject.subscribe({
      next: viewer => {
        const subscription = viewer.subscribe(
          'end_load',
          this.handle.bind(this)
        );

        this.dvmService.viewerSubscriptions.push(subscription);
      },
      error: error => {
        console.error(error);
        const modalData = {
          title: 'ERROR',
          content: 'An Error occurred while trying to get the Viewer.',
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      },
    });
    this.dvmService.viewerSubscriptions.push(viewerSubscription);
  }

  private handle() {
    const mapId = this.dvmService.viewer.getMapId();

    //* TOP VIEW
    if (mapId === this.dvmService.loadOptions.map_id) {
      // Selection Area Styles
      this.dvmService.viewer.selection_area.style_options.border_width2 = 2;

      // Availability of topview/sections map
      if (Object.keys(this.availabilityService.sectionsAvailability).length) {
        // map mmc ga separated sectors to bleacher
        let sectionsAvailable = Object.keys(
          this.availabilityService.sectionsAvailability
        );

        if (sectionsAvailable.includes(this.dvmService.bleacherSection)) {
          if (
            this.availabilityService.sectionsAvailability[
              this.dvmService.bleacherSection
            ].availableQuantity > 0
          ) {
            sectionsAvailable = sectionsAvailable.concat(
              this.configurationService.configuration.dvmData.gaSectorsIdArray
            );
          }
        }

        if (sectionsAvailable.includes(this.dvmService.bleacherAdaSection)) {
          if (
            this.availabilityService.sectionsAvailability[
              this.dvmService.bleacherAdaSection
            ].availableQuantityAda > 0
          ) {
            sectionsAvailable = sectionsAvailable.concat(
              this.configurationService.configuration.dvmData.gaAdaIdArray
            );
          }
        }

        this.dvmService.viewer.setAvailability('section', sectionsAvailable);
        this.dvmService.viewer.setAvailability(
          'general_admission',
          sectionsAvailable
        );

        if (this.seatManagementService.needADA) {
          this.availabilityService.activeAdaSections();
        }
      } else {
        this.availabilityService
          .getSectionAvailability()
          .pipe(take(1))
          .subscribe({
            next: (sectionsAvailable: string[]) => {
              // map mmc ga separated sectors to bleacher

              if (sectionsAvailable.includes(this.dvmService.bleacherSection)) {
                if (
                  this.availabilityService.sectionsAvailability[
                    this.dvmService.bleacherSection
                  ].availableQuantity > 0
                ) {
                  sectionsAvailable = sectionsAvailable.concat(
                    this.configurationService.configuration.dvmData
                      .gaSectorsIdArray
                  );
                }
              }

              if (
                sectionsAvailable.includes(this.dvmService.bleacherAdaSection)
              ) {
                if (
                  this.availabilityService.sectionsAvailability[
                    this.dvmService.bleacherAdaSection
                  ].availableQuantityAda > 0
                ) {
                  sectionsAvailable = sectionsAvailable.concat(
                    this.configurationService.configuration.dvmData.gaAdaIdArray
                  );
                }
              }

              this.dvmService.viewer.setAvailability(
                'section',
                sectionsAvailable
              );
              this.dvmService.viewer.setAvailability(
                'general_admission',
                sectionsAvailable
              );
            },
            error: error => {
              console.error(error);
              const modalData = {
                title: 'ERROR',
                content:
                  'An Error occurred while trying to get Section Availability.',
                // closeBtnName: 'CLOSE',
                acceptBtnName: 'CLOSE',
                // acceptFunction: () => {this.goTo('checkout')}
              };
              // If there's a custom api error.
              if (error.error.hasOwnProperty('code')) {
                modalData.content = error.error.message;
                if (error.error.code === 'E_EVENT_CANNOT_BE_SOLD') {
                  modalData.title = 'WE’RE SORRY';
                  modalData.content = `Your request cannot be processed online. Please call ${this.configurationService.configuration.phone} or email ${this.configurationService.configuration.email} to purchase tickets for this game.`;
                }
              }

              this.modalsService.openModal(modalData);
            },
          });
      }

      if (Object.keys(this.availabilityService.seatsAvailability).length) {
        let seatAvailability = [];
        Object.entries(this.availabilityService.seatsAvailability).forEach(
          ([sectionId, availability]) => {
            seatAvailability = seatAvailability.concat(
              Object.keys(availability)
            );
          }
        );
        this.dvmService.viewer.setAvailability('seat', seatAvailability);
      }
      // Tag creation
      let selectedSections = Object.keys(
        this.cartService.selectedSeatsBySection
      );
      if (selectedSections.length) {
        // this.dvmService.viewer.addNodesToGroup(selectedSections, 'group1');
        // Update selected sections on Topview and MiniMap
        this.seatManagementService.updateSelectedSectionsOnTopview();
        // Set section styles
        this.dvmService.changeSectionStyles('withTags');
      } else {
        // Set section styles
        this.dvmService.changeSectionStyles();
      }
      //* SEAT MAP
    } else {
      // Set seat styles
      this.dvmService.changeSeatStyles();

      // console.log('Seat Map', this.availabilityService.seatsAvailability, this.availabilityService.seatsAvailability[mapId]);
      // Take available seat ids to set availability on the viewer
      if (
        this.availabilityService.seatsAvailability &&
        this.availabilityService.seatsAvailability[mapId]
      ) {
        const adaSeats = Object.values(
          this.availabilityService.seatsAvailability[mapId]
        )
          .filter(a => a.isAda)
          .map(a => a.id);
        const availableSeats = Object.keys(
          this.availabilityService.seatsAvailability[mapId]
        );

        // Apply seats availability
        this.dvmService.viewer.setAvailability('seat', availableSeats);
        this.dvmService.viewer.addNodesToGroup(adaSeats, 'disabled');
      } else {
        this.availabilityService
          .getSeatAvailability([mapId])
          .pipe(take(1))
          .subscribe({
            next: availability => {
              this.dvmService.viewer.setAvailability('seat', availability);
            },
            error: error => {
              console.error('error is:::', error);
              const modalData = {
                title: 'ERROR',
                content:
                  'An Error occurred while trying to get Seat Availability.',
                acceptBtnName: 'CLOSE',
              };
              // If there's a custom api error.
              if (error?.error?.hasOwnProperty('code')) {
                modalData.content = error.error.message;
              }
              this.modalsService.openModal(modalData);
            },
          });
      }

      const dvmData = this.configurationService.configuration.dvmData;
      //* MINIMAP
      // Update selected sections on MiniMap
      if (dvmData.miniMapId && dvmData.miniMapEnabled) {
        this.dvmService.viewerMini.unselectAll();
        this.dvmService.viewerMini.setAvailable(
          'section',
          this.dvmService.seatmapId
        );
        this.dvmService.viewerMini.select(this.dvmService.seatmapId);
      }

      if (
        this.isolatedSeatsService.lastIsolatedSeats &&
        this.isolatedSeatsService.lastIsolatedSeats.length
      ) {
        this.dvmService.viewer.addNodesToGroup(
          this.isolatedSeatsService.lastIsolatedSeats,
          'pending'
        );
      }
    }

    // Selecting best seats
    if (Object.keys(this.cartService.selectedSeats).length) {
      this.dvmService.viewer.select(
        Object.keys(this.cartService.selectedSeats)
      );
    }
  }
}
