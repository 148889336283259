import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-multiselection-toast',
  templateUrl: './multiselection-toast.component.html',
  styleUrl: './multiselection-toast.component.scss',
})
export class MultiselectionToastComponent {
  @Output() hideToast: EventEmitter<boolean> = new EventEmitter();

  protected hide() {
    this.hideToast.emit(true);
  }
}
