<div *ngIf="validTransaction" class="h-full overflow-y-auto">
  <div class="w-full bg-gray-100 border-b border-gray-300">
    <div
      class="w-full px-5 py-7 md:max-w-max md:px-20 md:py-14 md:pb-7 3xl:pb-14 md:mx-auto md:flex">
      <div class="text-gray-700 text-center md:w-352 md:mr-10">
        <h2 class="text-base md:text-3xl">Summary</h2>
        <h1 class="lg:text-4xl 3xl:text-5xl">Thanks for your purchase</h1>
      </div>
    </div>
  </div>

  <div
    class="w-full px-5 py-7 lg:max-w-max md:px-20 lg:py-14 lg:pt-7 3xl:pt-14 lg:mx-auto lg:flex">
    <div class="lg:w-1/2 2xl:w-176">
      <h3 class="mb-4 font-bold text-main-dark text-center md:text-left">
        Your Order Details
      </h3>

      <!-- DATA BOX -->
      <div class="box-white shadow-lg px-4 py-2 mb-10 md:px-6 md:py-4 md:mb-0">
        <!-- Seats recap -->
        <h4
          *ngFor="let seat of seatsByPrice"
          class="font-bold text-main-dark text-center md:text-left">
          <span class="underline">{{ seat.quantity }} Seats</span> x
          <span class="underline">{{
            transaction.transaction.buyerTypeName
          }}</span>
          at
          <span class="text-red">${{ seat.price | number: '1.2-2' }}</span>
        </h4>

        <!-- Seats table -->
        <div class="w-full mt-2 mb-7 lg:mt-4 lg:mb-10">
          <table class="w-full table-fixed border-separate">
            <tr
              *ngFor="let section of seatsForTable"
              class="mb-3 flex flex-wrap sm:flex-nowrap">
              <td class="td w-full mb-1 sm:mb-0 md:w-16">
                <div class="flex justify-center items-center">
                  <img
                    src="/assets/img/chair-blue-icon.svg"
                    alt="main chair icon"
                    class="mr-1" />
                  <p class="font-bold text-center text-main">
                    {{ section.totalSectionSeats }}
                  </p>
                </div>
              </td>
              <td
                class="td w-full md:w-auto mb-1 flex justify-start md:justify-center sm:mb-0 sm:mx-2 md:items-center">
                <p class="flex items-center">
                  Section
                  <span class="ml-2 font-bold text-main md:text-center">{{
                    section.id
                  }}</span>
                </p>
              </td>
              <td class="p-0 w-full md:w-auto">
                <div *ngFor="let row of section.rows" class="td-lines flex">
                  <p class="mr-10">
                    Row<span class="ml-2 font-bold text-main">{{
                      row.id
                    }}</span>
                  </p>
                  <p>
                    Seats<span class="ml-2 font-bold text-main">{{
                      row.seatsRanges.join(', ')
                    }}</span>
                  </p>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <!-- Cart summary -->
        <div class="w-full mb-7 lg:flex md:mb-10">
          <div
            class="m-auto border-gray-200 pb-3 border-b lg:w-2/3 lg:pr-3 lg:border-r">
            <div class="m-auto">
              <h5 class="mb-2 font-bold text-center md:text-left">
                Your adjusted seat amount is listed below:
              </h5>
              <div
                class="text-gray-500 px-0 xs:px-20 sm:px-32 md:px-28 lg:px-0">
                <div
                  class="text-center md:text-left mb-1 flex justify-between md:px-10 items-center">
                  <h5>Seats:</h5>
                  <h5
                    class="w-24 px-2 py-1 bg-main-off-white rounded-lg mt-1 md:ml-4 md:text-right">
                    ${{
                      transaction?.transaction.tdc_transaction.totalPriceSeats
                        | number: '1.2-2'
                    }}
                  </h5>
                </div>
                <div
                  class="text-center md:text-left mb-1 flex justify-between md:px-10 items-center">
                  <h5 class="font-normal">Per ticket fees:</h5>
                  <h5
                    class="w-24 px-2 py-1 bg-main-off-white rounded-lg mt-1 md:mt-0 md:ml-4 md:text-right">
                    ${{
                      transaction?.transaction.tdc_transaction.serviceCharges
                        | number: '1.2-2'
                    }}
                  </h5>
                </div>
                <div
                  *ngIf="
                    transaction?.transaction.tdc_transaction.amusementTax > 0
                  "
                  class="text-center md:mb-0 md:text-left mb-1 flex justify-end items-center">
                  <h5 class="font-normal">Amusement tax (12% of seat cost):</h5>
                  <h5
                    class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:mt-0 md:ml-4 md:text-right">
                    ${{
                      transaction?.transaction.tdc_transaction.amusementTax
                        | number: '1.2-2'
                    }}
                  </h5>
                </div>
                <div
                  *ngIf="
                    transaction?.transaction.tdc_transaction.hasInsuranceRainOut
                  "
                  class="text-center md:mb-0 md:text-left mb-1 flex justify-end items-center">
                  <h5>
                    <span class="mr-1 font-normal">Insurance:</span>Rainout
                    Insurance:
                  </h5>
                  <h5
                    class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:mt-0 md:ml-4 md:text-right">
                    ${{
                      transaction?.transaction.tdc_transaction
                        .insueranceRainOutPrice | number: '1.2-2'
                    }}
                  </h5>
                </div>
                <div *ngIf="acquiredUpsells">
                  <div
                    *ngFor="let upsell of acquiredUpsells"
                    class="text-center md:mb-0 md:text-left mb-1 flex justify-end items-center">
                    <h5 class="text-right">
                      <span class="mr-1 font-normal">By purchasing</span
                      >{{ upsell.goal }} tickets
                      <span class="mx-1 font-normal inline-block"
                        >you receive:</span
                      >{{ upsell.name }}.
                    </h5>
                    <div class="min-w-max">
                      <h5
                        class="w-24 px-2 py-1 bg-main-off-white rounded-lg mx-auto mt-1 md:mt-0 md:ml-4 md:text-right">
                        BENEFIT
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-3 lg:w-1/3 lg:pl-3 lg:m-auto">
            <div class="text-center">
              <h4 class="font-bold">Ticket Total:</h4>
              <h4 class="font-bold">
                ${{
                  transaction?.transaction.tdc_transaction.balance
                    | number: '1.2-2'
                }}
              </h4>
            </div>
          </div>
        </div>

        <!-- Tickets email -->
        <div class="m-2 md:mt-0 md:mr-0 md:ml-0 mb-2">
          <h4 class="mb-2 font-bold text-main-dark">MLB Ballpark App</h4>
          <h5 class="font-normal">
            {{ configurationService.configuration.clientName }} tickets are
            available exclusively as mobile tickets via the free MLB Ballpark
            app. To view and use tickets, log in to your existing MLB.com
            account or create an account in the MLB Ballpark app with the email
            used to purchase your ticket(s). Detailed instructions on using the
            MLB Ballpark app, including email verification, viewing tickets, and
            forwarding tickets, are available at
            <a
              [href]="configurationService.configuration.clientBallParkUrl"
              target="_blank"
              >{{ configurationService.configuration.clientBallParkText }}</a
            >.
          </h5>
        </div>
      </div>
    </div>

    <div class="w-full md:ml-8 lg:w-1/2 2xl:ml-10">
      <h3
        class="mb-4 font-bold text-red text-base text-center md:text-lg md:text-left">
        You might also be interested in:
      </h3>

      <div
        class="text-center text-white flex flex-wrap justify-center -mx-4 xl:h-[90%]">
        <!-- ADS -->
        <span
          class="aspect-[9/12] w-60 lg:w-1/2 2xl:w-1/3 p-4"
          *ngIf="summaryLink">
          <a
            [href]="summaryLink?.link"
            target="_blank"
            class="h-full w-full box-white p-3 text-white bg-main-dark flex flex-col justify-between items-center shadow-lg mb-5 lg:mb-0 2xl:p-5">
            <div>
              <img
                *ngIf="!noImage"
                [src]="summaryLink?.url_image"
                class="max-h-32 m-auto mb-2"
                alt="{{ summaryLink?.title }}" />
              <div class="m-auto">
                <p class="max-h-14 mb-2 font-normal overflow-y-auto">
                  {{ summaryLink?.title }}
                </p>
                <!-- <h5 class="font-normal">{{ team.city }}</h5> -->
                <!-- <h1 class="font-normal">{{ team.name }}</h1> -->
              </div>
            </div>
            <div class="mb-3 flex flex-col items-center">
              <hr class="w-24 mb-4 border-white opacity-20" />
              <h4 class="font-bold">{{ summaryLink?.name }}</h4>
              <!-- <h4 class="font-bold">Other games against the same opponent</h4> -->
            </div>
          </a>
        </span>
        <span class="aspect-[9/12] w-60 lg:w-1/2 2xl:w-1/3 p-4">
          <a
            [href]="configurationService.configuration.mlbShopUrl"
            target="_blank"
            class="h-full w-full box-white p-3 text-main-dark flex flex-col justify-between items-center shadow-lg mb-5 lg:mb-0 2xl:p-5">
            <div class="m-auto">
              <img
                src="{{
                  configurationService.configuration.summaryPage.image1
                }}"
                alt="mechandise t-shirts image" />
            </div>
            <div class="mb-3 flex flex-col items-center">
              <hr class="w-24 mb-4" />
              <h4 class="font-bold">
                {{ configurationService.configuration.clientName }} Merchandise
              </h4>
            </div>
          </a>
        </span>
        <span class="aspect-[9/12] w-60 lg:w-1/2 2xl:w-1/3 p-4">
          <a
            [href]="configurationService.configuration.merchandiseWebUrl"
            target="_blank"
            class="h-full w-full box-white p-3 text-main-dark text-white bg-cover flex flex-col justify-end items-center shadow-lg mb-5 lg:mb-0 2xl:p-5"
            [ngStyle]="{
              'background-image':
                configurationService.configuration.summaryPage.image2,
            }"
            style="background-position: -150px; background-repeat: no-repeat">
            <div class="mb-4 flex flex-col items-center">
              <hr class="w-24 mb-4 border-white opacity-20" />
              <h4 class="font-bold text-white">
                {{ configurationService.configuration.clientName }}
              </h4>
              <h4 class="font-bold text-white">Suites</h4>
            </div>
          </a>
        </span>
      </div>
    </div>
  </div>
</div>
