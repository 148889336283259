<div
  [style]="ripple"
  class="relative overflow-visible w-full h-full"
  #container>
  <!-- Container -->
  <div
    class="absolute z-20 w-full h-full flex items-center justify-center bg-gray-900/50"
    #pillContainer>
    <!-- PILL -->
    <div
      class="bg-gray-900/90 p-5 w-52 h-52 3xl:w-64 3xl:h-64 rounded-full flex items-center justify-center ripple-animation"
      #pill>
      <!-- LOGO -->
      <img
        [src]="logo"
        alt="Club logo loader"
        class="h-32 3xl:h-44 animate-pulse delay-1000" />
    </div>
  </div>
</div>
