import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/shared/services';
import { SeatAvailability } from '../../../shared/models';
import { Popover3dviewService } from '../../services/popover-3dview.service';
import { DvmService } from 'src/app/shared/services/dvm.service';

@Component({
  selector: 'app-seat-popover',
  templateUrl: './seat-popover.component.html',
  styleUrls: ['./seat-popover.component.scss'],
})
export class SeatPopoverComponent implements OnInit {
  seatData: SeatAvailability;
  tooltipData: { section: string; row: string; seat: string; img: string };

  constructor(
    private dvmService: DvmService,
    private utilitiesService: UtilitiesService,
    private popover3dview: Popover3dviewService
  ) {}

  ngOnInit(): void {
    const seatNode = this.dvmService.viewerService.getNodeById(this.seatData.id);
    const seatInfo = this.utilitiesService.splittedSeatInfoFromId(
      seatNode.original_id
    );
    
    this.tooltipData = {
      section: seatInfo.section,
      row: seatInfo.seat_row,
      seat: seatInfo.seat,
      img: '',
    };

    this.dvmService.loadThumbnail(this.seatData.id).subscribe((img) => {
      this.tooltipData.img = img.src;
    })

  }

  onEnter() {
    this.popover3dview.deleteTimer();
  }

  onLeave() {
    this.popover3dview.setTimer();
  }
}
