<div
  data-cy="tutorial-container"
  class="w-full h-full flex justify-center items-center bg-black bg-opacity-95 backdrop-blur-3xl pointer-events-none">
  <div
    id="tutorial-wrapper"
    class="w-full h-full flex justify-center items-center"
    [ngClass]="isMobile ? 'p-5' : 'px-10 py-5'">
    <div
      id="tutorial-modal"
      class="max-w-full max-h-full relative pointer-events-auto bg-white border-white rounded-2.5xl overflow-hidden"
      [ngClass]="
        isMobile ? 'aspect-ratio-mob border-4' : 'aspect-ratio-des border-8'
      ">
      <!-- IMAGE -->
      <img
        id="tutorial-img"
        [src]="actualStep.img"
        class="object-contain"
        alt="Tutorial step image" />

      <!-- TUTORIAL POPOVER -->
      <div
        id="tutorial-popover"
        class="m-5"
        [class]="'step-' + actualStep.stepNum">
        <!-- <div id="tutorial-popover" [class]="'step-'+actualStep.stepNum.toString()"> -->
        <!-- <div id="tutorial-popover" [ngClass]="{'step-1': actualStep.stepNum === 1}"> -->
        <div [id]="'arrow-' + actualStep.arrowPosition"></div>
        <div
          id="tutorial-popover-box"
          [ngClass]="isMobile ? 'w-64 max-w-full p-4' : 'w-72 p-5'">
          <h5 class="font-normal">
            {{ actualStep.stepNum }}. {{ actualStep.language }}
          </h5>
          <hr class="my-3 border-gray-800" />
          <div class="flex justify-between">
            <div class="flex">
              <button
                data-cy="tutorial-exit-button"
                (click)="exitTutorial()"
                class="btn-white-reverse w-auto h-9 text-xs mr-1">
                Exit
              </button>
              <button
                data-cy="tutorial-back-button"
                *ngIf="actualStep !== tutorialSteps[1]"
                (click)="stepBack()"
                class="btn-white-reverse w-auto h-9 text-xs">
                Back
              </button>
            </div>
            <button
              data-cy="tutorial-next-button"
              *ngIf="actualStep !== tutorialSteps[totalSteps]"
              (click)="stepNext()"
              class="btn-white w-auto h-9 ml-1 text-xs text-main-dark">
              Next
            </button>
            <button
              data-cy="tutorial-end-button"
              *ngIf="actualStep === tutorialSteps[totalSteps]"
              (click)="exitTutorial()"
              class="btn-white w-auto h-9 ml-1 text-xs text-main-dark">
              End Tutorial
            </button>
          </div>
        </div>
      </div>

      <!--* Tooltip for Popper Library, future use -->
      <!-- <div id="tooltip" class="tutorialPopover z-10" role="tooltip">
        <div id="arrow" data-popper-arrow></div>
        <div>
          <h4 class="">{{ actualStep.stepNum }}. {{ actualStep.language }}</h4>
        </div>
      </div> -->
    </div>
  </div>
</div>
