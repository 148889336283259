import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';
import { Seat, SeatsBySection } from 'src/app/shared/models';
import { SeatsByRow } from 'src/app/shared/models/section.model';

@Component({
  selector: 'app-cart-accordion',
  templateUrl: './cart-accordion.component.html',
  styleUrl: './cart-accordion.component.scss',
})
export class CartAccordionComponent {
  @ViewChild('seatsContainer')
  public seatsContainer: ElementRef;

  @Input()
  public seatsBySection: SeatsBySection;

  // STATE
  public showSeats: boolean = false;

  // UTILITIES
  public itemsHeigth: number = 52.5;
  public isAnimating: boolean = false;

  // METHODS
  public toggleSeats(): void {
    if (this.isAnimating) {
      return;
    }

    const totalHeight =
        this.itemsHeigth * Object.values(this.seatsBySection.seats).length, // Tamaño del div que contiene los asientos
      maxHeight = this.itemsHeigth * 3; // Tamaño máximo del carrito (157.5px) asi al cerrar queda más smooth

    this.isAnimating = true;
    this.showSeats = !this.showSeats;

    if (this.showSeats) {
      this.seatsContainer.nativeElement.style.overflow = null;
    } else {
      this.seatsContainer.nativeElement.style.height = `${maxHeight}px`;
      this.seatsContainer.nativeElement.style.overflow = 'hidden';
    }

    anime({
      targets: this.seatsContainer.nativeElement,
      height: this.showSeats ? [0, totalHeight] : [maxHeight, 0],
      duration: 350,
      easing: 'easeInOutQuad',
      complete: () => {
        this.isAnimating = false;
        if (this.showSeats) {
          this.seatsContainer.nativeElement.style.height = 'auto';
          this.seatsContainer.nativeElement.style.overflow = 'visible';
        } else {
          this.seatsContainer.nativeElement.style.height = null;
        }
      },
    });
  }

  protected trackBySeat(index: number, seat: Seat): string {
    return seat.id;
  }

  protected trackByRow(index: number, row: SeatsByRow): string {
    return row.id;
  }
}
