<div class="flex items-center h-full justify-between">
  <!-- TITLE -->
  <div class="hidden md:block">
    <h4 class="text-sm">How Many Tickets?</h4>

    <!-- Minimum Seats -->
    <ng-container *ngIf="minSeats; else minSeatLoader">
      <p>Minimum of {{ minSeats }}</p>
    </ng-container>

    <!-- Min Seats Loader -->
    <ng-template #minSeatLoader>
      <p class="text-xs animate-pulse">
        <span> Loading... </span>
      </p>
    </ng-template>
  </div>

  <!-- SELECTOR -->
  <div class="w-full md:w-min">
    <app-selector
      *ngIf="minSeats"
      [useCaseId]="'seat-selection'"
      [initialValue]="minSeats"
      [minimumValue]="minSeats"
      [maximumValue]="maxSeats"
      [debugMode]="debugMode"
      (valueChange)="updateTotalSeats($event)" />
  </div>
</div>
