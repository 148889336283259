<div class="w-full flex items-center flex-col">
  <h1>Login</h1>

  <button type="button" class="btn-main" (click)="showPatron()">
    Show patron
  </button>
  <p *ngIf="name$ | async as name">Hello {{ name }}</p>
  <h1 *ngIf="patron">
    {{ patron }}
  </h1>
  <div *ngIf="(isAuthenticated$ | async) === false">
    <button (click)="signOut()" class="btn-main">Sign out</button>
  </div>
  <marquee direction="left">Login page bb</marquee>
</div>
