import { MapViewerNode } from '@3ddv/dvm-internal';
import { Injectable } from '@angular/core';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { BestAvailableService } from '../services/best-available.service';
import { DvmService } from '../services/dvm.service';
import { SeatManagementService } from '../services/seat-management.service';

@Injectable()
export class SelectionAreaEndHandler {
  constructor(
    private dvmService: DvmService,
    private seatService: SeatManagementService,
    private bestAvailableService: BestAvailableService,
    private modalsService: ModalsService
  ) {
    // CLICK subscribe
    const viewerSubscription = this.dvmService.viewerSubject.subscribe({
      next: viewer => {
        const subscription = viewer.subscribe(
          'end_selection_area',
          this.handle.bind(this)
        );
        this.dvmService.viewerSubscriptions.push(subscription);
      },
      error: error => {
        console.error(error);
        const modalData = {
          title: 'ERROR',
          content: 'An Error occurred while trying to get the Viewer.',
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      },
    });
    this.dvmService.viewerSubscriptions.push(viewerSubscription);
  }

  /**
   * Debemos invertir la seleccion:
   * 'available' -> 'selected' -> añadimos al cart
   * 'selected' -> borramos del cart -> pasa a 'selected'
   * https://mmcbcn.atlassian.net/browse/IT-2929
   * @param obj
   */
  protected handle(obj) {
    let currentNodes: Array<MapViewerNode> = obj.selection_area.current;
    let selectedNodes = currentNodes
      .filter(item => item.state === 'selected' && item.type !== 'section')
      .map(item => {
        return { id: item.id, original_id: item.original_id };
      });
    let availableNodes = currentNodes
      .filter(item => item.state === 'available' && item.type !== 'section')
      .map(item => {
        return { id: item.id, original_id: item.original_id };
      });
    // deseleccionamos la selecciona actual
    this.seatService.unselectSelectedSeats(selectedNodes);
    obj.instance.unselect(selectedNodes);
    // añadimos los nuevos
    this.seatService.selectSeatsForCart(availableNodes);
    obj.instance.select(availableNodes);
  }
}
