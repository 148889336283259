<div
  class="h-1/3 flex items-center px-4 xs:px-12 sm:px-24 md:px-36 lg:px-4 py-2 sm:py-4 lg:py-2 justify-between text-main">
  <!-- TEXT -->
  <h5 class="font-normal text-xs md:text-sm w-3/4 md:w-1/2">
    Do you need accessible seating?

    <span class="inline-block align-middle">
      <i class="w-3 h-3">
        <img src="/assets/img/accessible-icon.svg" alt="" />
      </i>
    </span>
  </h5>

  <!-- BUTTON & SELECTOR -->
  <div class="w-auto flex">
    <!-- BUTTON -->
    <ng-container *ngIf="!showSelector; else seatSelector">
      <button
        data-cy="toggle-ada-seat-selector"
        (click)="toggleSelector()"
        class="btn-main-reverse h-9 flex justify-center items-center hover:bg-main hover:text-white transition-colors w-min md:w-28 px-3">
        <span>Yes,</span>
        <span class="hidden md:inline pl-1">add accessible seating</span>
        <span class="inline md:hidden pl-1">show</span>
      </button>
    </ng-container>

    <!-- SELECTOR -->
    <ng-template #seatSelector>
      <app-selector
        [useCaseId]="'ada-seat-selection'"
        [initialValue]="selectedSeats"
        [maximumValue]="ticketCounter"
        [debugMode]="debugMode"
        [inputColor]="'black'"
        [showIcon]="false"
        [reverseColors]="true"
        (valueChange)="updateTotalSeats($event)" />

      <button
        (click)="toggleSelector()"
        class="btn-icon bg-red ml-2 rotate-45 w-8 h-8 transition-transform hover:scale-95 duration-150 ease-in">
        <i class="w-6 h-auto">
          <img src="/assets/img/plus-symbol.svg" alt="close symbol" />
        </i>
      </button>
    </ng-template>
  </div>
</div>
