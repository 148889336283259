import {
  Configuration,
  Custom,
  DvmData,
  DvmStyles,
  Theme,
} from '../models/configuration.model';

const custom: Custom = {
  paymentPage: {
    captureAddress: false,
    capturePhone: true,
  },
};

const theme: Theme = {
  'default-color': '23 52 130',
  'main-color': '23 52 130',
  'secondary-color': '25 149 255',
  'tertiary-color': '242 246 250',
  'accent-color': '189 48 57',
  red: '189 48 57',
  success: '86 163 67',
  'success-off-white': '236 246 232',
};

const dvmData: DvmData = {
  venueId: 'nam-us-00099-chicagocubs',
  mapId: 'main_venuemap',
  miniMapId: 'main_venuemap_minimap',
  miniMapEnabled: true,
  gaSectorsIdArray: [],
  gaAdaIdArray: [],
};

const dvmStyles: DvmStyles = {
  'seat-available-none-fill': '25 149 255',
  'seat-available-none-stroke': '0 90 156',
  'seat-available-disabled-fill': '25 149 255',
  'seat-available-disabled-stroke': '0 90 156',
  'seat-unavailable-none': '214 214 214',
  'seat-available-pending': '230 145 56',
  'section-available-normal-fill': '25 149 255',
  'section-available-normal-stroke': '0 90 156',
  'section-available-normal-group1-fill': '255 77 77',
  'section-available-normal-group1-stroke': '255 77 77',
  'section-available-hover-fill': '0 90 156',
  'section-available-hover-stroke': '255 255 255',
  'section-selected-normal-fill': '122 64 185',
  'section-selected-normal-stroke': '122 64 185',
  'section-selected-hover-stroke': '255 255 255',
};

export const ChicagoCubsSpring: Configuration = {
  id: 'chicagocubsspring',
  clientName: 'Chicago Cubs Spring',
  appName: 'Chicago Cubs Spring',
  favicon: '/assets/img/cubsspring/cubs_favicon.ico',
  logo: '/assets/img/logo.svg',
  phone: '',
  email: 'groupsales@cubs.com',
  paymentMethod: 'braintree',
  rainout: false,
  clientWebUrl: 'https://www.cubs.com/',
  clientWebText: 'www.cubs.com',
  clientBallParkUrl: 'https://www.mlb.com/cubs/apps/ballpark',
  clientBallParkText: 'cubs.com/BallparkApp',
  mlbShopUrl:
    'https://www.mlbshopeurope.com/en/chicago-cubs/t-36228622+z-808474-1125435766',
  merchandiseWebUrl: '',
  rainoutInsuranceUrl: '',
  termsAndConditionsUrl:
    'https://www.mlb.com/cubs/tickets/terms-and-conditions',
  analytics: '',
  adas: true,
  theme,
  dvmData,
  dvmStyles,
  tutorial: {
    desktop: {
      1: {
        stepNum: 1,
        img: '/assets/img/redsox/tutorial/1.jpg',
        language: 'Start by selecting the total number of seats to purchase.',
        arrowPosition: 'top',
      },
    },
    mobile: {},
  },
  custom,
  extraCheckbox: {
    active: false,
    required: false,
    text: '',
  },
  summaryPage: {
    image1: '',
    image2: '',
  },
};
