import { Component, OnInit } from '@angular/core';
import { Tutorial, TutorialStep } from '../shared/models';
import { UtilitiesService } from '../shared/services';
import { ConfigurationService } from '../shared/services/configuration.service';
import { TutorialService } from '../shared/services/tutorial.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
})
export class TutorialComponent implements OnInit {
  isMobile: boolean;

  actualStep: TutorialStep;
  totalSteps: number;
  tutorialSteps: Tutorial = this.configurationService.configuration.tutorial.desktop;
  tutorialStepsMobile: Tutorial = this.configurationService.configuration.tutorial.mobile;

  popover: HTMLElement = document.querySelector('.tutorialPopover')!;

  constructor(
    private tutorialService: TutorialService,
    private configurationService: ConfigurationService,
    private utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;
    if (this.isMobile) {
      this.tutorialSteps = this.tutorialStepsMobile;
    }

    this.actualStep = this.tutorialSteps[1];
    // console.log('Actual Step (Init): ', this.actualStep);
    this.totalSteps = Object.keys(this.tutorialSteps).length;

    //* Tooltip for Popper Library, future use
    // const tooltip: HTMLElement = document.querySelector('#tooltip');
    // // Initiate popover
    // this.popperInstance = createPopper(node, this.tooltip, {
    //   placement,
    //   modifiers: [
    //     {
    //       name: 'offset',
    //       options: {
    //         offset: [0, 8],
    //       },
    //     },
    //     {
    //       name: 'flip',
    //       options: {
    //         fallbackPlacements: [],
    //       }
    //     }
    //   ],
    // });
    // // Display popover
    // this.showPopover();
  }

  stepNext() {
    if (this.tutorialSteps[this.actualStep.stepNum + 1]) {
      this.actualStep = this.tutorialSteps[this.actualStep.stepNum + 1];
    }
    console.log('Actual Step (next): ', this.actualStep);
  }

  stepBack() {
    if (this.tutorialSteps[this.actualStep.stepNum - 1]) {
      this.actualStep = this.tutorialSteps[this.actualStep.stepNum - 1];
    }
    console.log('Actual Step (back): ', this.actualStep);
  }

  exitTutorial() {
    this.tutorialService.openTutorialExit();
    this.actualStep = this.tutorialSteps[1];
  }

  //* Tooltip for Popper Library, future use
  // // Display popover
  // showPopover() {
  //   this.popover.setAttribute('data-show', '');
  // }
  // // Remove popover
  // hidePopover() {
  //   this.popover.removeAttribute('data-show');
  // }
  // changeStep() {
  //   popperInstance.update();
  // }
}
