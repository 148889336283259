import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DvmModule, MapViewerService } from '@3ddv/ngx-dvm-internal';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TitleStrategy } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSliderModule } from 'ngx-slider-v2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SeatPopoverComponent } from './digital-venue/popovers/seat-popover/seat-popover.component';
import { SectionPopoverComponent } from './digital-venue/popovers/section-popover/section-popover.component';
import { EventLandingComponent } from './event-landing/event-landing.component';
import { SaleEventComponent } from './event-landing/sale-event/sale-event.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { PaginationModule } from './layout/pagination/pagination.module';
import { LoginComponent } from './login/login.component';
import { OktaIntegrationModule } from './okta-integration/okta-integration.module';
import { PaymentComponent } from './payment/payment.component';
import { OldCartComponent } from './seat-selection/cart/cart.component';
import { DvmComponent } from './seat-selection/dvm/dvm.component';
import { OldFiltersComponent } from './seat-selection/filters/filters.component';
import { SeatSelectionComponent } from './seat-selection/seat-selection.component';
import { UpsellsComponentOld } from './seat-selection/upsells/upsellsOld.component';
import { SelectSeatComponent } from './select-seat/select-seat.component';
import { CartComponent } from './select-seat/sidebar/cart/cart.component';
import { CartAccordionComponent } from './select-seat/sidebar/cart/partials/cart-accordion/cart-accordion.component';
import { CartHeaderComponent } from './select-seat/sidebar/cart/partials/cart-header/cart-header.component';
import { CartItemComponent } from './select-seat/sidebar/cart/partials/cart-item/cart-item.component';
import { FiltersComponent } from './select-seat/sidebar/filters/filters.component';
import { AccesibleSeatingComponent } from './select-seat/sidebar/filters/partials/accesible-seating/accesible-seating.component';
import { BestSeatsComponent } from './select-seat/sidebar/filters/partials/best-seats/best-seats.component';
import { PriceRangeComponent } from './select-seat/sidebar/filters/partials/price-range/price-range.component';
import { SelectTicketsComponent } from './select-seat/sidebar/filters/partials/select-tickets/select-tickets.component';
import { SelectorComponent } from './select-seat/sidebar/filters/partials/selector/selector.component';
import { SidebarComponent } from './select-seat/sidebar/sidebar.component';
import { TotalComponent } from './select-seat/sidebar/total/total.component';
import { MapLoaderComponent } from './select-seat/viewer/map-loader/map-loader.component';
import { MapControlsComponent } from './select-seat/viewer/topbar/map-controls/map-controls.component';
import { TopbarComponent } from './select-seat/viewer/topbar/topbar.component';
import { UpsellsComponent } from './select-seat/viewer/topbar/upsells/upsells.component';
import { ViewerComponent } from './select-seat/viewer/viewer.component';
import { MultiselectionToastComponent } from './shared/components/multiselection-toast/multiselection-toast.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { TimerComponent } from './shared/components/timer/timer.component';
import { NumbersInputDirective } from './shared/directives/numbers-input.directive';
import { EnvironmentInterceptor } from './shared/interceptors/environment.interceptor';
import { GaModalComponent } from './shared/modals/ga-modal/ga-modal.component';
import { ModalsComponent } from './shared/modals/modals.component';
import { RestrictedSeatsModalComponent } from './shared/modals/restricted-seats-modal/restricted-seats-modal.component';
import { SeatDefinitionsModalComponent } from './shared/modals/seat-definitions-modal/seat-definitions-modal.component';
import { TutorialWelcomeModalComponent } from './shared/modals/tutorial-welcome-modal/tutorial-welcome-modal.component';
import { ConfigurationService } from './shared/services/configuration.service';
import { TemplatePageTitleStrategyService } from './shared/services/template-page-title-strategy.service';
import { SummaryComponent } from './summary/summary.component';
import { TutorialComponent } from './tutorial/tutorial.component';

/**
 * Initializes application with certain parameters fetched from the URL and backend.
 * It's intended to run before the application fully bootstraps.
 * @param http - The HTTP client used for making the request.
 * @returns A function that returns a promise, signaling the app initialization completion.
 */
function initializeApp(configurationService: ConfigurationService): () => void {
  return () => configurationService.init();
}

const appInitObject = {
  provide: APP_INITIALIZER,
  useFactory: initializeApp,
  deps: [ConfigurationService],
  multi: true,
};

const environmentInterceptorObject = {
  provide: HTTP_INTERCEPTORS,
  useClass: EnvironmentInterceptor,
  multi: true,
};

const titleStrategy = {
  provide: TitleStrategy,
  useClass: TemplatePageTitleStrategyService,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    OldFiltersComponent,
    OldCartComponent,
    SeatSelectionComponent,
    CheckoutComponent,
    PaymentComponent,
    SummaryComponent,
    LayoutComponent,
    DvmComponent,
    LoginComponent,
    ModalsComponent,
    SeatDefinitionsModalComponent,
    GaModalComponent,
    RestrictedSeatsModalComponent,
    SectionPopoverComponent,
    SeatPopoverComponent,
    TimerComponent,
    UpsellsComponentOld,
    NotFoundComponent,
    NumbersInputDirective,
    TutorialComponent,
    EventLandingComponent,
    SaleEventComponent,
    FooterComponent,
    TutorialWelcomeModalComponent,
    SelectSeatComponent,
    SidebarComponent,
    FiltersComponent,
    SelectTicketsComponent,
    PriceRangeComponent,
    SelectorComponent,
    AccesibleSeatingComponent,
    BestSeatsComponent,
    CartComponent,
    CartHeaderComponent,
    CartItemComponent,
    TotalComponent,
    ViewerComponent,
    MapLoaderComponent,
    CartAccordionComponent,
    TopbarComponent,
    UpsellsComponent,
    MapControlsComponent,
    MultiselectionToastComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    LoadingBarHttpClientModule,
    LoadingBarModule,
    PaginationModule,
    NgxSliderModule,
    OktaIntegrationModule,
    DvmModule,
  ],
  providers: [
    titleStrategy,
    appInitObject,
    environmentInterceptorObject,
    MapViewerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
