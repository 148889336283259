import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { SeatManagementService } from 'src/app/digital-venue/services/seat-management.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { Seat, SeatsBySection } from 'src/app/shared/models';
import { SeatDetails } from 'src/app/shared/models/seat-availability.model';
import { AvailabilityService } from 'src/app/shared/services';
import { DvmService } from 'src/app/shared/services/dvm.service';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrl: './cart-item.component.scss',
})
export class CartItemComponent {
  // INPUTS & OUTPUTS
  @Input()
  public mode!: 'section' | 'seat';

  @Input()
  public data!: SeatsBySection | Seat;

  @Input()
  public showSeats: boolean = false;

  @Output()
  public toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  //SERVICES
  private dvmService: DvmService = inject(DvmService);
  private seatManagementService: SeatManagementService = inject(
    SeatManagementService
  );
  private availabilityService: AvailabilityService =
    inject(AvailabilityService);
  private modalService: ModalsService = inject(ModalsService);

  // GETTERS
  private get section(): SeatsBySection | null {
    return this.mode === 'section' ? (this.data as SeatsBySection) : null;
  }

  private get seat(): Seat | null {
    return this.mode === 'seat' ? (this.data as Seat) : null;
  }

  public get sectionVars(): {
    section: string;
    seats: number;
    price: number;
  } | null {
    return this.section
      ? {
          section: this.section.original_id.split('_')[1].split('-')[0],
          seats: this.section.totalSectionSeats,
          price: this.section.totalSectionPrice,
        }
      : null;
  }

  public get seatVars(): {
    section: string;
    row: string;
    seat: string;
    price: number;
    availability: SeatDetails;
  } | null {
    return this.seat
      ? {
          section: this.seat.section.split('_')[1],
          row: this.seat.seat_row,
          seat: this.seat.seat,
          price: this.seat.price,
          availability: this.getSeatAvailability(),
        }
      : null;
  }

  public toggleSeats(): void {
    if (this.mode === 'section') {
      this.toggle.emit(this.showSeats);
    }
    return;
  }

  // PUBLIC COMPONENT METHODS
  public removeSection(): void {
    if (this.mode === 'section') {
      const sectionData: SeatsBySection = this.data as SeatsBySection;
      const nodeIds: { id: string; originalId: string }[] = Object.values(
        sectionData.seats
      ).map(seat => ({ id: seat.id, originalId: seat.original_id }));

      this.seatManagementService.unselectSelectedSeats(nodeIds);
    }

    return;
  }

  public removeSeat(): void {
    if (this.mode === 'seat') {
      const seatData = this.data as Seat;
      this.seatManagementService.unselectSelectedSeats([
        { id: seatData.id, originalId: seatData.original_id },
      ]);
    }
    return;
  }

  //DVM METHODS

  /**
   * Se encarga de mostrar el hover en el mapa dependiendo del tipo de item
   *
   * Si es Seccion:
   * Comprueba que esté en la top view y hace hover en la sección correspondiente.
   *
   * Si es Asiento:
   * Comprueba si está en la top view, si lo está hace hover a la seción a la que pertenece el asiento,
   * si no, hace hover en el asiento en el seatmap.
   */
  public onHover(): void {
    const isTopView: boolean = this.dvmService.isTopView();

    switch (this.mode) {
      case 'section':
        if (isTopView) {
          this.dvmService.viewerService.hover([this.section.id]);
        }

        break;

      case 'seat':
        if (isTopView) {
          const sectionId: string = this.seat.id.split('-')[0];
          this.dvmService.viewerService.hover([sectionId]);
        } else {
          this.dvmService.viewerService.hover([this.seat.id]);
        }

        break;
    }
  }

  /**
   * Remueve el hover del mapa
   */
  public onLeave(): void {
    this.dvmService.viewerService.hover([]);
  }

  public goToSection(): void {
    if (!this.dvmService.viewerService.isLoaded()) {
      return;
    }

    const sectionId: string = this.section.id;
    const actualSection: string = this.dvmService.viewerService.getMapId();

    if (sectionId != actualSection) {
      this.dvmService.openSeatMap(sectionId).subscribe();
    }
  }

  public openSeatDefinition(): void {
    const seatInfo = this.availabilityService.getSeatByIdFromAvailability(
      this.seat.id
    );
    const dvmSeat = this.dvmService.viewerService.getNodeById(this.seat.id);

    this.modalService.openSeatDefinitionModal(seatInfo, dvmSeat.original_id);
  }

  public open3dView(): void {
    this.dvmService.load3dView(this.seat.id);
  }

  // PRIVATE COMPONENT METHODS
  protected hasSeatDefinition(): 'info' | 'adas' | null {
    const seatAvailability: SeatDetails = this.seatVars?.availability;

    if (seatAvailability.seatDefinition.length) {
      return 'info';
    } else if (
      seatAvailability.isAda &&
      !seatAvailability.seatDefinition.length
    ) {
      return 'adas';
    } else {
      return null;
    }
  }

  private getSeatAvailability(): SeatDetails {
    const sectionId: string = this.seat.id.split('-')[0];
    const { isAda, seatDefinition } =
      this.availabilityService.seatsAvailability[sectionId][this.seat.id];

    return { isAda, seatDefinition };
  }
}
