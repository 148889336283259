<!-- MAIN VIEW -->
<main
  class="h-full w-screen bg-gray-900 overflow-hidden flex flex-col lg:flex-row-reverse">
  <section
    class="w-full lg:h-full lg:w-[75vw] transition-all ease-in-out duration-700"
    [ngClass]="showTabsOnMobile ? 'h-1/2' : 'h-[calc(100%-3rem)]'">
    <app-viewer
      map
      *ngIf="availability"
      [baseAvailability]="availability"
      [debugMode]="debugger" />
  </section>

  <!-- h-12 = 3rem -->
  <aside
    class="w-full lg:h-full lg:w-[25vw] transition-all ease-in-out duration-700"
    [ngClass]="showTabsOnMobile ? 'h-1/2' : 'h-12'">
    <app-sidebar
      sidebar
      [debugMode]="debugger"
      [isTabsOpen]="showTabsOnMobile"
      (toggleTabs)="toggleTabsOnMobile()" />
  </aside>
</main>

<!-- TUTORIAL VIEW -->
<ng-container *ngIf="tutorialService.isTutorialOpen">
  <div id="tutorial" class="w-full h-full absolute top-0 left-0 z-50">
    <app-tutorial />
  </div>
</ng-container>
