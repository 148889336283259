<div
  class="flex h-1/3 px-4 xs:px-12 sm:px-24 md:px-36 lg:px-4 py-2 sm:py-4 lg:py-2 items-center justify-between flex-col lg:flex-row">
  <h5 class="font-normal text-xs text-gray-500 md:text-gray-700 pb-2 lg:pb-0">
    {{ templateVars.text }}
  </h5>

  <button
    data-cy="best-seats-button"
    class="btn-main h-9 disabled:opacity-30 transition-transform hover:scale-95 disabled:hover:transform-none"
    [tooltip]="templateVars.tooltip"
    [disabled]="!enableButton || isMapLoading"
    [tooltipEnable]="!enableButton || isMapLoading"
    (click)="startAddBestSeats()">
    <span>
      {{ templateVars.button }}
    </span>
  </button>
</div>
