import { MapViewerService } from '@3ddv/ngx-dvm-internal';
import {
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import anime from 'animejs/lib/anime.es.js';
import { skip, Subscription, tap } from 'rxjs';
import { UtilitiesService } from 'src/app/shared/services';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { DvmService } from 'src/app/shared/services/dvm.service';

@Component({
  selector: 'app-map-loader',
  templateUrl: './map-loader.component.html',
  styleUrl: './map-loader.component.scss',
})
export class MapLoaderComponent implements OnInit, OnDestroy {
  // VIEW CHILDS
  @ViewChild('container')
  public container: ElementRef;

  @ViewChild('pillContainer')
  public pillContainer: ElementRef;

  @ViewChild('pill')
  public pill: ElementRef;

  // SERVICES
  private utilities: UtilitiesService = inject(UtilitiesService);
  private viewerService: MapViewerService = inject(DvmService).viewerService;
  private readonly handlers: Subscription[] = [];

  // DATA
  protected logo: string = inject(ConfigurationService).configuration.logo;
  protected color: string =
    inject(ConfigurationService).configuration.theme['accent-color'];
  protected varName: string = '--ripple-color';

  // ANIMATION
  private duration: number = 500;
  private isFirstLoad: boolean = true;

  // GETTERS
  /**
   * Retorna la variable CSS para el color del ripple.
   * Necesitamos setear la variable css --ripple-color con el color accent de la configuración.
   * Como este valor originalmente viene sin comas para ser usado en Tailwind, debemos reemplazar los espacios por comas.
   * Finalmente retornamos el valor y lo implementamos en el div contenedor del ripple para ser usado por el scss.
   */
  public get ripple(): string {
    return `${this.varName}:${this.utilities.formatRgb(this.color, false)};`;
  }

  // LIFE CYCLE
  public ngOnInit(): void {
    this.initCompoenent();
  }

  public ngOnDestroy(): void {
    this.destroyComponent();
  }

  // METHODS

  /**
   * Muestra el loader.
   * Mediante una animación de animejs, mostramos el loader.
   * Al comenzar, verificará si el contenedor tiene la clase hidden y la removerá si es así.
   */
  private showLoader(): void {
    const containerClasses: string =
      this.container.nativeElement.classList.value;

    if (containerClasses.includes('hidden')) {
      this.container.nativeElement.classList.remove('hidden');
    }

    anime
      .timeline({
        duration: this.duration,
        easing: 'easeInCubic',
      })
      .add({
        targets: this.pillContainer.nativeElement,
        opacity: [0, 1],
        duration: 100,
      })
      .add(
        {
          targets: this.pill.nativeElement,
          scale: [0, 1],
          opacity: [0, 1],
        },
        '+=10'
      );
  }

  /**
   * Oculta el loader.
   * Cuando termina aplica la clase hidden al contenedor para ocultarlo.
   */
  private hideLoader(): void {
    anime
      .timeline({
        delay: 400,
        duration: this.duration,
        easing: 'easeOutQuad',
        complete: () => this.container.nativeElement.classList.add('hidden'),
      })
      .add({
        targets: this.pill.nativeElement,
        opacity: [1, 0],
        scale: [1, 0],
      })
      .add({
        targets: this.pillContainer.nativeElement,
        opacity: [1, 0],
      });
  }

  private handleLoadSuccess(): void {
    if (this.isFirstLoad) {
      this.hideLoader();
      return;
    }

    setTimeout(() => this.hideLoader(), this.duration);
  }

  /**
   * Inicializa el componente y sus handlers.
   * Haciendo uso de waitInitialize, esperamos a que el mapa se inicialice para poder suscribirnos a los eventos de carga del mapa.
   * Excepto en la carga inicial del mapa y para evitar superponer animaciones, skipeamos la primera carga y esperamos a que el mapa se recargue
   * para futuras ocasiones y delegamos en los handlers start y success para mostrar u ocultar el loader respectivamente.
   */
  private initCompoenent(): void {
    this.viewerService.waitInitialize().subscribe(() => {
      this.handlers.push(
        // LOAD START: Skipeamos la primera vez para evitar saltos de animacion y esperamos a si el mapa vuelve a cargar.
        this.viewerService
          .getObservable('load_start')
          .pipe(skip(1))
          .subscribe(() => this.showLoader()),

        // LOAD SUCCESS: Ocultamos el loader cuando el mapa termina de cargar. Esto ocurre siempre que el mapa se recargue.
        this.viewerService
          .getObservable('load_success')
          .pipe(
            tap(() => (this.isFirstLoad ? (this.isFirstLoad = false) : null))
          )
          .subscribe(() => this.handleLoadSuccess())
      );
    });
  }

  /**
   * Destruye el componente y sus handlers.
   * Recorre el array de handlers y se desuscribe de cada uno de ellos.
   */
  private destroyComponent(): void {
    this.handlers.forEach(h => h.unsubscribe());
  }
}
