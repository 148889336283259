import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';

@Component({
  selector: 'app-select-tickets',
  templateUrl: './select-tickets.component.html',
  styleUrl: './select-tickets.component.scss',
})
export class SelectTicketsComponent implements OnInit {
  // INPUT DATA
  @Input({ required: true })
  public minSeats!: number | undefined;

  @Input({ required: true })
  public maxSeats!: number | undefined;

  @Input()
  public debugMode: boolean = false;

  // EVENT EMITTER
  @Output()
  public updateSelectedSeats: EventEmitter<number> = new EventEmitter<number>();

  //STATE
  protected totalSeats: WritableSignal<number> = signal(this.minSeats ?? 0);

  // LIFECYCLE HOOKS
  ngOnInit(): void {
    this.initComponent();
  }

  //METHODS

  /**
   * Actualiza el total de asientos.
   * @param { number } value
   */
  public updateTotalSeats(value: number): void {
    if (this.debugMode) {
      console.log('updateTotalSeats', value);
    }

    this.totalSeats.set(value);
    this.updateSelectedSeats.emit(this.totalSeats());
  }

  /**
   * Valida los inputs
   * Comprueba que estan establecidos los inputs y que el menor sea menor que el mayor.
   */
  private validateInputs(): void {
    // Validar que el minimo sea menor que el maximo
    if (this.minSeats && this.maxSeats && this.minSeats > this.maxSeats) {
      throw new Error('MinSeats should be less than maxSeats');
    }
  }

  /**
   * Inicializa el componente
   * Valida los inputs y si el modo debug esta activado imprime en consola los valores de min y max
   */
  private initComponent(): void {
    this.validateInputs();

    if (this.debugMode) {
      console.log(
        'Select Tickets Component ',
        'Min: ' + this.minSeats,
        'Max: ' + this.maxSeats
      );
    }
  }
}
