{
  "name": "group-sales-portal",
  "version": "2.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration development",
    "start:local": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration local",
    "start:pre": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration preproduction",
    "start:dev": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration development",
    "start:prod": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration production",
    "start:ssl": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' ",
    "start:local:ssl": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration local --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' ",
    "start:pre:ssl": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration preproduction",
    "start:pre:astros": "ng serve --host local.houstonastros.3ddigitalvenue.com --port 4200 --open --configuration preproduction",
    "start:pre:redsox": "ng serve --host local.redsox.3ddigitalvenue.com --port 4200 --open --configuration preproduction",
    "build": "ng build",
    "build-dev": "ng build --configuration development --aot --output-hashing=all",
    "build-pro": "ng build --configuration production --aot --output-hashing=all",
    "build-pre": "ng build --configuration preproduction --aot --output-hashing=all",
    "serve:local": "angular-http-server --path dist/group-sales-portal/ --host local.3ddigitalvenue.com -p 4200 --silent",
    "cy:local": "cypress open -C cypress/config/cypress.local.js --e2e",
    "cy:local:run": "npm run serve:local & cypress run -C cypress/config/cypress.local.js",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@3ddv/dvm-internal": "~2.4.0",
    "@3ddv/ngx-dvm-internal": "^2.2.0",
    "@angular/animations": "^17.3.11",
    "@angular/common": "^17.3.11",
    "@angular/compiler": "^17.3.11",
    "@angular/core": "^17.3.11",
    "@angular/forms": "^17.3.11",
    "@angular/platform-browser": "^17.3.11",
    "@angular/platform-browser-dynamic": "^17.3.11",
    "@angular/router": "^17.3.11",
    "@ngx-loading-bar/core": "^6.0.2",
    "@ngx-loading-bar/http-client": "^6.0.2",
    "@okta/okta-angular": "^6.3.2",
    "@okta/okta-auth-js": "^7.5.0",
    "@popperjs/core": "^2.11.8",
    "@types/animejs": "^3.1.12",
    "animejs": "^3.2.2",
    "cypress-plugin-snapshots": "^1.4.4",
    "ngx-bootstrap": "~12.0.0",
    "ngx-device-detector": "~7.0.0",
    "ngx-slider-v2": "~17.0.0",
    "npn-slider": "^1.3.2",
    "rxjs": "^7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular-eslint/builder": "17.2.1",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/schematics": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.11",
    "@testing-library/cypress": "^10.0.2",
    "@types/braintree-web-drop-in": "^1.39.3",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^20.11.5",
    "@typescript-eslint/eslint-plugin": "6.19.0",
    "@typescript-eslint/parser": "6.19.0",
    "angular-http-server": "^1.12.0",
    "autoprefixer": "^10.4.17",
    "cypress": "^13.15.0",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "~8.4.18",
    "prettier": "^3.2.5",
    "prettier-eslint": "^16.3.0",
    "rimraf": "~5.0.5",
    "tailwindcss": "^3.3.3",
    "typescript": "~5.3.3",
    "zod": "^3.22.4"
  }
}
