<div class="w-full h-full flex items-center">
  <!-- MAP CONTROLS -->
  <div class="flex-1">
    <app-map-controls />
  </div>

  <!-- UPSELLS -->
  <div class="flex-initial">
    <app-upsells />
  </div>
</div>
