import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-accesible-seating',
  templateUrl: './accesible-seating.component.html',
  styleUrl: './accesible-seating.component.scss',
})
export class AccesibleSeatingComponent implements OnChanges {
  // INPUT DATA

  @Input({ required: true })
  public ticketCounter: number = 0; // Cantidad de tickets seleccionados

  @Input()
  public debugMode: boolean = false;

  // OUTPUTS
  @Output()
  public updateAccessibleSeats: EventEmitter<number> =
    new EventEmitter<number>();

  // STATE
  public showSelector: boolean = false;
  public selectedSeats: number = 0;

  // LIFE CYCLE
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ticketCounter']) {
      this.checkTicketCounter();
    }
  }

  // METHODS

  /**
   * Muestra o esconde el selector de asientos accesibles
   */
  public toggleSelector(): void {
    this.showSelector = !this.showSelector;
  }

  /**
   * Update Total Seats
   * Emite el valor de asientos accesibles seleccionados.
   * Si el modo debug está activado, se mostrará en consola el valor.
   * @param {number} value
   */
  public updateTotalSeats(value: number): void {
    this.selectedSeats = value;

    this.updateAccessibleSeats.emit(this.selectedSeats);

    if (this.debugMode) {
      console.log('Accesible Seat Update', this.selectedSeats);
    }
  }

  private checkTicketCounter(): void {
    if (this.selectedSeats > this.ticketCounter) {
      this.selectedSeats = this.ticketCounter;
    }
  }
}
