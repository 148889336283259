import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy } from '@angular/router';
import { ConfigurationService } from './configuration.service';
// import { APP_NAME } from 'src/app/constants';

/**
 * Service for setting the page title dynamically based on the router state. It extends Angular's TitleStrategy to
 * integrate with the Router's lifecycle and allows setting of a custom title as the user navigates through the app.
 *
 * // The service works automatically with the router to update the title based on router state.
 * // It can be further customized by uncommenting the APP_NAME import and usage below.
 *
 * @method updateTitle - Updates the page title when navigation ends.
 */
@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategyService extends TitleStrategy {
  private readonly title = inject(Title);
  private readonly configurationService = inject(ConfigurationService);

  override updateTitle() {
    const appName =
      this.configurationService?.configuration?.appName ?? 'Local';
    this.title.setTitle(appName + ' Online Group Sales');
  }
}
