<div class="w-full rounded-2.5xl">
  <!-- Header -->
  <div
    class="border-b border-gray-300 text-center text-gray-600 px-12 py-6 md:p-12">
    <h1 class="text-xl md:text-3xl xl:text-4xl" [innerHTML]="title"></h1>
  </div>
  <!-- <hr> -->
  <!-- Body -->
  <div id="ScrollBox" class="antialiased masked px-12 py-6 md:px-24">
    <h4 class="text-gray-600 font-poppins pt-5">
      The following seats have restrictions, check for them:
    </h4>
    <ul
      class="mt-2 mb-1 pb-3"
      style="
        list-style-type: disc;
        padding-left: 20px;
        max-height: 400px;
        overflow-y: auto;
      ">
      <li *ngFor="let seat of seats">
        <span>
          Section: {{ seat.section.split('_')[1] }} Row:
          {{ seat.seat_row }} Seat:
          {{ seat.seat }}
        </span>
        <ul style="list-style-type: circle; padding-left: 20px">
          <ng-container *ngFor="let esd of seat.definitions">
            @if (esd.restriction) {
              <li>
                <span>
                  {{ esd.description }}
                </span>
              </li>
              <br />
            }
          </ng-container>
        </ul>
      </li>
    </ul>
  </div>
  <div
    class="border-t border-gray-300 text-center px-12 py-6 md:px-24 md:py-12">
    <div class="flex justify-center">
      <button (click)="close()" class="btn-main mr-3 md:mr-6">Close</button>
      <button (click)="continue()" class="btn-main mr-3 md:mr-6">
        Continue
      </button>
    </div>
  </div>
</div>
