<div class="w-full rounded-2.5xl">
  <!-- Header -->
  <div
    class="border-b border-gray-300 flex justify-around items-center px-12 py-6 md:py-4 flex-col md:flex-row">
    <h1 class="text-gray-500 text-xl md:text-3xl" [innerHTML]="title"></h1>
    <h3
      class="bg-main-accent rounded-xl px-4 py-1 text-white text-sm mt-2 md:mt-0">
      Section: {{ seatSection }} &nbsp; Row: {{ seatRow }} &nbsp; Seat:
      {{ seat }}
    </h3>
  </div>
  <!-- <hr> -->
  <!-- Body -->
  <div class="px-12 py-6 md:px-14 md:py-4">
    <h4 class="text-gray-500">Information about the seat:</h4>
    <ul
      class="mt-2 font-poppins text-base font-medium text-gray-800"
      style="list-style-type: disc; padding-left: 20px">
      <li class="[&:not(:last-child)]:my-2" *ngFor="let item of definitions">
        {{ item.description }}
      </li>
      @if (isAda) {
        <li>The seat is ADA.</li>
      }
    </ul>
    <div class="flex justify-center mt-6 md:mt-12">
      <button
        (click)="close()"
        class="btn-main transition-transform ease-in-out hover:scale-95 hover:shadow hover:shadow-main/50 mr-3 md:mr-6">
        Close
      </button>
    </div>
  </div>
</div>
