import { MapViewerService } from '@3ddv/ngx-dvm-internal';
import {
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  Signal,
  ViewChild,
} from '@angular/core';
import anime from 'animejs/lib/anime.es.js';
import { Subscription } from 'rxjs';
import { Upsell } from 'src/app/shared/models';
import { CartService } from 'src/app/shared/services';
import { SelectedSeats } from 'src/app/shared/services/cart.service';
import { DvmService } from 'src/app/shared/services/dvm.service';
import { UpsellsService } from './upsells.service';

@Component({
  selector: 'app-upsells',
  templateUrl: './upsells.component.html',
  styleUrl: './upsells.component.scss',
})
export class UpsellsComponent implements OnInit, OnDestroy {
  // VIEWCHILD
  @ViewChild('benefitsContainer')
  public benefitsContainer: ElementRef<HTMLDivElement>;

  // SERVICES
  private upsellsService: UpsellsService = inject(UpsellsService);
  private cartService: CartService = inject(CartService);
  private viewer: MapViewerService = inject(DvmService).viewerService;

  // STATE
  protected is3dOpen: Signal<boolean> = inject(DvmService).is3dView;
  protected showBenefits: boolean = false;
  protected showUpsells: boolean = false;
  protected totalCart: number = 0;

  protected get upsellsVars(): {
    upsells: Upsell[];
    reachingUpsell: Upsell | null;
    acquiredUpsell: Upsell | null;
    availableUpsells: Upsell[];
    acquiredUpsells: Upsell[];
  } {
    return {
      upsells: this.upsellsService.upsells,
      reachingUpsell: this.upsellsService.reachingUpsell,
      acquiredUpsell: this.upsellsService.acquiredUpsell,
      availableUpsells: this.upsellsService.availableUpsells,
      acquiredUpsells: this.upsellsService.acquiredUpsells,
    };
  }

  // SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  // TEMPLATE VARS
  public templateVars: { text: string } = {
    text: 'Group Benefits',
  };

  // LIFE CYCLE HOOKS
  public ngOnInit(): void {
    this.initComponent();
  }

  public ngOnDestroy(): void {
    this.destroyComponent();
  }

  // METHODS
  public toggleBenefits(): void {
    this.showBenefits = !this.showBenefits;

    const container: HTMLDivElement = this.benefitsContainer.nativeElement;

    anime({
      targets: container,
      scale: this.showBenefits ? 1 : 0,
      easing: 'easeInOutQuad',
      duration: 330,
    });
  }

  /**
   * Se llama en cada carga del mapa, si la propiedad showUpsells es falsa,
   * se muestra el componente de upsells de aqui en adelante. Si ya estaba siendo mostrado se deja igual.
   *
   * Si los benefits estan abiertos en la carga del mapa, se cierran.
   */
  private handleLoad(): void {
    // SI NO HAY UPSELLS NO MOSTRAMOS NADA
    if (this.upsellsService.upsells.length === 0) {
      return;
    }

    if (!this.showUpsells) {
      this.showUpsells = true;
    }

    if (this.showBenefits) {
      this.toggleBenefits();
    }
  }

  private initComponent(): void {
    // Nos suscribimos al viewer para saber cuando sucede una carga en el mapa y lanzamos handleLoad()
    this.viewer.waitInitialize().subscribe({
      next: () => {
        const load = this.viewer
          .getObservable('load_success')
          .subscribe(() => this.handleLoad());
        this.subscriptions.push(load);
      },
    });

    // Nos suscribimos al servicio de carrito para saber cuando se seleccionan asientos y lanzamos checkCartSeatsForUpsellsList()
    this.cartService.selectedSeats$.subscribe({
      next: (selectedSeats: SelectedSeats) => {
        const totalCartSeats = Object.keys(selectedSeats).length;
        this.upsellsService.checkCartSeatsForUpsellsList(totalCartSeats);
        this.upsellsService.checkTotalSeatsForUpsellsMessages(totalCartSeats);
      },
      error: error => console.error(error),
    });

    this.upsellsService.totalCartAndFiltersSeats$.subscribe({
      next: (response: number) => {
        this.totalCart = response;
        this.upsellsService.checkTotalSeatsForUpsellsMessages(this.totalCart);
      },
    });
  }

  private destroyComponent(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
